import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import carousel1 from "../assets/AboutUs/carousel1.jpg";
import carousel2 from "../assets/AboutUs/carousel2.jpg";
import carousel3 from "../assets/AboutUs/carousel3.jpg";
import carousel4  from "../assets/AboutUs/carousel4.png";

export default function LearnMoreCarousel() {
const responsive = {

    superLargeDesktop: {
      
      breakpoint: { max: 4000, min: 2600 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 2250, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className='app'>
        
    
        <Carousel responsive={responsive}>
  <div>
    <div className='bg-gray-100 border-l-8 border-b-8 border-green-500 p-4 mx-auto max-w-sm lg:mr-4 md:mr-4 mr-4 md:h-[400px] h-[500px] ' >
      <div className="overflow-hidden">
        <img src={carousel1} alt="Card 1" className="w-3/4 h-[150px] object-cover" />
      </div>
      <p className="font-bold text-lg mt-4">24/7 On-Demand Mechanics</p>
      <p className='text-sm text-justify mt-2'>No matter the time or place in India, our certified mechanics are available round the clock to assist you. Say goodbye to stressful breakdowns and waiting in long queues – we’ll have you back on the road within just 20 minutes.</p>
    </div>
  </div>
  <div>
    <div className='bg-gray-100 border-l-8 border-b-8 border-green-500 p-4 mx-auto max-w-sm lg:mr-4 md:mr-4 mr-4 md:h-[400px] h-[500px]'>
      <div className="overflow-hidden">
        <img src={carousel2} alt="Card 2" className="w-3/4 h-[150px] object-cover" />
      </div>
      <p className="font-bold text-lg mt-4">Schedule Your Service</p>
      <p className='text-sm text-justify mt-2'>Take control of your car maintenance by scheduling your service at your convenience. Choose from various pickup, drop-off, and towing options to fit your schedule seamlessly, ensuring you get the service you need without any hassle.</p>
    </div>
  </div>
  <div>
    <div className='bg-gray-100 border-l-8 border-b-8 border-green-500 p-4 mx-auto max-w-sm lg:mr-4 md:mr-4 mr-4 md:h-[400px] h-[500px]'>
      <div className="overflow-hidden">
        <img src={carousel3} alt="Card 3" className="w-3/4 h-[150px] object-cover" />
      </div>
      <p className="font-bold text-lg mt-4">Subscription Plans</p>
      <p className='text-sm text-justify mt-2'>Simplify your auto maintenance with our user-centric subscription plans tailored to your individual needs. Enjoy consistent and hassle-free care of your car with ease.</p>
    </div>
  </div>
  <div>
    <div className='bg-gray-100 border-l-8 border-b-8 border-green-500 p-4 mx-auto max-w-sm  md:h-[400px] h-[500px]'>
      <div className="overflow-hidden">
        <img src={carousel4} alt="Card 4" className="w-3/4 h-[150px] object-cover" />
      </div>
      <p className="font-bold text-lg mt-4">HelpyStore</p>
      <p className='text-sm text-justify mt-2'>Explore an extensive selection of genuine OEM tools, equipment, and vehicle parts from the comfort of your home. With rapid delivery, courtesy of our partnerships with leading suppliers and major brands like Blinkit and Amazon, you can trust Helpy Moto to provide top-quality merchandise right to your doorstep.</p>
    </div>
  </div>
</Carousel>

  </div>
  );
}