import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import TrackPageNavbar from "../components/TrackPageNavbar";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import mixpanel from "../config/mixpanel";
import toast from "react-hot-toast";

const Paymentscreen = () => {
  const [selectedOption, setSelectedOption] = useState("Credit/Debit Card");
  // const [currentPage, setCurrentPage] = useState("Service");
  const location = useLocation();
  const [cardNumber, setCardNumber] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [cvv, setCvv] = useState("");
  const [validThru, setValidThru] = useState("");

  const handleCardNumberChange = (e) => {
    let input = e.target.value.replace(/\D/g, "");
    input = input.substring(0, 16);
    input = input.replace(/(\d{4})(?=\d)/g, "$1 ");
    setCardNumber(input);
  };

  const handleNameOnCardChange = (e) => {
    const input = e.target.value.replace(/[^a-zA-Z\s]/g, "");
    setNameOnCard(input);
  };

  const handleValidThruChange = (e) => {
    let input = e.target.value.replace(/\D/g, "");

    input =
      input.length === 1 && input >= "2" && input <= "9" ? "0" + input : input;
    input =
      input.length === 2 &&
      input.charAt(0) === "1" &&
      input.charAt(1) >= "3" &&
      input.charAt(1) <= "9"
        ? "01/"
        : input.length === 2
        ? input + "/"
        : input;
    input =
      input.length >= 3 && input.charAt(2) !== "/"
        ? input.substring(0, 2) + "/" + input.substring(2)
        : input;

    input =
      input.length === 3 && e.nativeEvent.inputType === "deleteContentBackward"
        ? input.substring(0, 2)
        : input;

    if (input.length >= 5) {
      input = input.substring(0, 5);
      const currentYear = new Date().getFullYear() % 100;
      const enteredYear = parseInt(input.substring(3, 5));
      if (enteredYear < currentYear) {
        input = input.substring(0, 4);
        toast.error("invalid year");
      }
    }

    setValidThru(input);
  };

  const handleCvvChange = (e) => {
    let input = e.target.value.replace(/\D/g, "");
    input = input.slice(0, 3);
    setCvv(input);
  };

  const handleOptionClick = (option, event) => {
    event.preventDefault();
    setSelectedOption(option);
  };

  useEffect(() => {
    const path = location.pathname;
    // setCurrentPage(getPageName(path));
  }, [location.pathname]);

  // const getPageName = (path) => {
  //   const parts = path.split("/");
  //   return parts[parts.length - 1];
  // };

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    fetchCartItems();
    mixpanel.track("Paymentscreen Viewed", {
      page: window.location.pathname,
    });
  }, []);

  const fetchCartItems = () => {
    const token = localStorage.getItem('TOKEN');

    axios
      .get(`${SERVER_URL}/user/cart/get`, {
        withCredentials: true,  headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setCartItems(response.data.cartItemsData || []);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((subtotal, item) => {
      // Check if item.price is a valid number before adding
      return subtotal + (typeof item.price === "number" ? item.price : 0);
    }, 0);
  };

  const calculateTax = () => {
    const subtotal = calculateSubtotal();
    const tax = 0.1 * subtotal; // Assuming tax is 10% of the subtotal
    return parseFloat(tax.toFixed(2)); // Round to two decimal places
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const tax = calculateTax();
    return (subtotal + tax).toFixed(2);
  };

  const handleButtonClick = () => {
    mixpanel.track("Payment Button Clicked", { paymentOption: selectedOption });
  };

  const handleRemoveItem = (itemId) => {
    console.log("id that jhas been clicked to remove is",itemId);
    const data = {
      serviceId: itemId,
    };
    axios
    .delete(`${SERVER_URL}/user/cart/remove`, {
      data: data,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response.data);
        // After successful removal, fetch the updated cart items
        fetchCartItems();
      })
      .catch((error) => {
        console.error("Error removing item:", error);
      });
  };

  const renderPaymentForm = () => {
    switch (selectedOption) {
      case "UPI":
        return (
          <div className="lg:col-span-3 lg:col-start-2 border-2 p-4 md:p-6 flex flex-col">
            <h1 className="mb-2 text-lg font-medium">Pay with UPI</h1>
            <div class="flex items-center mb-4">
              <input
                id="default-radio-1"
                type="radio"
                value=""
                name="default-radio"
                class="w-4 h-4 text-black bg-gray-100 border-gray-300 focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="default-radio-1"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-900"
              >
                Google pay
              </label>
            </div>
            <div class="flex items-center mb-4">
              <input
                checked
                id="default-radio-2"
                type="radio"
                value=""
                name="default-radio"
                class="w-4 h-4 text-black bg-gray-100 border-gray-300 focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="default-radio-2"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-900"
              >
                Phone Pay
              </label>
            </div>
            <div class="flex items-center mb-4">
              <input
                checked
                id="default-radio-2"
                type="radio"
                value=""
                name="default-radio"
                class="w-4 h-4 text-black bg-gray-100 border-gray-300 focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="default-radio-2"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-900"
              >
                Paytm
              </label>
            </div>
            <input
              type="text"
              className="border border-gray-800 p-2 mb-4 w-full h-12"
              placeholder="UPI ID"
            />
            <div className="flex justify-end">
              <button className="bg-green-500 w-1/3 h-12 mt-4 text-center text-lg text-white rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold">
                <Link to="/successpage">Pay Now</Link>
              </button>
            </div>
          </div>
        );
      case "EMI":
        return (
          <div className="lg:col-span-3 lg:col-start-2 border-2 p-4 md:p-6 flex flex-col">
            <h1 className="mb-2 text-lg font-medium">Pay with EMI</h1>
            {/* Add EMI payment fields */}
            <input
              type="text"
              className="border border-gray-800 p-2 mb-4 w-full h-12"
              placeholder="EMI Amount"
            />
            <button className="bg-green-500 w-full md:w-1/3 h-12 mt-4 text-center text-lg text-white rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold self-end">
              <Link to="/sucesspage">Pay Now</Link>
            </button>
          </div>
        );
      case "Net Banking":
        return (
          <div className="lg:col-span-3 lg:col-start-2 border-2 p-4 md:p-6 flex flex-col">
            <h1 className="mb-2 text-lg font-medium">Pay with Net Banking</h1>
            {/* Add Net Banking payment fields */}
            <input
              type="text"
              className="border border-gray-800 p-2 mb-4 w-full h-12"
              placeholder="Bank Name"
            />
            <input
              type="text"
              className="border border-gray-800 p-2 mb-4 w-full h-12"
              placeholder="Account Number"
            />
            <input
              type="text"
              className="border border-gray-800 p-2 mb-4 w-full h-12"
              placeholder="IFSC Code"
            />
            <button className="bg-green-500 w-full md:w-1/3 h-12 mt-4 text-center text-lg text-white rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold self-end">
              <Link to="/sucesspage">Pay Now</Link>
            </button>
          </div>
        );
      case "Cash on Delivery":
        return (
          <div className="lg:col-span-3 lg:col-start-2 border-2 p-4 md:p-6 flex flex-col">
            <h1 className="mb-2 text-lg font-medium">
              Pay with Cash on Delivery
            </h1>
            {/* No additional fields required for Cash on Delivery */}
            <button className="bg-green-500 w-full md:w-1/3 h-12 mt-4 text-center text-lg text-white rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold self-end">
              <Link to="/sucesspage">Pay Now</Link>
            </button>
          </div>
        );
      default:
        return (
          <div className="lg:col-span-3 lg:col-start-2 border-2 p-4 md:p-6 flex flex-col">
            <h1 className="mb-2 text-lg font-medium">
              Pay with Credit/Debit Card
            </h1>
            {/* Add Credit/Debit Card payment fields */}
            <input
              type="text"
              value={cardNumber}
              onChange={handleCardNumberChange}
              className="border border-gray-800 p-2 mb-4 w-full h-12"
              placeholder="Card Number"
            />
            <input
              type="text"
              value={nameOnCard}
              onChange={handleNameOnCardChange}
              className="border border-gray-800 p-2 mb-4 w-full h-12"
              placeholder="Name on Card"
            />
            <div className="grid grid-cols-3 gap-2">
              <div className="col-span-2">
                {/* <div className="flex mb-4">
        <select
          value={expiryMonth}
          onChange={handleExpiryMonthChange}
          className="border border-gray-800 p-2  w-1/2 h-12"
        >
          <option value="">Month</option>
          {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
            <option key={month} value={month}>{month}</option>
          ))}
        </select>
        <select
          value={expiryYear}
          onChange={handleExpiryYearChange}
          className="border border-gray-800 p-2 ml-2 w-1/2 h-12"
        >
          <option value="">Year</option>
          {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i).map((year) => (
            <option key={year} value={year}>{year % 100}</option>
          ))}
        </select>
      </div> */}
                <div className="col-span-2">
                  <div className="col-span-2">
                    <input
                      type="text"
                      value={validThru}
                      onChange={handleValidThruChange}
                      className="border border-gray-800 p-2 w-full h-12"
                      placeholder="Valid Thru (MM/YY)"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <input
                  type="text"
                  value={cvv}
                  onChange={handleCvvChange}
                  className="border border-gray-800 pr-4 p-2 w-full h-12"
                  placeholder="CVV (3 Digits)"
                />
              </div>
            </div>
            <div className="flex items-center col-span-4 mb-2 mt-2">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="default-checkbox"
                className="ms-2 text-sm font-medium text-gray-900"
              >
                Secure my card as per RBI guidelines.
                <a className="text-decoration: underline mx-auto" href="#">
                  {" "}
                  Learn More
                </a>
              </label>
            </div>
            <button
              className="bg-green-500 w-full md:w-1/3 h-12 mt-4 text-center text-lg text-white rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold self-end"
              onClick={handleButtonClick}
            >
              <Link to="/sucesspage">Pay Now</Link>
            </button>
          </div>
        );
    }
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="flex flex-col">
      <TrackPageNavbar showOnlyNav={false} />
      <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
    <p class="text-sm text-gray-500 font-bold">
    <a href="/" class="hover:text-black hover:cursor-pointer">Home</a> 
              <span class="text-black"> / </span>
                <a href="/ServicePage" class="hover:text-black hover:cursor-pointer">Service</a>
                <span class="text-black"> / </span>
                <a href="javascript:void(0);" onclick={goBack} class="hover:text-black hover:cursor-pointer">Detail</a>
                <span class="text-black"> / </span>
                <span class="text-black">Info</span>
    </p>
</div>

      <div className="max-w-8xl mx-4 md:mx-14">
        <div className="grid lg:grid-cols-6 gap-4 md:gap-8 lg:gap-12">
          <div className="lg:col-span-2 flex justify-between items-center md:border-r pr-4">
            <h2 className="text-base sm:text-base text-black font-bold relative mt-4">
              Drop date & time
              <h3 className="text-base sm:text-base font-normal text-gray-700 relative">
                24th Jan 2024 & 11.00PM
              </h3>
            </h2>
            <Link to="/serviceinfo">
              <button
                id="edit"
                className="flex items-center justify-between bg-primary-700 hover:bg-primary-800 text-sm text-black font-bold md:mr-4"
                type="button" // Not 'type="edit"', it should be 'type="button"'
              >
                Edit
              </button>
            </Link>
          </div>

          <div className="lg:col-span-2 flex justify-between items-center md:border-r pr-4">
            <h2 className="text-base sm:text-base text-black font-bold relative mt-4">
              Pickup date & time
              <h3 className="text-base sm:text-base font-normal text-gray-700 relative">
                24th Jan 2024 & 11.00PM
              </h3>
            </h2>
            <Link to="/serviceinfo">
              <button
                id="edit"
                className="flex items-center justify-between bg-primary-700 hover:bg-primary-800 text-sm text-black font-bold md:mr-4"
                type="button" // Not 'type="edit"', it should be 'type="button"'
              >
                Edit
              </button>
            </Link>
          </div>

          <div className="lg:col-span-2 flex justify-between items-center">
            <h2 className="text-base sm:text-base text-black font-bold relative mt-4">
              Pickup & Drop Address
              <h3 className="text-base sm:text-base font-normal text-gray-700 relative">
                !st Cross road,BAD COLONY, Banglore-600321
              </h3>
            </h2>
            <Link to="/serviceinfo">
              <button
                id="edit"
                className="flex items-center justify-between bg-primary-700 hover:bg-primary-800 text-sm text-black font-bold md:mr-4"
                type="button" // Not 'type="edit"', it should be 'type="button"'
              >
                Edit
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="max-w-8xl mx-4 md:mx-14 mt-8">
        <h1 className="text-black font-medium text-2xl mb-2">Payment Option</h1>

        <div className="grid lg:grid-cols-6 gap-4">
          <div className="lg:col-span-4 grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="overflow-y-auto overflow-x-hidden flex-grow lg:col-span-1 border gap-8">
              <ul className="flex flex-col pt-7">
                <li>
                  <a
                    href="#"
                    className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-black text-black hover:text-white hover:border-green-500 pr-6 ${
                      selectedOption === "Credit/Debit Card"
                        ? "bg-black text-white"
                        : ""
                    }`}
                    onClick={(event) => {
                      event.preventDefault();
                      handleOptionClick("Credit/Debit Card", event);
                    }}
                  >
                    <span className="text-lg font-medium tracking-wide truncate ml-5 text-left">
                      Credit/Debit Card
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-black text-black hover:text-white hover:border-green-500 pr-6 ${
                      selectedOption === "UPI" ? "bg-black text-white" : ""
                    }`}
                    onClick={(event) => {
                      event.preventDefault();
                      handleOptionClick("UPI", event);
                    }}
                  >
                    <span className="text-lg font-medium tracking-wide truncate ml-5 text-left">
                      UPI
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-black text-black hover:text-white hover:border-green-500 pr-6 ${
                      selectedOption === "EMI" ? "bg-black text-white" : ""
                    }`}
                    onClick={(event) => {
                      event.preventDefault();
                      handleOptionClick("EMI", event);
                    }}
                  >
                    <span className="text-lg font-medium ml-5 tracking-wide truncate text-left">
                      EMI
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-black text-black hover:text-white hover:border-green-500 pr-6 ${
                      selectedOption === "Net Banking"
                        ? "bg-black text-white"
                        : ""
                    }`}
                    onClick={(event) => {
                      event.preventDefault();
                      handleOptionClick("Net Banking", event);
                    }}
                  >
                    <span className="text-lg font-medium ml-5 tracking-wide truncate text-left">
                      Internet Banking
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-black text-black hover:text-white hover:border-green-500 pr-6 ${
                      selectedOption === "Cash on Delivery"
                        ? "bg-black text-white"
                        : ""
                    }`}
                    onClick={(event) => {
                      event.preventDefault();
                      handleOptionClick("Cash on Delivery", event);
                    }}
                  >
                    <span className="text-lg font-medium ml-5 tracking-wide truncate text-left">
                      Cash on Delivery
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            {renderPaymentForm()}
          </div>

          {/* Placeholder for the second part (Column 2) */}
          <div className="lg:col-span-2 border px-2 xl:px-8 p-2 ">
            <label htmlFor="" className="text-xl font-medium text-black ">
              Payment Detail({cartItems.length})
            </label>
            <div className="flow-root mt-2 mb-2 max-h-[220px] overflow-y-auto">
              <ul className="-my-2">
                {cartItems.map((item) => (
                  <li
                    key={item._id}
                    className="flex flex-col p-0 border-b-2 space-y-3 py-4 text-left sm:flex-row sm:space-x-5 sm:space-y-0"
                  >
                    <div className="shrink-0 relative">
                      <img
                        className="h-20 w-22 max-w-full object-cover"
                        // src="https://images.unsplash.com/photo-1533473359331-0135ef1b58bf?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dsss"
                        src={item.service.photo}
                        alt={item.service.name}
                      />
                    </div>

                    <div className="relative flex flex-1 flex-col justify-between">
                      <div className="sm:col-gap-5 sm:grid sm:grid-cols-2">
                        <div className="pr-8 sm:pr-5">
                          <p className="text-base font-medium text-gray-900">
                            {item.service.name}
                          </p>
                          <div class="flex items-center bg-green-500 p-2 rounded w-12 h-5">
                            <p class="text-sm font-medium text-gray-900 dark:text-white">
                              {item.service.avgRating || "0"}
                            </p>
                            <svg
                              class="w-3 h-3 text-white ml-1"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 22 20"
                            >
                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                            </svg>
                          </div>

                          <p className=" font-semibold mx-0 mt-1 mb-0 text-sm text-black-400">
                            ₹ {item.price}
                          </p>
                        </div>
                      </div>

                      <div className="absolute top-0 right-0 flex sm:bottom-0 sm:top-auto">
                        <button
                          type="button"
                          className="flex rounded p-2 text-center text-red-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900"
                          onClick={()=>
                            handleRemoveItem(item.service._id)}
                        >
                          <div>Remove</div>
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-2 border"></div>

            <ul class="text-[#333] text-sm  mt-1">
              <li class="flex flex-wrap gap-4 text-lg font-medium">
                Total
                <span class="ml-auto font-medium">₹{calculateSubtotal()}</span>
              </li>
              <li class="flex flex-wrap gap-4 py-1 text-lg font-medium">
                Tax <span class="ml-auto font-medium">₹{calculateTax()}</span>
              </li>
              <li class="border"></li>
              <li class="flex flex-wrap gap-4 py-1 text-xl font-semibold">
                Total Cost<span class="ml-auto">₹{calculateTotal()}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paymentscreen;
