import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import star from "../assets/ServiceDetails/star.png";
import img from "../assets/service.png";
import rightVector from "../../src/assets/ServiceDetails/rightVector.png";
import SERVER_URL from "../constants/constant";
import axios from "axios";
import toast from "react-hot-toast";
import mixpanel from "../config/mixpanel";
import { useNavigate } from "react-router-dom";

const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  const starArray = Array(fullStars).fill("★");
  if (halfStar) starArray.push("½");
  const emptyStarArray = Array(emptyStars).fill("☆");

  return (
    <div className="flex items-center">
      {starArray.map((star, index) => (
        <span key={index} className="text-yellow-500 text-2xl">
          {star}
        </span>
      ))}
    </div>
  );
};

const Trackorderservicedetail = ({ service }) => {
  const navigate = useNavigate();
  const [numServicesAdded, setNumServicesAdded] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isAdded, setIsAdded] = useState(false);



  // const handlebuyNow=()=>{
  //   navigate("/", {
  //     state: { serviceId: id},
  //   });
  // }

  const roundedRating = service.avgRating.toFixed(1);
  console.log(service);
  const [cartAdded, setCartAdded] = useState(0);
  const displayTextOptions = {
    0: "Add to",
    1: "Already in the",
    2: "Added to",
  };

  // const handleAddService = (id, price, e) => {
  //   console.log(`Clicked on add service with ID: ${id}`);
  //   mixpanel.track("Add Service Clicked", { serviceId: id });
  //   setNumServicesAdded(numServicesAdded + 1);
  //   setTotalPrice(totalPrice + price);
  //   setShowConfirmation(true); // Show confirmation bar when service is added
  //   e.preventDefault(); // Prevent default button behavior
  //   const storedServiceIds = localStorage.getItem("addedServiceIds");
  //   const storedIdsArray = storedServiceIds ? JSON.parse(storedServiceIds) : [];
  //   if (!storedIdsArray.includes(id)) {
  //     storedIdsArray.push(id);
  //     localStorage.setItem("addedServiceIds", JSON.stringify(storedIdsArray));
  //   }
  // };

  useEffect(() => {
    // Check if the service is already added when component mounts
    const storedServiceIds = localStorage.getItem("addedServiceIds");
    const storedIdsArray = storedServiceIds ? JSON.parse(storedServiceIds) : [];
    setIsAdded(storedIdsArray.includes(service._id));
  }, []);

  const handleAddService = (id, price, e) => {
    console.log(`Clicked on add service with ID: ${id}`);
    mixpanel.track("Add Service Clicked", { serviceId: id });
  
    // Prevent default button behavior
    e.preventDefault();
  
    // Check if the service is already added
    const storedServiceIds = localStorage.getItem("addedServiceIds");
    const storedIdsArray = storedServiceIds ? JSON.parse(storedServiceIds) : [];
    const isServiceAdded = storedIdsArray.includes(id);
  
    if (isServiceAdded) {
      // If the service is already added, display toast notification
      toast.success("Service already added");
    } else {
      // Update state variables
      setNumServicesAdded(numServicesAdded + 1);
      setTotalPrice(totalPrice + price);
      setShowConfirmation(true);
  
      // Store added service ID in local storage
      const updatedIds = [...storedIdsArray, id];
      localStorage.setItem("addedServiceIds", JSON.stringify(updatedIds));
  
      // Show toast message
      toast.success("Service added");

      // Update isAdded state to true
      setIsAdded(true);
    }
  };
  

  const handleAddCart = (id) => {
    axios
      .post(
        `${SERVER_URL}/user/cart/add`,
        { serviceId: id },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (
          (response.data.success === true) &
          (response.data.message === "successfully added to cart")
        ) {
          toast.success(response.data.message);
          setCartAdded(2);
          mixpanel.track("Added_to_Cart", { serviceId: id });
        } else if (response.data.success === true) {
          toast(response.data.message);
          setCartAdded(1);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  const handleBuyNowClick = (id) => {
    mixpanel.track("Buy Now Clicked");

    navigate("/serviceinfo");
  };

  const Trackorderservicedetail = ({ service }) => {
    const navigate = useNavigate();
    const [numServicesAdded, setNumServicesAdded] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);
  
    
    const handleAddService = (id, price) => {
      console.log(`Clicked on add service with ID: ${id}`);
      mixpanel.track("Add Service Clicked", { serviceId: id });
    
      // Update state variables
      setNumServicesAdded(numServicesAdded + 1);
      setTotalPrice(totalPrice + price);
      setShowConfirmation(true);
    
      // Show toast message
      toast.success("Service added");
    
      // Redirect user
      navigate(`/Trackorderservice`);
    };
  }

  return (
    <>
    <div className="px-4 sm:px-6 md:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
        <p className="text-sm text-gray-500 font-bold">
          Home / <span className="text-black">Service</span>
        </p>
    </div>
    <div className="bg-white p-4 shadow-md rounded-md flex flex-col gap-5 lg:flex-row w-full">
      <div className="w-full lg:w-3/5 md:w-full md:min-h-[590px] flex flex-col justify-start p-2">
        <img
          src={img}
          alt=""
          className="w-full h-auto object-cover rounded-md mb-4"
        />
        <div className="h-48 overflow-y-auto scrollbar-thin md:h-auto md:max-h-full">
          <h3 className="text-lg font-semibold mb-2">
            Reviews{" "}
            <span className="bg-black text-white px-5  rounded-sm">
              {service.reviews.length}
            </span>
          </h3>
          <ul className="list-none pl-4">
            {service.reviews.map((eachReview, index) => (
              <li key={index} className="mb-4">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <p className="text-gray-700 font-semibold">
                      {eachReview.userName}
                    </p>
                  </div>
                  {renderStars(eachReview.rating)}
                </div>
                <p className="text-gray-500">{eachReview.text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="w-full lg:w-2/5 p-2 md:w-full md:min-h-[590px] flex flex-col justify-start text-black">
        <h2 className="text-2xl font-semibold mb-2">{service.name}</h2>
        <div className="items-center">
          <div className="flex items-center rounded-sm">
            <div className="flex items-center border rounded  bg-green-500 transform hover:scale-105 transition-all duration-300 text-white mr-2 p-1">
              {roundedRating}{" "}
              <img
                src={star}
                className="flex items-center p-1 justify-center"
                alt="star"
              />
            </div>
          </div>
        </div>
        <div className="mt-2 mb-3">
          <p className="text-black">₹ {service.price}</p>
        </div>
        <div className="flex flex-col items-center mt-4 md:flex-row md:items-center md:mt-0 gap-2">
        <button
      className={`border border-green-500 text-green-500 transform hover:scale-105 transition-all duration-300 hover:bg-green-500 rounded-sm text-sm hover:text-white px-4 py-1 mr-2 font-bold ${
        isAdded ? 'bg-green-500 text-white' : ''
      }`}
      onClick={(e) => handleAddService(service._id, service.price, e)}
      disabled={isAdded} // Optionally disable the button when service is added
    >
      {isAdded ? 'Added' : '+ Add Service'}
    </button>
        </div>
        <div className="mt-4 border-b border-gray-300"></div>
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Description</h3>
          <p className="text-gray-700">{service.description}</p>
        </div>
        <div className="mt-4 border-b border-gray-300"></div>
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Service Included</h3>
          <ul className="list-none lg:pl-4">
            {service.serviceIncluded
              ? service.serviceIncluded.map((includedServicesData, index) => (
                  <li
                    key={includedServicesData.id}
                    className="flex items-start mb-4"
                  >
                    <img
                      src={rightVector}
                      alt="Bullet Point"
                      className="mr-2 w-4 h-4"
                    />
                    <div>
                      <h4 className="text-gray-700 font-semibold mb-2">
                        {includedServicesData.subService}
                      </h4>
                      <p className="text-gray-500">
                        {includedServicesData.subDescription}
                      </p>
                    </div>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
    </div>
    </>
  );
};

export default Trackorderservicedetail;

