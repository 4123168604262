// authUtils.js

import axios from "axios";
import SERVER_URL from "../constants/constant";
import toast from "react-hot-toast";

export const handleLogout = async () => {
  try {
    toast.loading("Logging out...", {
      position: "top-center",
    });

    // Retrieve the token from localStorage
    const token = localStorage.getItem("TOKEN");
    console.log(token);

    const response = await axios.post(
      `${SERVER_URL}/user/logout`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    console.log("logout response", response);
    if (response.status === 200 && response.data.success) {
      localStorage.removeItem("user");
      localStorage.removeItem("TOKEN");

      toast.dismiss();

      toast.success(response.data.message || "Successfully logged out!", {
        position: "top-center",
        duration: 5000,
      });

      window.location.href = "/";
    } else {
      toast.dismiss();

      toast.error(response.data.message || response.statusText, {
        position: "top-center",
      });

      window.location.href = "/";
    }
  } catch (error) {
    toast.dismiss();

    console.error("Logout failed:", error.message);

    toast.error("Logout failed. Please try again.", {
      position: "top-center",
    });

    // window.location.href = "/";
  }
};

// authUtils.js

// import toast from "react-hot-toast";

// export const handleLogout = () => {
//   try {
//     toast.loading("Logging out...", {
//       position: "top-center",
//     });

//     // Clear user-related data from localStorage
//     localStorage.removeItem("user");
//     localStorage.removeItem("TOKEN");

//     toast.dismiss();

//     toast.success("Successfully logged out!", {
//       position: "top-center",
//       duration: 5000,
//     });

//     // Redirect to the home page or any other desired page
//     window.location.href = "/";
//   } catch (error) {
//     toast.dismiss();

//     console.error("Logout failed:", error.message);

//     toast.error("Logout failed. Please try again.", {
//       position: "top-center",
//     });

//     // Redirect to the home page or any other desired page
//     window.location.href = "/";
//   }
// };
