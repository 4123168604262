import React, { useEffect } from "react";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import TextField from "@mui/material/TextField";
import useFetch from "../hooks/useFetch";
import SERVER_URL from "../constants/constant";
import axios from "axios";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";

const NewAccount = () => {
  const [otp, setOtp] = useState("");
  const [isFormValid, setFormValid] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [responseD, setResponseD] = useState({ otpToken: "", otp: "" }); // Initialize with an empty object
  const [token, setToken] = useState(""); // State to store the user token

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(0);

  const { handleGoogle, loading, error } = useFetch(
    "https://service-provider-apis.onrender.com/api/v1/user/auth/google"
  );

  const GoogleSignInButton = () => {
    const handleApiCall = () => {
      // Make an API request to fetch the HTML content
      axios
        .get(
          "https://service-provider-apis.onrender.com/api/v1/user/auth/google",
          {}
        )
        .then((response) => {
          if (response.status === 200) {
            const htmlContent = response.data;

            const newWindow = window.open("", "_blank");
            if (newWindow) {
              newWindow.document.open();
              newWindow.document.write(htmlContent);
              newWindow.document.close();
            } else {
              // A popup blocker may have prevented the window from opening
              // You can display a message to the user or handle this case as needed
            }
          } else {
            // Handle non-successful response status here
            console.error("API Request Failed. Status:", response.status);
          }
        })
        .catch((error) => {
          // Handle API request errors here
          console.error("API Request Error:", error);
        });
    };

    return (
      <button
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#4285F4",
          color: "#FFFFFF",
          padding: "5px 10px",
          margin: "5px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={handleApiCall}
      >
        <img
          style={{ width: "30px", height: "30px", marginRight: "10px" }}
          src="google_icon.png"
          alt="Google Logo"
        />
        Continue with Google
      </button>
    );
  };

  // const { handleGoogle, loading, error } = useFetch(
  //   'https://service-provider-apis.onrender.com/api/v1/user/auth/google'
  // );

  // useEffect(() => {
  //   /* global google */
  //   if (window.google) {
  //     window.google.accounts.id.initialize({
  //       client_id: "340826937388-1rvcsio800p1lq2lvt7kklu4s409o6p2.apps.googleusercontent.com",
  //     callback: handleGoogle,
  //     });

  //     window.google.accounts.id.renderButton(document.getElementById('signUpDiv'), {
  //       type: 'standard',
  //       theme: 'filled_blue',
  //       // size: "large",
  //       text: 'continue_with',
  //       shape: 'rectangular',
  //     });

  //     // google.accounts.id.prompt()
  //   }
  // }, [handleGoogle]);

  // const { handleGoogle, loading, error } = useFetch(
  //   `https://service-provider-apis.onrender.com/api/v1/user/auth/google`
  // );

  // useEffect(() => {
  //   /* global google */
  //   if (window.google) {
  //     google.accounts.id.initialize({
  //       callback: handleGoogle,
  //     });

  //     google.accounts.id.renderButton(document.getElementById('signUpDiv'), {
  //       type: 'standard',
  //       theme: 'filled_blue',
  //       text: 'continue_with',
  //       shape: 'rectangular',
  //     });

  //     // The button will take the user to the Google Sign-In page when clicked.
  //   }
  // }, [handleGoogle]);

  /* Need proper form validation */
  useEffect(() => {
    setOtpSent(false);
    if (
      fname.length > 0 &&
      lname.length > 0 &&
      email.length > 0 &&
      phone.length == 10
    ) {
      setFormValid(true);
    } else setFormValid(false);
  }, [fname, lname, email, phone]);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleOTPSend = async (e) => {
    e.preventDefault();
    setOtpSent(false);

    try {
      const data = {
        firstName: fname, // optional
        lastName: lname, // optional
        email: email, // Change it as needed to test it or use it
        phoneNo: phone, // optional
      };

      const response = await fetch(
        "https://service-provider-apis.onrender.com/api/v1/user/sign-up/otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();

      if (responseData.success != true) {
        toast.error(responseData.message);
      }
      if (responseData.success === true) {
        toast.success(responseData.message);
        setOtpSent(true); // Set OtpSent to true after a successful response
        setResponseD({ otpToken: responseData.otpToken });
      }
    } catch (error) {
      console.error("Something went wrong", error);
    }
  };

  const handleSignUp = async (e) => {
    try {
      const data = {
        firstName: fname, // optional
        lastName: lname, // optional
        email: email, // Change it as needed to test it or use it
        phoneNo: phone, // optional
      };
      const response = await fetch(
        `https://service-provider-apis.onrender.com/api/v1/user/sign-up`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const responseD = await response.json();

        if (responseD.success) {
          toast.success(responseD.message);
          // handleLogin();
          setToken(responseD.token);
          localStorage.setItem("TOKEN", responseD.token);
          localStorage.setItem("user", JSON.stringify(responseD.user));

          window.location.href = "/";
        } else {
          toast.error(responseD.message);
        }
      } else {
        toast.error("Failed to SignUp. Please try again later.");
      }
    } catch (error) {
      console.error("Something went wrong", error);
      toast.error("An error occurred while signing Up.");
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://service-provider-apis.onrender.com/api/v1/user/sign-up/verify-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            otp: otp,
            token: responseD.otpToken,
          }),
        }
      );

      if (response.ok) {
        const responseD = await response.json();

        if (responseD.success) {
          handleSignUp();
        } else {
          toast.error("Invalid OTP.");
        }
      } else {
        toast.error("Failed to verify OTP. Please try again later.");
      }
    } catch (error) {
      console.error("Something went wrong", error);
      toast.error("An error occurred while verifying OTP.");
    }
    setOtp("");
  };

  const isOtpFilled = otp.length === 4;

  return (
    <div
      style={{ minHeight: "100svh" }}
      className="flex items-center p-4 justify-center bg-gray-100"
    >
      <Toaster />
      <div className="bg-white rounded-lg shadow-lg p-4 max-w-xl w-full flex justify-center flex-col items-center">
        <img
          src="/Logo.png"
          alt=""
          className="w-[50px] h-[50px] object-contain"
        />
        <h2 className="text-2xl font-bold text-center mb-2">
          Create a new account
        </h2>
        <p className="text-center mb-8">
          Already have an account?{" "}
          <Link to="/LoginPage" className="text-green-600">
            Sign In
          </Link>
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          <div className="col-span-1 border-b-2 md:border-b-0 md:border-r-2 lg:border-b-0 lg:border-r-2 xl:border-b-0 xl:border-r-2 p-4 flex flex-col items-center">
            <form className="space-y-2" onSubmit={handleVerify}>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                required
                style={{ width: "100%" }}
                value={fname}
                onChange={(e) => setFname(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                required
                style={{ width: "100%" }}
                value={lname}
                onChange={(e) => setLname(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Email Address"
                variant="outlined"
                required
                style={{ width: "100%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                type="tel"
                label="Phone Number"
                variant="outlined"
                required
                style={{ width: "100%" }}
                value={phone || ""}
                onChange={(e) => setPhone(e.target.value)}
                onInput={(e) => {
                  e.preventDefault();
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  e.target.value = numericValue;
                }}
                inputProps={{ maxLength: 10 }}
              />

              <button
                className={` text-black underline font-semibold py-2 rounded-md px-3 w-full ${
                  isFormValid ? "" : "opacity-50 disabled cursor-not-allowed"
                }`}
                disabled={!isFormValid}
                onClick={(e) => handleOTPSend(e)}
              >
                {otpSent ? "Resend" : "Send"} OTP
              </button>

              {otpSent && (
                <OTPInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={4}
                  renderSeparator={<span className="w-[50px]"></span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (isOtpFilled) {
                            // Trigger form submission when Enter is pressed and OTP is filled
                            handleVerify(e);
                          }
                        }
                      }}
                    />
                  )}
                  inputStyle={{
                    border: "1px solid black",
                    padding: "4px",
                    width: "100%",
                  }}
                />
              )}

              {
                <button
                  type="submit"
                  className={`submit bg-green-500 hover:bg-green-600 text-white font-semibold py-2 rounded-md px-3 w-full ${
                    isOtpFilled ? "" : "opacity-50 disabled cursor-not-allowed"
                  }`}
                  disabled={!isOtpFilled}
                >
                  Submit
                </button>
              }
            </form>
          </div>

          <div className="col-span-1 flex flex-col items-center justify-center flex-grow p-4">
            <p className="text-center text-sm">
              Or, you can also sign in with Google!
            </p>
            {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
            {loading ? (
              <div className="mt-5">Loading....</div>
            ) : (
              <div>
                <GoogleSignInButton />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAccount;
