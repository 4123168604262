import React, { useState } from "react";
import trustedLogo from "../../src/assets/subscription/shield 1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const SubscriptionPlanCard = ({
  title,
  amount,
  description,
  buttonText,
  image,
  planFeatures,
  isHighlighted, // New prop to specify if the card should be highlighted
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-between mx-auto max-w-xs mb-8 md:mb-0 lg:mb-0`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={`bg-white p-4 m-2 shadow-xl border-2 border-grey rounded-md h-full max-w-xs ${
          isHighlighted ? "border-2 border-black relative transform scale-110 mt-[-20px]" : ""
        } md:w-full lg:w-full`}
      >
        <div className="relative ">
          <img
            src={image}
            alt="Logo"
            className="absolute top-0 right-0 w-12 h-14 mt-[-16px]"
          />
          <div
            className={`absolute p-2 -top-4 right-0  w-12 h-14 flex items-center justify-center text-white ${
              isHighlighted ? "text-xs" : "text-xs"
            }`}
            style={{ wordBreak: "break-word",
            // fontSize: isHighlighted ? "1rem" : "0.4rem",
          }}
          >
            {title}
          </div>
        </div>
        {isHighlighted && (
          <div className="flex items-center mt-2">
            <img
              src={trustedLogo}
              alt="Trusted Logo"
              className="w-4 h-4 mr-2"
            />
            <div className="text-base font-semibold text-red-500">Trusted</div>
          </div>
        )}
        <p className="text-3xl font-semibold p-1 mt-1"><CurrencyRupeeIcon/>{amount}</p>
        <p className="text-sm from-neutral-950 p-2">{description}</p>
        <div className="flex-grow flex items-end justify-end border-green-500">
          <button className="hover:bg-green-500 hover:text-white text-green-500 border border-green-500 w-full h-full px-4 py-3 m-0 rounded-md text-sm flex items-center justify-center font-semibold">
            {buttonText}
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </button>
        </div>

        <div className="my-4">
          <p className="mb-2 font-semibold">What's included?</p>
          <ul className="list-disc pl-6">
            {planFeatures.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

SubscriptionPlanCard.defaultProps = {
  isHighlighted: false, // Default value for isHighlighted
};

export default SubscriptionPlanCard;
