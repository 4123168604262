import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import mixpanel from "../config/mixpanel";
import toast from "react-hot-toast";
import {
  ExpandMoreOutlined as ExpandMoreOutlinedIcon,
  FilterListOutlined as FilterListOutlinedIcon,
  MyLocationOutlined as MyLocationOutlinedIcon,
  SearchOutlined as SearchOutlinedIcon,
} from "@mui/icons-material";
import TrackPageNavbar from "../components/TrackPageNavbar";

const Trackorderservice = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredServices, setFilteredServices] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLocationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [numServicesAdded, setNumServicesAdded] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [addedServiceIds, setAddedServiceIds] = useState([]);
  const navigate = useNavigate();
  const filterDropdownRef = useRef(null);
  const sortDropdownRef = useRef(null);

  useEffect(() => {
    mixpanel.track("Services Page View");
    axios
      .get(`${SERVER_URL}/service/getAll`)
      .then((response) => {
        const { services } = response.data;
        console.log("Fetched services:", services);
        if (services && services.length > 0) {
          setServices(services);

          // Retrieve added service details from local storage if available
          const storedServiceIds = localStorage.getItem("addedServiceIds");
          if (storedServiceIds) {
            const storedIdsArray = JSON.parse(storedServiceIds);
            setAddedServiceIds(storedIdsArray);
            setNumServicesAdded(storedIdsArray.length);

            // Calculate total price for added services
            let total = 0;
            storedIdsArray.forEach((id) => {
              const service = services.find((service) => service._id === id);
              if (service) {
                total += service.price;
              }
            });
            setTotalPrice(total);
            setShowConfirmation(true);
          }

          setLoading(false);
        } else {
          console.error("No services found in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterDropdownOpen(false);
      }
      if (
        sortDropdownRef.current &&
        !sortDropdownRef.current.contains(event.target)
      ) {
        setSortDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const filtered = services.filter((service) => {
      return service.name.toLowerCase().includes(searchText.toLowerCase());
    });
    setFilteredServices(filtered);
  }, [searchText, services]);

  const handleSearchChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText.trim());
    setLocationDropdownOpen(!!searchText.trim());
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setLocationDropdownOpen(false);
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setLocationDropdownOpen(false);
  };

  const handleService = (id, price) => {
    console.log(`Clicked on service with ID: ${id}`);
    mixpanel.track("Service Clicked", { serviceId: id });
    navigate(`/Trackorderservicedetail/${id}`); // Navigate to correct URL with service ID
  };

  const handleAddService = (id, price, e) => {
    if (addedServiceIds.includes(id)) {
      toast.success("Service already added!");
      return;
    }
    console.log(`Clicked on add service with ID: ${id}`);
    mixpanel.track("Add Service Clicked", { serviceId: id });
    const updatedIds = [...addedServiceIds, id];
    setAddedServiceIds(updatedIds);
    localStorage.setItem("addedServiceIds", JSON.stringify(updatedIds));
    setNumServicesAdded(numServicesAdded + 1);
    setTotalPrice(totalPrice + price);
    setShowConfirmation(true); // Show confirmation bar when service is added
    toast.success("Service added");
    e.preventDefault(); // Prevent default button behavior
  };

  const handleRemoveService = (id, price) => {
    console.log(`Clicked on remove service with ID: ${id}`);
    mixpanel.track("Remove Service Clicked", { serviceId: id });
    const updatedIds = addedServiceIds.filter((serviceId) => serviceId !== id);
    setAddedServiceIds(updatedIds);
    localStorage.setItem("addedServiceIds", JSON.stringify(updatedIds));
    const updatedTotalPrice = totalPrice - price;
    setTotalPrice(updatedTotalPrice);
    const updatedNumServicesAdded = numServicesAdded - 1;
    setNumServicesAdded(updatedNumServicesAdded);
    if (updatedNumServicesAdded > 0) {
      setShowConfirmation(true);
    } else {
      setShowConfirmation(false);
    }
    toast.success("Service removed");
  };

  const handlePriceChange = (e) => {
    const price = e.target.value;
    console.log("Price changed:", price);
  };

  return (
    <div className="overflow-y-hidden">
      <TrackPageNavbar showOnlyNav={false} />
      <div className="px-4 sm:px-6 md:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
        <p className="text-sm text-gray-500 font-bold">
          Home / <span className="text-black">Service</span>
        </p>
        <div className="w-full lg:w-3/4 flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center justify-between gap-x-8">
          <div className="w-full sm:w-3/5 flex items-center relative flex-col">
            <div className="flex items-center pl-1 sm:pl-2 gap-x-2 p-2 relative border border-gray-300 rounded-md w-full">
              <SearchOutlinedIcon
                style={{ width: "18px", height: "18px", color: "gray" }}
              />
              <input
                type="text"
                placeholder="Search Service"
                className="outline-none text-xs sm:text-base flex-1"
                onChange={handleSearchChange}
              />
            </div>
            {isLocationDropdownOpen && filteredServices.length > 0 && (
              <div
                className={`absolute z-50 w-full left-0 mt-11 bg-white border rounded-md shadow-md  overflow-y-auto max-h-48`}
              >
                {filteredServices.map((service) => (
                  <div
                    key={service._id}
                    className="p-2 cursor-pointer hover:bg-gray-100 w-full"
                    onClick={() => handleService(service._id, service.price)}
                  >
                    {service.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-between ">
            <div ref={filterDropdownRef} className="relative">
              <button
                onClick={toggleFilterDropdown}
                className="flex items-center gap-x-2 border-[1.5px] border-gray-400 outline-none p-1.5 px-3 rounded text-xs sm:text-base"
              >
                <FilterListOutlinedIcon className="cursor-pointer" />
                Filter
              </button>
              {isFilterDropdownOpen && (
                <div className="origin-top-right absolute -left-5 top-8 mt-2 w-60 z-50 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="p-6 flex flex-col gap-y-5">
                    <div>
                      <p className="font-semibold">Service Type</p>
                      <div>
                        <p className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                          <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                          Regular Maintenance
                        </p>
                        <p className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                          <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                          Repair
                        </p>
                        <p className="flex items-center gap-x-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                          <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                          Diagnostic Services
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="font-medium mb-2">Price Range</p>
                      <input
                        type="range"
                        className="w-full"
                        min="0"
                        max="20000"
                        defaultValue={8000}
                        onChange={handlePriceChange}
                      />
                      <div className="flex items-center justify-between -mt-2 text-sm">
                        <span>0</span>
                        <span>20k</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div ref={sortDropdownRef} className="relative">
              <button
                onClick={toggleSortDropdown}
                className="flex items-center gap-x-2 border-[1.5px] border-gray-400 outline-none p-1.5 px-3 rounded text-xs sm:text-base ml-4"
              >
                Sort
                <ExpandMoreOutlinedIcon className="cursor-pointer" />
              </button>
              {isSortDropdownOpen && (
                <div className="origin-top-right absolute -left-7 top-8 mt-2 w-[150px] z-50 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-4 py-6">
                    <p className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      Low to High
                    </p>
                    <p className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      High to Low
                    </p>
                    <p className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      Newest
                    </p>
                    <p className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      Oldest
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 md:px-8 lg:px-14 py-3 mt-5 mb-20">
      <p className="text-xl font-bold mb-5 max-w-max relative after:h-[3.5px] after:bg-green-400 after:mt-0.5 after:w-[40px] after:flex after:absolute after:right-0.5">
      Add New Services
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6">
          {filteredServices.map((service) => (
            <div
              key={service._id}
              className="relative rounded-md cursor-pointer overflow-hidden"
            >
              <div className="relative">
                <img
                  src="/cartcarousel.png"
                  alt=""
                  className="rounded-md w-full relative"
                  onClick={() => handleService(service._id)}
                />
                <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-black via-black opacity-75"></div>
                <div className="absolute bottom-0 left-0 right-0 p-3 text-white">
                  <div className="flex justify-between items-center  mb-1">
                    <p className="text-lg font-semibold truncate">
                      {service.name}
                    </p>
                  </div>
                  <p className="text-xs opacity-75  truncate">
                    1000 Kms or 1 Month warranty | Every 5000 Kms or 3 Months
                  </p>
                  <div className="flex justify-between items-center">
                    <p className="text-sm font-bold">
                      Rs.{" "}
                      <span className="line-through text-gray-400 font-light">
                        {service.price}
                      </span>{" "}
                      {service.price}
                    </p>
                    {addedServiceIds.includes(service._id) ? (
                      <button
                        className="p-1 bg-transparent border-red-500 border text-red-500 text-sm rounded-md hover:bg-red-500 hover:text-white transition-colors duration-300"
                        onClick={(e) =>
                          handleRemoveService(service._id, service.price)
                        }
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className="p-1 bg-transparent border-green-500 border text-green-500 text-sm rounded-md hover:bg-green-500 hover:text-white transition-colors duration-300"
                        onClick={(e) =>
                          handleAddService(service._id, service.price, e)
                        }
                      >
                        + Add
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Confirmation bar */}
      {showConfirmation && (
        <div className="fixed bottom-0 left-12 right-12 bg-green-500 border border-green-400 text-white p-4  flex items-center justify-between">
          <div>
            <p>{numServicesAdded} service added</p>
            <p className="font-semibold">₹ {totalPrice}</p>
          </div>
          <button className="px-4 py-2 bg-white text-green-500 rounded-md hover:bg-green-800 hover:text-white transition-colors duration-300">
            Submit Request
          </button>
        </div>
      )}
    </div>
  );
};

export default Trackorderservice;
