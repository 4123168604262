import React from "react";
import { Link } from "react-router-dom";

import personal from "../../src/assets/profile/personal.png";
import order from "../../src/assets/profile/order.png";
import vehicle from "../../src/assets/profile/vehicle.png";
import notification from "../../src/assets/profile/notification.png";
import setting from "../../src/assets/profile/setting.png";
import terms from "../../src/assets/profile/terms.png";
import help from "../../src/assets/profile/help.png";
import logout from "../../src/assets/profile/logout.png";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import toast from "react-hot-toast";
import { handleLogout } from "../utils/authUtils";
const profileSections = [
  {
    label: "Personal Information",
    to: "/Profile/personalInformation",
    image: personal,
  },
  { label: "Order History", to: "/profile/order-history", image: order },
  { label: "My Vehicle", to: "/profile/my-vehicle", image: vehicle },
  { label: "Notifications", to: "/profile/notifications", image: notification },
  { label: "Settings", to: "/profile/settings", image: setting },
  {
    label: "Terms & Conditions",
    to: "/profile/terms-conditions",
    image: terms,
  },
  { label: "Help & Support", to: "/profile/help-support", image: help },
];

const handleLogoutClick = async () => {
  await handleLogout();
};

const Profile = () => {
  return (
    <div className="flex items-center justify-center top-2 w-72 shadow-lg right-18 absolute">
      <div className="bg-white font-bold w-72 p-4 rounded-md  h-full">
        <div className="flex flex-col space-y-6 text-black">
          {profileSections.map((section, index) => (
            <Link
              to={section.to}
              key={index}
              className="flex items-center space-x-2"
            >
              <img
                src={section.image}
                alt={`${section.label} icon`}
                className="w-6 h-6"
              />
              <span>{section.label}</span>
            </Link>
          ))}
        </div>
        <div className="border-t border-gray-300 mt-4 pt-4">
          <button
            onClick={handleLogoutClick}
            className="flex items-center space-x-2 text-black"
          >
            <img src={logout} alt="Logout icon" className="w-6 h-6" />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
