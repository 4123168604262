import React from 'react';
import NavbarUpdate from '../components/NavbarUpdate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faCartPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import img from '../assets/service.png';
import './ServiceViewAll.css';


const ServiceViewAll = () => {
  const generateOuterBoxes = () => {
    const outerBoxes = [];
    for (let i = 0; i < 12; i++) {
      const outerBoxStyle = {
        width: '25%',
        height: '35%',
        border: '2px solid black',
        margin: '1.5%',
        position: 'relative',
        fontWeight: '700',
        display: 'flex',

        lineHeight: '2.7vw',
        letterSpacing: '0em',
        textAlign: 'left',
        color: 'white',
        borderRadius: '10px',
      };

      const headingStyle = {
        width: '100%',
        height: '40%',
        top: '65%',
        left: '15.43px',
        fontFamily: 'Poppins',
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '28px',
        letterSpacing: '0em',
        textAlign: 'left',
        position: 'absolute',
        color: 'white',
      };

      const ratingBoxStyle = {
        width: '15%',
        height: '8%',
        top: '65%',
        left: '82%',
        borderRadius: '5px',
        backgroundColor: 'rgba(34, 197, 93, 1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        color: 'white',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 900,
      };

      const descriptionStyle = {
        width: '100%',
        height: '5%',
        top: '79%',
        left: '4%',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '15px',
        letterSpacing: '0em',
        textAlign: 'left',
        position: 'absolute',
        color: 'white',
      };
      const rsBoxStyle = {
        width: '100%',
        height: '30px',
        top: '85%',
        left: '4%',
        color: 'white',
        position: 'absolute',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '30px',
        fontWeight: '600',
      };

      const cartIconsStyle = {
        width: '20px',
        height: '20px',
        top: '86%',
        left: '77%',
        color: 'white ',
        position: 'absolute',
        fontSize: '30px',
        display: 'flex',
        justifyContent: 'space-between',
      };

      outerBoxes.push(
        <div Bkey={i} style={outerBoxStyle} >
          <div style={headingStyle}>Basic Service</div>
          <div style={ratingBoxStyle}>
            {' '}
            4.0{' '}
            <FontAwesomeIcon icon={faStar} style={{ fontSize: '13px', marginBottom: '3%' }} />
          </div>
          <div style={descriptionStyle}>1000 Kms or 1 Month warranty | Every 5000 Kms or 3 Months</div>
          <div style={rsBoxStyle}>₹<span style={{ color: 'dimgray' }}>3̶0̶0̶0̶ </span>1000</div>
          <div style={cartIconsStyle}>
            <button className='cartButton' style={{ marginRight: '80%' }}>
              <FontAwesomeIcon icon={faCartShopping} style={{ fontSize: '100%' }} />
            </button>
            <button>
              <FontAwesomeIcon className='cartButton' icon={faCartPlus} style={{ fontSize: '100%' }} />
            </button>
          </div>
          <img src={img} alt={`Service ${i}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
      );
    }
    return outerBoxes;
  };

  return (
    <div className='flex flex-col items-center justify-center w-full h-screen'>
      <NavbarUpdate showOnlyNav={true} style={{ display: 'flex' }} />
      <div className='flex-container' style={{ height: '85%', marginTop: '50px' }}>
        {generateOuterBoxes()}
      </div>
    </div>
  );
};

export default ServiceViewAll;
