import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import md5 from 'crypto-js/md5';
import axios from 'axios';
import pusher from '../config/pusherConfig';
import SERVER_URL from '../constants/constant'



function useService() {
  const [searching, setSearching] = useState(false);
  const [providerDetails, setProviderDetails] = useState({});
  const [otp, setOTP] = useState(null);
  const [otpVerified, setOtpVerified] = useState(false);
  const [userLocation, setLocation] = useState([]);
  const [providerLocation, setProviderLocation] = useState([]);
  const [locationHash, setLocationHash] = useState();
  const [mechanicsInSegment, setMechanicsInSegment] = useState([]);
  const [searchLocations, updateSearchLocations] = useState([]);
  const [segmentThreshold, setSegmentThreshold] = useState(10);
  const [ticket, setTicket] = useState(null);
  const [update, setUpdate] = useState(false);



  const updateTargetMechanics = (mechanics) => {
    setMechanicsInSegment(mechanics);
  };


  const channel = pusher.subscribe('ticket-response');
  channel.bind('success', async (data) =>  {
    //console.log('Finding match ', data['targetHashes'][0]);

   
    if (searching && ticket !== null) {
      //console.log(ticketId?._id);
      if (data['ticketId'] !== null && data['ticketId'] === ticket?._id) {
  
        setUpdate(true);
        
      }
    }
  });

  const sendRequests = async (targetHashes) => {
    console.log('Sending requests');

        axios
          .post(
            `${SERVER_URL}/mechanic/sendRequestForService`,
            {
              ticketId: ticket,
              targetHashes: targetHashes,
            }
          )
          .then((response) => console.log(response)).catch((err) => console.log(err));
   
    console.log('request sent');
  };

  useEffect(() => {

    if(searching){

    const lat = parseFloat(userLocation[0]);
    const lon = parseFloat(userLocation[1]);

    if (
      userLocation[0] !== null &&
      userLocation[0] !== undefined &&
      userLocation[0] !== 0
    ) {
      
      var mechs = [];
      var locs = [];
      var hashes = [];

      const getMechanics = async () => {
  
        axios
          .post(
            `${SERVER_URL}/mechanic/getLocationHash`,
            {
              location: [lat.toFixed(2), lon.toFixed(2)],
            }
          )
          .then((response) => {
            setLocationHash(response.data?.hash);
            axios
              .post(
                `${SERVER_URL}/mechanic/getMechanicsInSegment`,
                {
                  locationHash: response.data?.hash,
                  proximityThreshold: segmentThreshold,
                }
              )
              .then((response) => {
                mechs = response.data?.mechanics;
                locs = response.data?.locations;
                hashes = response.data?.hashes;

                updateTargetMechanics(mechs);
                updateSearchLocations(locs);

                sendRequests(hashes);
              });
          });
      };

      getMechanics();


    }
    }
    
  }, [searching]);



  return {
    searching,
    setSearching,
    providerDetails,
    otp,
    setOTP,
    otpVerified,
    setOtpVerified,
    //handleSearching,
    setLocation,
    userLocation,
    providerLocation,
    locationHash,
    mechanicsInSegment,
    searchLocations,
    setProviderDetails,
    setProviderLocation,
    setTicket,
    ticket,
    update
  };
}

export default useService;
