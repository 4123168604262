import { createContext, useContext, useMemo, useReducer, useEffect } from "react";
import {LocationReducer, initialstate} from "./LocationReducer";

export const LocationData = createContext('');

export function LocationWrapper({ children }) {
  const [locationstate, locationdispatch] = useReducer(LocationReducer,initialstate, () => {
   
        if(localStorage.getItem("locationData")) {
          return {location: JSON.parse(localStorage.getItem("locationData")).location}
        } else{
        return {location : {latitude: 28.63269634193645, longitude: 77.21978510339272}}
        }
     
  });
    
 
  useEffect(() => {
    localStorage.setItem('locationData',JSON.stringify(locationstate))
  }, [locationstate]);


  return (
    <LocationData.Provider value={{locationstate, locationdispatch}}>{children}</LocationData.Provider>
  );
}


export function LocationItemState() {
  return useContext(LocationData);
}