import React from 'react'

const UserMsg = ({key,message}) => {
  return (
    <div className='flex justify-end my-2 text-sm'>
        <div className='ml-2 flex flex-col items-end gap-2'>
        <div className='flex gap-4'>
        <p className='text-[#A0A0A0] font-extralight'>{message.sendTime}</p>
        <p className=' text-[#515151]'>{message.senderName}</p>
        </div>
        <div className='p-2 w-2/3 font-normal bg-[#22C55D] text-white text-sm rounded-l-md rounded-b-md border-[0.76px] border-[#E7E7E7] py-2'>{message.senderMsg}</div>
      </div>
    <img src={message.senderProfile} className='w-12 h-12 '/>
  </div>
  )
}

export default UserMsg
