const servicePartnerData = [
    {
      imgSrc: "ServicePartner/car-repair-maintenance-theme-mechanic-uniform-working-auto-service 1.png",
      title: "Expert Mechanical Services for your Vehicle:",
      description: "We have established collaborations with well-known mechanical workshops to deliver expert car services catering to your vehicle's mechanical requirements."
    },
    {
      imgSrc: "ServicePartner/female-mechanic-holding-spare-parts-car 1.png",
      title: "Improving Your Drive with Quality Auto Parts from Trusted Suppliers:",
      description: "We proudly provide high-quality car parts sourced from our vast network of reliable and reputable partners, ensuring unparalleled performance."
    },
    {
      imgSrc: "ServicePartner/front-view-delivery-man-with-packagaes 1.png",
      title: "Boosting Quality with Our Product Partners:",
      description: "We proudly join forces with respected product suppliers who share our unwavering commitment to excellence."
    },
  ];

export default servicePartnerData

