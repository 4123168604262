import React, { useState, useEffect, useRef} from "react";
import NavbarUpdate from "../components/NavbarUpdate";
import ShoppingCart from "../components/ShoppingCart";
import axios from "axios";
import { Link } from "react-router-dom";
import SERVER_URL from "../constants/constant";
import TrackPageNavbar from "../components/TrackPageNavbar";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import mixpanel from "../config/mixpanel";
import { useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  ExpandMoreOutlined as ExpandMoreOutlinedIcon,
  FilterListOutlined as FilterListOutlinedIcon,
  MyLocationOutlined as MyLocationOutlinedIcon,
  Home as HomeIcon,
  SearchOutlined as SearchOutlinedIcon,
} from "@mui/icons-material";
const arr = [1, 2];

const ServiceInfo = () => {
  const [isPickUpLocationMenuOpen, setIsPickUpLocationMenuOpen] =
    useState(false);
  const [isDropOffLocationMenuOpen, setIsDropOffLocationMenuOpen] =
    useState(false);
  const [brand, setBrand] = useState("");
  const [pickUpLocation, setPickUpLocation] = useState(null);
  const [dropOffLocation, setDropOffLocation] = useState(null);
  const [pickUpDate, setPickUpDate] = useState("");
  const [dropOffDate, setDropOffDate] = useState("");
  const [vehicle, setVehicle] = useState(null);
  const [originalEmail, setOriginalEmail] = useState("");
  const [pickUpDateError, setPickUpDateError] = useState("");
  const [dropOffDateError, setDropOffDateError] = useState("");

  const commonButtonRef = useRef(null);

  const [userData, setUserData] = useState({
    Name: "",
    phoneNumber: "",
    email: "",
  });
  const dropdownRef = useRef(null);

  useEffect(() => {
    setOriginalEmail(userData.email);
  }, []);

  const handleInputChange = (field, value) => {
    setUserData((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handlePhoneNumberChange = (e) => {
    let input = e.target.value;
    const onlyNumbers = input.replace(/\D/g, "");
    const truncatedNumber = onlyNumbers.slice(0, 10);
    handleInputChange("phoneNumber", truncatedNumber);
  };

  const handleEmailBlur = () => {
    if (!isValidEmail(userData.email)) {
      setUserData((prevDetails) => ({
        ...prevDetails,
        email: originalEmail,
      }));
      toast.error("Please enter a valid email address");
    } else {
      setOriginalEmail(userData.email);
    }
  };

  const isValidEmail = (email) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  };

  const handleSaveDetails = () => {
    console.log("User details submitted:", userData);
    setIsDetailsSubmitted(true);
    setShowModal(true);
  };

  const [showModal, setShowModal] = useState(false);
  const [isDetailsSubmitted, setIsDetailsSubmitted] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUserData((prevDetails) => ({
      ...prevDetails,
      Name: (userData.firstName || "") + " " + (userData.lastName || ""),
      email: userData.email || "",
      phoneNumber: userData.phoneNo || "",
    }));
  }, []);

  const handleVehicleSelect = (vehicle) => {
    setVehicle(vehicle);
  };

  const handleChange = (event) => {
    setPickUpDate(event.target.value);
  };

  const handleLocationSelect = (location, type) => {
    if (type === "pickUp") {
      setPickUpLocation(location);
      setIsPickUpLocationMenuOpen(false);
    } else if (type === "dropOff") {
      setDropOffLocation(location);
      setIsDropOffLocationMenuOpen(false);
    }
  };

  const handlePickUpDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedDate >= today) {
      setPickUpDate(event.target.value);
      setPickUpDateError("");
      setDropOffDate("");
    } else {
      setPickUpDate("");
      setPickUpDateError("Pick up date must be today or a future date.");

      setTimeout(() => {
        setPickUpDateError("");
      }, 3000);
    }
  };

  const handleDropOffDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const pickUpDateObj = new Date(pickUpDate);

    if (selectedDate > pickUpDateObj) {
      setDropOffDate(event.target.value);
      setDropOffDateError("");
    } else {
      setDropOffDate("");
      setDropOffDateError("Drop off date must be after the pick up date.");

      setTimeout(() => {
        setDropOffDateError("");
      }, 3000);
    }
  };

  const [vehicleData, setVehicleData] = useState(
    JSON.parse(localStorage.getItem("vehicle-selected"))
      ? JSON.parse(localStorage.getItem("vehicle-selected"))
      : []
  );
  
  useEffect(() => {
    if (vehicle) {
      setBrand(vehicle.brand);
    }
  }, [vehicle]);

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsPickUpLocationMenuOpen(false);
        setIsDropOffLocationMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const arr = [
    "1st Cross road, BAD COLONY,Banglore,600251",
    "1st Cross road, BAD COLONY,Banglore,600222",
  ];

  useEffect(() => {
    fetchCartItems();

    // Mixpanel page view tracking
    mixpanel.track("SERVICE_INFO_PAGE_VIEW", {
      page: window.location.pathname,
      user: {
        name: userData.Name,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
      },
    });
  }, []);

  const fetchCartItems = () => {
    axios
      .get(`${SERVER_URL}/user/cart/get`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("cart", response.data);

        const fetchedCartItems = response.data.cartItemsData || [];

        const initialCartItems = fetchedCartItems.map((item) => ({
          ...item,
          isChecked: true,
        }));

        setCartItems(initialCartItems);
        mixpanel.track("Fetch_Cart_Items");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleCheckboxChange = (itemId) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item._id === itemId ? { ...item, isChecked: !item.isChecked } : item
      )
    );

    mixpanel.track("Checkbox_Change", { itemId });
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((subtotal, item) => {
      return item.isChecked
        ? subtotal + (typeof item.price === "number" ? item.price : 0)
        : subtotal;
    }, 0);
  };

  const calculateTax = () => {
    const subtotal = calculateSubtotal();
    const tax = 0.1 * subtotal;
    return parseFloat(tax.toFixed(2));
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const tax = calculateTax();
    return subtotal + tax;
  };

  const executeRemove = (itemId) => {
    const data = {
      serviceId: itemId,
    };

    axios
      .delete(`${SERVER_URL}/user/cart/remove`, {
        data: data,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        fetchCartItems();

        mixpanel.track("Execute_Remove", { itemId });
      })
      .catch((error) => {
        console.error("Error removing item:", error);
      });
  };

  const handleRemoveItem = (itemId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui border-black bg-white rounded p-6 shadow-md max-w-xs mx-auto">
            <div className="relative mb-4">
              <button
                onClick={onClose}
                className="absolute top-0 right-0 text-gray-600 focus:outline-none hover:text-gray-800"
              >
                &times;
              </button>
              <h1 className="text-2xl font-bold mb-2">Remove from cart</h1>
              <p className="text-gray-700 mb-2">
                Are you sure you want to delete this service?
              </p>
            </div>
            <div className="border mb-2"></div>
            <div className="flex justify-between w-full mx-auto">
              <button
                onClick={onClose}
                className="bg-gray-300 text-black px-6 py-2 rounded focus:outline-none hover:bg-gray-400 border-r border-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  onClose();
                  executeRemove(itemId);
                }}
                className="bg-red-500 text-white px-6 py-2 rounded focus:outline-none hover:bg-red-600 flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 mr-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
                Remove
              </button>
            </div>
          </div>
        );
      },
      overlayClassName: "custom-overlay",
      transition: "scale",
    });
    mixpanel.track("Remove_Item", { itemId });
  };

  const handleCheckout = () => {
    mixpanel.track("Check_Out", { numItems: cartItems.length });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (isPickUpLocationMenuOpen &&
          commonButtonRef.current &&
          !commonButtonRef.current.contains(event.target)) ||
        (isDropOffLocationMenuOpen &&
          commonButtonRef.current &&
          !commonButtonRef.current.contains(event.target))
      ) {
        setIsPickUpLocationMenuOpen(false);
        setIsDropOffLocationMenuOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPickUpLocationMenuOpen, isDropOffLocationMenuOpen]);

  const goBack = () => {
    window.history.back();
  };
  

  return (
    <div>
      <TrackPageNavbar showOnlyNav={false} />
      <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
    <p class="text-sm text-gray-500 font-bold">
    <a href="/" class="hover:text-black hover:cursor-pointer">Home</a> 
              <span class="text-black"> / </span>
                <a href="/ServicePage" class="hover:text-black hover:cursor-pointer">Service</a>
                <span class="text-black"> / </span>
                <a href="javascript:void(0);" onclick={goBack} class="hover:text-black hover:cursor-pointer">Detail</a>
                <span class="text-black"> / </span>
                <span class="text-black">Info</span>
    </p>
</div>
      <div class="max-w-8xl mx-auto md:mx-6 p-6 ">
        <h2 class="text-2xl font-extrabold text-black">Vehicle Service Info</h2>
        <h3 class="text-base  text-black">
          Please Select your perfect date and time.
        </h3>
        <div class="grid lg:grid-cols-3 gap-12 relative mt-0">
          <div class="lg:col-span-2   ">
            <div className="flex mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                />
              </svg>
              <p className="font-bold text-base text-black ml-2">Pick up</p>
            </div>

            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="pickUpLocation"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Location
                </label>
                <div className="mt-2 relative">
                  <button
                    ref={commonButtonRef}
                    type="button"
                    onClick={() =>
                      setIsPickUpLocationMenuOpen(!isPickUpLocationMenuOpen)
                    }
                    className="relative inline-flex w-full h-11 justify-between items-center px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                  >
                    <span className="text-sm font-medium text-gray-700">
                      {pickUpLocation
                        ? pickUpLocation
                        : "Select a pick-up address"}
                    </span>
                    <svg
                      className={`-mr-1 h-5 w-5 text-gray-400 ${
                        isPickUpLocationMenuOpen ? "transform rotate-180" : ""
                      }`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {/* Pick-up Location Dropdown Menu */}
                  {isPickUpLocationMenuOpen && (
                    <div
                      className="origin-top-left absolute z-50 mt-2 w-full sm:max-w-xs rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none location-dropdown pick-up-location-menu"
                      ref={dropdownRef}
                    >
                      <div className="p-6 flex flex-col gap-y-5">
                        <div className="flex gap-x-3 border-[1.5px] border-gray-200 rounded p-3 w-full cursor-pointer hover:bg-gray-100">
                          <MyLocationOutlinedIcon className="opacity-75 mt-1.5" />
                          <div>
                            <p className="font-bold mb-2">
                              Get Current Location
                            </p>
                            <p className="text-sm text-gray-400 font-light">
                              Using GPS
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="font-semibold">Pick-up Address</p>
                          <div className="flex flex-col">
                            {arr.map((el, index) => (
                              <div
                                className={`flex gap-x-3 py-3 rounded w-full ${
                                  index === arr.length - 1
                                    ? ""
                                    : "border-b-2 border-dotted"
                                } hover:bg-gray-100 pl-1 cursor-pointer`}
                                key={index}
                                onClick={() =>
                                  handleLocationSelect(el, "pickUp")
                                }
                              >
                                <HomeIcon
                                  style={{ width: "18px", height: "18px" }}
                                />
                                <p className="text-xs">{el}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Date
                </label>
                <div className="mt-2">
                  <input
                    type="Date"
                    id="Pickupdate"
                    name="select a pickUp Date"
                    value={pickUpDate}
                    onChange={handlePickUpDateChange}
                    className="inline-flex w-full h-11 justify-between items-center px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                  />
                  {pickUpDateError && (
                    <p className="text-red-500 text-sm mt-1">
                      {pickUpDateError}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Time
                </label>
                <div className="mt-2">
                  <input
                    type="time"
                    className="inline-flex w-full h-11 justify-between items-center px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black mb-4"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                />
              </svg>

              <p className=" font-bold text-base text-black ml-8 mt-[-1.5rem] mb-2">
                Drop Off
              </p>
            </div>

            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="dropOffLocation"
                  className="block text-gray-700 font-bold mb-2 mt-4"
                >
                  Location
                </label>
                <div className="mt-2 relative">
                  <button
                    ref={commonButtonRef}
                    type="button"
                    onClick={() =>
                      setIsDropOffLocationMenuOpen(!isDropOffLocationMenuOpen)
                    }
                    className="relative inline-flex w-full h-11 justify-between items-center px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                  >
                    <span className="text-sm font-medium text-gray-700">
                      {dropOffLocation
                        ? dropOffLocation
                        : "Select a drop-off address"}
                    </span>
                    <svg
                      className={`-mr-1 h-5 w-5 text-gray-400 ${
                        isDropOffLocationMenuOpen ? "transform rotate-180" : ""
                      }`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {/* Drop-off Location Dropdown Menu */}
                  {isDropOffLocationMenuOpen && (
                    <div
                      className="origin-top-left absolute z-50 mt-2 w-full sm:max-w-xs rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none location-dropdown drop-off-location-menu"
                      ref={dropdownRef}
                    >
                      <div className="p-6 flex flex-col gap-y-5">
                        <div className="flex gap-x-3 border-[1.5px] border-gray-200 rounded p-3 w-full cursor-pointer hover:bg-gray-100">
                          <MyLocationOutlinedIcon className="opacity-75 mt-1.5" />
                          <div>
                            <p className="font-bold mb-2">
                              Get Current Location
                            </p>
                            <p className="text-sm text-gray-400 font-light">
                              Using GPS
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="font-semibold">Drop-off Address</p>
                          <div className="flex flex-col">
                            {arr.map((el, index) => (
                              <div
                                className={`flex gap-x-3 py-3 rounded w-full ${
                                  index === arr.length - 1
                                    ? ""
                                    : "border-b-2 border-dotted"
                                } hover:bg-gray-100 pl-1 cursor-pointer`}
                                key={index}
                                onClick={() =>
                                  handleLocationSelect(el, "dropOff")
                                }
                              >
                                <HomeIcon
                                  style={{ width: "18px", height: "18px" }}
                                />
                                <p className="text-xs">{el}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Date
                </label>
                <div className="mt-2 ">
                  <input
                    type="Date"
                    id="DropOffDate"
                    value={dropOffDate}
                    onChange={handleDropOffDateChange}
                    name="select a drop-off Date"
                    className=" inline-flex w-full h-11 justify-between items-center px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                  />
                  {dropOffDateError && (
                    <p className="text-red-500 text-sm mt-1">
                      {dropOffDateError}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="Time"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Time
                </label>
                <div className="mt-2">
                  <input
                    type="time"
                    className="inline-flex w-full h-11 justify-between items-center px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black mb-4"
                  />
                </div>
              </div>
            </div>

            <div className=" border "></div>

            <div className="mb-4">
              <div className="flex justify-between">
                <div className="mt-4">
                  <label className="block font-bold text-base text-black mb-2">
                    Vehicle Detail
                  </label>
                </div>
                <div>
                  <div className="flex items-center mt-2">
                    <a
                      href="#"
                      className=" text-green-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900"
                    >
                      Change
                    </a>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-3 mt-2">
                <div className="w-full sm:w-11/12 mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    Brand
                  </label>
                  <div className="border border-gray-300 p-4 rounded-md">
                    {vehicleData.brand
                      ? vehicleData.brand.name
                      : "select brand"}
                  </div>
                  <div className="w-full mt-4">
                    <label className="block text-gray-700 font-bold mb-2">
                      Fuel
                    </label>
                    <div className="border border-gray-300 p-4 rounded-md">
                      {vehicleData.fuel ? vehicleData.fuel : "Fuel type"}
                    </div>
                  </div>
                </div>

                <div className="w-full sm:w-11/12 mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    Model
                  </label>
                  <div className="border border-gray-300 p-4 rounded-md">
                    {vehicleData.model
                      ? vehicleData.model.name
                      : "select Model"}
                  </div>
                </div>

                <div className="w-full">
                  <div className="mt-2" style={{ maxHeight: "200px" }}>
                    <img
                      src="https://images.unsplash.com/photo-1578659258511-4a4e7dee7344?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Vehicle"
                      className="w-full max-w-sm border border-gray-300 rounded-md mx-auto"
                    />
                  </div>
                </div>
              </div>
              <div className="border"></div>
              <div className="mt-4">
                <label className="block font-bold text-base text-black mb-2">
                  Contact Info
                </label>
              </div>
              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      placeholder="Enter name"
                      onChange={(e) =>
                        handleInputChange("Name", e.target.value)
                      }
                      value={userData.Name}
                      autoComplete="given-name"
                      className="w-full border border-gray-300 p-2 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Phone Number
                  </label>
                  <div className="mt-2">
                    <input
                      type="tel"
                      placeholder="10 digit number"
                      onChange={handlePhoneNumberChange}
                      value={userData.phoneNumber}
                      className="w-full border border-gray-300 p-2 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Email id
                  </label>
                  <div className="mt-2">
                    <input
                      type="email"
                      placeholder="Enter Mail Id"
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      onBlur={handleEmailBlur} // Added onBlur event handler
                      value={userData.email}
                      className="w-full border border-gray-300 p-2 rounded-md "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white h-max rounded-md p-6 shadow-[0_0px_4px_0px_rgba(6,81,237,0.2)] sticky top-0">
            <label
              htmlFor=""
              className="text-2xl font-extrabold text-black mb-2"
            >
              Cart
            </label>
            <h2 className="text-semibold mb-1 text-justify">
            Review and finalize your selected items for your vehicle for a seamless checkout experience
            </h2>

            <div className="flow-root mt-1 mb-2 max-h-[250px] overflow-y-auto">
              <ul className="-my-2">
                {cartItems.map((item) => (
                  <li
                    key={item._id}
                    className="flex flex-col p-2 border-b-2 space-y-3 py-4 text-left sm:flex-row sm:space-x-5 sm:space-y-0"
                  >
                    <div className="shrink-0 relative">
                      <img
                        className="h-24 w-28 max-w-full rounded-lg object-cover"
                        src="/cartcarousel.png"
                        alt={item.name}
                      />
                    </div>

                    <div className="relative flex flex-1 flex-col justify-between">
                      <div className="sm:col-gap-5 sm:grid sm:grid-cols-2">
                        <div className="pr-8 sm:pr-5">
                          <p className="text-base font-semibold text-gray-900">
                            {item.name}
                          </p>
                          <input
                            id={`checkbox-${item._id}`}
                            type="checkbox"
                            checked={item.isChecked}
                            onChange={() => handleCheckboxChange(item._id)}
                            className="w-4 h-4 justify-between md:absolute md:right-2 md:top-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />

                          <div className="flex items-center bg-green-500 p-2 rounded w-10 h-5 mt-1">
                            <p className="text-sm font-bold text-gray-900 dark:text-white">
                              {item.avgRating || "0"}
                            </p>
                            <svg
                              className="w-3 h-3 text-white ml-1"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 22 20"
                            >
                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                            </svg>
                          </div>

                          <p className="font-semibold mx-0 mt-2 mb-0 text-sm text-black-400">
                            ₹ {item.price}
                          </p>
                        </div>
                      </div>

                      <div className="absolute top-0 right-0 flex sm:bottom-0 sm:top-auto">
                        <button
                          type="button"
                          className="flex rounded p-2 text-center md:mt-1 mt-5 text-red-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900"
                          onClick={() => handleRemoveItem(item._id)}
                        >
                          <div>Remove</div>
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div class="relative">
              <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                  />
                </svg>
              </div>
              <input
                type="Coupon"
                class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 "
                placeholder="Apply Promo Code"
                required
              />
              <button
                type="submit"
                className="text-green-500 absolute end-2.5 bottom-2.5 bg-white font-medium rounded-lg text-sm px-4 py-2 dark:hover:bg-green-700 transition-all duration-300 ease-in-out hover:translate-y-0.5 hover:shadow-md hover:bg-green-500 hover:text-white"
              >
                Apply now
              </button>
            </div>
            <div className="mt-4 border"></div>

            <ul className="text-[#333] text-sm mt-2">
              <li className="flex flex-wrap gap-4 py-3 text-lg font-bold">
                Subtotal
                <span className="ml-auto font-bold">
                  ₹{calculateSubtotal()}
                </span>
              </li>
              <li className="flex flex-wrap gap-4 py-3 text-lg font-bold">
                Tax <span className="ml-auto font-bold">₹{calculateTax()}</span>
              </li>
              <li className="border"></li>
              <li className="flex flex-wrap gap-4 py-3 text-xl font-extrabold">
                Total Cost<span className="ml-auto">₹{calculateTotal()}</span>
              </li>
            </ul>

            <div className="mt-1 border mb-4"></div>
            <div className="flex justify-between ">
              <button
                type="button"
                className="group inline-flex rounded-md py-2 text-md font-semibold text-green-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900"
              >
                <Link to="/ServicePage">+ Add more service</Link>
              </button>

              <button
                type="button"
                onClick={handleCheckout}
                class="py-3 px-4  inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-green-500 text-white hover:bg-green-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                <Link to="/paymentscreen">Check Out</Link>
                <svg
                  class="flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m5 11 4-7" />
                  <path d="m19 11-4-7" />
                  <path d="M2 11h20" />
                  <path d="m3.5 11 1.6 7.4a2 2 0 0 0 2 1.6h9.8c.9 0 1.8-.7 2-1.6l1.7-7.4" />
                  <path d="m9 11 1 9" />
                  <path d="M4.5 15.5h15" />
                  <path d="m15 11-1 9" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceInfo;
