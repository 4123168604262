import React from 'react'

const MechanicMsg = ({key,message}) => {
    console.log(message)
    const sender=message.senderName;
  return (
    <div className='ml-4 flex justify-start my-2 text-sm gap-4'>
      <img src={message.senderProfile} className='w-12 h-12 '/>
      <div className=' flex flex-col gap-2'>
        <div className='flex gap-4'>
        <p className=' text-[#515151]'>{message.senderName}</p>
          <p className='text-[#A0A0A0] font-extralight'>{message.sendTime}</p>
        </div>
        
        <div className='p-2 font-normal w-2/3 text-sm rounded-r-md rounded-b-md border-[0.76px] border-[#E7E7E7] py-2'>{message.senderMsg}</div>
      </div>
    </div>
  )
}

export default MechanicMsg
