import React, { useState} from 'react'
import Ellipse from '../assets/Ellipse 2.png';
import { motion} from 'framer-motion';
import { RxCross1 } from "react-icons/rx"
import { Link } from 'react-router-dom';
import Logo from '../assets/Logo.png';


const OrderPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [menu, setMenu] = useState(false);

  const handleModalContentClick = (event) => {
    event.stopPropagation();
  };


  return (
    <div onClick={() => {
      if (showModal === true) {
        setShowModal(false)
      }
    }}>
      {/*
      <nav className="bg-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <h1 className="text-white font-bold text-lg">Helpy Moto</h1>
            </div>
          
          </div>
        </div>
      </nav>
  */}
      <div
        className={
          menu
            ? 'flex flex-col w-full  backdrop-blur-lg text-black px-4 py-2'
            : 'flex flex-col w-full bg-white backdrop-blur-lg text-black px-4 py-2'
        }
      >
        {/* Logo and Slogan */}
        <div className='flex items-center justify-between bg-transparent '>
          <div className='flex space-x-3 items-center '>
            <img
              src={Logo}
              alt='logo'
              className='w-[80px] h-[50px] object-contain'
            />
            <p className='text-center font-semibold opacity-100'>For Unstoppable Journey</p>

            {/* Rest buttons */}
            <ul className=' w-full text-lg items-center hidden md:flex lg:flex xl:flex space-x-3'>
              <li className='ml-3 nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-red-500 after:h-[2px] -bottom-[2px] w-[85px]  '>
                <Link
                  className='text-black hover:text-black hover:no-underline text-start font-semibold'
                  to='/'
                >
                  Home
                </Link>
              </li>
              <li className='nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-red-500 after:h-[2px] -bottom-[2px] w-[85px] '>
                <Link
                  className='text-black hover:text-black hover:no-underline text-start font-semibold'
                  to='/'
                >
                  Services
                </Link>
              </li>
              <li className='nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-red-500 after:h-[2px] -bottom-[2px] w-[85px] '>
                <Link
                  className='text-black hover:text-black hover:no-underline text-start font-semibold'
                  to='/'
                >
                  HelpyStore
                </Link>
              </li>

              <p className=' after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-red-500 after:h-[2px] -bottom-[2px] w-[100px]  '>
                <Link
                  className='text-black hover:text-black hover:no-underline text-start font-semibold'
                  to='/'
                >
                  About Us
                </Link>
              </p>
            </ul>
          </div>
          <div className='md:hidden lg:hidden xl:hidden  justify-end w-[35px]'>
            <div
              className='w-[35px] h-[25px] flex flex-col justify-between cursor-pointer'
              onClick={() => setMenu(!menu)}
            >
              <div className='h-[3px] bg-black w-full'></div>
              <div className='h-[3px] bg-black w-full'></div>
              <div className='h-[3px] bg-black w-full'></div>
            </div>
            <div
              className={
                menu
                  ? ' z-40  duration-1000 ease-in-out transform transition'
                  : ' z-40 h-[0px] hidden duration-100000 ease-in-out transform transition'
              }
            >
              <div className='flex w-full pb-1 justify-end'>
                <ul className=' text-lg items-end flex flex-col '>
                  <li className='nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-red-500 after:h-[2px] -bottom-[2px] w-[85px] text-end '>
                    <Link
                      className='text-black hover:text-white hover:no-underline text-end'
                      to='/'
                    >
                      Home
                    </Link>
                  </li>
                  <li className='nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-red-500 after:h-[2px] -bottom-[2px] w-[85px] text-end'>
                    <Link
                      className='text-black hover:text-white hover:no-underline text-end'
                      to='/'
                    >
                      Services
                    </Link>
                  </li>
                  <li className='nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-red-500 after:h-[2px] -bottom-[2px] w-[85px] text-end'>
                    <Link
                      className='text-black hover:text-white hover:no-underline text-end'
                      to='/'
                    >
                      HelpyStore
                    </Link>
                  </li>
                  <li className='nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-red-500 after:h-[2px] -bottom-[2px] w-[85px] text-end'>
                    <Link
                      className='text-black hover:text-white hover:no-underline text-end'
                      to='/'
                    >
                      About Us
                    </Link>
                  </li>


                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h2 className="text-2xl font-bold mb-4">Your Orders</h2>
        <div className="flex space-x-4 mb-4">
          <button className="bg-on-time-color text-black py-2 px-8 w-192 h-53 rounded-lg rounded-15 shadow-lg relative">
            On-Time
            <span className="bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
              2
            </span>
          </button>
          <button className="bg-white text-black py-2 px-8 w-192 h-53 rounded-lg rounded-15 shadow-lg relative">
            Scheduled
            <span className="bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
              1
            </span>
          </button>
        </div>

        <p className="text-xl font-semibold mb-4">Ongoing services</p>

        <div className="flex flex-col flex-wrap gap-4">
          <div className="bg-white rounded-lg shadow-lg p-4 flex items-center space-x-4 w-full md:w-2/3 hover:border-2 cursor-pointer " onClick={() => { setShowModal(!showModal) }}>
            <div>
              <img className="h-16 w-16 rounded-full" src={Ellipse} alt="Service Provider" />
              <p className="text-gray-800 text-sm font-semibold mb-1">Provider Name</p>
              <p className="text-sm text-gray-500 mb-2">Workshop Name</p>
            </div>

            <div className='flex-grow'>
              <p className="text-gray-800 font-semibold">Date of Booking: 2023-07-01</p>
              <p className="text-gray-500">Ticket ID: <span className="bg-green-500 text-white rounded-lg rounded-25 py-1 px-2 ml-2">123456789</span></p>
              <p className="text-gray-500">Vehicle Details:</p>
              <div className="flex">
                <div className="mr-auto">
                  <p className="text-sm text-gray-500">Vehicle Number: ABC123</p>
                  <p className="text-sm text-gray-500">Model: XYZ</p>
                </div>
                <p className="text-sm text-gray-500">Brand: BrandXYZ</p>
              </div>

              <div className="flex items-center justify-between mt-4">
                <p className="text-gray-800 font-bold">Current Bill Amount: $50</p>
                <button className="bg-pay-now ml-auto text-white py-2 px-3 rounded">Pay Now</button>
              </div>
            </div>

          </div>
          <div className="bg-white rounded-lg shadow-lg p-4 flex items-center space-x-4 w-full md:w-2/3 hover:border-2 cursor-pointer " onClick={() => { setShowModal(!showModal) }}>
            <div>
              <img className="h-16 w-16 rounded-full" src={Ellipse} alt="Service Provider" />
              <p className="text-gray-800 text-sm font-semibold mb-1">Provider Name</p>
              <p className="text-sm text-gray-500 mb-2">Workshop Name</p>
            </div>

            <div className='flex-grow'>
              <p className="text-gray-800 font-semibold">Date of Booking: 2023-07-01</p>
              <p className="text-gray-500">Ticket ID: <span className="bg-green-500 text-white rounded-lg rounded-25 py-1 px-2 ml-2">123456789</span></p>
              <p className="text-gray-500">Vehicle Details:</p>
              <div className="flex">
                <div className="mr-auto">
                  <p className="text-sm text-gray-500">Vehicle Number: ABC123</p>
                  <p className="text-sm text-gray-500">Model: XYZ</p>
                </div>
                <p className="text-sm text-gray-500">Brand: BrandXYZ</p>
              </div>

              <div className="flex items-center justify-between mt-4">
                <p className="text-gray-800 font-bold">Current Bill Amount: $50</p>
                <button className="bg-pay-now ml-auto text-white py-2 px-3 rounded">Pay Now</button>
              </div>
            </div>

          </div>


        </div>
      </div>
      {showModal ?

        <motion.div animate={{ x: [100, 0] }}
          transition={{ duration: 0.7 }}
          className='bg-white w-full left-0 fixed top-5 right-0 md:left-2/3 z-[400] md:w-1/3 h-full shadow-xl'
          onClick={handleModalContentClick}
        >
          <div className='flex flex-col m-4'>
            <div className='flex flex-row'>
              <div className='text-2xl font-medium'>Order Details</div>
              <div className='ml-auto hover:cursor-pointer'><RxCross1 onClick={() => setShowModal(false)} /></div>
            </div>
            <div className='mt-3 flex flex-row'>
              <p className="text-gray-500">Ticket ID: <span className="bg-green-500 text-white rounded-lg rounded-25 py-1 px-2 ml-2">123456789</span></p>
              <div className='ml-auto'>Status:<div className='font-medium'>Completed</div></div>
            </div>
            <div className='flex flex-col m-3'>
              <div className='flex flex-col'>
                <h1 className='font-bold'>Vehicle Details:</h1>
                <div className=''>Vehicle Number:<span className='font-medium'>MH14HPXXXX</span></div>
                <div className=''>Brand:<span className='font-medium'>Honda</span></div>
                <div className=''>Model:<span className='font-medium'>City</span></div>
              </div>
            </div>
            <div className='flex flex-col m-3'>
              <div className='flex flex-col'>
                <h1 className='font-bold'>Provider Details:</h1>
                <div className='flex flex-row m-2'>
                  <img className="h-16 w-16 rounded-full" src={Ellipse} alt="Service Provider" />
                  <div className='flex flex-col ml-3'>
                    <div className=''>Name:<span className='font-medium'>Provider Name</span></div>
                    <div className=''>Provider Name:<span className='font-medium'>Workshop Name</span></div>
                  </div>
                </div>
              </div>

            </div>
            <div className='flex flex-col m-3'>
              <h1 className='font-bold' >Services Added:</h1>
              
            </div>

          </div>
        </motion.div>

        :
        <>

        </>}
    </div>
  )
}

export default OrderPage