import React, { useEffect, useState } from "react";
import TrackPageNavbar from "../components/TrackPageNavbar";
import { FaCartPlus, FaShoppingCart } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import img from "../assets/service.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import HomeIcon from "@mui/icons-material/Home";

const ServiceViewAllNew = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const categoryName = location.state?.categoryName;
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState(""); // New state for sorting

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/service/getAll?category=${categoryName}&modelId=`)
      .then((response) => {
        console.log("services", response.data.services[0].createdAt);
        const { services } = response.data;
        console.log("Fetched services:", services);

        if (services && services.length > 0) {
          setServices(services);
          setFilteredServices(services);
        } else {
          console.error("No services found in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [categoryName]);

  const handleService = (id) => {
    console.log(`Clicked on service with ID: ${id}`);
    navigate("/servicepage/1", { state: { serviceId: id } });
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
  };

  const handleSearch = () => {
    // Filter services based on the search query
    const filtered = services.filter((service) =>
      service.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredServices(filtered);
  };

  const handleSort = (option) => {
    setSortOption(option);

    // Sort services based on the selected option
    let sortedServices = [...filteredServices];
    switch (option) {
      case "lowToHigh":
        sortedServices.sort((a, b) => a.price - b.price);
        break;
      case "highToLow":
        sortedServices.sort((a, b) => b.price - a.price);
        break;
      case "newest":
        sortedServices.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        break;
      case "popular":
        sortedServices.sort((a, b) => b.avgRating - a.avgRating);

        break;
      default:
        break;
    }

    setFilteredServices(sortedServices);
  };

  return (
    <div>
      <TrackPageNavbar showOnlyNav={false} />
      <div className="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
        <p className="text-sm text-gray-500 font-bold">
        <a href="/" class="hover:text-black hover:cursor-pointer">Home</a> 
        <span className="text-black"> / </span>
        <a href="/ServicePage" class="hover:text-black hover:cursor-pointer">Service</a> 
        <span className="text-black"> / </span>
           
          <span className="text-black">View More</span>
        </p>
        <div className="w-full lg:w-3/4 flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center justify-between gap-x-8">
          <div className="w-full sm:w-3/5 flex items-center border-[1.5px] border-gray-400 rounded p-2">
            <div className="flex items-center pl-1 sm:pl-2 gap-x-2">
              <SearchOutlinedIcon style={{ width: "18px", height: "18px" }} />
              <input
                type="text"
                placeholder="Search Service"
                className="outline-none text-xs sm:text-base"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onChangeCapture={handleSearch}
              />
            </div>
          </div>
          <div className="flex items-center justify-between gap-x-3">
            <p className="text-base text-black">
              {filteredServices.length} Services
            </p>
            <div className="flex items-center gap-x-2 relative border-[1.5px] border-gray-400 outline-none p-1 sm:p-1.5 px-1.5 sm:px-2 rounded">
              Sort
              <ExpandMoreOutlinedIcon
                onClick={toggleSortDropdown}
                className="cursor-pointer"
              />
              {isSortDropdownOpen && (
                <div className="origin-top-right absolute -left-7 top-8 mt-2 w-[150px] z-50 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-4 py-6">
                    {/* Add onClick handlers for sorting options */}
                    <p
                      onClick={() => handleSort("lowToHigh")}
                      className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer"
                    >
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      Low to High
                    </p>
                    <p
                      onClick={() => handleSort("highToLow")}
                      className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer"
                    >
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      High to Low
                    </p>
                    <p
                      onClick={() => handleSort("newest")}
                      className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer"
                    >
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      Newest
                    </p>
                    <p
                      onClick={() => handleSort("popular")}
                      className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer"
                    >
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      Popular
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 md:px-8 lg:px-14 py-3 mt-5 mb-20">
        <p className="text-xl font-bold max-w-max relative after:h-[2px] after:bg-green-400 after:mt-0.5 after:w-[40px] after:flex after:absolute after:right-0">
          {categoryName}
        </p>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6">
          {filteredServices.map((item, index) => (
            <div
              className="relative rounded-md"
              key={index}
              onClick={() => handleService(item._id)}
            >
              <img src={img} alt="" className="rounded-md" />
              <div className="absolute bottom-0 p-2 sm:p-3.5 w-full">
                <div className="flex justify-between items-center">
                  <p className="text-[9px] md:text-base lg:text-lg xl:text-xl text-white font-bold">
                    {item.name}
                  </p>
                  <p className="text-white text-[8px] md:text-xs font-semibold py-0 sm:py-0.5 px-1 rounded bg-green-500 flex items-center gap-x-1">
                    {item.avgRating}
                    <FaStar color="white" />
                  </p>
                </div>
                <p className="hidden sm:block text-white opacity-85 text-[8px] sm:text-[10.5px]">
                  1000 Kms or 1 Month warranty | Every 5000 Kms or 3 Months
                </p>
                <div className="flex justify-between items-center">
                  <p className="text-[8px] sm:text-xs xl:text-sm text-white font-bold">
                    Rs.{" "}
                    <span className="line-through text-gray-400 font-light">
                      {item.price}
                    </span>
                    {item.price}
                  </p>
                  <div className="flex justify-between items-center gap-x-5">
                    <FaShoppingCart
                      color="white"
                      className="w-3 h-3 md:w-5 md:h-5"
                    />
                    <FaCartPlus
                      color="white"
                      className="w-3 h-3 md:w-5 md:h-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceViewAllNew;
