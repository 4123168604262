import React, { useState,useContext } from 'react'
import NavbarUpdate from '../components/NavbarUpdate';
import { Link,useNavigate } from 'react-router-dom';
import { useWishlist } from '../context/WishlistContext';
import { CartContext } from '../context/CartContext';
import {RiDeleteBin6Line} from 'react-icons/ri'
import toast from 'react-hot-toast';



const WishlistScreen = () => {

  const navigate = useNavigate()
  //const [wishlistItems, setWishlistItems] = useState([]);
  const { wishlistItems, dispatch: wishlistDispatch } = useWishlist();
  const { dispatch: cartDispatch } = useContext(CartContext);

  const removeFromWishlist = (item) => {
    wishlistDispatch({ type: 'REMOVE_FROM_WISHLIST', payload: item });
    notifyDeleteWishlist();
  };

  const addToCartFromWishlist = (item) => {
    cartDispatch({ type: 'ADD_TO_CART', payload: item });
    removeFromWishlist(item);
    navigate("/cart")
  };

  const notifyDeleteWishlist = () => toast.success("Removed from wishlist")


  return (
    <div className='flex flex-col w-full h-screen'>
      <NavbarUpdate showOnlyNav={true} />
      <div className='p-4'>
        <div className='grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3'>
          {/* Cart Section */}
          <div className='col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 flex flex-col'>
            <p className='text-3xl font-bold mb-6'>Your Wishlist</p>
            <div className='flex flex-col w-full'>
              {wishlistItems.length > 0 ? (
                <div>{/* Loop through items here */}

                  {wishlistItems.map((item, index) => (
                    <div key={index} className='border p-4 mb-4'>
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center'>
                          <img
                            src={item.photo}
                            alt={item.name}
                            className='w-16 h-16 mr-4 object-contain'
                          />
                          <h2 className='text-lg font-semibold'>{item.name}</h2>
                        </div>

                        <div className='flex items-center justify-center space-x-20'>
                          <button className='text-white bg-[#18f98f]/80 hover:bg-[#18f98f] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'
                            onClick={() => addToCartFromWishlist(item)}
                            
                          >
                            Add to Cart
                          </button>
                          <button
                            onClick={() => removeFromWishlist(item)}
                            className='text-red-600 hover:text-red-800 ml-4'
                          >
                            <RiDeleteBin6Line/>
                          </button>
                        </div>

                      </div>
                      <p className='mt-2 text-gray-600'>
                        <span className='font-bold'>Price :</span>{item.discount !== null ? <div className='flex flex-col'>
                                    <p className='text-lg font-semibold'>&#8377; {Number(item.basePrice-Number(item.basePrice*item.discount/100)).toFixed(0)}</p>
                                    <s className='text-sm text-red-500'>&#8377; {item.basePrice}</s>
                                    
                                </div> : <div className='flex flex-col'>
                                    <p className='text-lg font-semibold'>&#8377; {Number(item.basePrice)}</p>
                                    
                                    
                                </div>}
                      </p>
                    </div>
                  ))}

                </div>
              ) : (
                <Link to='/'>
                  <div className='flex my-20 w-full justify-center items-center flex-col'>
                    <p className='text-xl mt-8 text-center mx-2'>No items in the wishlist</p>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WishlistScreen