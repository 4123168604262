import React, { useState, useEffect } from "react";
import picture from "../../src/assets/profile/picture.png";
import coverImg from "../../src/assets/coverImg.png";
import change from "../../src/assets/profile/change.png";
import { MdCloudUpload } from "react-icons/md";
import remove from "../../src/assets/profile/remove.png";
import { PiCamera } from "react-icons/pi";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import TrackPageNavbar from "./TrackPageNavbar";
import mixpanel from "../config/mixpanel";
import { input } from "@material-tailwind/react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center">
      <div className="bg-black opacity-50 fixed inset-0"></div>
      <div className="bg-white p-8 rounded-md z-20">
        {children}
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const PersonalInformation = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    countryCode: "",
    phoneNumber: "",
    email: "",
    address: "",
    landmark: "",
    state: "",
    pincode: "",
    licenceNumber: "",
    licenceName: "",
    rcNumber: "",
    rcName: "",
    profilePicture: null,
    coverImg: null,
    licenceImg: null,
    rcImg: null,
  });

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];
  const [showModal, setShowModal] = useState(false);
  const [isDetailsSubmitted, setIsDetailsSubmitted] = useState(false);
  const authToken = localStorage.getItem("TOKEN");
  console.log("user token", authToken);

  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString); // Assuming user details are stored as JSON

  // Extract ID from user details
  const userId = userData._id;
  console.log("userId", userId);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    //setFiles([...e.target.files]);
    const imageUrl = URL.createObjectURL(file);

    setUserDetails({
      ...userDetails,
      profilePicture: imageUrl,
    });
  };
  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);

    setUserDetails({
      ...userDetails,
      coverImg: imageUrl,
    });
  };

  const handleRemovePicture = () => {
    setUserDetails({
      ...userDetails,
      profilePicture: null,
    });
  };

  const handleInputChange = (field, value) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));

    mixpanel.track(`Input Changed - ${field}`);
  };
  const handleLicenceUpload = (e) => {
    const file = e.target.files[0];
    const licenceUrl = URL.createObjectURL(file);

    setUserDetails({
      ...userDetails,
      licenceImg: licenceUrl,
    });
    console.log(userDetails);
  };
  const handleRcUpload = (e) => {
    const file = e.target.files[0];
    //console.log(files.length)

    const rcUrl = URL.createObjectURL(file);

    setUserDetails({
      ...userDetails,
      rcImg: rcUrl,
    });

    console.log(userDetails);
  };
  const isNameValid = () => {
    const firstName = userDetails.firstName.trim();
    const lastName = userDetails.lastName.trim();

    let isFirstNameValid = true;
    let isLastNameValid = true;

    if (firstName === "") {
      console.log("Invalid first name. Please enter a first name.");
      isFirstNameValid = false;
    }

    if (lastName === "") {
      console.log("Invalid last name. Please enter a last name.");
      isLastNameValid = false;
    }

    const nameRegex = /^[A-Za-z]+$/;

    if (!nameRegex.test(firstName)) {
      console.log("Invalid first name format. Please use only alphabets.");
      isFirstNameValid = false;
    }

    if (!nameRegex.test(lastName)) {
      console.log("Invalid last name format. Please use only alphabets.");
      isLastNameValid = false;
    }

    return isFirstNameValid && isLastNameValid;
  };

  const handlePhoneNumberChange = (e) => {
    const enteredValue = e.target.value;
    // Allow only numeric input
    const numericValue = enteredValue.replace(/\D/g, "");

    // Limit to 10 digits
    const limitedValue = numericValue.slice(0, 10);

    setUserDetails({
      ...userDetails,
      phoneNumber: limitedValue,
    });
  };

  const handlePincodeChange = (e) => {
    const enteredValue = e.target.value;
    // Allow only numeric input
    const numericValue = enteredValue.replace(/\D/g, "");

    setUserDetails({
      ...userDetails,
      pincode: numericValue,
    });
  };

  const isPincodeValid = () => {
    const pincodeRegex = /^[0-9]+$/;
    return pincodeRegex.test(userDetails.pincode);
  };

  console.log("userID", userId);

  const handleSaveDetails = () => {
    console.log("User details submitted:", userDetails);
    const data = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      userphoto: userDetails.profilePicture,
      Landmark: userDetails.landmark,
      pincode: userDetails.pincode,
      State: userDetails.state,
      RCname: userDetails.rcName,
      licenseName: userDetails.licenceName,
      email: userDetails.email,
      phoneNo: userDetails.phoneNumber,
      photo: userDetails.coverImg,
      address: userDetails.address,
      licenseNumber: userDetails.licenceNumber,
      carLicenseScan: userDetails.licenceImg,
      registrationCertificate: userDetails.rcNumber,
      registrationCertificateScan: userDetails.rcImg,
    };
    console.log("request data", data);
    setIsDetailsSubmitted(true);
    axios
      .put(`${SERVER_URL}/user/update/${userId}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setShowModal(true);
    mixpanel.track("Details Submitted", { userDetails });
  };

  useEffect(() => {
    mixpanel.track("Page View - Personal Information", {
      page: window.location.pathname,
    });
    axios
      .get(`${SERVER_URL}/user/dashboard`, {
        withCredentials: true,
      })
      .then((response) => {
        const user = response.data.user;
        console.log("user data", user);
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          email: user.email || "",
          phoneNumber: user.phoneNo || "",
          address: user.address || "",
          landmark: user.landmark || "",
          state: user.State || "",
          pincode: user.pincode || "",
          licenceNumber: user.licenceNumber || "",
          licenceName: user.licenceName || "",
          rcNumber: user.rcNumber || "",
          rcName: user.rcName || "",
          profilePicture: user.profilePicture || null,
          coverImg: user.coverImg || null,
          licenceImg: user.licenceImg || null,
          rcImg: user.rcImg || null,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  console.log("user details", userDetails);
  const handleAlphabetsOnly = (e) => {
    const inputChar = String.fromCharCode(e.charCode);
    const pattern = /[A-Za-z]/;
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  };

  const isEmailValid = () => {
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(userDetails.email);
  };

  const isStateNotGiven = userDetails.state.trim() === "";

  return (
    <div>
      <TrackPageNavbar showOnlyNav={false} />
      <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
    <p class="text-sm text-gray-500 font-bold">
    <a href="/" class="hover:text-black hover:cursor-pointer">Profile</a> 
              <span class="text-black"> / </span>
                <span class="text-black">Personal Information</span>
                
    </p>
</div>
      <div className="mx-8 flex flex-col lg:justify-between lg:w-full lg:mx-4 md:justify-between px-4 md:px-8 lg:px-10 mt-8 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
        <div className="w-full mt-2">
          <div className="mb-5 ">
            <div className="relative ">
              <img
                src={userDetails.coverImg || coverImg}
                alt="Profile"
                className="w-full h-[150px] md:h-[200px] lg:h-[250px] relative"
              />
              <input
                type="file"
                id="coverImg"
                accept="image/*"
                onChange={handleCoverChange}
                className="hidden"
              />
              <label htmlFor="coverImg">
                <PiCamera
                  alt="img with Text"
                  className="m-2 w-6 h-6 absolute bottom-0 right-0 md:w-7 md:h-7 md:rounded-2xl lg:w-9 lg:h-9 lg:rounded-5xl bg-white p-1 rounded-xl text-black"
                />
              </label>
            </div>
            <div className="relative lg:ml-6">
              <img
                src={userDetails.profilePicture || picture}
                alt="Profile"
                className="relative w-20 h-20 mx-4 -translate-y-12 md:w-32 md:h-32 md:-translate-y-16 lg:-translate-y-20 lg:w-40 lg:h-40 rounded-full object-cover"
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                id="profileImg"
                className="hidden"
              />
              <label htmlFor="profileImg">
                <PiCamera
                  alt="img with Text"
                  className="m-2 w-6 h-6 z-20 absolute top-0 left-[60px] md:top-6 md:left-[110px] md:w-7 md:h-7 md:rounded-2xl lg:w-9 lg:h-9 lg:rounded-5xl lg:top-10 lg:left-[130px] bg-[#22C55D] p-1 rounded-xl text-white"
                />
              </label>
            </div>
          </div>
          <div className="hidden md:flex gap-5 lg:gap-0 items-center justify-center">
            {/*<div className="flex mb-4 border-2 cursor-pointer border-gray-500 w-[120px] px-3 py-2 justify-center gap-2 items-center">
              <label>
                <img
                  src={change}
                  alt="img with Text"
                  className="w-5 h-4 md:w-6 md:h-6 object-cover"
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                />
              </label>
              <p className="text-base font-semibold">Change</p>
  </div>

            <div className="flex  cursor-pointer border-2 border-gray-500 w-[120px] px-3 py-2 justify-center gap-2 items-center">
              <label
                onClick={handleRemovePicture}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={remove}
                  alt="Remove"
                  className="object-cover w-4 h-4"
                />
              </label>
              <p className="text-base font-semibold text-red-500">Remove</p>
            </div>*/}
          </div>
        </div>
        <div className="w-full lg:px-20">
          <div className="flex flex-col md:flex-row md:gap-x-5 lg:align-center mb-4">
            <div className="w-full mb-2 md:w-1/2 md:mb-0">
              <label className="text-gray-800 font-bold">First Name</label>
              <input
                type="text"
                placeholder="First Name"
                value={userDetails.firstName}
                onChange={(e) => handleInputChange("firstName", e.target.value)}
                // onKeyDown={handleAlphabetsOnly}
                className={`shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 mt-1 w-full border-gray-300 outline-none rounded ${
                  !isNameValid() &&
                  (userDetails.firstName.trim() === "" ||
                    !/^[A-Za-z]+$/.test(userDetails.firstName))
                    ? "border-red-500"
                    : ""
                }`}
                required
              />
              {!isNameValid() && userDetails.firstName.trim() === "" && (
                <p className="text-red-500">Please enter a first name.</p>
              )}
              {!isNameValid() &&
                userDetails.firstName.trim() !== "" &&
                !/^[A-Za-z]+$/.test(userDetails.firstName) && (
                  <p className="text-red-500">
                    Please enter a valid first name using only alphabets.
                  </p>
                )}
            </div>
            <div className="w-full md:w-1/2 md:mb-0">
              <label className="text-gray-800 font-bold mb-0">Last Name</label>
              <input
                type="text"
                placeholder="Last Name"
                value={userDetails.lastName}
                onChange={(e) => handleInputChange("lastName", e.target.value)}
                // onKeyDown={handleAlphabetsOnly}
                className={`shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 w-full border-gray-300 mt-1 outline-none rounded ${
                  !isNameValid() &&
                  (userDetails.lastName.trim() === "" ||
                    !/^[A-Za-z]+$/.test(userDetails.lastName))
                    ? "border-red-500"
                    : ""
                }`}
                required
              />
              {!isNameValid() && userDetails.lastName.trim() === "" && (
                <p className="text-red-500">Please enter a last name.</p>
              )}
              {!isNameValid() &&
                userDetails.lastName.trim() !== "" &&
                !/^[A-Za-z]+$/.test(userDetails.lastName) && (
                  <p className="text-red-500">
                    Please enter a valid last name using only alphabets.
                  </p>
                )}
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:gap-x-5 mb-4">
            <div className="w-full md:w-1/2 mb-2 md:mb-0">
              <label className="text-gray-800 font-bold">Contact Number</label>
              <div className="flex items-center shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] mt-1 border rounded">
                <p className="p-2 border-r-[1.6px] font-bold">+91</p>
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={userDetails.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  pattern="[0-9]{10}"
                  title="Please enter a 10-digit phone number"
                  className="p-2 w-3/4 sm:w-[90%] outline-none "
                  style={{ overflowX: "hidden" }}
                  required
                />
              </div>
              {userDetails.phoneNumber.length === 10 ? null : (
                <p className="text-red-500">
                  Please enter a 10-digit phone number
                </p>
              )}
            </div>
            <div className="w-full md:w-1/2 mb-2">
              <label className="text-gray-800 font-bold">Email ID</label>
              <input
                type="email"
                placeholder="123@gmail.com"
                value={userDetails.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                className="shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 mt-1 w-full border-gray-300 rounded outline-none"
                style={{ overflowX: "hidden" }}
                required
              />
              {!isEmailValid() && userDetails.email.length > 0 && (
                <p className="text-red-500">
                  Please enter a valid email address
                </p>
              )}
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:gap-x-5 mb-4">
            <div className="w-full md:w-1/2 mb-2 md:mb-0">
              <label className="text-gray-800 font-bold">Address</label>
              <input
                type="text"
                placeholder="Area/Street"
                value={userDetails.address}
                onChange={(e) => handleInputChange("address", e.target.value)}
                className="shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 mt-1 w-full border-gray-300 rounded outline-none"
                style={{ overflowX: "hidden" }}
              />
            </div>
            <div className="w-full md:w-1/2">
              <label className="text-gray-800 font-bold">
                Nearby landmark (optional)
              </label>
              <input
                type="text"
                value={userDetails.landmark}
                onChange={(e) => handleInputChange("landmark", e.target.value)}
                className="shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 mt-1 w-full border-gray-300 rounded outline-none"
                style={{ overflowX: "hidden" }}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:gap-x-5 mb-4">
            <div className="w-full md:w-1/2 mb-2 md:mb-0">
              <label className=" text-gray-800 font-bold">State</label>
              <div className="relative">
                <select
                  value={userDetails.state}
                  onChange={(e) => handleInputChange("state", e.target.value)}
                  className="shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 mt-1 w-full border-gray-300 rounded outline-none"
                >
                  {userDetails.state.trim() === "" && (
                    <option value="">Select a State</option>
                  )}
                  {indianStates.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              {isStateNotGiven && (
                <p className="text-red-500">
                  Please select a state or choose from the options below.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/2">
              <label className="text-gray-800 font-bold">Pincode</label>
              <input
                type="text"
                value={userDetails.pincode}
                onChange={handlePincodeChange}
                className={`shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 mt-1 w-full border-gray-300 rounded outline-none ${
                  !isPincodeValid() ? "border-red-500" : ""
                }`}
              />
              {userDetails.pincode.length > 0 && !isPincodeValid() && (
                <p className="text-red-500">Please enter a valid pincode.</p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:gap-x-5  mb-4 ">
            <div
              className="w-full mt-4 md:w-1/2 mb-8 md:mb-4"
              style={{ height: "100px" }}
            >
              <label className="text-gray-800 font-bold">Licence Details</label>
              <div className="flex flex-col justify-start">
                <div className="flex flex-row">
                  <label className="text-gray-800 font-light mt-4 w-[40%] lg:w-[40%]">
                    Licence Number
                  </label>
                  <input
                    type="number"
                    placeholder="eg-00234567"
                    value={userDetails.licenceNumber}
                    onChange={(e) =>
                      handleInputChange("licenceNumber", e.target.value)
                    }
                    className={`w-[60%] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 mt-1 w-full border-gray-300 rounded outline-none`}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-start">
                <div className="flex flex-row">
                  <label className="text-gray-800 font-light mt-4 w-[40%] lg:w-[40%]">
                    Licence Name
                  </label>
                  <input
                    type="text"
                    placeholder="eg-00234567"
                    value={userDetails.licenceName}
                    onChange={(e) =>
                      handleInputChange("licenceName", e.target.value)
                    }
                    className={`w-[60%] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 mt-1 w-full border-gray-300 rounded outline-none`}
                  />
                </div>
              </div>

              {/*<textarea
                value={userDetails.licenceDetails}
                onChange={(e) =>
                  handleInputChange("licenceDetails", e.target.value)
                }
                className="border p-2 mt-1 w-full border-gray-300 rounded outline-none h-full"
                style={{
                  wordWrap: "break-word",
                  resize: "none",
                  scrollbarWidth: "thin",
                }}
              />*/}
            </div>
            <div
              className="w-full mt-4 md:w-1/2 mb-8 md:mb-4"
              style={{ height: "100px" }}
            >
              <label className="text-gray-800 font-bold">RC Details</label>
              <div className="flex flex-col justify-start">
                <div className="flex flex-row">
                  <label className="text-gray-800 font-light mt-4 w-[40%] lg:w-[30%]">
                    RC Number
                  </label>
                  <input
                    type="number"
                    placeholder="eg-00234567"
                    value={userDetails.rcNumberNumber}
                    onChange={(e) =>
                      handleInputChange("rcNumber", e.target.value)
                    }
                    className={`w-[60%] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 mt-1 w-full border-gray-300 rounded outline-none`}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-start">
                <div className="flex flex-row">
                  <label className="text-gray-800 font-light mt-4 w-[40%] lg:w-[30%]">
                    RC Name
                  </label>
                  <input
                    type="text"
                    placeholder="eg-00234567"
                    value={userDetails.rcName}
                    onChange={(e) =>
                      handleInputChange("rcName", e.target.value)
                    }
                    className={`w-[60%] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2 mt-1 w-full border-gray-300 rounded outline-none`}
                  />
                </div>
              </div>
              {/*<textarea
                value={userDetails.rcDetails}
                onChange={(e) => handleInputChange("rcDetails", e.target.value)}
                className="border p-2 mt-1 w-full border-gray-300 outline-none rounded h-full"
                style={{
                  wordWrap: "break-word",
                  resize: "none",
                  scrollbarWidth: "thin",
                }}
              />*/}
            </div>
          </div>
          <div className=" mt-4 lg:flex lg:align-center md:mt-12">
            <div className="m-2 lg:mt-8 lg:w-1/2 lg:ml-0">
              <label htmlFor="licence">
                <div className=" py-6 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] flex flex-col justify-middle align-center text-center cursor-pointer">
                  <div className="flex justify-center">
                    <MdCloudUpload
                      size={40}
                      className="w-10 h-10 text-[#5A5A5A]"
                    />
                  </div>
                  <p className="text-[#5A5A5A]">
                    Upload a scanned copy of your licence
                  </p>
                  <p className="text-[#22C55D] text-xl font-semibold">
                    Upload File
                  </p>
                </div>
              </label>
              <input
                type="file"
                id="licence"
                accept="image/*, application/*"
                className="hidden"
                onChange={handleLicenceUpload}
              />
            </div>
            <div className="m-2 mt-8 lg:w-1/2">
              <label htmlFor="rc">
                <div className=" py-6 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] flex flex-col justify-middle align-center text-center cursor-pointer">
                  <div className="flex justify-center">
                    <MdCloudUpload
                      size={40}
                      className="w-10 h-10 text-[#5A5A5A]"
                      //onClick={handleInputChange}
                    />
                  </div>
                  <p className="text-[#5A5A5A]">
                    Upload a scanned copy of your RC
                  </p>
                  <p className="text-[#22C55D] text-xl font-semibold">
                    Upload File
                  </p>
                </div>
              </label>
              <input
                type="file"
                id="rc"
                accept="image/*, application/*"
                className="hidden"
                onChange={handleRcUpload}
              />
            </div>
          </div>
          <div className="flex justify-between mb-10 gap-6  mt-8">
            <button
              className="border border-green-500 text-green-500 px-10 py-2 rounded"
              onClick={() => setShowModal(true)}
            >
              Cancel
            </button>
            <button
              onClick={handleSaveDetails}
              className="bg-green-500 text-white px-6 py-2 rounded"
            >
              Save Details
            </button>
          </div>
        </div>
        {isDetailsSubmitted && (
          <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <p>Your details have been submitted.</p>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default PersonalInformation;