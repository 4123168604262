import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SERVER_URL from "../constants/constant";
import TrackPageNavbar from "../components/TrackPageNavbar";
import toast from "react-hot-toast";

const ServiceInfo = () => {
  const [isPickUpLocationMenuOpen, setIsPickUpLocationMenuOpen] =
    useState(false);
  const [isDropOffLocationMenuOpen, setIsDropOffLocationMenuOpen] =
    useState(false);
  const [dropOffLocation, setDropOffLocation] = useState(null);
  const [pickUpDate, setPickUpDate] = useState("");
  const [dropOffDate, setDropOffDate] = useState("");
  const [pickUpDateError, setPickUpDateError] = useState("");
  const [dropOffDateError, setDropOffDateError] = useState("");
  const [userData, setUserData] = useState({
    Name: "",
    phoneNumber: "",
    email: "",
  });

  const handleInputChange = (field, value) => {
    setUserData((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    const onlyNumbers = input.replace(/\D/g, "");
    handleInputChange("phoneNumber", onlyNumbers);
  };

  const handleSaveDetails = () => {
    console.log("User details submitted:", userData);
    setIsDetailsSubmitted(true);
    setShowModal(true);
  };

  const [showModal, setShowModal] = useState(false);
  const [isDetailsSubmitted, setIsDetailsSubmitted] = useState(false);

  useEffect(() => {
    const userDataFromLocalStorage = JSON.parse(localStorage.getItem("user"));
    if (userDataFromLocalStorage) {
      setUserData((prevDetails) => ({
        ...prevDetails,
        Name:
          (userDataFromLocalStorage.firstName || "") +
          " " +
          (userDataFromLocalStorage.lastName || ""),
        email: userDataFromLocalStorage.email || "",
        phoneNumber: userDataFromLocalStorage.phoneNo || "",
      }));
    }
  }, []);

  const handleLocationSelect = (location, type) => {
    if (type === "dropOff") {
      setDropOffLocation(location);
      setIsDropOffLocationMenuOpen(false);
    }
  };

  const handlePickUpDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedDate >= today) {
      setPickUpDate(event.target.value);
      setPickUpDateError("");
      setDropOffDate("");
    } else {
      // Show toast error message
      toast.error("Invalid date.");
    }
  };

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Fetch cart items initially
    fetchCartItems();
  }, []);

  const fetchCartItems = () => {
    axios
      .get(`${SERVER_URL}/user/cart/get`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setCartItems(response.data.cartItemsData || []);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((subtotal, item) => {
      // Check if item.price is a valid number before adding
      return subtotal + (typeof item.price === "number" ? item.price : 0);
    }, 0);
  };

  const calculateTax = () => {
    const subtotal = calculateSubtotal();
    const tax = 0.1 * subtotal; // Assuming tax is 10% of the subtotal
    return parseFloat(tax.toFixed(2)); // Round to two decimal places
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const tax = calculateTax();
    return subtotal + tax;
  };

  return (
    <div class="font-[sans-serif]">
      <div className="flex flex-col">
        <TrackPageNavbar showOnlyNav={false} />
        <div className="px-4 py-2 text-gray-500">
          <span>Home / Service / Detail / Info</span>
        </div>
      </div>
      <div className="border-t-2 border-gray-200 my-2"></div>
      <div class="max-w-8xl mx-auto md:mx-6 p-6 ">
        <h2 class="text-2xl font-extrabold text-black">
          Convert On time to schedule service
        </h2>
        <h3 class="text-base  text-black">
          Please Select your preferred date and time.
        </h3>
        <div class="grid lg:grid-cols-3 gap-12 relative mt-0">
          <div class="lg:col-span-2   ">
            <div className="mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                />
              </svg>

              <p className=" font-bold text-base text-black ml-8 mt-[-1.5rem] mb-2">
                Drop Off
              </p>
            </div>

            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="dropOffLocation"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Location
                </label>
                <div className="mt-2 relative">
                  <button
                    type="button"
                    onClick={() =>
                      setIsDropOffLocationMenuOpen(!isDropOffLocationMenuOpen)
                    }
                    className="relative inline-flex w-full h-11 justify-between items-center px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                  >
                    <span className="text-sm font-medium text-gray-700">
                      {dropOffLocation
                        ? dropOffLocation
                        : "Select a drop off address"}
                    </span>
                    <svg
                      className={`-mr-1 h-5 w-5 text-gray-400 ${
                        isDropOffLocationMenuOpen ? "transform rotate-180" : ""
                      }`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {/* Location Dropdown Menu */}
                  {isDropOffLocationMenuOpen && (
                    <ul className="absolute right-0 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <li
                        onClick={() => handleLocationSelect("Thane", "dropOff")}
                        className="  text-gray-700 block px-4 py-2 text-sm w-full text-left cursor-pointer"
                        role="menuitem"
                      >
                        Thane
                      </li>
                      <li
                        onClick={() =>
                          handleLocationSelect("Mumbai", "dropOff")
                        }
                        className="text-gray-700 block px-4 py-2 text-sm w-full text-left cursor-pointer"
                        role="menuitem"
                      >
                        Mumbai
                      </li>
                    </ul>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Date
                </label>
                <div className="mt-2">
                  <input
                    type="Date"
                    id="Pickupdate"
                    name="select a pickUp Date"
                    value={pickUpDate}
                    onChange={handlePickUpDateChange}
                    className="inline-flex w-full h-11 justify-between items-center px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="Time"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Time
                </label>
                <div className="mt-2">
                  <input
                    type="time"
                    className="inline-flex w-full h-11 justify-between items-center px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black mb-4"
                  />
                </div>
              </div>
            </div>

            <div className="mb-4">
              <div className="border mt-4 mb-4"></div>
              <div className="mt-4">
                <label className="block font-bold text-base text-black mb-4">
                  Contact Info
                </label>
              </div>
              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      placeholder="Enter name"
                      onChange={(e) =>
                        handleInputChange("Name", e.target.value)
                      }
                      value={userData.Name}
                      autoComplete="given-name"
                      className="w-full border border-gray-300 p-2 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Phone Number
                  </label>
                  <div className="mt-2">
                    <input
                      type="tel"
                      placeholder="10 digit number"
                      onChange={handlePhoneNumberChange}
                      value={userData.phoneNumber}
                      className="w-full border border-gray-300 p-2 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Email id
                  </label>
                  <div className="mt-2">
                    <input
                      type="email"
                      placeholder="Enter Mail Id"
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      value={userData.email}
                      className="w-full border border-gray-300 p-2 rounded-md "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white h-max rounded-md p-6 shadow-[0_0px_4px_0px_rgba(6,81,237,0.2)] sticky top-0">
            <label
              htmlFor=""
              className="text-2xl font-extrabold text-black mb-2"
            >
              Service Detail
            </label>
            <h2 className="text-semibold mb-1 text-justify">
              Review and finalize your selected items for a seamless experience
            </h2>

            <div className="flow-root mt-1 mb-2 max-h-[180px] overflow-y-auto">
              <ul className="-my-2">
                {cartItems.map((item, index) => (
                  <li
                    key={item._id}
                    className={`flex flex-col p-2 space-y-3 py-2 mt-3 text-left sm:flex-row sm:space-x-5 sm:space-y-0 ${
                      index !== cartItems.length - 1 ? "" : ""
                    }`}
                  >
                    <div className="relative flex flex-1 flex-col justify-between">
                      <div className="sm:col-gap-5 sm:grid sm:grid-cols-2">
                        <div className="pr-8 sm:pr-5">
                          <p className="text-base font-semibold text-gray-900">
                            {item.name}
                          </p>
                        </div>
                        <div className="sm:text-right text-left">
                          <p className="font-semibold mx-0 mt-2 mb-0 text-sm text-black-400">
                            ₹ {item.price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-2 border"></div>

            <ul className="text-[#333] text-sm mt-2">
              <li className="flex flex-wrap gap-4 py-3 text-xl font-extrabold">
                Total Cost
                <span className="ml-auto mr-4">₹ {calculateTotal()}</span>
              </li>
            </ul>
            <div className="mt-1 border mb-2"></div>

            <div className="flex items-center col-span-4 mb-2 mt-2">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="default-checkbox"
                className="ms-2 text-base font-medium text-gray-900"
              >
                Towing service
              </label>
            </div>
            <p className="text-sm font-normal">
              if vehicle is not running condition we will be adding towing
              services.Charges included seperately.
            </p>

            <div className="flex justify-between mt-4 ">
              <button
                type="button"
                className="group inline-flex rounded-md py-2 text-md font-semibold text-green-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900"
              >
                Go back
              </button>

              <button
                type="button"
                class="py-3 px-4  inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-green-500 text-white hover:bg-green-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                <Link to="/paymentscreen">Check Out</Link>
                <svg
                  class="flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m5 11 4-7" />
                  <path d="m19 11-4-7" />
                  <path d="M2 11h20" />
                  <path d="m3.5 11 1.6 7.4a2 2 0 0 0 2 1.6h9.8c.9 0 1.8-.7 2-1.6l1.7-7.4" />
                  <path d="m9 11 1 9" />
                  <path d="M4.5 15.5h15" />
                  <path d="m15 11-1 9" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceInfo;
