import { useRef, useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DownloadIcon from "@mui/icons-material/Download";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TrackPageNavbar from "../components/TrackPageNavbar";
import Xarrow from "react-xarrows";
import mixpanel from "../config/mixpanel";
import "./OrderDetails.css";
import { useLocation } from "react-router-dom";
import SERVER_URL from "../constants/constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineChat } from "react-icons/md";
import { Link } from "react-router-dom";





const OrderDetails = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  const step1 = useRef(null);
  const step2 = useRef(null);
  const step3 = useRef(null);
  const step4 = useRef(null);
  const step5 = useRef(null);
  const step6 = useRef(null);

  // const location = useLocation();
  const location = useLocation();
  // console.log(location);
  const orderId = location.state?.orderId;

  console.log("order id", orderId);

  const imageStyles = {
    width: "60px",
    height: "50px",
  };

  
  useEffect(() => {
    const handleBackButton = (event) => {
      // Prevent the default behavior of the back button
      event.preventDefault();

      // Navigate to the order history page
      navigate("/profile/order-history");
    };

    // Attach the custom back button handler
    window.addEventListener("popstate", handleBackButton);

    return () => {
      // Remove the custom back button handler when the component unmounts
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `${SERVER_URL}/user/getSingleOrder/${orderId}`,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const fetchedOrder = response.data.order;

        if (fetchedOrder) {
          setOrder(fetchedOrder);
        } else {
          console.error("No services found in the response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    }
  }, [orderId]);


  useEffect(() => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    console.log(user);

    let track_details = {
      username: `${user.firstName} ${user.lastName}`,
      userId: user.userUid,
      email: user.email,
      page: window.location.pathname,
      orderId: "some order goes here",
    };

    // console.log(track_details)

    mixpanel.track("PAGE_VIEW", track_details);
  }, []);

  // console.log(order);


const generatePDF = () => {
  const downloadButton = document.getElementById('download-button');
  if (downloadButton) {
    downloadButton.style.display = 'none';
  }

  // Hide the "Need Help?" and "Click here" elements
  const needHelpLink = document.getElementById('need-help-link');
  if (needHelpLink) {
    needHelpLink.style.display = 'none';
  }

  const input = document.getElementById('order-details'); 
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');


      const pdf = new jsPDF();
      const imgHeight = canvas.height * 210 / canvas.width;
      // const padding = 10; // Adjust padding here
      // const imgWidth = 190; // Adjust content width here
      pdf.addImage(imgData, 'PNG', 5, 10, 210, imgHeight);
      pdf.save(`order_id_${orderId}.pdf`);

      if (downloadButton) {
        downloadButton.style.display = 'block';
      }
      if (needHelpLink) {
        needHelpLink.style.display = 'block';
      }

    })
    .catch((error) => {
      console.error('Error generating PDF:', error);

      // Ensure the download button is shown even if there's an error
      if (downloadButton) {
        downloadButton.style.display = 'block';
      }

      if (needHelpLink) {
        needHelpLink.style.display = 'block';
      }
    });

};


  

  const { mechanicId, vehicleData, paymentMode } = order || {};
  const {  avgRating } = mechanicId || {};

  const renderOrderDetails = () => {
    return (
      <>
        <TrackPageNavbar showOnlyNav={false} />
        <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
    <p class="text-sm text-gray-500 font-bold">
    <a href="/" class="hover:text-black hover:cursor-pointer">Profile</a> 
              <span class="text-black"> / </span>
              <a href="/profile/order-history" class="hover:text-black hover:cursor-pointer">Order History</a> 
              <span class="text-black"> / </span>
                <span class="text-black">Order details</span>
                
    </p>
</div>


        <div id="order-details"
        className="w-full my-7 flex flex-col items-center justify-center">
          <div className="w-11/12 lg:flex lg:justify-between md:flex md:justify-between ">
            <div className="flex align-center xl:justify-evenly xl:items-center  sm:items-center sm:justify-start sm:mb-5">
              <h1
                style={{ fontFamily: "poppins" }}
                className="xl:text-2xl md:text-xl sm:text-lg font-bold pr-2"
              >
                Order details
              </h1>
              <h2 className="font-medium">{`order id: ${orderId}`}</h2>
              
            </div>
            <div className="md:text-xs" id="need-help-link">
            <div 
            className="flex items-center justify-start lg:ml-2">
              <div className="text-sm flex justify-start items-senter">
              <HelpOutlineIcon
                style={{
                  fontSize: "15px",
                }}
                className="flex justify-start mr-1 mt-0.5"
              />
              <p 
               >Need Help ?</p>
               </div>
              <a
                
                href="#"
                className="pl-1 "
                style={{
                  color: "#27BA48",
                  fontFamily: "poppins",
                  fontWeight: "normal",
                  textDecoration: "underline",
                }}
              >
                {" "}
                Click here
              </a>
            </div>
            </div>
          </div>
          
          <div 
          className="w-11/12 border border-slate-950 p-5 ">
            <div className="flex flex-col lg:flex lg:justify-between lg:items-center md:flex md:justify-between md:items-center sm:flex sm:flex-row sm:justify-between sm:items-center header-mech-detail">
              <div className="flex items-center">
                <img
                  src={require("../assets/OrderDetailsPage/mechanic_A.png")}
                  style={{
                    width: "70px",
                    height: "70px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                  alt=""
                />
                <div className="flex ml-3">
                  <h3 className="text-18/24 font-bold">
                    {mechanicId?.generalDetails.fullName}
                  </h3>
                  <p className="text-14/16 flex items-center">
                    <StarIcon style={{ color: "orange" }} /> {avgRating} (18
                    Review){" "}
                  </p>
                  <div className="flex align-center sm:flex sm:align-center md:flex md:align-center cursor-pointer">
              <div className="flex justify-center align-center border-[1px] border-[#EFEFEF] rounded-lg ml-3 px-2 py-1 mb-0.5 sm:mb-0">
              <LuPhoneCall className="text-[#22C55D] text-sm mr-1.5  "/>
              <p className="text-[#22C55D] text-xs font-semibold">Call</p>
              </div>
              <Link to="/profile/chat">
              <div 
              className="cursor-pointer flex justify-center align-center border-[1px] border-[#EFEFEF] rounded-lg ml-3 px-2 py-1 sm:mt-0">

              <MdOutlineChat className="text-[#22C55D] text-sm mr-1.5 mt-0.5"/>
              
              <p className="text-[#22C55D] text-xs font-semibold">Chat</p>
              </div>
              </Link>
              </div>
                </div>
              </div>
              <button 
                id="download-button"
                onClick={generatePDF}
                className="text-sm rounded "
                style={{
                  background: "black",
                  padding: "5px 10px",
                  color: "white",
                }}
              >
                <DownloadIcon style={{ fontSize: "15px" }} />
                Download Invoice
              </button>
            </div>
            <div
              className="
                        lg:flex lg:flex-row my-5 md:flex md:flex-row
                        sm:flex sm:flex-col sm:items-center sm:w-full
                        "
            >
              <div
                className="
                        lg:w-1/2 md:w-1/2 xl:flex xl:flex-row lg:flex lg:flex-row xl:border-r lg:border-r
                        md:flex md:flex-col md:items-start sm:w-full
                        "
              >
                <div className="xl:w-1/2 lg:w-1/2 sm:w-full">
                  <div className="mb-5">
                    <p className="font-normal text-slate-700 text-14/16">
                      Pickup Date & Time:
                    </p>
                    <h2 className="text-18/24 font-medium">
                      17 May 2024(10:30)
                    </h2>
                  </div>
                  <div className="mb-5">
                    <p className="font-normal text-slate-700 text-14/16">
                      Pickup Location:
                    </p>
                    <h2 className="text-18/24 font-medium">
                      1st cross road,AAD colony, Bangalore,600251
                    </h2>
                  </div>
                  <div className="mb-5">
                    <p className="font-normal text-slate-700 text-14/16">
                      Payment Mode
                    </p>
                    <h2 className="text-18/24 font-medium">{paymentMode}</h2>
                  </div>
                </div>
                <div className="xl:w-1/2 lg:w-1/2 sm:w-full">
                  <div className="mb-5">
                    <p className="font-normal text-slate-700 text-14/16">
                      Drop-up Date & Time:
                    </p>
                    <h2 className="text-18/24 font-medium">
                      19 May 2024(11:30)
                    </h2>
                  </div>
                  <div className="mb-5">
                    <p className="font-normal text-slate-700 text-14/16">
                      Drop-up Location
                    </p>
                    <h2 className="text-18/24 font-medium">
                      1st cross road,BAD colony,Bangalore,600251
                    </h2>
                  </div>
                  <div className="mb-5">
                    <p className="font-normal text-slate-700 text-14/16">
                      Vecicle details
                    </p>

                    <h2 className="text-18/24 font-medium">
                      {vehicleData?.vehicleBrand},{vehicleData?.vehicleModel}
                      <br />
                      {vehicleData?.vehicleFuel},
                      <br />
                      {vehicleData?.vehicleNo}
                    </h2>
                  </div>
                </div>
              </div>
              <div
                className="
                                        lg:w-1/2 lg:flex lg:flex-row lg:justify-center 
                                        md:w-1/2 md:flex md:flex-row
                                        sm:w-full md:justify-center
                                        "
              >
                <div className="lg:w-3/4 sm:w-full md:3/4">
                  <h1 className="text-14/16 font-bold mb-3">
                    Order Summary(2 items)
                  </h1>
                  <div className="border-b ">
                    <div className="flex justify-between mb-3">
                      <h2 className="text-14/16 font-light">Basic Service</h2>
                      <p className="text-14/16 font-light">
                        {" "}
                        <CurrencyRupeeIcon /> 1000{" "}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <h2 className="text-14/16 font-light">
                        Advanced Service
                      </h2>
                      <p className="text-14/16 font-light">
                        <CurrencyRupeeIcon /> 1000
                      </p>
                    </div>
                  </div>
                  <div className="my-5 border-b">
                    <div className="flex justify-between">
                      <h2 className="text-14/16 font-light">
                        Subtotal(2 items)
                      </h2>
                      <p className="text-14/16 font-light">
                        <CurrencyRupeeIcon /> 2000
                      </p>
                    </div>
                    <div className="flex justify-between mb-5">
                      <h2 className="text-14/16 font-light">Tax</h2>
                      <p className="text-14/16 font-light">
                        <CurrencyRupeeIcon />
                        50
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <h2 className="text-14/16 font-bold">Total Cost</h2>
                    <p className="text-14/16 font-light">
                      {" "}
                      <CurrencyRupeeIcon />
                      2050
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full my-7 flex flex-row items-center justify-center">
          <div
            className="
                    w-11/12
                    lg:flex lg:flex-row lg:justify-around
                    md:flex md:flex-row md:justify-between
                    "
          >
            <div
              className="
                        lg:flex lg:flex-col lg:items-start
                        md:flex md:flex-col md:items-start
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center
                        "
            >
              <img
                src={require("../assets/OrderDetailsPage/Group 984.png")}
                style={imageStyles}
                ref={step1}
                alt=""
              />
              <p className="lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
                Mechanic Accepted service
              </p>
            </div>
            <div
              className="
                        lg:flex lg:flex-col lg:items-start
                        md:flex md:flex-col md:items-start
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center
                        "
            >
              <img
                src={require("../assets/OrderDetailsPage/Group 985.png")}
                style={imageStyles}
                ref={step2}
                alt=""
              />
              <p className="lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
                Mechanic picked the vechile
              </p>
            </div>
            <div
              className="
                        lg:flex lg:flex-col lg:items-start
                        md:flex md:flex-col md:items-start
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center

                        "
            >
              <img
                src={require("../assets/OrderDetailsPage/Group 987.png")}
                style={imageStyles}
                ref={step3}
                alt=""
              />
              <p className="lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
                Mechanic reached the workshop
              </p>
            </div>
            <div
              className="
                        lg:flex lg:flex-col lg:items-start
                        md:flex md:flex-col md:items-start
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center


                        "
            >
              <img
                src={require("../assets/OrderDetailsPage/car 1.png")}
                style={imageStyles}
                ref={step4}
                alt=""
              />
              <p className="lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
                Service in progress
              </p>
            </div>
            <div
              className="
                        lg:flex lg:flex-col lg:items-start
                        md:flex md:flex-col md:items-start
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center

                        "
            >
              <img
                src={require("../assets/OrderDetailsPage/Group 33310.png")}
                style={imageStyles}
                ref={step5}
                alt=""
              />
              <p className="lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
                Service Done & out for delivery
              </p>
            </div>
            <div
              className="
                        lg:flex lg:flex-col lg:items-center
                        md:flex md:flex-col md:items-start
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center

                        "
            >
              <img
                src={require("../assets/OrderDetailsPage/payment 1.png")}
                style={imageStyles}
                ref={step6}
                alt=""
              />
              <p className="lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
                Payment Completed
              </p>
            </div>
            <Xarrow
              start={step1}
              zIndex={-1}
              end={step2}
              color="#22C55D"
              showHead={false}
              dashness
            />
            <Xarrow
              start={step2}
              zIndex={-1}
              end={step3}
              color="#22C55D"
              showHead={false}
              dashness
            />
            <Xarrow
              start={step3}
              zIndex={-1}
              end={step4}
              color="#22C55D"
              showHead={false}
              dashness
            />
            <Xarrow
              start={step4}
              zIndex={-1}
              end={step5}
              color="#22C55D"
              showHead={false}
              dashness
            />
            <Xarrow
              start={step5}
              zIndex={-1}
              end={step6}
              color="#22C55D"
              showHead={false}
              dashness
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>{loading ? <p>Loading order details...</p> : renderOrderDetails()}</>
  );
};

export default OrderDetails;
