
// import React, { createContext, useReducer, useEffect } from 'react';
// import { cartReducer } from './CartReducer';


// // import axios from "axios";
// // import SERVER_URL from "../constants/constant";
// // import { useState } from 'react';
// export const CartContext = createContext();

// export const CartProvider = ({ children }) => {


//   // const [cartProducts, setCartProducts] = useState([]);


//   const [cartItems, dispatch] = useReducer(cartReducer, [], (initial) => {
    
//     const localData = localStorage.getItem('cartItems');
//     return localData ? JSON.parse(localData) : initial;
    
//   });
 
//   // Calculate cartTotal
//   const cartTotal = cartItems.reduce((total, item) => item.discount===null ? total + item.basePrice : total + parseInt(item.basePrice*((100-item.discount)/100)), 0);


//   useEffect(() => {
//     localStorage.setItem('cartItems', JSON.stringify(cartItems));
//   }, [cartItems]);
  
 
  
//   return (
//     <CartContext.Provider value={{ cartItems, dispatch, cartTotal }}>
//       {children}
//     </CartContext.Provider>
//   );
// };






















import React, { createContext, useReducer, useEffect, useState } from 'react';
import axios from 'axios';
import SERVER_URL from '../constants/constant';
import { cartReducer } from './CartReducer'; // Assuming this handles adding/removing items from the cart

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Initialize cartItems from localStorage or default to an empty array
  const [cartItems, dispatch] = useReducer(cartReducer, [], (initial) => {
    const localData = localStorage.getItem('cartItems');
    return localData ? JSON.parse(localData) : initial;
  });

  const [cartTotal, setCartTotal] = useState(0);
  const [loading, setLoading] = useState(true); // To show loading state during API fetch

  // Effect to fetch cart items from the API and update the state
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const token = localStorage.getItem('TOKEN');
        const response = await axios.get(`${SERVER_URL}/user/cart/get`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedCartItems = response.data.cartItemsData || [];
        dispatch({ type: 'SET_CART_ITEMS', payload: fetchedCartItems });
        
        // Calculate the total based on the API response
        const total = fetchedCartItems.reduce((total, item) =>
          item.discount === null
            ? total + item.basePrice
            : total + parseInt(item.basePrice * ((100 - item.discount) / 100)), 0
        );
        setCartTotal(total);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cart data:', error);
        setLoading(false);
      }
    };

    fetchCartItems();
  }, []);

  // Effect to update localStorage whenever cartItems changes
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    // Recalculate total whenever cartItems changes
    const total = cartItems.reduce((total, item) =>
      item.discount === null
        ? total + item.basePrice
        : total + parseInt(item.basePrice * ((100 - item.discount) / 100)), 0
    );
    setCartTotal(total);
  }, [cartItems]);

  return (
    <CartContext.Provider value={{ cartItems, dispatch, cartTotal, loading }}>
      {children}
    </CartContext.Provider>
  );
};
