import React, { useContext } from "react";
//import servicesData from '../data/Data';
import { Link } from "react-router-dom";
import { CartContext } from "../context/CartContext";
import topService from "./Services";
import { useWishlist } from "../context/WishlistContext";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { BsCartPlus } from "react-icons/bs";
import toast from "react-hot-toast";
// import {serviceID} from './Services';
import Services from "./Services";

const ServiceModal = ({ serviceID, setService }) => {
  const [servicesData, setData] = useState();
  const [all, setAll] = useState([]);

  useEffect(() => {
    setData(JSON.parse(localStorage.getItem("selected-service")));
    console.log(servicesData);
    setAll(JSON.parse(localStorage.getItem("user-services")));
  }, []);

  const { dispatch: cartDispatch } = useContext(CartContext);
  const { dispatch: wishlistDispatch } = useWishlist();

  const addToCart = (service) => {
    cartDispatch({ type: "ADD_TO_CART", payload: service });
    notifyAddProduct();
  };

  const addToWishlist = (service) => {
    wishlistDispatch({ type: "ADD_TO_WISHLIST", payload: service });
    notifyAddWishlist();
  };

  const notifyAddProduct = () => toast.success("Product Added!");
  const notifyAddWishlist = () => toast.success("Added to Wishlist!");

  return (
    <div
      className=" fixed top-0 right-0 left-0 z-[400] w-full p-4 font-bold text-center flex justify-center items-center"
      style={{ height: "100svh" }}
    >
      <div
        onClick={() => setService({ show: false, id: null })}
        className="fixed top-0 right-0 left-0 flex w-full h-full bg-black/30"
      ></div>
      <div class="relative w-full md:w-2/3 lg:w-2/3 h-fit flex justify-center  ">
        <div class="relative rounded-sm shadow bg-white w-full h-full flex flex-col justify-between">
          <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 class="text-xl font-semibold ">
              Service
              {/* {servicesData?.name} */}
            </h3>
            <button
              type="button"
              onClick={() => setService({ show: false, id: null })}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="defaultModal"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>

          <div class="p-6 overflow-y-auto flex h-full w-full flex-col">
            <div className=" flex flex-col lg:flex-row lg:items-center">
              <img
                src={servicesData?.photo}
                alt=""
                className="w-[200px] object-contain"
              />
              <div className="flex w-full flex-wrap gap-2  ">
                {/* {servicesData[serviceID - 1].tags.map((tag, index) => (
                                    <div key={index} className='px-3 py-1 text-white text-start rounded-md bg-[#18f98f]/80 font-semibold h-fit'>
                                        {tag}
                                    </div>
                                ))} */}
              </div>
            </div>

            <div className="flex w-full flex-col">
              {/* <p className='text-lg md:text-xl mb-5'>Services</p> */}
              <div>
                {all.map((service, index) => {
                  if (service._id === servicesData._id) {
                    return (
                      <div
                        key={index}
                        // className='flex min-w-[280px] max-w-[280px] flex-grow flex-col items-between bg-gray-100 shadow-lg  border-t-2 border-gray-100 rounded-md'
                      >
                        {/* <div className='mb-10'> */}
                        {/* <div className='px-3 py-1'></div> */}
                        <div className="flex w-full justify-between items-center px-3 py-1">
                          <div className=" text-black text-start  text-lg rounded-md font-semibold">
                            {service.name}
                          </div>
                          <div>
                            {service.discount !== null ? (
                              <div className=" text-black text-start rounded-md font-semibold">
                                <div className="flex flex-col">
                                  <p>
                                    &#8377;{" "}
                                    {Number(
                                      service.price -
                                        Number((service.price * 10) / 100)
                                    ).toFixed(0)}
                                  </p>
                                  <s className="text-sm text-red-500">
                                    &#8377; {service.price}
                                  </s>
                                </div>
                              </div>
                            ) : (
                              <div className=" text-black text-start rounded-md font-semibold">
                                &#8377; {service.price}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: service.description,
                          }}
                          className="px-3 py-1 text-black text-start rounded-md font-normal"
                        ></div>
                        {/* </div> */}
                        <div className=" w-full h-full flex flex-col justify-end">
                          <div className="px-3 py-1 flex w-full justify-between ">
                            <AiOutlineHeart
                              color="red"
                              size={23}
                              className="cursor-pointer hover:scale-110"
                              onClick={() => addToWishlist(service)}
                            />
                            <BsCartPlus
                              color="green"
                              size={23}
                              className="cursor-pointer hover:scale-110"
                              onClick={() => addToCart(service)}
                            />
                          </div>
                          <div className="px-3 py-1"></div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>

          {/*  <div class="flex items-center justify-between p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600 h-fit bg-black left-0 w-full ">
                        <div className="text-white  font-normal rounded-lg text-xl px-5  py-2.5 text-center  cursor-pointer">
                            <span className='font-bold'>Price :</span> &#8377; {servicesData[serviceID - 1].price}
                        </div>
                        <Link to='/cart'>
                            <div onClick={() => addToCart(servicesData[serviceID - 1])} className="text-white bg-[#18f98f]/80 hover:bg-[#18f98f] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  cursor-pointer" >Add to Cart</div>
                        </Link>

                        <Link to='/wishlist'>
                            <div onClick={() => addToWishlist(servicesData[serviceID - 1])} className="flex items-center text-white bg-[#18f98f]/80 hover:bg-[#18f98f] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 2a5.96 5.96 0 00-4.242 1.758A5.96 5.96 0 004 10a5.96 5.96 0 001.758 4.242l4.242 4.243a.25.25 0 00.354 0l4.243-4.243A5.96 5.96 0 0016 10a5.96 5.96 0 00-1.758-4.242A5.96 5.96 0 0010 2zM6 10a4 4 0 016.326-3.305l1.41-1.41A6 6 0 004 10a6 6 0 001.935 4.448l1.41 1.41A4 4 0 016 10z" clipRule="evenodd" />
                                </svg>
                                Add to Wishlist
                            </div>
                        </Link>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
