import { io } from "socket.io-client";
import { SOCKET_URL } from "../constants/constant";
let baseURL = 'http://localhost:5000';


let user = JSON.parse(localStorage.getItem('userid')) ?? {};
// console.log("Auth user is ",user.token);

export const socket = io(SOCKET_URL,{
    autoConnect:false,
    extraHeaders:{
         token: user.token || ' '
    }
});
