import React, { useState } from "react";
import Logo from "../assets/Logo.png";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { PiMapPinLineFill } from "react-icons/pi";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLinkedinIn, FaXTwitter } from "react-icons/fa6";
import { AiOutlineYoutube } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import SERVER_URL from "../constants/constant";


import { IoMdMail } from "react-icons/io";
import axios from "axios";

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

const Footer = () => {

  const [email, setEmail] = useState({
    email: "",
  })
  const [message, setMessage] = useState()
  const navigate = useNavigate();
  // const [servicesByCategory, setServicesByCategory] = useState([]);

  const handleSubscribe = () => {
    if (email) {
        
      const API = axios.create({
        baseURL: `${SERVER_URL}`,
        withCredentials: true,
      });
   
      API.post(`/user/notifications/subscribe`,      
        email
      )
      .then((response) => {
        console.log(response.data);
        setMessage(response.data.message || []);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  };
const handleInput = (e) => {
  const {name, value} = e.target
  setEmail((prev) => {
    return {
      ...prev,
      [name]: value
    }
  })
}
  const handleServiceAll = (name) => {
    console.log(`Clicked on service with ID: ${name}`);
    navigate(`/servicepage/${name}`, { state: { categoryName: name } });
  };
  

  return (
    <div className="bg-black text-white px-5 md:px-16 py-5">
      <div className="flex flex-col md:flex-row gap-6 md:gap-0 justify-between">
        <div className="w-full md:w-[35%] flex flex-col gap-5 md:gap-0 col-span-5 lg:col-span-1 md:col-span-1 items-center lg:items-start md:items-start">
          <img
            src={Logo}
            alt=""
            className="w-[80px] h-[80px] object-contain mb-2"
          />
          <p className="md:text-start lg:text-start text-base">
          At our venture, we strive to restore cars all across India in an efficient manner that saves our customers' valuable time by eliminating the need to take their cars to a mechanic.
          </p>
        </div>
        <div className="w-full md:w-[55%] flex flex-col lg:pl-24">
          <p className="text-xl font-bold mb-1">Subscribe to our Newsletter</p>
          <p className="text-sm text-gray-300 mb-4">
          Get the latest news and updates from Helpy Moto , delivered to your inbox
          </p>
          <div className="w-full md:w-full lg:w-3/4 flex justify-between p-[3px] flex-col justify-center">
            <div className="flex flex-row border-[1.6px] gap-0.5 border-gray-300 rounded-lg" >
            <input
              type="email"
              name="email"
              value={email.email}
              placeholder="Your email address"
              className="bg-transparent outline-none pl-3 w-[65%]"
              
              onChange={handleInput}

            />
              <button className="bg-green-500 text-white px-5 py-2 pl-7 font-semibold rounded" onClick={handleSubscribe}>
              Subscribe Now
              </button> 
            </div>           
            {message && <p className="text-green-400 mt-4 m-auto">{message}</p>}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap mt-8 mb-5">
        <div className="w-1/2 md:w-[30%]">
          <p className="mb-5 after:mt-1 after:h-[1.5px] after:bg-green-400 after:w-1/5 after:bottom-0 after:left-0 after:abolute after:flex text-xl font-bold">
            Useful Link
          </p>

          <ul>
            <li className="hover:underline cursor-pointer mb-4 text-sm">
              <Link to="/About">About Us</Link>
            </li>
            <li className="hover:underline cursor-pointer mb-4 text-sm">
              <Link to="/ServicePage">Our Services</Link>
            </li>
            <li className="hover:underline cursor-pointer mb-4 text-sm">
              <Link to="/" onClick={scrollToTop}>
                Home
              </Link>
            </li>
            <li className="hover:underline cursor-pointer mb-4 text-sm">
              <Link to="/profile/terms-conditions">Terms & Service</Link>
            </li>
          </ul>
        </div>

        <div className="w-1/2 md:w-[30%] ">
          <p className="mb-5 after:mt-1 after:h-[1.5px] after:bg-green-400 after:w-1/5 after:bottom-0 after:left-0 after:abolute after:flex text-xl font-bold">
          Our Field of Expertise
          </p>

          <ul>
            <li className="hover:underline cursor-pointer mb-4 text-sm"
             onClick={() => handleServiceAll('Periodic Services')}
            >
            Periodic Services
            </li>
            <li className="hover:underline cursor-pointer mb-4 text-sm"
             onClick={() => handleServiceAll('Ac Service And Repair')}
            >
              Ac Service And Repair
            </li>
            <li className="hover:underline cursor-pointer mb-4 text-sm"
             onClick={() => handleServiceAll('Denting And Painting')}
            >
              Denting And Painting
            </li>
            <li className="hover:underline cursor-pointer mb-4 text-sm"
             onClick={() => handleServiceAll('Tyres And Wheel Care')}
             >
              Tyres And Wheel Care
            </li>
          </ul>
        </div>

        <div className="w-full md:w-[40%]">
          <p className="mb-5 after:mt-1 after:h-[1.5px] after:bg-green-400 after:w-[15%] after:bottom-0 after:left-0 after:abolute after:flex text-xl font-bold">
            Community
          </p>

          <ul>
            <li className="mb-2">
              <div className="flex space-x-3">
                <PiMapPinLineFill className="block w-5 h-5 md:w-6 md:h-6 flex-shrink-0" />
                <Link to="https://www.google.com/maps?q=Building+no+6,+First+floor,+near+Hanuman+Temple+Corner+Market,+Sadhna+Enclave,+Malviya+Nagar,+New+Delhi,+110017" 
                target="_blank" 
                >
                  <p className=" items-center text-sm md:text-base">
                  Building no 6, First floor, near
                  <br />
                  Hanuman Temple Corner Market
                  <br />
                  Sadhna Enclave, Malviya Nagar,
                  <br />
                  New Delhi, 110017
                </p>
                </Link>
              </div>
            </li>
            <li className="mb-2">
              <div className="flex space-x-3">
                <IoMdMail className="block w-5 h-5 md:w-6 md:h-6 flex-shrink-0" />
                <Link to="mailto:officialbusiness.sb@gmail.com">
                <p className=" items-center text-sm md:text-base"
                target="_blank"
                >
                  officialbusiness.sb@gmail.com
                </p>
                </Link>
                
              </div>
            </li>
            <li className="mb-2">
              <div className="flex items-center space-x-3">
                <FaPhoneAlt className="block w-5 h-5 mt-0.5 flex-shrink-0" />
                <Link to="tel:+919098630635" target="_blank">
                <p className=" items-center text-sm md:text-base">
                  +91 9098630635
                </p>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="text-white bg-black text-center flex flex-col md:flex-row gap-2 md:gap-0 w-full items-center justify-between">
        <p className="text-lg">
          Copyrights<sup>&copy;</sup>2024 Helpymoto, Inc.
        </p>
        <div className="flex items-center">
          <p className="text-lg mr-10">Stay in touch</p>
          <div className="flex gap-x-5 items-center">
            <Link to="https://www.linkedin.com/company/helpy-moto/
            " target="_blank">
              <FaLinkedinIn />
            </Link>
            <Link
              to="//twitter.com/HelpyMoto?t=FmxcdcAnmVX6GO20FkKFzA&s=09"
              target="_blank"
            >
              <FaXTwitter />
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=100069828099402&mibextid=ZbWKwL"
              target="_blank"
            >
              <FaFacebookF />
            </Link>
            <Link
              to="https://instagram.com/helpy_moto?igshid=YmMyMTA2M2Y="
              target="_blank"
            >
              <FaInstagram />
            </Link>
            <Link to="https://www.youtube.com/@helpy_moto7894" target="_blank">
              <AiOutlineYoutube className="w-5 h-5" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
