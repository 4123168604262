import React, { useEffect, useState } from "react";
import TrackPageNavbar from "./TrackPageNavbar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

const OrderHistory = () => {
  // const dummyData = [
  //   {
  //     mechanicName: "Mechanic Name",
  //     amount: "2,500",
  //     orderId: "12345671",
  //     paymentMethod: "UPI",
  //     serviceDetails: "Basic Service, Front Break Pad (2 service) ",
  //     status: "On Going",
  //     estimationTime: "17 May 2024 (10:30)",
  //     address: "1st Cross road, BAD COLONY, Bangalore, 600251",
  //     mechanicImage:
  //       "https://s3-alpha-sig.figma.com/img/ed7a/fb95/d4bab49345e40720db7092a270b0d65d?Expires=1707696000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FZkDZOWFFwEFCA58GkgMt8~o7dr6zoGulQZpPSpzrwKxJ7U0nmjbFynFd0VXzGtZg8N5FS5y25Tlst-i25ijXT0wwJyiXbvTLpDdfGhlxES9Na30zUyydi2GGTeYn8~mpESWB1TLF6in3VdBjkLzO3h39YFsnkI1h1ZWZ8~6EN7NvbSYd8krEZQkOiKREhwAdJTbYXM9p8rB4wrGUNJ6R8CzTUlytgc1OQvAr5z1sM4ApiFg2in7txGMUctxNrb4qLOeAdhAfGpRH978HTmVTyBqpKU6pIgytdz39sfWQ-QkvETitpbRABvLYaCKyPwEOWz6ZFiQXg-UbPQmSeAcYw__",
  //   },
  //   {
  //     mechanicName: "Mechanic Name",
  //     amount: "3,000",
  //     orderId: "78901234",
  //     paymentMethod: "Credit Card",
  //     serviceDetails: "Basic Service, Front Break Pad (2 service)",
  //     status: "Completed",
  //     estimationTime: "20 May 2024 (12:00)",
  //     address: "1st Cross road, BAD COLONY, Bangalore, 600251",
  //     mechanicImage:
  //       "https://s3-alpha-sig.figma.com/img/ed7a/fb95/d4bab49345e40720db7092a270b0d65d?Expires=1707696000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FZkDZOWFFwEFCA58GkgMt8~o7dr6zoGulQZpPSpzrwKxJ7U0nmjbFynFd0VXzGtZg8N5FS5y25Tlst-i25ijXT0wwJyiXbvTLpDdfGhlxES9Na30zUyydi2GGTeYn8~mpESWB1TLF6in3VdBjkLzO3h39YFsnkI1h1ZWZ8~6EN7NvbSYd8krEZQkOiKREhwAdJTbYXM9p8rB4wrGUNJ6R8CzTUlytgc1OQvAr5z1sM4ApiFg2in7txGMUctxNrb4qLOeAdhAfGpRH978HTmVTyBqpKU6pIgytdz39sfWQ-QkvETitpbRABvLYaCKyPwEOWz6ZFiQXg-UbPQmSeAcYw__",
  //   },
  //   {
  //     mechanicName: "Mechanic Name",
  //     amount: "3,000",
  //     orderId: "78901234",
  //     paymentMethod: "Credit Card",
  //     serviceDetails: "Basic Service, Front Break Pad (2 service)",
  //     status: "Completed",
  //     estimationTime: "20 May 2024 (12:00)",
  //     address: "1st Cross road, BAD COLONY, Bangalore, 600251",
  //     mechanicImage:
  //       "https://s3-alpha-sig.figma.com/img/ed7a/fb95/d4bab49345e40720db7092a270b0d65d?Expires=1707696000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FZkDZOWFFwEFCA58GkgMt8~o7dr6zoGulQZpPSpzrwKxJ7U0nmjbFynFd0VXzGtZg8N5FS5y25Tlst-i25ijXT0wwJyiXbvTLpDdfGhlxES9Na30zUyydi2GGTeYn8~mpESWB1TLF6in3VdBjkLzO3h39YFsnkI1h1ZWZ8~6EN7NvbSYd8krEZQkOiKREhwAdJTbYXM9p8rB4wrGUNJ6R8CzTUlytgc1OQvAr5z1sM4ApiFg2in7txGMUctxNrb4qLOeAdhAfGpRH978HTmVTyBqpKU6pIgytdz39sfWQ-QkvETitpbRABvLYaCKyPwEOWz6ZFiQXg-UbPQmSeAcYw__",
  //   },
  // ];

  const [orders, setOrders] = useState();
  const navigate = useNavigate();
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);

  const data = {
    customerId: "64da595b90a60c8f718d7271",
  };

  const handleOrder = (id) => {
    console.log(`Clicked on service with ID: ${id}`);
    navigate("/profile/order-history/order-details", {
      state: { orderId: id },
    });
  };

  useEffect(() => {
    axios
      .post(
        `${SERVER_URL}/user/getorderhistory/?status=&page=1&limit=30`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const orders = response.data.orders;
        if (orders) {
          const ordersAssigned = orders.mechanicTickets.filter(
            (order) =>
              order.status === "accepted" ||
              order.status === "inProcess" ||
              order.status === "completed"
          );
          setOrders(ordersAssigned);
          console.log(ordersAssigned);
        } else {
          console.error("No services found in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      <TrackPageNavbar showOnlyNav={false} />
      <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
    <p class="text-sm text-gray-500 font-bold">
    <a href="/" class="hover:text-black hover:cursor-pointer">Profile</a> 
              <span class="text-black"> / </span>
                <span class="text-black">Order History</span>
                
    </p>
</div>

      <div className="mt-6 px-4 sm:px-6 md:px-14">
        <div
          className="my-4"
          style={{
            fontSize: "28px",
            fontWeight: "700px",
            fontFamily: "poppins",
          }}
        >
          <h1>Order History</h1>
        </div>

        {orders
          ? orders.map((order, index) => (
              <div
                key={index}
                className="border border-black border-opacity-20 p-3 md:p-6 mb-3"
                style={{
                  // maxWidth: "97%",
                  width: "100%",
                  borderRadius: "5px",
                }}
              >
                <div className="flex flex-col sm:flex-row sm:items-center">
                  <div className="flex-shrink-0 mb-4 sm:mb-0">
                    <img
                      alt=""
                      src=""
                      className="w-12 h-12 rounded-full overflow-hidden"
                    />
                  </div>
                  <div className="flex-grow ml-0 sm:ml-4">
                    <div className="flex justify-between">
                      <span className="font-bold text-black">
                        Mechanic Name
                      </span>
                      <span
                        className="font-bold text-black"
                        style={{ fontSize: "20px" }}
                      >
                        {/* {order.amount} */} 2500
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <p style={{ color: "#515151" }}>
                        Order ID: {order._id}
                      </p>
                      <p style={{ color: "#515151" }}>
                        {/* {order.paymentMethod} */} UPI
                      </p>
                    </div>
                  </div>
                </div>

                <hr className="border-b border-dashed border-black border-opacity-20 my-4 " />

                <div className="flex flex-col ml-0 sm:ml-16">
                  <div className="flex flex-row justify-between">
                    <span
                      className="font-bold text-black"
                      style={{ fontSize: "18px" }}
                    >
                      Service :{" "}
                      <span
                        style={{
                          color: "#515151",
                          fontWeight: "lighter",
                          fontSize: "16px",
                        }}
                      >
                        {/* {order.serviceDetails} */} Service Details
                      </span>
                    </span>{" "}
                    {order.status !== "completed" ? (
                      <span
                        style={{
                          fontFamily: "Poppins",
                          whiteSpace: "nowrap",
                        }}
                        className="font-bold text-red-500 mt-1"
                      >
                        {order.status}
                      </span>
                    ) : (
                      <div className="flex items-center space-x-2">
                        {Array.from({ length: 5 }).map((_, i) => (
                          <span
                            key={i}
                            className={`text-yellow-500 ${
                              i < 4 ? "fill-star" : "empty-star"
                            } md:text-2xl lg:text-3xl xl:text-4xl`}
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    className="font-bold text-black"
                    style={{ fontSize: "18px" }}
                  >
                    Estimation Time :{" "}
                    <span
                      style={{
                        color: "#515151",
                        fontWeight: "lighter",
                        fontSize: "16px",
                      }}
                    >
                      {/* {order.estimationTime} */}TIME
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between items-center">
                    <span
                      className="font-bold text-black"
                      style={{ fontSize: "18px" }}
                    >
                      Address :{" "}
                      <span
                        style={{
                          color: "#515151",
                          fontWeight: "lighter",
                          fontSize: "16px",
                        }}
                      >
                        {/* {order.address} */}Address
                      </span>
                    </span>
                    <span
                      style={{ color: "#22C55D", fontSize: "16px" }}
                      className="font-bold mr-2"
                    >
                      Help
                    </span>

                    {order.status !== "completed" ? (
                      <button
                        className="w-200 h-40 rounded-5 bg-white text-green-500 border-2 border-green-500 transition duration-300 hover:bg-green-500 hover:text-white"
                        style={{
                          width: "200px",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                      >
                        <Link to="/profile/order-history/order-details/track">
                          Track order
                        </Link>
                      </button>
                    ) : (
                      <button
                        className="w-200 h-40 rounded-5 bg-white text-green-500 border-2 border-green-500 transition duration-300 hover:bg-green-500 hover:text-white"
                        style={{
                          width: "200px",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                        onClick={() => handleOrder(order._id)}
                      >
                        Order Details
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default OrderHistory;
