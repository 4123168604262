import React,{useContext} from 'react'
import NavbarUpdate from '../components/NavbarUpdate'
import { CartContext } from '../context/CartContext';
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

const CheckoutScreen = () => {

    const {cartItems,cartTotal} = useContext(CartContext);

  return (
    <div>
        <NavbarUpdate showOnlyNav={true}/>
        <div className='p-4'>
            <p className='text-2xl font-bold mb-10'>CheckOut</p>

        <div className='grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3'>
            {/* Get additional details like pickup drop location date and time */}
            <div className='col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2'>
                {/* Date and Time of Service */}
                <div className='bg-gray-100 shadow-md rounded-md p-4'>
                    <p className='text-xl font-semibold mb-3'>Select Date and Time of Pickup</p>

                    {/* date picker */}
                    <DateTimePicker className='bg-white w-fit' disableOpenPicker={false} defaultValue={dayjs('2022-04-17T15:30')} />

                </div>


                {/* Select Address */}
                <div className='bg-gray-100 shadow-md rounded-md p-4 mt-3'>
                    <p className='text-xl font-semibold mb-3'>Add/Select Address</p>

                    {/* pickup/drop address*/}
                    <div className='flex flex-col justify-between'>

                    <p className='text-lg font-semibold'>Pick-Up Address</p>
                    <input type="text" placeholder='Enter Address' className='px-3 py-2'/>

                    <p className='text-lg font-semibold mt-8'>Drop Address</p>
                    <input type="text" placeholder='Enter Address' className='px-3 py-2'/>
                    </div>

                </div>
            </div>


            {/* Bill invoice */}
            <div className='col-span-1'>
                {/* CartItems, cartTotal and Pay Now btn */}
                <div className='shadow-md bg-gray-100 p-4'>
                    <p className='text-xl font-semibold mb-10'>Cart</p>
                    {/*<p className='text-center my-20'>Cart items here.</p>*/}

                    {cartItems.length > 0 ? (
              <div>
                {cartItems.map((item, index) => (
                  <div key={index} className='border bg-white p-2 mb-4'>
                    {/* Display cart item details */}
                    <div className='grid grid-cols-4'>
                      <div className='flex items-center justify-start col-span-3 w-full'>
                        <img
                          src={item.photo}
                          alt={item.name}
                          className='w-16 h-16 mr-4 object-contain'
                        />
                        <h2 className='md:text-lg font-semibold'>{item.name}</h2>
                      </div>

                      {/* Rest of your code */}
                      <div className='col-span-1 flex w-full justify-end items-center'>
                      <p className='mt-2 text-gray-600'>
                        
                        {item.discount !== null ? <div className='flex flex-col'>
                                    <p className='text-lg font-semibold'>&#8377; {Number(item.basePrice-Number(item.basePrice*item.discount/100)).toFixed(0)}</p>
                                    <s className='text-sm text-red-500'>&#8377; {item.basePrice}</s>
                                    
                                </div> : <div className='flex flex-col'>
                                    <p className='text-lg font-semibold'>&#8377; {Number(item.basePrice)}</p>
                                    
                                    
                                </div>}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className='text-center my-20'>No items in cart.</p>
            )}

                    <div>
                        <p className='font-semibold text-lg'>Bill Details</p>
                        <div className='flex justify-between mb-2'>

                        <p>Item Total</p>
                        <p>&#8377;{cartTotal}</p>
                        </div>
                        <div className='flex justify-between mb-3'>

                        <p>Safety and Warranty Fees</p>
                        <p>&#8377; 88</p>
                        </div>

                        <div className='bg-green-500 px-3 py-3 text-white font-semibold justify-between rounded-md cursor-pointer flex'>
                            <div className='h-8 text-xl flex w-full items-center justify-between'>

                            <p>PAY</p>
                            <p>&#8377; {cartTotal+88}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default CheckoutScreen;