import React from 'react'

const SinglePromo = ({code,desc,appliedCoupon,setAppliedCoupon,onClose}) => {
    console.log(code)
    const handleApply=()=>{
      setAppliedCoupon(code)
        onClose()
    }
  return (
    <div  className='w-full py-4 border-white border-b-[#CBCBCB] border-2'>
      <div className='flex justify-between allign-center'>
        <div className='bg-[#73012C0F] border-2 p-1 border-[#73012C] px-6'>
            {code}
        </div>
        <div className='text-[#73012C] cursor-pointer ' 
          onClick={handleApply}>
            Apply Coupon
        </div>
      </div>
      <div className='mt-4 text-sm'>
      {desc}
      </div>
    </div>
  )
}

export default SinglePromo
