import React, { useState } from 'react';
import Footer from '../components/Footer';
import TrackPageNavbar from "../components/TrackPageNavbar";


const SubscriptionPlan = () =>{

    
    return(
        <div>
        <div className='flex flex-col w-full h-screen'>
    <TrackPageNavbar showOnlyNav={false} />



  
        <Footer />
        </div>
        
        </div>
    );
};

export default SubscriptionPlan;