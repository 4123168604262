
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


// ../assets/OrderDetailsPage/excellence 1.png

const CancelService = ()=>{
    return(
        <>
            <div className="w-full h-screen grid place-items-center">
                <div className="grid place-items-center">
                    <img style={{width: '200px',height:'200px',objectFit:'contain'}} src={require("../assets/OrderDetailsPage/cancelled 1.png")}/>
                    <h1 className="w-3/4 my-4 text-lg font-bold md:text-2xl text-center">Your booking has been canceled as no service provider accepted your request . </h1>
                    <p className="w-1/2 my-3 text-center text-sm">[Refund details will be processed, and you will be notified shortly.]</p>
                    <button style={{background:'#22C55D'}} className="my-8 text-white py-2 px-4 rounded">Go back to home screen</button>
                    <div className="flex flex-row itens-center">
                        <HelpOutlineIcon/>
                        <p className='mx-2' >Need help ?</p>
                        <a style={{color: '#22C55D', textDecoration: 'underline'}} href="#">Click here</a>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CancelService;