import React, { useState, useContext, useRef ,useEffect } from "react";
import TrackPageNavbar from "../components/TrackPageNavbar";
import "../styles/navbar.css";
import mechanicImage from "../assets/Ellipse 2.png";
import TextField from "@mui/material/TextField";
import { Link,  useNavigate } from "react-router-dom";
import VehicleSelector from "../components/VehicleSelector";
// import MapLocationSelector from "../components/MapLocationSelector";
import { LocationData } from "../context/LocationContext";
// import { LocationItemState} from "../context/LocationContext";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import { APIProvider , Map , Marker  } from "@vis.gl/react-google-maps";
import InputSuggestionMaps from "../components/InputSuggestionMaps";
import { socket } from "../utils/socketConfigure";
import DirectionsComponent from "../components/DirectionComponent";
import pusher from "../config/pusherConfig";
// import { Co2Sharp } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import Xarrow from "react-xarrows";
import mixpanel from "../config/mixpanel";


const OnTimeScheduleService = () => {
  const MAP_API = process.env.REACT_APP_MAP_API;
  const mapRef = useRef(null);
  // const [cartItems, setCartItems] = useState([]);
  const { locationdispatch, locationstate } = useContext(LocationData);
  // const [suggestions, setSuggestions] = useState([]);
  // const [inputValue, setInputValue] = useState("");
  const [coords,setCoords] = useState({});
  // const [loading, setLoading] = useState(false);

  // Use this coordinates for testing... locates to delhi
  // let del = {lat:28.659261, lng:77.190601}

  const [issue, setIssue] = useState("");
  const [vehicleno, setVehicleno] = useState("");
  const [address,setAddress] = useState("");

  const navigate = useNavigate();

  const token = localStorage.getItem("TOKEN");

  const [showSelectVehicle, setShowSelectVehicle] = useState(true);
  const [showSearchMechanic, setShowSearchMechanic] = useState(false);
  const [showMechanicDetails, setShowMechanicDetails] = useState(false);

  const [showServiceDetails,setShowServiceDetails] = useState(false);
  const [mechanic] = useState({
    name: "Mechanic A",
    rating: 4.6,
    reviews: 18,
  });

  const [onGoingService, setOnGoingService] = useState([
    "Engine Poblem",
    "A/C Repair",
    "ABC",
  ]);
  const [totalCost, setTotalCost] = useState(2800);
  const [totalTime,setTotalTime] = useState(4);
  const [diagnosing, setDiagnosing] = useState(false);
  const [show, setShow] = useState(false);
  const [continueService, setService] = useState(false);
  const [ontime, setOntime] = useState(false);
  const [book, setBook] = useState(false);
  const [success, setSuccess] = useState(false);
  const [cancel, setCancel] = useState(false);


  const step1 = useRef(null);
  const step2 = useRef(null);
  const step3 = useRef(null);
  const modalRef = useRef(null);

  const [nearbyMech,setNearByMechanic] = useState([]);
  // const [showDetails, setShowDetails] = useState(true);
  const [mechanicDetailTimeout] = useState(null);
  const [nearByRange,setNearByRange] = useState(500);
  // const [ticketData,setTicketData] = useState([]);
  const [otp,setOtp] = useState(null);
  const [mechanicUid,setMechanicUid] = useState();
  const [mechanicDetail,setMechanicDetails] = useState(null);
  const [vehicleData,setVehicleData] = useState(null);
  // const [address,setAddress] = useState(null);
  const [reRouteDetails,setReRouteDetails] = useState(null);
  const [directionsDetails,setDirectionsDetails] = useState(null);
  const [loggedinUser] = useState(JSON.parse(localStorage.getItem('user'))?._id);
  const [ticket_Id,setTicketId] = useState(null);
  // const [accepted,setAcceptedStatus] = useState(false);


  const INCREMENT = 500;

  // let testCoordinates = [
  //   {lat:15.605319027711012, lng:76.65849197597773}, // milan
  //   {lat:15.603603685238554, lng:76.66078794692031}, // Masjid
  //   {lat:15.605432694476582, lng:76.65615308969045}, // padmavati
  // ]

  const channel = pusher.subscribe('ticket-response');

  channel.bind('success', (data) => {
    console.log("Mechanic Accepted Ticket data is ",data);
    setTicketId(data?.ticketId);
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = parseFloat(position.coords.latitude).toFixed(3);
          const lng = parseFloat(position.coords.longitude).toFixed(3);



          // console.log("Window object is ",window.google.maps.LatLng)
          
          // let googleCoords = new window.google.maps.LatLng(lat,lng);
          
          // console.log("Google coords are ",googleCoords)
          console.log("type of latitude is ",typeof(Number(lat)))
          console.log("TYpe of longitude is ",typeof(Number(lng)))
          console.log("Lat lng after type conversion is ",Number(lat),Number(lng))
          let locationCoordinates = {
            lat: Number(lat),
            lng: Number(lng),
          }
          setCoords(locationCoordinates);
          console.log("coordinates from first useEffes are are ",locationCoordinates);

          locationdispatch({
            type: "UPDATE_LOCATION",
            payload: {
              latitude: lat,
              longitude: lng,
            },
          });
        },
        (error) => {
          console.log("Error getting location:", error);
        }
      );
    }
  }, []);

  useEffect(()=>{

    if(loggedinUser){
      let authToken = JSON.parse(localStorage.getItem('userid'))?.token;
      let user = JSON.parse(localStorage.getItem('user'));
      let userUid = user.userUid;

      socket.io.opts.extraHeaders.token = authToken;
      socket.connect();
      console.log("Socket is connected with Server ",socket);

      let payload = {
        Uid: userUid,
        location: [coords.lat,coords.lng]
      }

      // console.log("Payload to be sent is ",payload)
      socket.emit('update-location-user',payload);

    }

  },[loggedinUser,coords]);


  useEffect(()=>{

    let url =  `${SERVER_URL}/mechanic/getMechanicsNearby`;
    const user = JSON.parse(localStorage.getItem('userid'));
    const authToken = user?.token;

    console.log("Near by range is ",nearByRange);
    // const testLocation = [28.659261, 77.190601];
    let location = [coords.lng,coords.lat];
    console.log("Payload location is ",location);

    axios({
      method:'POST',
      url,
      data:{
        location:location,
        range:nearByRange
      },
      withCredentials:true,
      headers:{
        Authorization:`${authToken}`,
        'Content-Type':'application/json',
      }
    })
    .then((response)=>{
      console.log(response.data.mechanics);
      setNearByMechanic([]);
      response.data.mechanics.forEach(element => {
        let coordinates = element.location.coordinates;
        setNearByMechanic((prev)=> [ ...prev , { lat: coordinates[0], lng:coordinates[1] } ])
      });
    })
    .catch((err)=>{
      console.log("Something went wrong :(",err);
      if(err.response.status === 404)
        if(nearByRange < 2000 ) setNearByRange((prev)=> prev + INCREMENT);
    })

  },[coords,nearByRange]);

  useEffect(()=>{ 

    // Get the details of the mechanic using Mechanic Uid
    let url = `${SERVER_URL}/mechanic/${mechanicUid}`;
    const token = JSON.parse(localStorage.getItem('userid'))?.token;

    axios({
      method:"GET",
      url,
      withCredentials:true,
      headers:{
        Authorization:`${token}`,
        "Content-Type":"application/json"
      }
    })
    .then((response)=>{
      console.log("Sucessfully fetched Mechanic Information :)",response.data.mechanic);
      setMechanicDetails(response.data.mechanic);
    })
    .catch((err)=>{
      console.log(err);
    })

  },[mechanicUid]);

  useEffect(()=>{
    if(ticket_Id){

      console.log("Emitted")
      let payload = {
        ticketId:ticket_Id,
        room:'mechanicTickets'
      };

      socket.emit('re-calculate-route',payload);

      socket.emit('fetch-ticket',{
        ticketId: ticket_Id,
        room:"mechanicTickets"
      });

    }
  },[ticket_Id]);

  // const handleLocationSearch = async (query) => {
  //   setInputValue(query); // Update the input field value
  //   try {
  //     const response = await fetch(
  //       `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
  //         query
  //       )}.json?access_token=pk.eyJ1IjoiaGVscHltb3RvIiwiYSI6ImNsamNscHVuejAyOXAzZG1vNXppYnM1NzkifQ.BB9fpPJb9eDpRJkWwkRHXA&type=address&autocomplete=true&limit=5&country=IN`
  //     );

  //     if (!response.ok) {
  //       throw new Error("Error fetching location data");
  //     }

  //     const data = await response.json();

  //     if (data.features && data.features.length > 0) {
  //       const [lng, lat] = data.features[0].center;

  //       setSuggestions(data.features);
  //     } else {
  //       setSuggestions([]);
  //     }
  //   } catch (error) {
  //     console.error("Error searching location:", error);
  //   }
  // };

  // const handleSelectSuggestion = (suggestion) => {
  //   setInputValue(suggestion.place_name);

  //   locationdispatch({
  //     type: "UPDATE_LOCATION",
  //     payload: {
  //       latitude: suggestion.center[1],
  //       longitude: suggestion.center[0],
  //     },
  //   });
  //   setSuggestions([]); // Clear the suggestions after selecting one
  // };

  // const handleCards = () => {
  //   setShowSelectVehicle(false);
  //   setShowSearchMechanic(true);

  //   const mechanicDetailTimer = setTimeout(() => {
  //     setShowSearchMechanic(false);
  //     setShowMechanicDetails(true);
  //   }, 5000);
  // };

  const handleProceed = async () => {
    try {
      // setLoading(true);
      const storedVehicle = JSON.parse(
        localStorage.getItem("vehicle-selected")
      );

      if (!storedVehicle) {
        throw new Error("Vehicle not selected");
      }

      const brands = JSON.parse(localStorage.getItem("user-brands"));
      const vehicles = JSON.parse(localStorage.getItem("user-vehicles"));
      const fuels = JSON.parse(localStorage.getItem("user-vehicle-fuels"));

      const brand = brands.find(
        (brand) => brand.name === storedVehicle.brand.name
      );
      const model = vehicles.find(
        (model) => model.name === storedVehicle.model.name
      );
      const fuel = fuels.includes(storedVehicle.fuel)
        ? storedVehicle.fuel
        : null;

      const user = JSON.parse(localStorage.getItem("userid"));

      if (!user || !user.token) {
        throw new Error("User or user token is null");
      }

      const authToken = user.token;
      const customerId = user.user.userUid;
      console.log("HI from 216 ",locationstate)
      const location = [
        Number(locationstate?.location?.longitude),
        Number(locationstate?.location?.latitude),
      ];

      console.log("Location is ",location);
      let locationCoords = [coords.lng,coords.lat];
      console.log("My coords are ",locationCoords);

      // const role = "mechanic";
      // let baseURL = 'http://localhost:5000/api/v1'
      const response = await axios.post(
        `${SERVER_URL}/user/ticket/createOrder/mechanic`,
        {
          // Sign in as User
          // Creates service provider Ticket, When a service provider accepts, the ticket will get updated with the service provider Uid
          "userUid": customerId , // {{userUid}}, // default: 100002, Substitute the userUid here, as returned after Sign In
          "scheduleOfService": "onTime", // "onTime" or "scheduled"
          "typesOfServices": "others", // {"brakedown", "puncher", "others" for mechanic}|| {"normalCleaning", "fullCleaning", "dryCleaning", "others" for cleaners} || {["onewayTrip", "roundTrip" for drivers}
          "otherServiceTypeText": "cracking noise in transmission", // mandatory if chosen "others" otherwise optional thid not needed for driver
          "description": issue,
           // description of problem
          "pickLocation": 
          [ // [pi and drop locations needed  and no need of vehicle related data for driver tickect ]
              18.509484,
              73.813855
          ],
          "dropLocation": [
              18.509484,
              73.813855
          ],
          "vehicleBrand": brand,
          "vehicleModel": model,
          "vehicleFuel": fuel,
          "vehicleNo": vehicleno,
          "services": [
              "6561cf30addec79fbae4df04",
              "6561ce7faddec79fbae4dd28"
          ],
          "serviceRequested": "tire replacement",
          "location": [
              18.509484,
              73.813855
          ], // use your longitude, latitude
          "currentTrack": "Stage1",
          "status": "pending"
          // Required when service is scheduled
          /* In both provide all below, if pickup provide only pickup details, in drop only provide drop details  */
          // "modeOfService": "both",  // "pick-up", "drop", "both"
          // "pickupPlace": "Address of pickup place",  
          // "pickupDate": "Date of pickup",
          // "pickupTime": "Time of pickup",
          // "dropPlace": "Address of pickup place",
          // "dropDate": "Date of pickup",
          // "dropTime": "Time of pickup"
      },
        {
          withCredentials: true,
          headers: {
            Authorization: `${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("respose data", response.data);
      console.log("Vehcile data from backend is :) ",response.data.ticket[0].vehicleData)
      setVehicleData(response.data.ticket[0].vehicleData)

      const ticket = response.data?.ticket;
      // console.log("Order Ticket is ",ticket);
      // console.log("Ordered Ticket id is ",ticket[0]._id)
      // console.log("Ordered Ticket id is ",typeof(ticket[0]._id))
      // setLoading(false);
      setShowSelectVehicle(false);
      setShowSearchMechanic(true);

      if(authToken){
        // Incldue token in the header option 
        let ticketId = ticket[0]._id;
        socket.emit('fetch-ticket',{
          ticketId,
          room:'mechanicTickets'
        });
        
      }else{

        console.log('You need to Login first')

      }


      // navigate(`/service/${ticket[0]?._id}`);
    } catch (error) {
      console.error("Error in handleProceed:", error);
      // setLoading(false);
      alert("An error occurred while processing your request.");
    }
  };

  const handleMapClick = (event)=>{
    console.log("Map click event is ",event.detail.latLng);
    setCoords(event.detail.latLng)
  }

  /*socket.on('processed',(data)=>{
    
    if(data.message === 'Route Recalculated'){

      console.log("Start and End Address are :",{
        start:data.route.start_address,
        end: data.route.end_address
      })

      let directions = {
        status: true,
        origin: data.route.start_address,
        destination: data.route.end_address
      };

      setDirectionsDetails(directions);
      
      // console.log("Socket data from processed event is ",data);
      let state = {
        eta: data.route.time,
        mechanicAddress: data.route.start_address
      };

      // console.log("State for Mechanic Route Details is ",state);
      setReRouteDetails(state);

      // This will get the ETA and current mechanic address

    }else{
      // This fetches the OTP from ticket and mechanic Uid
      setOtp(data.ticket.scheduledArrivedOTP);
      setMechanicUid(data.ticket.mechanicUid);

        if(!showMechanicDetails && data.ticket.mechanicUid !== null){
          setShowSelectVehicle(false);
          setShowSearchMechanic(false);
          setShowMechanicDetails(true);
        }
        setTicketData(data.ticket);
    }
  })*/

  socket.on('processed',(data)=>{
    console.log("Data is ",data)
    if(data.message === 'Ticket Fetched'){
      setOtp(data.ticket.onTimeOTP);
      if(data.ticket.mechanicUid) setMechanicUid(data.ticket.mechanicUid);
      if(data.ticket?.status === 'accepted'){
        // setAcceptedStatus(true);
        setShowMechanicDetails(true);
        setShowSelectVehicle(false);
        setShowSearchMechanic(false);

        setTimeout(()=>{
          setShowMechanicDetails(false);
          setShowSelectVehicle(false);
          setShowSearchMechanic(false);
          setShowServiceDetails(true);

          socket.emit('fetch-estimation',{ 
            ticketId:'65f475e3c8b52dd3a38012ee',
            room:'mechanicTickets'
          });

        },5000);

      }
    }

    if(data.message === 'Route Recalculated'){
      let directions = {
        status: true,
        origin: data.route.start_address,
        destination: data.route.end_address
      };

      setDirectionsDetails(directions);
      
      // console.log("Socket data from processed event is ",data);
      let state = {
        eta: Math.ceil(data.route.time),
        mechanicAddress: data.route.start_address
      };

      // console.log("State for Mechanic Route Details is ",state);
      setReRouteDetails(state);
    }

    if(data.message === 'Services Fetched'){
      console.log("Ticket ETA has been Fetched!!",data);

      let services = [];
      let cost = 0;
      let time = 0;

      data.ticket.forEach((item)=>{
        
        console.log("Ticket item is ",item);
        let obj = {
          name: item?.name,
          time: parseInt(item?.duration.split(' ')[1]),
          price: item?.price,
        };
        cost += item?.price;
        time += obj?.time;
        services.push(obj);
      })


      console.log("Service Data is ",services);
      setTotalCost(cost);
      setTotalTime(time);
      setOnGoingService(services);
      setDiagnosing(true);

    }

  })



  return (
    <>
      <TrackPageNavbar showOnlyNav={false} />
      {show && (
        <div
          style={{ background: "#212121" }}
          className="w-full z-10 h-screen fixed top-0 right-0 left-0 grid place-items-center"
          onClick={(e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
              setService(false);
              setOntime(false);
              setBook(false);
              setSuccess(false);
              setCancel(false);
              setShow(false);
            }
          }}
        >
          {continueService && (
            <div
              className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4  rounded"
              ref={modalRef}
            >
              <CancelIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow(false);
                  setService(false);
                }}
              />
              <div className="text-center">
                <h1 className="text-lg font-bold">
                  Convert to schedule service ?
                </h1>
                <p className="my-3">
                  The assessment and service for your car may take longer than
                  expected, approximately 1 day. To better accommodate this,
                  would you like to convert your ongoing service to a scheduled
                  one?
                </p>
              </div>
              <div className="flex md:flex-row flex-col items-center justify-center">
                <button
                  onClick={() => {
                    setOntime(true);
                    setService(false);
                  }}
                  style={{
                    borderColor: "#22C55D",
                    border: "1px solid #22C55D",
                  }}
                  className="py-2 px-4 mr-3 rounded md:my-0 my-2"
                >
                  On time Service
                </button>
                <button
                  onClick={() => {
                    setOntime(false);
                    setService(false);
                    setBook(true);
                  }}
                  style={{ background: "#22C55D" }}
                  className="text-white py-2 px-4 rounded md:my-0 my-2"
                >
                  Schedule Service
                </button>
              </div>
            </div>
          )}
          {ontime && (
            <div
              className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4 rounded"
              ref={modalRef}
            >
              <CancelIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow(false);
                  setService(false);
                  setOntime(false);
                }}
              />
              <div className="text-center my-4">
                <ErrorIcon style={{ color: "red", fontSize: "50px" }} />
                <h1 className="text-lg font-bold">
                  On-Time Service is not available for this service.
                </h1>
              </div>
              <div className="flex md:flex-row flex-col items-center justify-center my-3">
                <button
                  onClick={() => {
                    setShow(false);
                    setService(false);
                    setOntime(false);
                    setBook(false);
                  }}
                  style={{
                    borderColor: "#22C55D",
                    border: "1px solid #22C55D",
                  }}
                  className="py-2 px-4 mr-3 rounded md:my-0 my-2"
                >
                  Cancel service
                </button>
                <button
                  onClick={() => {
                    setService(false);
                    setOntime(false);
                    setBook(true);
                  }}
                  style={{ background: "#22C55D" }}
                  className="text-white py-2 px-4 rounded md:my-0 my-2"
                >
                  Schedule Service
                </button>
              </div>
            </div>
          )}
          {book && (
            <div
              className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4  rounded"
              ref={modalRef}
            >
              <CancelIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow(false);
                  setService(false);
                  setOntime(false);
                  setBook(false);
                }}
              />
              <h1 className="text-center text-lg font-bold mt-5 mb-3">
                Are you sure you want to book service ?
              </h1>
              <div className="flex flex-row justify-center mt-3 mb-8">
                <button
                  onClick={() => {
                    setCancel(true);
                    setService(false);
                    setBook(false);
                    setOntime(false);
                    setSuccess(false);
                  }}
                  style={{ border: "1px solid red" }}
                  className="py-1 px-5 mr-3 rounded"
                >
                  No
                </button>
                <button
                  onClick={() => {
                    let user = JSON.parse(localStorage.getItem("user"));
                    mixpanel.track("SUCCESS_SCHEDULE", {
                      userId: user.userUid,
                      email: user.email,
                      username: `${user.firstName} ${user.lastName}`,
                      mechanic,
                      onGoingServices: onGoingService,
                      totalCost: totalCost,
                    });
                    setSuccess(true);
                    setCancel(false);
                    setBook(false);
                    setService(false);
                    setOntime(false);
                  }}
                  style={{ background: "#22C55D" }}
                  className="text-white py-1 px-5 rounded"
                >
                  Yes
                </button>
              </div>
            </div>
          )}
          {success && (
            <div
              className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4  rounded"
              ref={modalRef}
            >
              <div className="grid place-items-center mt-5">
                <img
                  alt=""
                  src={require("../assets/OrderDetailsPage/excellence 1.png")}
                  style={{
                    width: "100px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <h1
                className="text-lg text-center font-bold my-5"
                style={{ color: "#22C55D" }}
              >
                Service Booked Successfully!
              </h1>
            </div>
          )}
          {cancel && (
            <div
              className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4  rounded"
              ref={modalRef}
            >
              <div className="flex flex-col items-center mt-8">
                <h1 className="text-lg font-bold text-center">
                  Are you sure you wanna cancel service ?
                </h1>
                <p className="w-3/4 text-center mb-3">
                  You will be charged an average amount for the mechanic's visit
                  due to the service cancellation
                </p>
              </div>
              <div className="flex md:flex-row flex-col items-center justify-center mt-5 mb-8">
                <button
                  style={{
                    borderColor: "#22C55D",
                    border: "1px solid #22C55D",
                  }}
                  className="py-2 px-4 mr-3 rounded md:my-0 my-2"
                >
                  Continue Service
                </button>
                <button
                  onClick={() => {
                    navigate("/cancel/service");
                  }}
                  style={{ background: "#22C55D" }}
                  className="text-white py-2 px-4 md:my-0 my-2 rounded"
                >
                  Pay Now
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col md:flex-row w-full h-screen p-2">
        <div className="w-full md:w-1/2 lg:w-2/5 p-4 relative h-fit">
          {showSelectVehicle && (
            <div
              className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 flex flex-col gap-y-0.5 bg-white shadow-lg rounded-md p-4"
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
            >
              <h2 className="text-xl font-semibold">Schedule a Service</h2>
              {/* <select id="carBrand" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                  <option selected>Select Car Brand</option>
                  <option value="Toyota">Toyota</option>
                  <option value="Honda">Honda</option>
                  <option value="Ford">Ford</option>
              </select> */}
              <VehicleSelector />
              {/* <div className="inputBox-navbar mb-5">
                <label htmlFor="issue" className="block mb-2 text-sm font-medium text-gray-900">Issue</label>
                <select id="issue" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                  <option selected>Select Issue</option>
                  <option value="Engine">Engine</option>
                  <option value="Transmission">Transmission</option>
                  <option value="Brakes">Brakes</option>
                </select>
              </div> */}
{/* 
               <TextField
                id="outlined-multiline"
                label="Address"
                multiline
                rows={6}
                className="w-full"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
               */}
              <TextField
                id="outlined-multiline-static"
                label="Issue Description"
                multiline
                rows={6}
                className="w-full"
                value={issue}
                onChange={(e) => setIssue(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                type="text"
                label="Enter Vehicle RegNo."
                variant="outlined"
                className="w-full"
                style={{ marginTop: "16px" }}
                value={vehicleno}
                onChange={(e) => setVehicleno(e.target.value)}
              />
              {token ? (
                <div
                  onClick={() => {
                    // handleCards();
                    handleProceed();
                  }}
                  className="bg-green-500 shadow-md rounded-lg cursor-pointer text-white font-semibold px-3 py-3 flex justify-center items-center mt-4"
                >
                  Schedule Service
                </div>
              ) : (
                <Link to="/LoginPage">
                  <div className="bg-green-500 shadow-md rounded-lg cursor-pointer text-white font-semibold px-3 py-3 flex justify-center mt-4">
                    <p>Log in to continue</p>
                  </div>
                </Link>
              )}
            </div>
          )}
          {showSearchMechanic && (
            <div
              className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 flex flex-col h-full bg-white shadow-lg rounded-md p-6 ml-6"
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
            >
              <h2 className="text-xl font-semibold mb-2">
                Search for Nearby Mechanic
              </h2>
              {/* Google Maps-style Location Picker Icon */}
              <div className="flex justify-center items-center w-full h-32 p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="red"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-map-pin"
                  width="55"
                  height="50"
                >
                  <path d="M12 2C8.68678 2 6 4.68678 6 8C6 13 12 22 12 22C12 22 18 13 18 8C18 4.68678 15.3132 2 12 2ZM12 10C10.3431 10 9 11.3431 9 13C9 14.6569 10.3431 16 12 16C13.6569 16 15 14.6569 15 13C15 11.3431 13.6569 10 12 10Z" />
                </svg>
              </div>
              <div className="my-5">
                <h1 className="text-xl font-semibold mb-2">Service Location</h1>
                <div className="flex flex-col md:flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-map-pin"
                    width="30"
                    height="30"
                  >
                    <path d="M12 2C8.68678 2 6 4.68678 6 8C6 13 12 22 12 22C12 22 18 13 18 8C18 4.68678 15.3132 2 12 2ZM12 10C10.3431 10 9 11.3431 9 13C9 14.6569 10.3431 16 12 16C13.6569 16 15 14.6569 15 13C15 11.3431 13.6569 10 12 10Z" />
                  </svg>
                  <p className="ml-2">{address}</p>
                </div>
              </div>

                <div className="my-6"> 
                <h1 className="text-xl font-semibold mb-2">Vehicle Details</h1>
                <div className="flex flex-col">
                  <div className="flex flex-wrap justify-start">
                    <div className="mb-2 md:mb-0 md:w-1/2 lg:w-1/4 p-1">
                      <p className="font-bold">Brand:</p>
                      <p>{vehicleData.vehicleBrand}</p>
                    </div>
                    <div className="mb-2 md:mb-0 md:w-1/2 lg:w-1/4 p-1">
                      <p className="font-bold">Model:</p>
                      <p>{vehicleData.vehicleModel}</p>
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-start p-1">
                    <div className="mb-2 md:mb-0 md:w-1/2 lg:w-1/4">
                      <p className="font-bold">Vehicle No:</p>
                      <p>{vehicleData.vehicleNo}</p>
                    </div>
                    <div className="mb-2 md:mb-0 md:w-1/2 lg:w-1/4">
                      <p className="font-bold">Fuel:</p>
                      <p>{vehicleData.vehicleFuel}</p>
                    </div>
                  </div>
                </div>
              </div> 

              {/* <div className="my-6">
  <h1 className="text-xl font-semibold mb-2">Vehicle Details</h1>
  <div className="flex flex-col">
    <div className="flex flex-wrap justify-start">
      <div className="mb-2 md:mb-0 w-full md:w-auto lg:w-auto p-1">
        <p className="font-bold">Brand:</p>
        <p>{vehicleData.vehicleBrand}</p>
      </div>
      <div className="mb-2 md:mb-0 w-full md:w-auto lg:w-auto p-1">
        <p className="font-bold">Model:</p>
        <p>{vehicleData.vehicleModel}</p>
      </div>
    </div>
    <div className="flex flex-wrap justify-start p-1">
      <div className="mb-2 md:mb-0 w-full md:w-1/2 lg:w-1/4">
        <p className="font-bold">Vehicle No:</p>
        <p>{vehicleData.vehicleNo}</p>
      </div>
      <div className="mb-2 md:mb-0 w-full md:w-1/2 lg:w-1/4">
        <p className="font-bold">Fuel:</p>
        <p>{vehicleData.vehicleFuel}</p>
      </div>
    </div>
  </div>
</div> */}

              <button
                onClick={() => {
                  clearTimeout(mechanicDetailTimeout);
                  setShowSelectVehicle(true);
                  setShowSearchMechanic(false);
                  setShowMechanicDetails(false);
                }}
                className="bg-red-500 text-white font-semibold px-3 py-2 mt-4 rounded-lg"
              >
                Cancel
              </button>
            </div>
          )}
          {showMechanicDetails && (
            <div
              className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 flex flex-col h-full bg-white shadow-lg rounded-md p-6 ml-8"
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
            >
              <div className="flex ">
                <img src={mechanicImage} alt=""></img>
                <div className="ml-4">
                  <p className="font-bold">{mechanicDetail?.generalDetails.fullName}</p>
                  <div className="flex items-center">
                    {Array.from({ length: 5 }).map((_, i) => (
                      <span
                        key={i}
                        className={`text-yellow-400 ${
                          i < 4 ? "fill-star" : "empty-star"
                        } md:text-2xl lg:text-3xl xl:text-4xl`}
                      >
                        &#9733;
                      </span>
                    ))}
                  </div>
                  <p>4.6 (16 Review)</p>
                </div>
              </div>
              <hr className="border-b border-dashed border-black border-opacity-20 my-4" />

              <div className="my-8">
                <h1 className="text-sm md:text-base lg:text-lg xl:text-4xl font-bold">
                  OTP : {otp}
                </h1>
                <p>Share OTP Once Mechanic Reached Your Loacation</p>
                <div className="mt-5 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    width="350"
                    height="200"
                  >
                    {/* Define linear gradient */}
                    <defs>
                      <linearGradient
                        id="greenGradient"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                      >
                        <stop
                          offset="70%"
                          style={{ stopColor: "#27BA48", stopOpacity: 1 }}
                        />
                        <stop
                          offset="83%"
                          style={{ stopColor: "#ccc", stopOpacity: 1 }}
                        />
                        <stop
                          offset="100%"
                          style={{ stopColor: "#ccc", stopOpacity: 1 }}
                        />
                      </linearGradient>
                    </defs>

                    {/* Circle background */}
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      fill="none"
                      stroke="url(#greenGradient)"
                      strokeWidth="10"
                    />

                    {/* Text */}
                    <text
                      x="50"
                      y="50"
                      textAnchor="middle"
                      alignmentBaseline="middle"
                      fill="#000"
                      fontSize="18"
                      fontWeight="bold"
                      className="text-sm font-semibold"
                    >
                      {reRouteDetails?.eta + ' min' || '20 min' } 
                    </text>
                  </svg>
                  <div className="p-3 ">
                    {/* Custom location pin icon */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="text-black"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M12 2C7.58 2 4 5.58 4 10c0 5.25 8 13 8 13s8-7.75 8-13c0-4.42-3.58-8-8-8z" />
                      <circle cx="12" cy="10" r="3" />
                    </svg>

                    {/* Other information */}
                    <p className="font-bold">Mechanic Location</p>
                    <p>
                      {reRouteDetails?.mechanicAddress || '3/690, SS street, near St Thomas school, Delhi - 600345'}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex justify-around mt-10">
                {/* Chat button */}
                <button className="flex items-center space-x-2 p-2 bg-white border border-green-500 text-green-500 rounded w-32 justify-center">
                  <p className="p-1">Chat</p>
                </button>

                {/* Call button */}
                <button className="flex items-center space-x-2 p-2 bg-green-500 text-white rounded w-32 justify-center">
                  <p className="p-1">Call</p>
                </button>
              </div>
              <p
                onClick={() => {
                  setShowSelectVehicle(true);
                  setShowSearchMechanic(false);
                  setShowMechanicDetails(false);
                }}
                className="flex justify-center text-red-500 mt-4 rounded-lg cursor-pointer"
              >
                Go Back
              </p>
            </div>
          )}
          {showServiceDetails && (
            <div
            className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 flex flex-col gap-y-0.5 bg-white shadow-lg rounded-md p-4"
            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }} 
            >
              <div className=" md:w-3/4 md:m-auto">
                <div className="flex flex-row">
                  <img
                  alt=""
                    src={require("../assets/OrderDetailsPage/mechanic_A.png")}
                    className="rounded-full"
                    style={{
                      width: "77px",
                      height: "77px",
                      objectFit: "cover",
                    }}
                  />
                  <div className="ml-3">
                    <h1 className="text-14/18 font-bold">{mechanic.name}</h1>
                    <h1>
                      <StarIcon style={{ color: "orange" }} />
                      <StarIcon style={{ color: "orange" }} />
                      <StarIcon style={{ color: "orange" }} />
                      <StarIcon style={{ color: "orange" }} />
                      <StarBorderIcon style={{ color: "orange" }} />
                    </h1>
                    <p>
                      {mechanic.rating}({mechanic.reviews} Reviews)
                    </p>
                  </div>
                </div>
                <div className="my-4">
                  <h1 className="text-14/18 font-bold mb-1">Ticket Id</h1>
                  <p className="text-slate-700">#123456</p>
                </div>
                <div className="my-3">
                  <h3 className="text-sm font-bold">List of ongoing service:</h3>
                  {onGoingService.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex flex-row items-center my-3"
                      >
                        <TaskAltIcon />
                        <p className="text-slate-700 ml-4 text-sm">{item.name}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="my-3">
                  <h1 className="text-base font-bold">Issue Discription</h1>
                  <p className="my-3 text-slate-700 text-sm">
                    While traveling, my car stalled, and I'm uncertain about the
                    exact issue. I suspect it might be an engine problem
                  </p>
                </div>
                <div className="mb-7">
                  <h1 className="text-base font-bold">
                    if you want to add more services?
                  </h1>
                  <button
                    className="rounded my-5"
                    style={{
                      background: "#22C55D",
                      padding: "5px 10px",
                      border: "none",
                      color: "white",
                    }}
                  >
                    Add new Service
                  </button>
                </div>
                <div className="flex flex-row items-center">
                  <HelpOutlineIcon />
                  <p className="text-sm ml-3">
                    Need help?{" "}
                    <a
                      href="#"
                      className="font-semibold"
                      style={{ color: "#22C55D", textDecoration: "underline" }}
                    >
                      Click here
                    </a>
                  </p>
                </div>
              </div>
          </div>
          )}
        </div>
        
        {!showServiceDetails && (
          <div className="w-full md:w-1/2 lg:w-3/5 p-2 py-2">
            <div className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 flex flex-col gap-1 h-full">
              {/* <MapLocationSelector
                lat={locationstate.location.latitude}
                lon={locationstate.location.longitude}
                dispatch={locationdispatch}
                state={locationstate}
              /> */}


              <div className="w-full h-full">
                {
                  coords.lat && typeof(coords.lat) == 'number' &&
                          <APIProvider apiKey={MAP_API} >
                            <div className="mb-1">
                              <InputSuggestionMaps Set_coords = {setCoords} Set_range={setNearByRange} Coordinates = {coords} Address={setAddress} />
                            </div>
                            <Map defaultZoom={12} defaultCenter={coords} ref={mapRef} onClick={handleMapClick} >
                                <Marker position={coords} />
                                {
                                  showSearchMechanic && nearbyMech.map((item,index)=>{
                                    return <Marker key={index} position={item} icon='http://maps.google.com/mapfiles/ms/icons/blue-dot.png' />
                                  })
                                }
                                {
                                  directionsDetails && directionsDetails.status === true && <DirectionsComponent Route = {directionsDetails} /> 
                                }
                            </Map>
                        </APIProvider>
                }
              </div>
            </div>
          </div>
        )}

        {showServiceDetails && (
          <div className="lg:w-3/5 md:w-full">
            <div className="w-full ml-2">
              <div className="border rounded flex flex-row justify-between p-5 progress">
                <div className="flex flex-col items-center progress-item">
                  <img
                    src={require("../assets/OrderDetailsPage/Group 33324.png")}
                    alt=""
                    ref={step1}
                    style={{
                      width: "60px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <p className="w-40 text-bol font-semibold text-sm text-center">
                      Mechanic reached location
                    </p>
                    <p className="font-semibold text-center text-sm">
                      [10:30 AM]
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center progress-item">
                  <img
                    src={require("../assets/OrderDetailsPage/car 1.png")}
                    alt=""
                    ref={step2}
                    style={{
                      width: "60px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <p className="w-40 text-bol font-semibold text-sm text-center">
                      Mechanic Started Working
                    </p>
                    <p className="font-semibold text-sm text-center">
                      [10:30 AM]
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center progress-item">
                  <img
                    src={require("../assets/OrderDetailsPage/Group 985.png")}
                    alt=""
                    ref={step3}
                    style={{
                      width: "60px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <p className="w-40 text-bol font-semibold text-sm text-center">
                      Assesses the problem
                    </p>
                    <p className="font-semibold text-sm text-center">
                      [10:45 AM]
                    </p>
                  </div>
                  <Xarrow start={step1} end={step2} zIndex={-1} dashness showHead={false} color="#22C55D" />
                  <Xarrow start={step2} end={step3} zIndex={-1} dashness showHead={false} color="#22C55D" />
                </div>
                    {/* <Xarrow
                    start={step1}
                    end={step2}
                    zIndex={-1}
                    showHead={false}
                    color="#22C55D"
                    dashness
                  />
                  <Xarrow
                    start={step2}
                    end={step3}
                    zIndex={-1}
                    showHead={false}
                    color="#dedad9"
                    dashness
                  />   */}
              </div>
              <div className="">
                {!diagnosing && (
                  <div className="p-8">
                    <div>
                      <h1 className="text-xl font-bold">
                        Mechanic Started Working
                      </h1>
                      <p className="lg:w-96">
                        Mechanic is currently diagnosing the issue and will
                        provide a cost estimation for the service.
                      </p>
                    </div>
                    <div
                      style={{ height: "300px" }}
                      className="grid place-items-center mt-5"
                    >
                      <div
                        className="rounded-full grid place-items-center"
                        style={{
                          width: "200px",
                          height: "200px",
                          borderWidth: "15px",
                        }}
                      >
                        <p
                          className="w-32 text-center font-bold"
                          style={{ color: "#22C55D" }}
                        >
                          Estimation in Progress
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {diagnosing && (
                  <div className="md:p-8">
                    <div>
                      <h1 className="text-xl font-bold">Mechanic Assessment</h1>
                      <p className="text-sm lg:w-96 text-slate-700">
                        “Problem Identified Here’s the Cost and time estimation
                        for your service”
                      </p>
                    </div>
                    <div className="flex flex-row mt-5 mech-time">
                      <h1 className="font-bold ml-2 text-3xl">EST - {totalTime} Hours</h1>
                      <p className="font-bold text-base self-end ml-3">
                        (On time service Possible)
                      </p>
                    </div>
                    <div className="mt-5 ml-2 flex flex-row service-billing">
                      <h1 className="mr-6">Service:</h1>
                      <div className="w-80">
                        <div className="w-11/12 md:m-auto">
                          {
                            onGoingService.map((item,index)=>{
                              return(
                                <div className="flex flex-row justify-between mb-5">
                                  <h2>{item.name}</h2>
                                  <p>
                                    <CurrencyRupeeIcon /> {item.price}
                                  </p>
                                </div>
                              )
                            })
                          }
                          <div className="border-b border-t py-2 flex flex-row justify-between mb-5">
                            <h2 className="text-xl font-bold">Total EST</h2>
                            <p className="text-xl font-bold">
                              <CurrencyRupeeIcon /> {totalCost}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center mb-4">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="text-slate-800 ml-3"
                          >
                            Towing Service
                          </label>
                        </div>
                        <p className="w-11/12 m-auto text-xs">
                          If vehicle is not running condition we will be adding
                          towing services. Charges included seperately.
                        </p>
                        <div className="mt-7">
                          <button
                            onClick={() => {
                              let user = JSON.parse(
                                localStorage.getItem("user")
                              );
                              let track_details = {
                                username: `${user.firstName} ${user.lastName}`,
                                userId: user.userUid,
                                email: user.email,
                                page: window.location.pathname,
                                mechanic,
                                ongoingServices: onGoingService,
                                totalCost,
                              };

                              mixpanel.track("CANCEL_SERVICE", track_details);
                            }}
                            style={{ color: "#22C55D" }}
                            className="w-1/2 py-2 text-xs"
                          >
                            Cancel Service
                          </button>
                          <button
                            style={{ background: "#22C55D" }}
                            className="rounded text-xs w-1/2 py-2 text-white"
                            onClick={() => {
                              setShow(true);
                              setService(true);
                            }}
                          >
                            Schedule Service
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OnTimeScheduleService;