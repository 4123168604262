import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import TextField from "@mui/material/TextField";
import useFetch from "../hooks/useFetch";
import SERVER_URL from "../constants/constant";
import toast from "react-hot-toast";
import "./LoginPage.css";

const LoginPage = () => {
  const [otp, setOtp] = useState("");
  const [isFormValid, setFormValid] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [responseD, setResponseD] = useState({ otpToken: "" });
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const otpInputRef = useRef(null);

  const { handleGoogle, loading, error } = useFetch(
    `${SERVER_URL}/user/auth/google/webcallback`
  );

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
        type: "standard",
        theme: "filled_blue",
        // size: "large",
        text: "continue_with",
        shape: "rectangular",
      });

      // google.accounts.id.prompt()
    }
  }, [handleGoogle]);

  useEffect(() => {
    setOtpSent(false);
    if (email.length > 0) {
      setFormValid(true);
    } else setFormValid(false);
  }, [email]);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://service-provider-apis.onrender.com/api/v1/user/login/verify-otp-login`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            otp: otp,
            email: email,
            token: responseD.otpToken,
          }),
        }
      );

      if (response.ok) {
        const responseD = await response.json();

        if (responseD.success) {
          console.log(responseD);
          toast.success("Otp is valid");
          setToken(responseD.token);
          localStorage.setItem("TOKEN", responseD.token);
          const userid = responseD;
          localStorage.setItem("userid", JSON.stringify(userid));
          fetch(
            "https://service-provider-apis.onrender.com/api/v1/user/dashboard",
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${responseD.token}`,
              },
            }
          )
            .then((response) => {
              if (response.ok) {
                console.log(response);
                return response.json();
              } else {
                throw new Error("Failed to fetch user details");
              }
            })
            .then((userDetails) => {
              localStorage.setItem("user", JSON.stringify(userDetails.user));
              navigate("/");
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          toast.error("Invalid OTP.");
        }
      } else {
        toast.error("Failed to verify OTP. Please try again later.");
      }
    } catch (error) {
      console.error("Something went wrong", error);
      toast.error("An error occurred while verifying OTP.");
    }
    setOtp("");
  };

  const handleOTPSend = async (e) => {
    e.preventDefault();
    setOtpSent(false);

    try {
      const data = {
        email: email,
      };

      const response = await fetch(
        "https://service-provider-apis.onrender.com/api/v1/user/login/otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();

      if (responseData.success !== true) {
        toast.error(responseData.message);
      }
      if (responseData.success === true) {
        toast.success(responseData.message);
        setOtpSent(true);
        setResponseD({ otpToken: responseData.otpToken });
      }
    } catch (error) {
      console.error("Something went wrong", error);
    }
  };

  const isOtpFilled = otp.length === 4;

  return (
    <div
      style={{ minHeight: "100vh" }}
      className="flex items-center justify-center flex-col flex-grow bg-gray-100 p-4"
    >
      <div className="bg-white rounded-lg shadow-lg p-4 max-w-xl w-full flex justify-center flex-col items-center form-container">
        <img
          src="/Logo.png"
          alt=""
          className="w-[50px] h-[50px] object-contain logo"
        />
        <h2 className="text-2xl font-bold text-center mb-2 form-title">
          Login to your account
        </h2>
        <p className="text-center mb-8">
          Don't have an account yet?{" "}
          <Link to="/NewAccount" className="text-green-600">
            Sign Up
          </Link>
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          <div className="col-span-1 border-b-2 md:border-b-0 md:border-r-2 lg:border-b-0 lg:border-r-2 xl:border-b-0 xl:border-r-2 p-4 flex flex-col items-center">
            <form className="space-y-2" onSubmit={handleVerify}>
              <TextField
                id="outlined-basic"
                label="Email Address"
                variant="outlined"
                required
                style={{ width: "100%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-input"
              />

              <button
                className={`text-black underline font-semibold py-2 rounded-md px-3 w-full ${
                  isFormValid ? "" : "opacity-50 disabled cursor-not-allowed"
                }`}
                disabled={!isFormValid}
                onClick={(e) => handleOTPSend(e)}
              >
                {otpSent ? "Resend" : "Send"} OTP
              </button>

              {otpSent && (
                <OTPInput
                  ref={otpInputRef}
                  value={otp}
                  onChange={handleOtpChange}
                  inputType="tel"
                  numInputs={4}
                  renderSeparator={<span className="w-[50px]"></span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      inputMode="numeric"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleVerify(e);
                        }
                      }}
                    />
                  )}
                  inputStyle={{
                    border: "1px solid black",
                    padding: "4px",
                    width: "100%",
                  }}
                />
              )}

              {
                <button
                  type="submit"
                  className={`submit bg-green-500 hover:bg-green-600 text-white font-semibold py-2 rounded-md px-3 w-full ${
                    isOtpFilled ? "" : "opacity-50 disabled cursor-not-allowed"
                  }`}
                  disabled={!isOtpFilled}
                >
                  Submit
                </button>
              }
            </form>
          </div>

          <div className="col-span-1 flex flex-col items-center justify-center flex-grow p-4">
            <p className="text-center text-sm">
              Or, you can also sign in with Google!
            </p>
            {loading ? (
              <div className="mt-5">Loading....</div>
            ) : (
              <div
                id="signUpDiv"
                className="flex mt-5 w-fit google-signin"
                data-text="signup_with"
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
