import React, { useState } from 'react';
// import NavbarUpdate from '../components/NavbarUpdate';
import '../styles/navbar.css';

import TrackPageNavbar from "../components/TrackPageNavbar";
import learnmoreimage from "../assets/AboutUs/image8.png";
import LearnMoreCarousel from '../components/LearnMoreCarousel';
import { Link } from 'react-router-dom';
// import AboutUs from "../screens/About";
import "../styles/About.css";

const About = () => {
    // const [currentSlide, setCurrentSlide] = useState(0);
    const [descriptionIndex, setDescriptionIndex] = useState(null);

    const buttons = [
        {
            label: "Expand Your Research",
            description: "Broaden your market presence by leveraging our vast network of mechanic workshops across the nation. We take care of all client interactions and service charges, allowing you to concentrate on what you do best – delivering exceptional service and offering premium quality products.",
        },
        {
            label: "Boost Your Visibility",
            description: "Optimise your brand exposure by tapping into our user-driven platform and strategic marketing efforts. Attract new clients and amplify your brand presence to a wider audience.",
        },
        {
            label: "Optimised Inventory Management",
            description: "Maximise the sale of your OEM tools, equipment, and parts with our platform’s fast and reliable delivery services. From swift order processing to customer support, we handle the entire process, liberating your time to focus on your core business activities.",
        },
    ];

    const handleButtonClick = (index) => {
        setDescriptionIndex(prevIndex => prevIndex === index ? null : index);
    };

    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    // };

    // const handleNext = () => {
    //     setCurrentSlide(currentSlide + 1);
    // };

    // const handlePrev = () => {
    //     setCurrentSlide(currentSlide - 1);
    // };

    return (
        <div>
            <div className='flex flex-col w-full h-screen'>
                <TrackPageNavbar showOnlyNav={false} />
                <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
    <p class="text-sm text-gray-500 font-bold">
        <a href="/About" class="hover:text-black hover:cursor-pointer">About us</a> 
        <span class="text-black"> / </span>
        <span class="text-black"> Learn more</span>
    </p>
</div>


                <div className="mt-20 relative">
                    <div className='padding-gg'>
                    <p className="border-b-4 md:ml-20 ml-2 mb-2 md:w-44 w-24 " style={{ borderBottomColor: "#22C55D" }}></p>
                    </div>
                    <div className='grid md:ml-20 ml-2 md:mr-20 mr-2 md:grid-cols-2 md:mb-20 padding-gg'>
                        <div>
                            <p className="font-bold text-4xl ">Revolutionary Solution: Your</p>
                            <p className="font-bold text-4xl">Path to Stress- Free Vehicle Care</p>
                        </div>
                        <div className="flex items-center justify-end">
                            
                        </div>
                    </div>

                    <div className='relative  md:ml-20 ml-4 md:mr-20 mr-4 mt-8 padding-gg '>
                        <LearnMoreCarousel />

                    </div>

                    <div className="md:flex md:flex-row md:ml-20 ml-2 md:mr-20 mr-2 pt-20 pb-20 padding-gg">
                        {/* Left section */}
                        <div className="md:w-1/2 md:p-8 mt-8 md:mb-0 mb-6">
                            <p className="border-b-4 mb-2 md:w-44 w-24" style={{ borderBottomColor: "#22C55D" }}></p>
                            <p className='font-bold text-4xl pb-2 '>For Service Provider &</p>
                            <p className='font-bold text-4xl'>Part Supplier</p>
                        </div>

                        {/* Right section */}
                        <div className="md:w-1/2 md:p-8">
                            {buttons.map((button, index) => (
                                <div key={index} className="mb-4">
                                    <div className="flex flex-col">
                                        <button
                                            onClick={() => handleButtonClick(index)}
                                            className="border-b-2 border-grey-500 bg-white text-black font-medium text-lg px-8 py-4 text-left inline-flex items-center justify-between mb-2 w-full box-text box-text1"
                                        >
                                            <span>{button.label}</span>
                                            <svg
                                                className={`w-4 h-4 transition-transform transform ${descriptionIndex === index ? 'rotate-180' : ''}`}
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M19 9l-7 7-7-7"
                                                />
                                            </svg>
                                        </button>
                                        {descriptionIndex === index && (
                                            <div className="bg-white text-black font-medium rounded-lg text-lg px-8 py-4 ">
                                                <ul className='list-disc'>
                                                    {button.description.split("\n").map((item, i) => (
                                                        <li key={i} className="text-justify text-sm box-text-inner">{item}</li>
                                                    ))}
                                                </ul>
                                                {/* <p className='text-justify  text-sm'>{button.description}</p> */}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className=" w-full mt-4 ml-0">
                        <img src={learnmoreimage} className="" alt="demo1" />
                    </div>

                    <div className="md:flex flex-row md:ml-20 ml-2  md:mr-20 mr-2 mt-10 padding-gg">
                        {/* Left section */}
                        <div className="md:w-1/2 p-8 box-text-inner">
                            <p className='text-sm text-justify box-text-inner'>Helpy Moto is dedicated to delivering unparalleled service and value. With our focus on transparency, efficiency, and customer-centricity, we aim to be your ultimate destination for all your vehicle needs.</p>
                        </div>

                        {/* Right section */}
                        <div className="md:w-1/2 p-8">
                            <p className='font-bold text-4xl pb-2 '>Join us today and discover the Helpy Moto advantage firsthand!</p>
                        </div>
                    </div>
                    <div className='flex justify-center mb-10'>
  <Link to="/About">
    <button className="mt-4 md:mt-6 border border-black text-black font-medium text-sm md:text-lg px-6 md:px-8 py-3 md:py-4 inline-flex items-center justify-center rounded">
    <svg
  className="w-3 md:w-4 h-3 md:h-4 mr-2" // Adjusted margin to separate the icon and text
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  strokeWidth="2"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path d="M19 12H5M12 19l-7-7 7-7"/>
</svg>


      <span>Back</span>
    </button>
  </Link>
</div>



                </div>
            </div>
        </div>
    );
};

export default About;
