// WishlistContext.js
import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { wishlistReducer } from './WishlistReducer';

export const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
    const [wishlistItems, dispatch] = useReducer(wishlistReducer, [], (initial) => {
        const localData = localStorage.getItem('wishlistItems');
        return localData ? JSON.parse(localData) : initial;
      });
    
      useEffect(() => {
        localStorage.setItem('wishlistItems', JSON.stringify(wishlistItems));
      }, [wishlistItems]);

  return (
    <WishlistContext.Provider value={{ wishlistItems, dispatch }}>
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlist = () => {
  return useContext(WishlistContext);
};
