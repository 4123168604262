import React from 'react'
import NavbarUpdate from '../components/NavbarUpdate'

function ServiceCommerce() {
  return (
    <div>
        <NavbarUpdate showOnlyNav={true}/>
    </div>
  )
}

export default ServiceCommerce