import React, { useRef, useEffect, useState } from "react";
import servicesData from "../data/Data";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import ServicePageCrousal from "../screens/ServicePageCrousal";
import ServiceCarouselNew from "../components/ServiceCarouselNew";
const Services = ({ setService, parents }) => {
  const [loading, setLoading] = useState(false);
  const [topServices, setTopServices] = useState([]);
  // const [modelID, setModelID] = useState();
  const [sliderRef, setSliderRef] = useState(null);
  const titleControl = useAnimation();
  const [titleRef, titleinView] = useInView();

  useEffect(() => {
    if (titleinView) {
      titleControl.start("visible");
    } else {
      titleControl.start("hidden");
    }
  }, [titleControl, titleinView]);

  const titleVariants = {
    hidden: { opacity: 1, scale: 1, x: "-100%" },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.0 }, x: "0%" },
  };

  const arr = [0, 0, 0, 0, 0, 0, 0];

  useEffect(() => {
    if (parents.length < 1) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [parents]);

  const vehicle = JSON.parse(localStorage.getItem("vehicle-selected"));
  console.log(vehicle);
  // let modelID;
  // useEffect(() => {

  // const selectedFuel = vehicle.fuel ?? 'Petrol';
  // if (!vehicle){
  //   modelID = '';
  // }
  // else{
  // modelID = vehicle.model.fuels[vehicle.fuel];
  // console.log(modelID);
  // }
  let modelID;

  if (
    vehicle &&
    vehicle.model &&
    vehicle.model.fuels &&
    vehicle.fuel in vehicle.model.fuels
  ) {
    modelID = vehicle.model.fuels[vehicle.fuel];
  } else {
    modelID = "6537f1722171ae0062960d40"; // Default value when vehicle or its properties are undefined
  }

  useEffect(() => {
    axios
      .get(
        `https://service-provider-apis.onrender.com/api/v1/service/getTopServices?id=${modelID}`
      )
      .then((response) => {
        console.log(response," rsponse is  ");
        setTopServices(response.data.services);
        console.log(topServices);
        localStorage.setItem(
          "user-services",
          JSON.stringify(response.data.services)
        );
      })
      .catch((error) => console.log(error));
  }, []);

  const handleServiceClick = () => {};
  return (
    <div className="px-4 md:px-10 md:mt-48 xl:mt-52">
      <motion.div
        variants={titleVariants}
        ref={titleRef}
        initial="hidden"
        animate={titleControl}
        className="text-center mt-12 mb-12"
        style={{ fontFamily: "Poppins, sans-serif", color: "#333" }}
      >
        <h1 className="text-4xl font-bold">
          Our <span className="text-green-500">Best</span> Services
        </h1>
        <p className="mx-4 md:mx-auto md:w-2/3 lg:w-1/2 xl:w-1/2 text-center mb-5">
          Get affordable and hassle-free periodic car service, car repair, wheel
          care services, cashless insurance claim, and much more!
        </p>
      </motion.div>

      {/* <ServicePageCrousal /> */}
      <ServiceCarouselNew />

      {/* <motion.div
        // ref={titleRef}
        className='flex w-full justify-center gap-x-3 gap-y-3 px-4 my-2 flex-wrap mx-auto'
      >
        {console.log(topServices)}

        {
          modelID ? (
            (topServices) ? (
              (topServices.filter(service => service.modelId === modelID).length > 0 && (
                topServices.map((service, index) => (
                  <motion.div
                    variants={serviceVariants}
                    initial='hidden'
                    animate={serviceControl}
                    key={index}
                  >
                    <div
                      className='shadow-lg hover:scale-105 transition transform duration-200 ease-in-out w-[130px] md:w-[170px] lg:w-[200px] md:h-[170px] lg:h-[200px] h-[130px]  rounded-md bg-gray-200 p-3 flex justify-center items-center flex-col cursor-pointer'
                      onClick={() => {
                        const serviceID = service._id;
                        localStorage.setItem('selected-service', JSON.stringify(service));
                        console.log(serviceID);
                        console.log(service);
                        setService({ show: true, id: service._id });
                      }}
                    >
                      <img
                        src={service.photo}
                        alt=''
                        className='w-2/3 object-contain'
                      />
                      <p className='md:text-md lg:text-md h-1/3 object-contain font-sans font-semibold text-center'>
                        {service.name}
                      </p>
                    </div>
                  </motion.div>
                ))
              ))
            ) : (
              arr.map((service) => (
                <div className='shadow-lg animate-pulse hover:scale-105 transition transform duration-200 ease-in-out w-[130px] md:w-[170px] lg:w-[200px] md:h-[170px] lg:h-[200px] h-[130px]  rounded-md bg-gray-200 p-3 flex justify-center items-center flex-col cursor-pointer'>
                  <div className='w-2/3 h-2/3 object-contain'></div>
                  <p className='md:text-xl lg:text-xl font-sans font-semibold text-center'>
                    {'    '}
                  </p>
                </div>
              ))
            )
          ) : null
        } */}

      {/* {(topServices && topServices.length > 0 && !loading)? 
    (topServices.map((service, index) => {
            return(
        
        
                <motion.div
                    variants={serviceVariants}
                    initial='hidden'
                    animate={serviceControl}
                    key={index}
                  >
                    <div
                      className='shadow-lg hover:scale-105 transition transform duration-200 ease-in-out w-[130px] md:w-[170px] lg:w-[200px] md:h-[170px] lg:h-[200px] h-[130px]  rounded-md bg-gray-200 p-3 flex justify-center items-center flex-col cursor-pointer'
                      onClick = 
                      {() =>
                        { 
                        const serviceID = service._id;
                        localStorage.setItem('selected-service', JSON.stringify(service));
                        console.log(serviceID);
                        console.log(service);
                        setService({ show: true, id: service._id});}
                      }
                    >
                      <img
                      src = {service.photo}
                      alt = ''
                      className='w-2/3 object-contain'
                      />
                                            <p className='md:text-md lg:text-md h-1/3 object-contain font-sans font-semibold text-center'>
                                              {service.name}
                        </p>
                       </div>
                  </motion.div> 
                     );
        }) 
        ): (arr.map((service) => {
          return (
            <div className='shadow-lg animate-pulse hover:scale-105 transition transform duration-200 ease-in-out w-[130px] md:w-[170px] lg:w-[200px] md:h-[170px] lg:h-[200px] h-[130px]  rounded-md bg-gray-200 p-3 flex justify-center items-center flex-col cursor-pointer'>
              <div className='w-2/3 h-2/3 object-contain'></div>
              <p className='md:text-xl lg:text-xl font-sans font-semibold text-center'>
                {'    '}
              </p>
            </div>
          );
        })
  ) */}
      {/* } */}
      {/* </motion.div> */}
    </div>
  );
};

export default Services;
