import React, {useState }from 'react';
import serviceImage from "../assets/service.png";
import { Link } from 'react-router-dom';



const Trackorderstep3 = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    
    const AddServiceModal = ({ isOpen, onClose }) => {
        const [additionalDetails, setAdditionalDetails] = useState("");
        //   const [totalCost, setTotalCost] = useState("");
        const [selectedServices, setSelectedServices] = useState([]);
        const [isLoading, setLoading] = useState(false);
        const [requestResult, setRequestResult] = useState(null);
      
        const options = [
          { value: "service1", label: "Service 1" },
          { value: "service2", label: "Service 2" },
          // Add more options as needed
        ];
      
        const CheckboxOption = ({ innerProps, label, isSelected }) => (
          <div>
            <input type="checkbox" {...innerProps} checked={isSelected} />
            <span style={{ marginLeft: "8px" }}>{label}</span>
          </div>
        );
      
        const handleServiceChange = (selectedOptions) => {
          setSelectedServices(selectedOptions || []);
        };
      
        const handleSubmit = () => {
          setLoading(true);
      
          // Simulate a delay for the request
          setTimeout(() => {
            const mechanicAccepted = true;
      
            setRequestResult(mechanicAccepted ? "accepted" : "rejected");
            setLoading(false);
          }, 2000);
        };
      
        //   useEffect(() => {
        //     if (requestResult) {
        //       // Clear the result after a certain time
        //       const timeoutId = setTimeout(() => {
        //         setRequestResult(null);
        //         onClose();
        //       }, 3000);
      
        //       return () => clearTimeout(timeoutId);
        //     }
        //   }, [requestResult, onClose]);
      
        const handleClose = () => {
          // Reset the requestResult only when the user clicks on the cross button
          setRequestResult(null);
          onClose();
        };
      
        return (
          isOpen && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
              <div className="bg-white p-8 rounded-md w-96">
                <span
                  className="cursor-pointer absolute text-lg"
                  onClick={handleClose}
                >
                  &#10006;
                </span>
      
                {isLoading && (
                  <div className="text-center flex flex-col items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="animate-spin h-28 w-28 text-red-500 items-center"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <path d="M14.5 9.5L12 12l-2.5-2.5" />
                    </svg>
                    <p className="text-red-500 mt-2 font-bold font-poppins">
                      Waiting for mechanic
                    </p>
                    <p className="text-red-500 font-semibold">confirmation</p>
                  </div>
                )}
      
                {/* Form */}
                {!isLoading && !requestResult && (
                  // <form>
                  //   <div className="flex font-bold items-center justify-center mb-4 font-poppins">
                  //     <h1>Add new Services</h1>
                  //   </div>
                  //   {/* Service Dropdown */}
                  //   <div className="mb-4">
                  //     <label className="block text-sm font-bold text-gray-700 mb-1">
                  //       Select Service Type
                  //     </label>
                  //     <Select
                  //       options={options}
                  //       isMulti
                  //       value={selectedServices}
                  //       components={{ Option: CheckboxOption }}
                  //       onChange={handleServiceChange}
                  //     />
                  //   </div>
                  //   {/* Additional Details */}
                  //   <div className="mb-4">
                  //     <label
                  //       htmlFor="details"
                  //       className="block text-sm font-bold text-gray-700 mb-1"
                  //     >
                  //       Additional Details
                  //     </label>
                  //     <textarea
                  //       id="details"
                  //       className="w-full border border-gray-300 rounded p-2"
                  //       value={additionalDetails}
                  //       onChange={(e) => setAdditionalDetails(e.target.value)}
                  //     />
                  //   </div>
                  //   {/* Total Cost */}
                  //   <div className="flex p-2 items-center justify-between">
                  //     <div className="mb-4 mt-4">
                  //       <p className="text-sm font-bold text-gray-700 font-poppins">
                  //         Total Cost
                  //       </p>
                  //     </div>
                  //     {/* Submit Button */}
                  //     <div className="text-center font-poppins">
                  //       <button
                  //         type="button"
                  //         className="bg-green-500 text-white rounded px-4 py-2"
                  //         onClick={handleSubmit}
                  //       >
                  //         Submit Request
                  //       </button>
                  //     </div>
                  //   </div>
                  // </form>
                  <div className="p-8 text-center">
                    <p className="w-full">Are You Sure Want To Add New Service?</p>
                    <div className="mt-4 flex justify-center items-center">
                      <button
                        className="border border-red-500 text-red-500 px-3 py-2 mx-2 md:px-6 md:py-2 md:mx-4"
                        onClick={handleClose}
                      >
                        No
                      </button>
                      <Link to="/Trackorderservice">
                        <button className="bg-green-500 text-white px-3 py-2 mx-2 md:px-6 md:py-2 md:mx-4">
                          Yes
                        </button>
                      </Link>
                    </div>
                  </div>
                )}
                {requestResult === "accepted" && (
                  <div className="text-green-500 mt-4 flex flex-col items-center">
                    <div className="mb-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-32 w-32 text-green-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.95-10.95a1 1 0 00-1.414 0l-5 5-2-2a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l6-6a1 1 0 000-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="text-center font-semibold font-poppins">
                      Service accepted
                    </p>
                    <p className="text-center font-semibold font-poppins">
                      successfully.
                    </p>
                  </div>
                )}
      
                {/* Display rejection message */}
                {requestResult === "rejected" && (
                  <div className="mt-4 flex flex-col items-center">
                    <div className="mb-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-28 w-28 text-red-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                        />
                        <text
                          x="10"
                          y="15"
                          fontSize="14"
                          textAnchor="middle"
                          fill="currentColor"
                        >
                          !
                        </text>
                      </svg>
                    </div>
                    <p className="text-center  font-poppins">
                      We regret to inform you that your service request has not been
                      accepted by a mechanic at this time.
                    </p>
                  </div>
                )}
              </div>
            </div>
          )
        );
      };
      
    const openModal = () => {
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };
    return (
      <div>
              <div className="flex gap-5 mt-10 sm:mt-0">
                <div className="h-auto w-full border border-solid border-black rounded p-4">
                  <h1 className="text-black text-opacity-75 font-semibold">
                    {" "}
                    Service Details (2 items)
                  </h1>
                  <div>
                    <div className="flex p-2 items-center">
                      <div className="h-24 w-32 p-2">
                        <img
                          src={serviceImage}
                          alt="noImage"
                          className="rounded w-full h-full"
                        />
                      </div>
                      <div className="ml-2 font-poppins font-bold">
                        <p>Basic Service</p>
                        <p>2000</p>
                      </div>
                    </div>
                    <div className="flex p-2 items-center">
                      <div className="h-24 w-32 p-2 rounded">
                        <img
                          src={serviceImage}
                          alt="noImage"
                          className="rounded h-full"
                        />
                      </div>
                      <div className="ml-2 font-poppins font-bold">
                        <p>Basic Service</p>
                        <p>2000</p>
                      </div>
                    </div>
                  </div>
                  <hr className="border-b border-dashed border-black border-opacity-20 my-4" />
                  <div className="font-bold font-poppins p-2">
                    <h1>Estimation Cost : 2000</h1>
                  </div>
                  <div className="flex flex-col lg:flex-row justify-between p-2 items-center mt-4">
                    <a href="/" className="text-green-500 mb-2 lg:mb-0 lg:mr-4">
                      Remove Service
                    </a>
                    <div className="bg-green-500 text-white rounded">
                      <button className="px-4 py-2" onClick={openModal}>
                        Add New Service
                      </button>
                    </div>
                  </div>
                </div>
              </div>
        <AddServiceModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
  );
};

export default Trackorderstep3;
