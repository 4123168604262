import React, { useRef, useEffect } from 'react';
import Xarrow from 'react-xarrows';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import "./Work.css"

const Works = () => {
  const step1 = useRef(null);
  const step2 = useRef(null);
  const step3 = useRef(null);
  const titleControl = useAnimation();
  const [titleRef, titleinView] = useInView();

  useEffect(() => {
    if (titleinView) {
      titleControl.start('visible');
    } else {
      titleControl.start('hidden');
    }
  }, [titleControl, titleinView]);

  const titleVariants = {
    hidden: { opacity: 1, scale: 1, x: '100%' },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.0 }, x: '0%' },
  };



  return (
    <div className='px-4 bg-white text-black mt-6 py-2  '>
       <motion.div
        ref={titleRef}
        variants={titleVariants}
        initial='hidden'
        animate={titleControl}
        className='text-4xl font-bold text-center mt-10 mb-12'
        style={{ color: '#333', fontFamily: 'poppins', /* add your custom font if needed */ }}
      >
        How <span className='text-black'>HelpyMoto</span> Operates?
      </motion.div>


      <div className='grid skew-y-[1.5] lg:grid-rows-4 lg:grid-flow-col gap-4 mt-5'>
        <div className='lg:row-start-1 lg:row-span-3 bg-white px-3 py-3   flex items-center flex-col text-black rounded-md m-4 justify-center shadow-gray-400 shadow-lg mx-auto w-[250px]' ref={step1}>
          <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/Select-The-Perfect-Car-Service.png" alt="Explore the perfect car service tailored to your needs." className='w-2/3 object-contain' />
          <div className='flex flex-col w-full '>
            <p className=' text-lg font-semibold text-green-500 text-center'>Select the Perfect Car Service</p>
            <p className='text-start text-sm text-black-600'>From HelpyMoto’s broad portfolio of services.</p>
          </div>

        </div>
        <div className='lg:row-start-2 lg:row-span-3 bg-white px-3 py-3  flex items-center flex-col text-black rounded-md m-4 justify-center shadow-gray-400 shadow-lg mx-auto w-[250px]' ref={step2}>
          <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/Schedule-Free-Doorstep-Pick-up.png" alt="Book a free pickup at your doorstep." className='w-2/3 object-contain' />
          <div className='flex flex-col w-full '>
            <p className=' text-lg font-semibold text-green-500 text-center'>Schedule a Free Doorstep Pick-up</p>
            <p className='text-start text-sm text-black-600'> We offer pickup and drop for all booked services at zero cost.</p>
          </div>

        </div>
        <div className='lg:row-start-1 lg:row-span-3 bg-white px-3 py-3 flex items-center flex-col text-black rounded-md m-4 justify-center shadow-gray-400 shadow-lg mx-auto w-[250px]' ref={step3}>
          <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/track-your-car-service-real-time.png" alt="Real time car care updates." className='w-2/3 object-contain' />
          <div className='flex flex-col w-full '>
            <p className='text-center text-lg font-semibold text-green-500'>Track your car service Real-Time</p>
            <p className='text-start text-sm text-black-600'>We will let you stay informed anywhere, anytime.</p>
          </div>

        </div>

      </div>

      <Xarrow start={step1} end={step2} dashness />
      <Xarrow start={step2} end={step3} dashness />


    </div>
  );
};

export default Works;
