import React, { useState } from 'react';
import '../styles/navbar.css';
import Footer from '../components/Footer';
import TrackPageNavbar from "../components/TrackPageNavbar";
import AboutUsImage from "../assets/AboutUs/image1.png";
import image2 from "../assets/AboutUs/image2.png";
import image3 from "../assets/AboutUs/image3.png";
import image4 from "../assets/AboutUs/image4.png";
import image5 from "../assets/AboutUs/image5.png";
import image6 from "../assets/AboutUs/image6.png";

// import founderimage from "../assets/AboutUs/founderimage.png";
// import directorimage from "../assets/AboutUs/directorimage.png";
// import salesofficerimage from "../assets/AboutUs/salesofficerimage.png";
import upliftingimage from "../assets/AboutUs/image7.png";
import { Link } from 'react-router-dom';
import Emptyimg from "../assets/AboutUs/Emptyimg.png";
import "../styles/About.css";

const About = () => {
  const [descriptionIndex, setDescriptionIndex] = useState(null);

  const buttons = [
    {
      label: "24/7 On-Demand Mechanics",
      description: "24/7 On-Demand Mechanics: Gain access to our team of certified skilled technicians around the clock, no matter where you are in India. Say goodbye to the frustration of waiting in long lines or the anxiety of unexpected breakdowns. With Helpy Moto, we’re committed to getting you back on the road swiftly, with our top-notch services ensuring you’re up and running in just 20 minutes flat.",
    },
    {
      label: "Scheduled Services",
      description: "Take control of your auto maintenance with our convenient booking system. Choose the time and location that suits you best, and we’ll take care of the rest. Whether you prefer the convenience of doorstep service or need assistance with roadside towing, we’ve got you covered. Manage your schedule effortlessly and ensure peace of mind knowing your vehicle is in expert hands.",
    },
    {
      label: "HelpyStore",
      description: " Explore our comprehensive selection of authentic OEM tools, equipment, and vehicle parts from the convenience of your home. Benefit from swift delivery, thanks to our partnerships with top suppliers and renowned brands like Blinkit and Amazon.",
    },
    {
      label: "Tailored Subscription Plans",
      description: " Experience user-centric subscription plans designed to cater your unique needs, ensuring seamless and hassle-free vehicle maintenance.",
    },
    {
      label: "Expansive Service Network",
      description: " Tap into a vast network of certified mechanic workshops nationwide to connect with a wide array of potential customers. Let us handle all client interactions and service fees, allowing you to concentrate on delivering exceptional service and top-tier products.",
    },
    {
      label: "Enhanced Visibility & Reach",
      description: "Increase your exposure to a broader audience through our user-centric platform and strategic marketing initiatives. Attract potential clients and elevate your brand presence.",
    },
    {
      label: "Streamlined Inventory Management",
      description: " Simplify the process of selling your OEM tools, equipment, and parts with our user-centric platforms. We take care of everything, from order processing to customer support, so you can focus on your core business activities. At Helpy Moto, we’re constantly dedicated to delivering unmatched service and value. With our unwavering commitment to transparency, efficiency, and customer satisfaction, we aim to be your ultimate solution for all your vehicle needs.",
    },
  ];

  const handleButtonClick = (index) => {
    setDescriptionIndex(prevIndex => prevIndex === index ? null : index);
  };
  

  

  return (
    <div>
  <div className='flex flex-col w-full h-screen '>
    <TrackPageNavbar showOnlyNav={false} />
    <div className="relative">
  <img src={AboutUsImage} alt="About Us Image" className="about-us-image"  />
  <div className="absolute top-0 left-0 w-full h-full bg-green-500 opacity-30"></div>
  <div className="absolute bottom-0 left-0 w-full flex items-center justify-center">
    <div className="bg-black opacity-40 p-1 sm:p-2 md:p-4 lg:p-8 rounded-lg text-white text-center">
      <h1 className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-4xl font-bold pb-auto xl:pb-5">About Us</h1>
      <div className="text-xs sm:text-xs md:text-base lg:text-lg box-text ">
        <div className="mb-auto box-text1">
          <p>Helpy Moto is your ultimate destination for on-demand mechanics and auto supplies,</p>
        </div>
        <div className="mb-auto box-text1">
          <p>providing a seamless experience for customers. More than just a service company,</p>
        </div>
        <div className="mb-auto box-text1">
          <p>we're your trusted partner for all your vehicle needs, offering convenient access to</p>
        </div>
        <div className='box-text'>
          <p>on-demand mechanic services and a comprehensive online store.</p>
        </div>
      </div>
    </div>
  </div>


    </div>
    <div className='pt-12 lg:pl-12 pb-8 ml-3 md:ml-4 lg:ml-12 md:ml-10 lg:ml-12 padding-gg title-text'
    style={{'@media (min-width: 768px) and (max-width:1023)': { marginLeft: '0px' } }}
    >
  <p className="border-b-4 mb-2 w-24 lg:w-44" style={{ borderBottomColor: "#22C55D" }}></p>
  <p className='font-bold text-4xl  pb-2 '>What makes us</p>
  <p className='font-bold text-4xl  '>Unique</p>
</div>


    <div className='pt-4 lg:pl-12 pb-8 grid grid-cols-1 lg:grid-cols-2 gap-4  padding-gg'>
  <div className="order-2 lg:order-1 lg:mt-8">
    {buttons.map((button, index) => (
      <div key={index} className="mb-4 ">
        <div className="flex flex-col">
          <button
            onClick={() => handleButtonClick(index)}
            className="border-b-2 border-grey-500 bg-white text-black font-medium text-lg px-8 xl:text-3xl py-4 text-left inline-flex items-center justify-between mb-2 w-full"
          >
            <span>{button.label}</span>
            <svg
              className={`w-4 h-4 transition-transform transform ${descriptionIndex === index ? 'rotate-180' : ''}`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          {descriptionIndex === index && (
            <div className="bg-white text-black font-medium rounded-lg text-lg px-8 py-4 ">
              <ul className='list-disc'>
                {button.description.split("\n").map((item, i) => (
                  <li key={i} className="text-justify text-sm xl:text-xl">{item}</li>
                ))}
              </ul>
              {/* <p className='text-justify  text-sm'>{button.description}</p> */}
            </div>
          )}
        </div>
      </div>
    ))}
  </div>
  <div className="order-1 lg:order-2 lg:mt-8 image-2">
    <img src={image2} alt="demo1" className="w-full " />
  </div>
</div>


<div className="w-full flex flex-col  padding-gg">
  <div className="w-full flex flex-col md:flex-row">
    <img src={image3} alt="demo1" className=" rounded-tr-3xl w-full md:w-1/2" />
    <div className="w-full md:w-1/2 ml-4 md:ml-20 lg:mr-4  mt-4 lg:mt-24 md:mt-12 md:pr-8 lg:pr-16 comprehensive-container">
      <p className="border-b-4 mb-2 w-28 lg:w-44" style={{ borderBottomColor: "#22C55D" }}></p>
      <p className="font-bold lg:text-4xl text-2xl ">Your Comprehensive Solution</p>
      <p className="font-bold lg:text-4xl text-2xl ">for Stress-Free Vehicle Care</p>
      
      <p className="text-sm  text-gray-500 text-justify  md:text-base mt-2 lg:mt-12 md:mt-6 md:mb-8 ">Helpy Moto goes beyond the ordinary service provider role; we’re your dependable ally for all your vehicle essentials. Whether you’re dealing with a breakdown, in need of routine maintenance, or seeking upgrades for your car, we provide a seamless and hassle-free experience for individuals and service providers/part suppliers alike. By intuitively integrating on-demand mechanic services with a comprehensive online store, we ensure that you receive premium quality care for your vehicle, at your convenience.</p>
      <Link to="/About/LearnMore">
        <button className="mt-4 lg:mt-6 md:mt-2 bg-green-500 text-white font-medium text-sm md:text-lg xl:text-2xl px-6 md:px-8 py-3 md:py-4 inline-flex items-center justify-between mb-2 rounded">
          <span>Learn More</span>
          <svg
            className="w-3 md:w-4 h-3 md:h-4 ml-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="5" y1="12" x2="19" y2="12" />
            <polyline points="12 5 19 12 12 19" />
          </svg>
        </button>
      </Link>
    </div>
  </div>
</div>


    <div className='flex flex-col items-center mt-20  '>
  <p className='font-bold lg:text-4xl sm:text-xl  text-center'>
    Your Reliable Roadside Assistance
  </p>
  <p className='font-bold lg:text-4xl md:mt-3 ml-1 sm:text-xl  text-center'>
    Partner, Always at Your Service
  </p>
  <p className='md:w-1/2   text-base  mt-4 md:ml-20 md:mr-20 mr-4 ml-4 text-center text-justify'>
  Have you ever found yourself helpless due to a sudden car breakdown?Don’t get afraid ! Helpy Moto is your trusty knight in dazzling armour, ready to ride to your rescue 24/7, no matter where you are in India. Say goodbye to the stress of towing or waiting in long lines – with Helpy Moto, we’ll have you back on the road in a flicker, quicker than you can say “zoom!”
  </p>
  
</div>

<div className='flex grid md:grid-cols-2 justify-between w-full md:mx-4 mt-16 md:ml-0 ml-2 md:mr-0 mr-2 custom-container  padding-gg '>
  <div className=' md:mr-2 lg:ml-20 md:ml-10 ml-2 border-r md:pr-16 pr-6 h-full custom-section'>
    
    <p className='font-bold lg:text-3xl text-2xl '>For Individual:</p>
    <p className='font-bold lg:text-xl text-base mt-4 md:mt-8 '>Need immediate help?</p>
    <p className='lg:text-lg text-sm mt-2 text-justify '>Enhance how you work with images in bulk and raise the bar for your team’s potential with the most simplified image library.</p>
    <p className='font-bold lg:text-xl text-base mt-3 '>Prefer planning ahead?</p>
    <p className='lg:text-lg text-sm mt-2 text-justify '> Schedule your car service at your convenience, with options for pickup, drop-off, and even towing. Manage your schedule with ease and get the service you need without any trouble.</p>
    <p className='font-bold lg:text-xl text-base mt-3 '>Love your car?</p>
    <p className='lg:text-lg text-sm mt-2 text-justify '> Generate high-res transformed images and efficiently deliver images that are responsive for web & mobile using URL-based transformations.</p>
    <p className='font-bold lg:text-xl text-base mt-3 '>Shop smart</p>
    <p className='lg:text-lg text-sm mt-2 text-justify '>Explore our wide range of genuine tools, equipment, and parts for your car, all delivered straight to your door with turbo speed. Think of it as Amazing for your car, but even faster!</p>

  </div>


  <div className='flex md:w-1/2 lg:ml-24 md:ml-1 ml-1 md:mr-0 mr-6 relative custom-image-container'>
    <img src={image4} alt="image" className="lg:w-[550px] md:w-[380px] w-full lg:h-auto md:h-[450px] h-auto " style={{ maxWidth: "800px" }} />
  </div>
</div>


<div className="mx-4 border-t pt-6 mt-20 "></div>

<div className='flex justify-between mx-4 mt-6 mb-12 custom-container grid grid-cols-1 md:grid-cols-2 gap-4  padding-gg'>
  <div className='col-start-1 row-start-1 md:col-start-1 md:row-start-auto lg:ml-20  relative md:border-r lg:pr-12  mt-0 lg:mt-16 md:mt-36'>
    <img src={image5} alt="image" className="lg:w-[550px] lg:h-[550px] md:w-[380px] w-full h-auto" />
    
  </div>
  
  <div className='col-start-1 row-start-2 md:col-start-2 md:mr-2 lg:ml-20 md:ml-10 lg:pr-16 md:pr-8 md:row-start-auto'>
    <p className='font-bold lg:text-3xl text-2xl'>For Service Providers &</p>
    <p className='font-bold lg:text-3xl text-2xl'>Part Suppliers:</p>
    <p className='font-bold lg:text-xl text-base mt-4 md:mt-8'>Expand Your Business</p>
    <p className='lg:text-lg text-sm text-justify'>Unlock access to a huge network of potential customers through Helpy Moto. We take care of every aspect, from customer interaction to payments, allowing you to focus on delivering satisfactory service and top-quality products.</p>
    <p className='font-bold lg:text-xl text-base mt-3'>Stand Out</p>
    <p className='lg:text-lg text-sm text-justify'>Increase your visibility and attract a wider listeners through our platform and marketing efforts. Attract new customers and establish your brand as the desired choice in the market.</p>
    <p className='font-bold lg:text-xl text-base mt-3'>Sell Seamlessly</p>
    <p className='lg:text-lg text-sm mt-2 text-justify'>Convert your supply of OEM tools, equipment, and parts into profit effortlessly with our fast and efficient transferral system. We handle all the deployments, leaving you free to focus on managing your business easily.</p>
    <p className='font-bold lg:text-xl text-base mt-3'>Helpy Moto</p>
    <p className='lg:text-lg text-sm mt-2 text-justify'>More than just a service, it’s calmness of mind for you and your vehicle. We’re dedicated to visibility, efficiency, and customer satisfaction. Join us and experience the Helpy Moto difference – it’s the refined ride you’ll ever take!
    </p>
  </div>
</div>



<div className='grid grid-cols-1 lg:grid-cols-2 md:gap-4 mx-4 md:mt-20 mt-6 mb-20  padding-gg'>
  <div className='md:mr-2  lg:ml-20 md:ml-10 lg:pr-16 md:pr-8'>
    <p className="border-b-4 mb-2 w-44 " style={{ borderBottomColor: "#22C55D" }}></p>
    <p className='font-bold lg:text-4xl text-2xl'>About Founders and</p>
    <p className='font-bold lg:text-4xl text-2xl'>Co-founders</p>
    <p className='text-sm mt-8 text-gray-500 text-justify'><b className='text-base'>Helpy Moto:</b> Where Vision Meets Expertise, Transforming the Service Industry. At Helpy Moto, we’re not just in the business of fitting tires and repairing engines; we’re revolutionizing the entire service industry. Guided by a visionary leader and supported by a dynamic team of experts, we’re committed to delivering a service experience that’s characterized by transparency, efficiency, and value for all parties involved.
      <p className='text-sm mt-5 text-gray-500 text-justify'>For us, each tire fitted and every engine repaired represents an opportunity to make a positive impact in someone’s life. We understand the anxiety and frustration that accompany vehicle troubles, which is why we approach each interaction with a deep sense of understanding and a commitment to providing unwavering support.</p>
      <p className='text-sm mt-5 text-gray-500 text-justify'><b>At Helpy Moto</b>, led by the visionary guidance of Shantanu, our mission extends far beyond the realm of simply fitting tires and repairing engines. Driven by a profound desire to redefine the essence of the service industry, we’re propelled forward by the passion of our dynamic team of experts. Our goal is to create an experience that transcends mere transactions, one that is characterized by empathy, compassion, and genuine connections.</p>
      <p className='text-sm mt-5 text-gray-500 text-justify'><b>Furthermore, we also</b> believe in the power of service to touch hearts and transform experiences. Join us on this journey where every service call is an opportunity to make a meaningful difference in someone’s day.</p>
    </p>
  </div>

  <div className='lg:ml-8 lg:mr-24 md:mt-auto mt-6 lg:mb-auto'>
    <img src={image6} alt="image" className=" md:w-[500px] lg:ml-16 md:ml-32 w-full h-auto" />
  </div>
</div>

 {/* 1st main div */}
<div className="bg-gray-100 h-fit relative  padding-gg">
  <div className="flex flex-col md:flex-row md:justify-between md:mt-8 md:mx-10 relative">
    <div className="md:w-1/3 mb-8 md:mb-0 md:z-10">
      <div className="rounded-lg md:p-6 lg:mr-20 md:mr-4 mr-8 ml-8">
        <img src={Emptyimg} className="bg-gray-400 md:h-[300px] md:w-[300px] h-auto w-auto" />
        <h2 className="text-xl font-semibold mb-2 mt-4">Shantanu</h2>
        <h2 className="text-xl font-semibold mb-2">(Founder)</h2>
        <p className="border-b-4 mb-2 lg:w-44 md:w-14 w-44 mt-6" style={{ borderBottomColor: "#22C55D" }}></p>
        <p className="text-sm text-justify">Shantanu's vision is to establish a globally influential organisation that empowers both users and service providers alike. This vision is realised through the collective efforts of our talented team.</p>
      </div>
    </div>
    <div className="md:w-1/3 mb-8 md:mb-0 md:z-10">
      <div className="rounded-lg md:p-6 lg:mr-20 md:mr-4  md:mt-14 mr-8 ml-8">
        <img src={Emptyimg} className="bg-gray-400 md:h-[300px] md:w-[300px] h-auto w-auto " />
        <h2 className="text-xl font-semibold mb-2 mt-4">Neeraj Gupta</h2>
        <h2 className="text-xl font-semibold mb-2">(Director Of Finance)</h2>
        <p className="border-b-4 mb-2 lg:w-44 md:w-14 w-44 mt-6" style={{ borderBottomColor: "#22C55D" }}></p>
        <p className="text-sm text-justify">Neeraj Gupta With over 18 years of experience and dual CA certifications, Neeraj is instrumental in ensuring Helpy Moto's financial stability and fostering sustainable growth.
        </p>
      </div>
    </div>
    <div className="md:w-1/3  md:mb-0 md:z-10">
      <div className="rounded-lg md:p-6 lg:mr-20 md:mr-4 md:mt-28 mr-8 ml-8">
        <img src={Emptyimg} className="bg-gray-400 md:h-[300px] md:w-[300px] h-auto w-auto " />
        <h2 className="text-xl font-semibold mb-2 mt-4">Amit Mathur</h2>
        <h2 className="text-xl font-semibold mb-2">(Chief Sales Officer)</h2>
        <p className="border-b-4 mb-2 lg:w-44 md:w-14 w-44 mt-6" style={{ borderBottomColor: "#22C55D" }}></p>
        <p className="text-sm text-justify">Amit mature over 30 years of experience in multinational corporations, Amit leads the charge in expanding our market reach and forging strategic partnerships.
        </p>
      </div>
    </div>
  </div>
  
  <div className="md:bg-green-500 md:h-20 md:w-full md:absolute md:top-64 md:z-0 ">
  </div>
</div>




    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-4 lg:mt-16 mt-6 md:pt-0  padding-gg">
  <div className="lg:mr-4 mr-2 lg:ml-20 md:ml-14 ml-2 lg:pr-16 lg:mt-20  md:mt-4">
    <p className="border-b-4 mb-2 lg:w-44 md:w-24 w-24 md:mt-14" style={{ borderBottomColor: "#22C55D" }}></p>
    <p className="font-bold lg:text-4xl text-2xl">Uplifting Service Providers</p>
    <p className="text-sm md:text-base mt-4 text-gray-500 text-justify mt-6">We prioritize the well-being of our service providers, providing them with the necessary resources and training to thrive in their careers. Adding Value to the Ecosystem: We foster collaborations with partners and investors to make a meaningful contribution to the service industry at large. Together, we create positive change and drive progress.</p>
  </div>
  <img src={upliftingimage} alt="demo1" className="rounded-tl-3xl md:mt-16 lg:mt-0 mt-0 md:rounded-tl-3xl w-auto h-auto" />
</div>


    <div className='mt-16'>
    <Footer />
    </div>
</div>

    </div>


  


  );
};

export default About;
