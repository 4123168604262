import React, { useLayoutEffect } from "react";
import { IoIosSearch } from "react-icons/io";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import SingleContact from "./SingleContact";
import { useState } from "react";
import ChatMessages from "./ChatMessages";
import "./ChatContacts.css"
import { useEffect } from "react";

const ChatContacts = () => {
  const contacts = [
    {
      name: "Mechanic 2",
      profileImg: require("../assets/Chat/mech2.png"),
      time: "07:23 PM",
      lastMessage: "We'll schedule you in for Tues...",
      sentByUser: false,
      selected: false,
      orderId: 1234,
    },
    {
      name: "Mechanic 1",
      profileImg: require("../assets/Chat/mech1.png"),
      time: "08:45 PM",
      lastMessage: "It could be a number of things!",
      sentByUser: false,
      selected: true,
      orderId: 1234,
    },
    {
      name: "Mechanic 3",
      profileImg: require("../assets/Chat/mech3.png"),
      time: "Yesterday",
      lastMessage: "Will do. Thanks for your help...",
      sentByUser: true,
      selected: false,
      orderId: 1234,
    },
    {
      name: "Mechanic 4",
      profileImg: require("../assets/Chat/mech4.png"),
      time: "Yesterday",
      lastMessage: "Looking forward to the weekend...",
      sentByUser: false,
      selected: false,
      orderId: 1234,
    },
    {
      name: "Mechanic 5",
      profileImg: require("../assets/Chat/mech5.png"),
      time: "Yesterday",
      lastMessage: "Finished reading a captivating no...",
      sentByUser: false,
      selected: false,
      orderId: 1234,
    },
  ];
  const [currChat, setCurrChat] = useState(0);
  const [showAllMsgs, setShowAllMsgs] = useState(true);
  useEffect(()=>{
    const handleResize=()=>{
      if(window.innerWidth>768)
      setShowAllMsgs(true)
    }
    
  
  window.addEventListener('resize',handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
},[]);
  //const width = useWindowSize();
  return (
    <>
      <div className="p-4 pb-4 md:flex md:justify-start">
        <div className="relative flex flex-col items-start justify-start md:w-1/3">
          {showAllMsgs && <div className='fixed flex align-center w-[95%]'>
            <input
              type="text"
              placeholder="Search order id,name"
              className="border-[1px] border-[#CCCFD0] rounded-sm md:text-sm w-full p-2 pl-8"
            />
            <IoIosSearch className="absolute left-0 bottom-[7px] md:bottom-[5px] text-[#CCCFD0] text-3xl pl-2" />
          </div>
          }
        <div className="relative">
        <div
          onClick={() => setShowAllMsgs(!showAllMsgs)}
          className={showAllMsgs?"cursor-pointer fixed flex align-center gap-2 mt-14 mb-8 w-full":"fixed flex align-center gap-2 mt-6 mb-8 w-full"}
        >
          <BiSolidMessageSquareDetail className="text-[#818181] text-lg" />
          <p className="text-[#818181] text-sm font-semibold font-">
            ALL MESSAGES
          </p>
        </div>
        </div>
        <div className="w-full h-full mt-20 flex " >
          {showAllMsgs && (
            <div className={showAllMsgs?"h-full flex flex-col mt-1 w-full overflow-y-auto no-scrollbar responsive-container":"flex flex-col mt-1 w-full h-full overflow-y-auto no-scrollbar responsive-container"}>
              {contacts.map((contact, index) => (
                //console.log(contact)
                <SingleContact
                  index={index}
                  contact={contact}
                  currChat={currChat}
                  setCurrChat={setCurrChat}
                  showAllMsgs={showAllMsgs}
                  setShowAllMsgs={setShowAllMsgs}
          
                />
                
                ))}
            </div>
          )}
        </div>
        </div>
        {console.log(currChat)}
        <div className={showAllMsgs ? " relative w-full hidden md:flex md:w-2/3" : "relative w-full flex md:flex md:w-2/3"}>
          <div className={showAllMsgs?" flex flex-col w-full overflow-y-auto no-scrollbar responsive": " flex flex-col w-full overflow-y-auto no-scrollbar responsive2"}>
          <ChatMessages
            name={contacts[currChat].name}
            profileImg={contacts[currChat].profileImg}
            orderId={contacts[currChat].orderId}
          />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatContacts;
