// WishlistReducer.js
export const wishlistReducer = (state, action) => {
    switch (action.type) {
      case 'ADD_TO_WISHLIST':
        if (state.find(item => item._id === action.payload._id)) {
          return state; // Item already in wishlist
        }
        return [...state, action.payload];
  
      case 'REMOVE_FROM_WISHLIST':
        return state.filter(item => item._id !== action.payload._id);
  
      default:
        return state;
    }
  };
  