import React, { useState, useEffect } from "react";
import NavbarUpdate from "../components/NavbarUpdate";
import { useLocation, Link } from "react-router-dom";
import TrackPageNavbar from "../components/TrackPageNavbar";
import axios from "axios";
import SERVER_URL from "../constants/constant";



const Schedulepayment = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [currentPage, setCurrentPage] = useState("Service");
    const location = useLocation();

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  useEffect(() => {
    const path = location.pathname;
    setCurrentPage(getPageName(path));
  }, [location.pathname]);

  const getPageName = (path) => {
    const parts = path.split("/");
    return parts[parts.length - 1];
  };

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Fetch cart items initially
    fetchCartItems();
  }, []);

  const fetchCartItems = () => {
    axios.get(`${SERVER_URL}/user/cart/get`, {
      withCredentials: true,
    })
      .then((response) => {
        console.log(response.data);
        setCartItems(response.data.cartItemsData || []);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((subtotal, item) => {
      // Check if item.price is a valid number before adding
      return subtotal + (typeof item.price === 'number' ? item.price : 0);
    }, 0);
  };
  
  const calculateTax = () => {
    const subtotal = calculateSubtotal();
    const tax = 0.1 * subtotal; // Assuming tax is 10% of the subtotal
    return parseFloat(tax.toFixed(2)); // Round to two decimal places
  };
  
  
  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const tax = calculateTax();
    return subtotal + tax;
  };
  



  const handleRemoveItem = (itemId) => {
    axios.post(`${SERVER_URL}/user/cart/remove`, { itemId }, {
      withCredentials: true,
    })
      .then((response) => {
        console.log(response.data);
        // After successful removal, fetch the updated cart items
        fetchCartItems();
      })
      .catch((error) => {
        console.error('Error removing item:', error);
      });
  };

  return (
      <div className="flex flex-col">
        <TrackPageNavbar showOnlyNav={false} />
      {/* Header Section */}
      <div
        className=""
        style={{
          background: "white",
          border: "1px #F0F0F0 solid",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 10px",
        }}
      >
        {/* Left Section:*/}
        <div
          style={{
            marginRight: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "#6B6B6B",
              fontSize: "16px",
              fontFamily: "Poppins",
              fontWeight: 500,
              wordWrap: "break-word",
            }}
          >
            Home/Service/Detail/Cart
          </span>
          <span
            style={{
              color: "black",
              fontSize: "16px",
              fontFamily: "Poppins",
              fontWeight: 500,
              wordWrap: "break-word",
            }}
          >
            {" "}
            /{" "}
          </span>
          <span
            style={{
              color: "black",
              fontSize: "16px",
              fontFmily: "Poppins",
              fontWeight: 600,
              wordWrap: "break-word",
            }}
          >
            {currentPage}
          </span>
        </div>
        </div>

      <div className="max-w-8xl mx-4 md:mx-14">
        <div className="grid lg:grid-cols-6 gap-4 md:gap-8 lg:gap-12">
          <div className="lg:col-span-2 flex justify-between items-center md:border-r pr-4">
            <h2 className="text-base sm:text-base text-black font-bold relative mt-4">
              Drop date & time
              <h3 className="text-base sm:text-base font-normal text-gray-700 relative">
                24th Jan 2024 & 11.00PM
              </h3>
            </h2>
            <button
              id="edit"
              className="flex items-center justify-between bg-primary-700 hover:bg-primary-800  text-sm text-black font-bold md:mr-4"
              type="edit"
            >
              Edit
            </button>
          </div>

          <div className="lg:col-span-2 flex justify-between items-center md:border-r pr-4">
            <h2 className="text-base sm:text-base text-black font-bold relative mt-4">
              Drop Address
              <h3 className="text-base sm:text-base font-normal text-gray-700 relative">
              !st Cross road,BAD COLONY, Banglore-600321
              </h3>
            </h2>
            <button
              id="edit"
              className="flex items-center justify-between bg-primary-700 hover:bg-primary-800  text-sm text-black font-bold md:mr-4"
              type="edit"
            >
              Edit
            </button>
          </div>

        </div>
      </div>

      <div className="max-w-8xl mx-4 md:mx-14 mt-8">
  <h1 className="text-black font-bold text-2xl mb-2">Payment Option</h1>

  <div className="grid lg:grid-cols-6 gap-4">
    <div className="lg:col-span-4 grid grid-cols-1 lg:grid-cols-3 gap-4">
<div class="overflow-y-auto overflow-x-hidden flex-grow lg:col-span-1 border gap-8">
<ul className="flex flex-col pt-7">
  <li>
    <a
      href="#"
      className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-black text-black hover:text-white hover:border-green-500 pr-6 ${
        selectedOption === 'Credit/Debit Card' ? 'bg-black text-white' : ''
      }`}
      onClick={() => handleOptionClick('Credit/Debit Card')}
    >
      <span className="text-lg font-bold tracking-wide truncate ml-5 text-left">Credit/Debit Card</span>
    </a>
  </li>
  <li>
    <a
      href="#"
      className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-black text-black hover:text-white hover:border-green-500 pr-6 ${
        selectedOption === 'UPI' ? 'bg-black text-white' : ''
      }`}
      onClick={() => handleOptionClick('UPI')}
    >
      <span className="text-lg font-bold tracking-wide truncate ml-5 text-left">UPI</span>
    </a>
  </li>
  <li>
    <a
      href="#"
      className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-black text-black hover:text-white hover:border-green-500 pr-6 ${
        selectedOption === 'EMI' ? 'bg-black text-white' : ''
      }`}
      onClick={() => handleOptionClick('EMI')}
    >
      <span className="text-lg font-bold ml-5 tracking-wide truncate text-left">EMI</span>
    </a>
  </li>
  <li>
    <a
      href="#"
      className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-black text-black hover:text-white hover:border-green-500 pr-6 ${
        selectedOption === 'Net Banking' ? 'bg-black text-white' : ''
      }`}
      onClick={() => handleOptionClick('Net Banking')}
    >
      <span className="text-lg font-bold ml-5 tracking-wide truncate text-left">Net Banking</span>
    </a>
  </li>
  <li>
    <a
      href="#"
      className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-black text-black hover:text-white hover:border-green-500 pr-6 ${
        selectedOption === 'Cash on Delivery' ? 'bg-black text-white' : ''
      }`}
      onClick={() => handleOptionClick('Cash on Delivery')}
    >
      <span className="text-lg font-bold ml-5 tracking-wide truncate text-left">Cash on Delivery</span>
    </a>
  </li>
</ul>

    </div>
      
      {/* Input fields (Column 3) - Adjusted to touch the Sidebar on larger screens */}
      <div className="lg:col-span-3 lg:col-start-2 border-2 p-4 md:p-6 flex flex-col">
  <h1 className="mb-2 text-lg font-bold"> Pay with</h1>
  <input type="text" className="border border-gray-800 p-2 mb-4 w-full h-12" placeholder="Card Number" />
  <input type="text" className="border border-gray-800 p-2 mb-4 w-full h-12" placeholder="Name on Card" />
  <div className="grid grid-cols-3 gap-2">
    <div className="col-span-2">
      <input type="text" className="border border-gray-800 p-2 w-full h-12" placeholder="Valid Thru (MM/YY)" />
    </div>
    <div className="col-span-1">
      <input type="text" className="border border-gray-800 p-2 w-full ml-2 h-12" placeholder="CVV (3 Digits)" />
    </div>
    <div className="flex items-center col-span-4 mb-2 mt-2">
      <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
      <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900">
        Secure my card as per RBI guidelines.
        <a className="text-decoration: underline mx-auto" href="#"> Learn More</a>
      </label>
    </div>
  </div>
  <button className='bg-green-500 w-full md:w-1/3 h-12 mt-4 text-center text-lg text-white rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold self-end'>
    <Link to='/sucesspage'>Pay Now</Link>
  </button>
</div>

</div>

    {/* Placeholder for the second part (Column 2) */}
    <div className="lg:col-span-2 border px-2 xl:px-8 p-2 ">
    <label
              htmlFor=""
              className="text-xl font-bold text-black "
            >
              Service Detail
            </label>
            <div className="flow-root mt-2 mb-2 max-h-[250px] overflow-y-auto">
            <ul className="-my-2">
                {cartItems.map((item, index) => (
                  <li
                    key={item._id}
                    className={`flex flex-col p-0 border-b-2 space-y-3 py-4 text-left sm:flex-row sm:space-x-5 sm:space-y-0 ${
                      index !== cartItems.length - 1 ? "" : ""
                    }`}
                  >
                  <div className="shrink-0 relative">
                    <img
                      className="h-20 w-22 max-w-full object-cover"
                      src="https://images.unsplash.com/photo-1533473359331-0135ef1b58bf?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dsss"
                      alt=""
                    />
                  </div>

                  <div className="relative flex flex-1 flex-col justify-between">
                    <div className="sm:col-gap-5 sm:grid sm:grid-cols-2">
                      <div className="pr-8 sm:pr-5">
                
                        <p className="text-base font-bold text-gray-900">
                        {item.name}
                        </p>
                        <div className="sm:text-right text-left">
                        <p className=" font-semibold mx-0 mt-1 mb-0 text-sm text-black-400">
                          ₹ {item.price}
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                ))
}

            
              </ul>
            </div>

            <div className="mt-2 border"></div>

            <ul class="text-[#333] text-sm  mt-1">             
              <li class="border"></li>
              <li class="flex flex-wrap gap-4 py-1 text-xl font-extrabold">
                Total Est:<span class="ml-auto">₹{calculateTotal()}</span>
              </li>
            </ul>            
            <div className="mt-1 border mb-2"></div>
            <div className="flex items-center col-span-4 mb-2 mt-2">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="default-checkbox"
                className="ms-2 text-base font-medium text-gray-900"
              >
                Towing service
              </label>
            </div>
            <p className="text-sm font-normal">
              if vehicle is not running condition we will be adding towing
              services.Charges included seperately.
            </p>
          </div>
        </div>
        </div>
    </div>

  );
};

export default Schedulepayment;
