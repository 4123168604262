import React, { useState } from 'react'
import SinglePromo from './SinglePromo'
import { IoClose } from "react-icons/io5";

const PromoCodes = ({onClose,appliedCoupon,setAppliedCoupon}) => {
    const promos=[{
        promoCode:"FRESHIP4U",
        promoDesc:"Get free delivery on all service. Applicable On both online and cod", 
    },
    {
        promoCode:"FRESHIP4U",
        promoDesc:"Get free delivery on all service. Applicable On both online and cod",
        
    },
    {
        promoCode:"FRESHIP4U",
        promoDesc:"Get free delivery on all service. Applicable On both online and cod",
        
    }]
    //const [selectedCode,setSelectedCode]=useState("");
    //console.log(promos)
    //setAppliedCoupon(selectedCode);
  return (
    <div className='fixed inset-0  bg-black bg-opacity-70 top-0 right-0 bottom-0 left-0 flex justify-center items-center p-6 py-4 z-50 h-full'>
      <div
      onClick={(e) => e.stopPropagation()}
      className='absolute w-[500px] bg-white max-w-full h-[530px] rounded-md p-6  m-4 flex flex-col shadow-[3px_4px_8px_0.1px_rgba(0,0,0,0.2)] shadow-[#3d3251] relative'>
        <div className='flex justify-between align-center'>
        <div className='text-lg font-semibold mt-2 '>
            Coupons and offer
        </div>
        <div className='mt-2.5 mr-4'>
            <IoClose className='w-6 h-6 cursor-pointer' onClick={onClose}/>
        </div>
        </div>
        <div className='text-md mt-4 mb-1'>
            Available coupons
        </div>
        <div className='w-20 h-1 bg-[#73012C]'>
        </div>
        {promos.map((data)=>{
        //console.log(data)
        return <SinglePromo code={data.promoCode} desc={data.promoDesc} appliedCoupon={appliedCoupon} setAppliedCoupon={setAppliedCoupon} onClose={onClose}/>
        }
        )}
        
      </div>
    </div>
    
  )
}

export default PromoCodes
