import React, { createContext, useState } from 'react';

// Create the context
export const LocationContext = createContext();

// Create the provider component
export const LocationProvider = ({ children }) => {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const updateLocation = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
  };

  // Provide the latitude, longitude, and updateLocation function to the children
  return (
    <LocationContext.Provider value={{ latitude, longitude, updateLocation }}>
      {children}
    </LocationContext.Provider>
  );
};