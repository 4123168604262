import React, { useEffect, useState } from "react";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import grey from "../../src/assets/subscription/greyvector.png";
import black from "../../src/assets/subscription/blackvector.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const SubscriptionPlansContainer = () => {
  const [highlightedCard, setHighlightedCard] = useState("Pro"); // State to track highlighted card
  const [sliderRef, setSliderRef] = useState(null);
  const titleControl = useAnimation();
  const [titleRef, titleinView] = useInView();


  const handleCardHover = (title) => {
    setHighlightedCard(title);
  };

  useEffect(() => {
    if (titleinView) {
      titleControl.start("visible");
    } else {
      titleControl.start("hidden");
    }
  }, [titleControl, titleinView]);

  const titleVariants = {
    hidden: { opacity: 1, scale: 1, x: "100%" },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.0 }, x: "0%" },
  };

  const plansData = [
    {
      title: "Basic",
      amount: "1587/mo",
      description: "Affordable car care essentials with priority service.",
      buttonText: "Upgrade to Basic",
      image: grey,
      planFeatures: [
        "Routine maintenance",
        "Standard issues check",
        "Exclusive car care tips",
        "Priority booking",
        "Basic service discounts",
      ],
    },
    {
      title: "Pro",
      amount: "4094/mo",
      description: "Complete Care and Premium Support for reliable rides",
      buttonText: "Upgrade to Pro",
      isHighlighted: true,
      image: black,
      planFeatures: [
        "Basic plan features",
        "thorough Vehicle check",
        "Emergency assistance priority",
        "Advanced tips and advice",
        "Better discount on service",
      ],
    },
    {
      title: "Premium",
      amount: "4094/mo",
      description:
        "VIP car service experience with exclusive perks for ultimate peace of mind.",
      buttonText: "Upgrade to Premium",
      image: grey,
      planFeatures: [
        "Includes basic & pro features",
        "Advance diagnostics",
        "Specific discount on partner ",
        "VIP Treatment, exclusive content.",
        "Extra discount and perks",
      ],
    },
  ];

  return (
    <div className="mt-28 px-4">
      <motion.div
        variants={titleVariants}
        ref={titleRef}
        initial="hidden"
        animate={titleControl}
        className="text-center mb-8 md:mb-14"
        style={{ fontFamily: "poppins" /* add your custom font if needed */ }}
      >
        <h1 className="text-5xl font-bold text-black mb-2 md:text-4xl">
          Subscription Plans
        </h1>
        <p className="text text-black mb-4 md:mb-14">
          Choose a plan that works best for you
        </p>
      </motion.div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5 mx-4 md:mx-8 lg:mx-16">
        {plansData.map((plan, index) => (
          <SubscriptionPlanCard
            key={index}
            {...plan}
            isHighlighted={highlightedCard === plan.title} // Check if this card is highlighted
            onMouseEnter={() => handleCardHover(plan.title)} // Set this card as highlighted on hover
            onMouseLeave={() => handleCardHover("Pro")} // Reset to default "Pro" when not hovered
          />
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPlansContainer;
