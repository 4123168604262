import React, { useState } from 'react'



const SingleContact = ({index,contact,currChat,setCurrChat,showAllMsgs,setShowAllMsgs}) => {
    //console.log(profileImg)
    const [openChat,setOpenChat]=useState(false);
    const handleClick=()=>{
        setOpenChat(true)
        setCurrChat(index)
        setShowAllMsgs(!showAllMsgs)
    }
  return (
    <div onClick={handleClick} className='cursor-pointer m-4 mx-0 flex align-center justify-between w-full md:mt-2 md:mb-1'>
        <div className='flex w-full'>
      <div className='flex justify-center'>
      <img src={contact.profileImg} alt="contact-image" className='w-16 h-16 sm:w-20 sm:h-20 md:w-14 md:h-10 lg:w-16 lg:h-16 '/>
      </div>
      <div className='flex flex-col justify-start ml-2 sm:text-lg md:text-sm md:ml-2 align-center '>
        <p className={contact.selected?`text-black font-semibold `:`text-[#717171] font-medium `}>{contact.name}</p>
        <p className={contact.selected?`text-[#5F5F5F] md:text-xs`:`text-[#A0A0A0] md:text-xs `}>{contact.lastMessage}</p>
      </div>
      </div>
      <div className='flex justify-end mr-4 text-sm sm:text-md md:text-xs md:ml-2'>
        <p className='text-[#A0A0A0] text-sm'>{contact.time}</p>
      </div>
      
    </div>
    
  )
}

export default SingleContact
