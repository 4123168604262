import React from 'react';
// import NavbarUpdate from '../components/NavbarUpdate';
import TrackPageNavbar from '../components/TrackPageNavbar';
import '../styles/navbar.css';
// import Footer from '../components/Footer';
import left from '../assets/ServiceDetails/left.png';
import right from '../assets/ServiceDetails/right.png';


const HMStore = () => {

  return (
    <div>
      <TrackPageNavbar showOnlyNav={false} />
      {/* <NavbarUpdate showOnlyNav={true} /> */}
      <div className="px-14 py-3 shadow-sm flex justify-between items-center">
        <p className="text-sm text-gray-500 font-bold">Home  <span className="text-black">HelpyStore</span></p>
        <div className='w-3/4 flex items-center gap-x-8'>
          <div className='w-3/5'>
            <select className='border-[1.5px] border-gray-400 outline-none p-1.5 rounded-l'>
              <option value=''>Location</option>
              <option value=''>Location 1</option>
              <option value=''>Location 2</option>
              <option value=''>Location 3</option>
              <option value=''>Location 4</option>
              <option value=''>Location 5</option>
            </select>
            <input 
              type='text'
              placeholder='Search Spare Parts [ eg. Car battery ]'
              className='border-[1.5px] border-l-0 border-gray-400 outline-none px-2 py-[5.5px] rounded-r w-4/5'
            />
          </div>
          <div className='border-[1.5px] border-gray-400 outline-none p-1.5 px-5 rounded'>
            Filter
          </div>
        </div>
      </div>
      <div className='px-14 py-3 mt-5 flex gap-5'>
        <div className='flex-1 h-[400px] rounded bg-gray-300'></div>
        <div className='flex-1 h-[400px] rounded bg-gray-300'></div>
      </div>
      <div className='px-14 py-2 mt-5 flex justify-between items-center gap-x-5'>
        <img src={left} alt="left" className='w-8 h-8' />
        <div className='w-[95%] gap-y-4 flex flex-wrap justify-between'>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
          <div className='w-[15%] h-[65px] flex justify-center items-center border-2 border-gray-300 rounded'>Logo</div>
        </div>
        <img src={right} alt="left" className='w-8 h-8' />
      </div>
      <div className='px-14 py-3 mt-5'>
        <div className='flex justify-between items-center mb-5'>
          <p className="text-xl font-bold max-w-max relative after:h-[2px] after:bg-green-400 after:mt-0.5 after:w-[40px] after:flex after:absolute after:right-0">Top Product</p>
          <p className='text-xs font-bold text-green-400'>View All</p>
        </div>
        <div className='flex justify-between'>
          <div className='w-[18%] flex flex-col justify-between gap-y-0.5 border-2 border-gray-300 p-3 rounded'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mb-1.5'>Front Ceramic Series Brake Pads With...</p>
            <p className='text-black font-bold'>Rs. <span className='line-through text-gray-300'>2000</span>1250</p>
            <div className='flex justify-between gap-x-3 mt-2'>
              <button className='w-1/2 text-green-400 border-[1.5px] border-green-400 px-2.5 py-2 text-xs rounded font-bold'>Add to Cart</button>
              <button className='w-1/2 text-white bg-green-500 px-2.5 py-2 text-xs rounded font-bold'>Add to Cart</button>
            </div>
          </div>
          <div className='w-[18%] flex flex-col justify-between gap-y-0.5 border-2 border-gray-300 p-3 rounded'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mb-1.5'>Muffler Tip Show Pipe 60MM</p>
            <p className='text-black font-bold'>Rs. <span className='line-through text-gray-300'>2000</span>1250</p>
            <div className='flex justify-between gap-x-3 mt-2'>
              <button className='w-1/2 text-green-400 border-[1.5px] border-green-400 px-2.5 py-2 text-xs rounded font-bold'>Add to Cart</button>
              <button className='w-1/2 text-white bg-green-500 px-2.5 py-2 text-xs rounded font-bold'>Add to Cart</button>
            </div>
          </div>
          <div className='w-[18%] flex flex-col justify-between gap-y-0.5 border-2 border-gray-300 p-3 rounded'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mb-1.5'>Car engine Oil filter Compatible...</p>
            <p className='text-black font-bold'>Rs. <span className='line-through text-gray-300'>2000</span>1250</p>
            <div className='flex justify-between gap-x-3 mt-2'>
              <button className='w-1/2 text-green-400 border-[1.5px] border-green-400 px-2.5 py-2 text-xs rounded font-bold'>Add to Cart</button>
              <button className='w-1/2 text-white bg-green-500 px-2.5 py-2 text-xs rounded font-bold'>Add to Cart</button>
            </div>
          </div>
          <div className='w-[18%] flex flex-col justify-between gap-y-0.5 border-2 border-gray-300 p-3 rounded'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mb-1.5'>Sway Bar</p>
            <p className='text-black font-bold'>Rs. <span className='line-through text-gray-300'>2000</span>1250</p>
            <div className='flex justify-between gap-x-3 mt-2'>
              <button className='w-1/2 text-green-400 border-[1.5px] border-green-400 px-2.5 py-2 text-xs rounded font-bold'>Add to Cart</button>
              <button className='w-1/2 text-white bg-green-500 px-2.5 py-2 text-xs rounded font-bold'>Add to Cart</button>
            </div>
          </div>
          <div className='w-[18%] flex flex-col justify-between gap-y-0.5 border-2 border-gray-300 p-3 rounded'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mb-1.5'>Engine Oil</p>
            <p className='text-black font-bold'>Rs. <span className='line-through text-gray-300'>2000</span>1250</p>
            <div className='flex justify-between gap-x-3 mt-2'>
              <button className='w-1/2 text-green-400 border-[1.5px] border-green-400 px-2.5 py-2 text-xs rounded font-bold'>Add to Cart</button>
              <button className='w-1/2 text-white bg-green-500 px-2.5 py-2 text-xs rounded font-bold'>Add to Cart</button>
            </div>
          </div>
        </div>
      </div>
      <div className='px-14 py-4 mt-6 mb-16'>
        <p className="text-xl font-bold max-w-max relative after:h-[2.5px] after:bg-green-400 after:mt-1 after:w-[40px] after:flex after:absolute after:right-0">Search By Category</p>
        <div className='mt-5 flex flex-wrap gap-x-7 gap-y-3'>
          <div className='w-[15%] border-2 border-gray-300 p-3 rounded text-center'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mt-2'>Brake System</p>
          </div>
          <div className='w-[15%] border-2 border-gray-300 p-3 rounded text-center'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mt-2'>Engine Component</p>
          </div>
          <div className='w-[15%] border-2 border-gray-300 p-3 rounded text-center'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mt-2'>Exhaust System</p>
          </div>
          <div className='w-[15%] border-2 border-gray-300 p-3 rounded text-center'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mt-2'>Suspension System</p>
          </div>
          <div className='w-[15%] border-2 border-gray-300 p-3 rounded text-center'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mt-2'>Cooling System</p>
          </div>
          <div className='w-[15%] border-2 border-gray-300 p-3 rounded text-center'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mt-2'>Fuel System</p>
          </div>
          <div className='w-[15%] border-2 border-gray-300 p-3 rounded text-center'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mt-2'>Interior Accessories</p>
          </div>
          <div className='w-[15%] border-2 border-gray-300 p-3 rounded text-center'>
            <div className='flex justify-center py-3'>
              <img src={left} alt='' className='w-[125px]' />
            </div>
            <p className='text-black font-bold mt-2'>Exterior Accessories</p>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
    
  );
};

export default HMStore;