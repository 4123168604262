export const cartReducer = (state, action) => {
  
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingItemIndex = state.findIndex(item => item._id === action.payload._id);

      if (existingItemIndex !== -1) {
        // If the item already exists in the cart, update its quantity
        const updatedState = [...state];
        updatedState[existingItemIndex].quantity += 1;
        return updatedState;
      } else {
        // If the item doesn't exist in the cart, add it with a quantity of 1
        return [...state, { ...action.payload, quantity: 1 }];
      }

    case 'UPDATE_QUANTITY':
      return state.map(item =>
        item.id === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
      );

    case 'REMOVE_FROM_CART':
      return state.filter(item => item._id !== action.payload._id);

    default:
      return state;
  }
};




