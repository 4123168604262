import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const WhyService = () => {
  const [sliderRef, setSliderRef] = useState(null);
  const titleControl = useAnimation();
  const [titleRef, titleinView] = useInView();

  useEffect(() => {
    if (titleinView) {
      titleControl.start("visible");
    } else {
      titleControl.start("hidden");
    }
  }, [titleControl, titleinView]);

  const titleVariants = {
    hidden: { opacity: 1, scale: 1, x: "100%" },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.0 }, x: "0%" },
  };

  return (
    <div className="px-6 md:px-12 mt-8">
      <motion.div
        variants={titleVariants}
        ref={titleRef}
        initial="hidden"
        animate={titleControl}
        className="text-center mb-14"
        style={{ fontFamily: "poppins" /* add your custom font if needed */ }}
      >
        <p className="text-4xl font-bold text-black  md:text-4xl">
          Why choose <span className="text-green-500">HelpyMoto </span>?
        </p>
      </motion.div>
      <div className="grid grid-cols-4 gap-8">
        <div className="col-span-4 md:col-span-2 lg:col-span-1 border-[1px] border-gray-400 rounded-lg  border-solid bg-black text-white">
          <img
            src="service-1.jpg"
            alt=""
            className="w-full object-contain rounded-tr-lg rounded-tl-lg"
          />
          <p className="font-semibold px-3 pt-3 text-xl text-white">
          24/7 accessibility
          </p>
          <p className="px-3 pb-2 text-sm">
          Our car services are designed to be accessible 24/7 across India. Simply provide some details, and you can conveniently book a mechanic anytime using our app or website. Everything you need will be at your fingertips.
          </p>
        </div>

        <div className="col-span-4 md:col-span-2 lg:col-span-1 border-[1px] border-gray-400 rounded-lg  border-solid bg-black text-white">
          <img
            src="service-2.jpg"
            alt=""
            className="w-full object-contain rounded-tr-lg rounded-tl-lg"
          />
          <p className="font-semibold px-3 pt-3 text-xl text-white">
          Reasonable service charges:
          </p>
          <p className="px-3 pb-2 text-sm">
          We offer transparent pricing methods for our services, ensuring you to break free from concerns about unrevealed fees or charges.
          </p>
        </div>

        <div className="col-span-4 md:col-span-2 lg:col-span-1 border-[1px] border-gray-400 rounded-lg  border-solid bg-black text-white">
          <img
            src="service-3.jpg"
            alt=""
            className="w-full object-contain rounded-tr-lg rounded-tl-lg"
          />
          <p className="font-semibold px-3 pt-3 text-xl text-white">
          Premium Quality Spare Parts for Exceptional Performance
          </p>
          <p className="px-3 pb-2 text-sm">
          We utilize only top-tier spare parts for all our repairs and services, ensuring your vehicle is in capable hands.
          </p>
        </div>

        <div className="col-span-4 md:col-span-2 lg:col-span-1 border-[1px] border-gray-400 rounded-lg  border-solid bg-black text-white">
          <img
            src="service-4.jpg"
            alt=""
            className="w-full object-contain rounded-tr-lg rounded-tl-lg"
          />
          <p className="font-semibold px-3 pt-3 text-xl text-white">
          Verified safety mechanism:
          </p>
          <p className="px-3 pb-2 text-sm">
          Our certified mechanics, well-trained and experienced, strictly adhere to safety procedures to ensure the well-being of our customers and their vehicles.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyService;
