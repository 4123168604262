import React, {useState }from 'react';
import serviceImage from "../assets/service.png";
import doneImage from "../assets/done.png";
import { Link } from 'react-router-dom';

const Trackorderstep5 = () => {
  return (
    <div className="grid grid-cols-1 border border-black p-2 md:mx-8 mt-2 sm:grid-cols-5 gap-10 xl:px-20 lg:gap-15">
      {/* Address */}
      <div class="sm:col-span-2 bg-white p-4 rounded-lg">
  <div class="mt-4 flex flex-col items-center"> 
    <img
      class="h-48 w-42 justify-center rounded-lg"
      src={doneImage}
      alt=""
    />
  </div>
  <h1 class="text-xl sm:text-xl md:text-2xl font-bold text-black mt-4">Mechanic has delivered your car</h1>
    <h2 class="mt-2 text-base sm:text-lg md:text-base font-semibold text-black">Please choose a payment mode to complete the overall service</h2>
</div>


  
      {/* Cart */}
      <div className="sm:col-span-3 md:gap-5 bg-white p-4 rounded-lg">
        {/* Cart Content */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
          {/* Cart Items */}
          <div className="sm:col-span-2">
            <h1 className="text-xl font-bold text-black"> Service Detail (2 item) </h1>
            <div className="flow-root">
              <ul>
                {/* Cart items go here */}
                <li className="flex flex-col py-4 ">
                  <div className="relative flex items-center">
                    <img
                      className="h-20 w-20 max-w-full object-cover mr-3 rounded-lg"
                      src={serviceImage}
                      alt=""
                    />
                    <div className="flex-1">
                      <p className="text-base font-bold text-black">Basic Service</p>
                      <p className="text-base font-semibold text-black">₹ 2000</p>
                    </div>
                  </div>
                </li>
                <li className="flex flex-col py-4">
                  <div className="relative flex items-center">
                    <img
                      className="h-20 w-20 max-w-full object-cover mr-3 rounded-lg"
                      src={serviceImage}
                      alt=""
                    />
                    <div className="flex-1">
                      <p className="text-base font-bold text-black">Basic Service</p>
                      <p className="text-base font-semibold text-black">₹ 2000</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <ul className="py-4 text-lg font-bold text-black border-t border-gray-700">
              <li className="flex justify-between">
                Total Cost
                <span>₹2050</span>
              </li>
            </ul>
            <button className="bg-green-500 w-full h-12 text-lg text-white rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold mt-4">
              <Link to="/" className="text-black">Pay Now</Link>
            </button>
          </div>
  
          {/* Need Help */}
          <div className="sm:col-span-1 bg-white rounded-lg">
              <h1 className="text-lg font-bold text-black">Need Help?
                <a href="#" className="text-green-500 underline">Click here</a>
              </h1>
          </div>
        </div>        
      </div>
    </div>
  );
};


export default Trackorderstep5;
