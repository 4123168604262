import React from 'react'
import TrackPageNavbar from "../components/TrackPageNavbar";
import ChatContacts from '../components/ChatContacts';

const Chat = () => {
    
  return (
    <>
    <div className='relative z-50'>
    <div className='fixed top-0 w-full '>
    <TrackPageNavbar showOnlyNav={false} />
    </div>
    <div class="mt-28  px-4 sm:px-6 md:px-8 lg:px-14 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
          <p class="text-sm text-gray-500 font-bold">
            <a href="/" class="hover:text-black hover:cursor-pointer">
              Profile
            </a>
            <span class="text-black"> / </span>
            <span class="text-black">Chat</span>
          </p>
    </div>
    <div className='overflow-y-auto no-scrollbar' style={{ maxHeight: "calc(100vh - 180px)" }}>

    <ChatContacts/>
    </div>
    </div>
    </>
  )
}

export default Chat

