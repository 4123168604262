import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import servicePartnerData from "../data/ServicePartnerData";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

// ... (other imports)

// ... (other imports)

const ServicePartnerItem = ({ imgSrc, title, description }) => {
  return (
    <div
      className={`border-solid border-2 rounded-md bg-white p-4 w-full h-full overflow-hidden transition-all ease-in-out duration-300 relative`}
    >
      <img
        src={imgSrc}
        alt="images"
        className="w-full h-48 object-cover mb-4 rounded-md"
      />
      <p className="font-semibold text-lg mb-1 text-black">{title}</p>
      <p
        className={`pb-4 text-base mb-12 text-black  overflow-hidden transition-all ease-in-out duration-300`}
      >
        {description}
      </p>
      <div className="absolute bottom-5 left-4">
        <div className="bg-green-500 text-white text-center text-sm rounded-md px-4 py-2 cursor-pointer transition duration-300">
          <Link to="/details" className="no-underline">
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
};

const ServicePartner = () => {
  const titleControl = useAnimation();
  const [titleRef, titleinView] = useInView();

  useEffect(() => {
    if (titleinView) {
      titleControl.start("visible");
    } else {
      titleControl.start("hidden");
    }
  }, [titleControl, titleinView]);

  const titleVariants = {
    hidden: { opacity: 1, scale: 1, x: "-100%" },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.0 }, x: "0%" },
  };
  return (
    <div className="px-4 mt-10 md:px-16 mb-8 md:mb-16">
      <motion.div
        variants={titleVariants}
        ref={titleRef}
        initial="hidden"
        animate={titleControl}
        className="text-center mt-2 md:mt-2 mb-2"
        style={{ fontFamily: "poppins" /* add your custom font if needed */ }}
      >
        <p className="text-4xl font-bold text-black mb-2">
          Your trusted service allies
        </p>
        <p className="text-base text-black  md:mb-11">
          Meet Our Trusted Partner
        </p>
      </motion.div>
      <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8`}>
        {servicePartnerData.map((service, index) => (
          <div key={index} className={`w-30`}>
            <ServicePartnerItem
              imgSrc={service.imgSrc}
              title={service.title}
              description={service.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicePartner;


