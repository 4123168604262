import React, { useState, useEffect } from 'react';
import Trackorderservicedetail from '../screens/Trackorderservicedetail';
import NavbarUpdate from '../components/NavbarUpdate';
import { useLocation } from 'react-router-dom';
import SERVER_URL from '../constants/constant';
import TrackPageNavbar from '../components/TrackPageNavbar';
import mixpanel from "../config/mixpanel";

const Todetail = () => {
  const [serviceData, setServiceData] = useState(null);
  const location = useLocation();
  const serviceId = location.pathname.split("/").pop(); // Get serviceId from URL
  console.log(serviceId);
  
  const fetchServiceData = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/service/single/${serviceId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Fetched service data:", data);
      setServiceData(data.service);
    } catch (error) {
      console.error('Error fetching service data:', error);
    }
  };

  useEffect(() => {
    fetchServiceData();
    mixpanel.track("ServiceDetail Page View", {
      page: window.location.pathname,
    });
  }, []);

  if (!serviceData) {
    return <p>Loading...</p>;
  }

  return (
    <div className='overflow-y-hidden mb-20'>
      <TrackPageNavbar showOnlyNav={false} />
      <div className="mx-auto px-4 md:px-8 lg:px-14">
        <Trackorderservicedetail service={serviceData} />
      </div>
    </div>
  );
};

export default Todetail;
