import React from "react";

const VehicleIcon = ({ name, icon }) => {
  // console.log("icon", icon);
  // console.log("name", name);
  return (
    <div className="flex flex-col justify-between h-[70px] items-center cursor-pointer">
      <img src={icon} alt="" className="w-[50px] object-contain rounded-md" />
      <p className="text-center text-ellipsis">{name}</p>
    </div>
  );
};

export default VehicleIcon;
