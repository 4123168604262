import React from "react";
// import NavbarUpdate from "../components/NavbarUpdate";
import TrackPageNavbar from "../components/TrackPageNavbar";


const NotificationScreen = () => {
return (
      <div className="flex flex-col">
      <TrackPageNavbar showOnlyNav={false} />
      
      <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3 flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
    <p class="text-sm text-gray-500 font-bold">
    <a href="/" class="hover:text-black hover:cursor-pointer">Profile</a> 
              <span class="text-black"> / </span>
                <span class="text-black">Notification</span>
                
    </p>
</div>

      <div className="border-t-2 border-gray-200 my-1"></div>

      <div class="max-w-8xl mx-4 md:mx-14   ">
      <div class="grid lg:grid-cols-7 gap-1 ">
  <div class="lg:col-span-4 flex justify-between items-center">
  <h2 class="text-xl font-bold sm:text-3xl sm:font-extrabold text-black mb-2 relative">
  Notification
  <span class="absolute bottom-0 right-0 w-1/3 h-1 bg-green-500"></span>
</h2>

    <div class="p-4">
  <div class="relative">
    <button
      id="dropdownDefault"
      data-dropdown-toggle="dropdown"
      class="flex items-center justify-between bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-2 py-2 text-black border-2 transition-all duration-200 ease-in-out"
      type="button"
    >
      Filter 
      <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
      </svg>
    </button>
  </div>
</div>

  </div>
</div>




        <div class="grid lg:grid-cols-7 gap-12 relative mt-18">
          <div class="lg:col-span-4 ">
          <div className="flex items-center mb-4">
            <div className="flex-grow border-t border-b"></div>
            <div className="px-2">12 February 2024</div>
            <div className="flex-grow border-t border-b"></div>
          </div>

          <div className="mt-4 flex flex-col">
  <div className="flex  mb-4">
    <div className="w-12 h-12 shrink-0">
      <img
        src="/cartcarousel.png"
        className="w-full h-full object-contain"
        alt="Payment Confirmation"
      />
    </div>
    <div className="ml-4">
      <h1 className="font-semibold text-base text-black sm:text-xl sm:font-bold">
        Payment Confirmation
      </h1>
      <h2 className="text-md text-gray-500">
        You have successfully made a payment for scheduled service.
      </h2>
      <h3>
        Amount: 2500 by UPI
      </h3>
      <h4 className="text-sm text-gray-500">
        12 March 2024, 10:30 AM
      </h4>
    </div>
  </div>

  <div className="flex mb-4">
    <div className="w-12 h-12 shrink-0">
      <img
        src="/cartcarousel.png"
        className="w-full h-full object-contain"
        alt="Payment Confirmation"
      />
    </div>
    <div className="ml-4">
      <h1 className="font-semibold text-base text-black sm:text-xl sm:font-bold">
        Payment Confirmation
      </h1>
      <h2 className="text-md text-gray-500">
        You have successfully made a payment for scheduled service.
      </h2>
      <h3>
        Amount: 2500 by UPI
      </h3>
      <h4 className="text-sm text-gray-500">
        12 March 2024, 10:30 AM
      </h4>
    </div>
  </div>

  <div className="flex mb-4">
    <div className="w-12 h-12 shrink-0">
      <img
        src="/cartcarousel.png"
        className="w-full h-full object-contain"
        alt="Payment Confirmation"
      />
    </div>
    <div className="ml-4">
      <h1 className="font-semibold text-base text-black sm:text-xl sm:font-bold">
        Payment Confirmation
      </h1>
      <h2 className="text-md text-gray-500">
        You have successfully made a payment for scheduled service.
      </h2>
      <h3>
        Amount: 2500 by UPI
      </h3>
      <h4 className="text-sm text-gray-500">
        12 March 2024, 10:30 AM
      </h4>
    </div>
  </div>
  <div className="flex items-center mb-4">
            <div className="flex-grow border-t border-b"></div>
            <div className="px-2">12 February 2024</div>
            <div className="flex-grow border-t border-b"></div>
          </div>

          <div className="mt-4 flex flex-col">
  <div className="flex  mb-4">
    <div className="w-12 h-12 shrink-0">
      <img
        src="/cartcarousel.png"
        className="w-full h-full object-contain"
        alt="Payment Confirmation"
      />
    </div>
    <div className="ml-4">
      <h1 className="font-semibold text-base text-black sm:text-xl sm:font-bold">
        Payment Confirmation
      </h1>
      <h2 className="text-md text-gray-500">
        You have successfully made a payment for scheduled service.
      </h2>
      <h3>
        Amount: 2500 by UPI
      </h3>
      <h4 className="text-sm text-gray-500">
        12 March 2024, 10:30 AM
      </h4>
    </div>
  </div>

  <div className="flex mb-4">
    <div className="w-12 h-12 shrink-0">
      <img
        src="/cartcarousel.png"
        className="w-full h-full object-contain"
        alt="Payment Confirmation"
      />
    </div>
    <div className="ml-4">
      <h1 className="font-semibold text-base text-black sm:text-xl sm:font-bold">
        Payment Confirmation
      </h1>
      <h2 className="text-md text-gray-500">
        You have successfully made a payment for scheduled service.
      </h2>
      <h3>
        Amount: 2500 by UPI
      </h3>
      <h4 className="text-sm text-gray-500">
        12 March 2024, 10:30 AM
      </h4>
    </div>
  </div>

  <div className="flex mb-4">
    <div className="w-12 h-12 shrink-0">
      <img
        src="/cartcarousel.png"
        className="w-full h-full object-contain"
        alt="Payment Confirmation"
      />
    </div>
    <div className="ml-4">
      <h1 className="font-semibold text-base text-black sm:text-xl sm:font-bold">
        Payment Confirmation
      </h1>
      <h2 className="text-md text-gray-500">
        You have successfully made a payment for scheduled service.
      </h2>
      <h3>
        Amount: 2500 by UPI
      </h3>
      <h4 className="text-sm text-gray-500">
        12 March 2024, 10:30 AM
      </h4>
    </div>
  </div>
  </div>
</div>



          </div>
          
          <div class="bg-white h-max rounded-md p-6 shadow-[0_0px_4px_0px_rgba(6,81,237,0.2)] sticky top-0 lg:col-span-3">
  <label htmlFor="Settings" className="block text-2xl font-extrabold text-black mb-2">
    Notification Settings
  </label>
  <div className="flex items-center justify-between mb-4">
    <div className=" text-lg font-bold">
      General Notification
 
      <p className=" text-base font-normal text-gray-700 xl:w-80 lg:w-60 md:w-80 grid grid-cols-1">Recieve important updates and annoucements.</p>
    </div>
    <div className="flex items-center ml-2">
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" value="" className="sr-only peer" />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
      </label>
    </div>
  </div>
  <div className="flex items-center justify-between mb-4">
    <div className=" text-lg font-bold">
      Appointment Settings
      <p className=" text-base font-normal text-gray-700 xl:w-80 lg:w-60 md:w-80 grid grid-cols-1">Get remainder for upcoming service appointments.</p>
    </div>
    <div className="flex items-center ml-2">
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" value="" className="sr-only peer" />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
      </label>
    </div>
  </div>
  <div className="flex items-center justify-between mb-4">
    <div className=" text-lg font-bold">
      Payment Notifications
      <p className=" text-base font-normal text-gray-700 xl:w-80 lg:w-60 md:w-80 grid grid-cols-1">Recieve alerts for payment transactions and receipts.</p>
    </div>
    <div className="flex items-center ml-2">
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" value="" className="sr-only peer" />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
      </label>
    </div>
  </div>
  <div className="flex items-center justify-between mb-4">
    <div className=" text-lg font-bold">
      Mechanic Updates
      <p className=" text-base font-normal text-gray-700 xl:w-80 lg:w-60 md:w-80 grid grid-cols-1">Stay Informed when a Mechanic is assigned or updates the service status.</p>
    </div>
    <div className="flex items-center ml-2">
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" value="" className="sr-only peer" />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
      </label>
    </div>
  </div>
  <div className="flex items-center justify-between mb-4">
    <div className=" text-lg font-bold">
      Promotions and offers
      <p className=" text-base font-normal text-gray-700 xl:w-80 lg:w-60 md:w-80 grid grid-cols-1">opt-in to receive exclusive promotion and special offers.</p>
    </div>
    <div className="flex items-center ml-2">
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" value="" className="sr-only peer" />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
      </label>
    </div>
  </div>
</div>

        </div>
      </div>
      </div>
  );
};

export default NotificationScreen;


