import React, { useState, useRef, useEffect } from "react";
import TestimonialCard from "./TestimonialCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left from "../../src/assets/testimonialPage/left.png";
import right from "../../src/assets/testimonialPage/right.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const TestimonialCarousel = () => {
  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const titleControl = useAnimation();
  const [titleRef, titleinView] = useInView();

  useEffect(() => {
    if (titleinView) {
      titleControl.start("visible");
    } else {
      titleControl.start("hidden");
    }
  }, [titleControl, titleinView]);

  const titleVariants = {
    hidden: { opacity: 1, scale: 1, x: "-100%" },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.0 }, x: "0%" },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: activeIndex === i ? "#000" : "#888",
          cursor: "pointer",
        }}
      ></div>
    ),
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          top: "22rem",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          className="arrow-btn prev-btn mb-2 p-2"
          onClick={() => {
            sliderRef.current.slickPrev();
            setActiveIndex((prev) => prev - 1);
          }}
        >
          <img src={left} alt="Left Arrow" />
        </button>
        <ul style={{ margin: "0", padding: "0", display: "flex", gap: "1rem" }}>
          {dots}
        </ul>
        <button
          className="arrow-btn next-btn mb-2 p-2"
          onClick={() => {
            sliderRef.current.slickNext();
            setActiveIndex((prev) => prev + 1);
          }}
        >
          <img src={right} alt="Right Arrow" />
        </button>
      </div>
    ),
    beforeChange: (current, next) => setActiveIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    swipeToSlide: true, // Enable swipe functionality
    touchMove: true, // Enable touch events
  };

  const testimonials = [
    // ... (same as before)
    {
      id: 1,
      name: "Joanna Prohaska",
      profile: "car-owner",
      rating: 5,
      message:
      "“I recently had my car serviced, and I couldn't be happier with the hassle-free experience. The team was prompt,courteous, and went above and beyond to address all my concerns. The quality of work was exceptional, and my car feels top-notch.”"
    },
    {
      id: 2,
      name: "Sai Krisnan",
      profile: "car-owner",
      rating: 5,
      message:
        "\"The car service I received was timely, efficient and exceeded my expectations. The staff was friendly, attentive and reliable. I appreciated the thorough inspection and the detailed explanation of the repairs needed. My car now drives like a dream, and I'm grateful for the top-notch service.”",
    },
    {
      id: 3,
      name: "Joanna Prohaska",
      profile: "car-owner",
      rating: 5,
      message:
        "“I recently had my car serviced, and I couldn't be happier with the hassle-free experience. The team was prompt,courteous, and went above and beyond to address all my concerns. The quality of work was exceptional, and my car feels top-notch.”",
    },
    {
      id: 4,
      name: "Sai Krisnan",
      profile: "car-owner",
      rating: 5,
      message:
        "\"The car service I received was timely, efficient and exceeded my expectations. The staff was friendly, attentive and reliable. I appreciated the thorough inspection and the detailed explanation of the repairs needed. My car now drives like a dream, and I'm grateful for the top-notch service.”",
    },
    {
      id: 5,
      name: "Joanna Prohaska",
      profile: "car-owner",
      rating: 5,
      message:
        "“I recently had my car serviced, and I couldn't be happier with the hassle-free experience. The team was prompt,courteous, and went above and beyond to address all my concerns. The quality of work was exceptional, and my car feels top-notch.”",
    },
    {
      id: 6,
      name: "Sai Krisnan",
      profile: "car-owner",
      rating: 5,
      message:
        "\"The car service I received was timely, efficient and exceeded my expectations. The staff was friendly, attentive and reliable. I appreciated the thorough inspection and the detailed explanation of the repairs needed. My car now drives like a dream, and I'm grateful for the top-notch service.”",
    },
  ];

  return (
    <div className="px-4">
      <div className="p-4 md:p-6">
        <motion.div
          variants={titleVariants}
          ref={titleRef}
          initial="hidden"
          animate={titleControl}
          className="text-center mt-8 md:mt-16 mb-2"
          style={{ fontFamily: "poppins" }}
        >
          <p className="text-4xl font-bold text-black mb-2 md:text-4xl">
            Testimonial
          </p>
          <p className="text-black mb-8 md:mb-6">Client Reviews</p>
        </motion.div>
      </div>
      <div className="block mb-4" style={{ position: "relative" }}>
        <Slider ref={sliderRef} {...settings}>
          {testimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.id} testimonial={testimonial} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
