import React, { useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faCartPlus,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

import "./ServicePageCarousal.css";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

import img1 from "../assets/service.png";
import img2 from "../assets/service.png";
import img3 from "../assets/service.png";
import img4 from "../assets/service.png";

const images = [img1, img2, img3, img4];

function BoxComponent() {
  const [slideIndex, setSlideIndex] = useState(0);

  const cardStyle = {
    width: "80%",
    height: "4em",
    top: "63%",
    left: "5%",
    fontFamily: "Poppins, sans-serif",
    fontSize: "30px",

    fontWeight: "700",
    lineHeight: "36px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "white",
  };

  const ratingBoxStyle = {
    width: "15%",
    height: "8%",
    top: "65%",
    left: "82%",
    borderRadius: "5px",
    backgroundColor: "rgba(34, 197, 93, 1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    color: "white",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 900,
  };

  const descriptionStyle = {
    width: "100%",
    height: "5%",
    top: "77%",
    left: "5%",
    fontFamily: "Poppins, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
    letterSpacing: "0em",
    textAlign: "left",
    position: "absolute",
    color: "white",
  };
  const rsStyle = {
    width: "100%",
    height: "30px",
    top: "85%",
    left: "5%",
    color: "white",
    position: "absolute",
    fontFamily: "Poppins, sans-serif",
    fontSize: "30px",
    fontWeight: "600",
  };
  const cartIconsStyle = {
    width: "20px",
    height: "20px",
    top: "86%",
    left: "77%",
    color: "white ",
    position: "absolute",
    fontSize: "30px",
    display: "flex",
    justifyContent: "space-between",
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSlideIndex(next),
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (current, next) => (
      <div className={current === slideIndex ? "dot dot-active" : "dot"}></div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          nextArrow: <EmptyArrow />,
          prevArrow: <EmptyArrow />,
        },
      },
    ],
  };

  function SampleNextArrow({ onClick }) {
    return (
      <div className="arrow arrow-right" onClick={onClick}>
        <BsArrowRight />
      </div>
    );
  }

  function SamplePrevArrow({ onClick }) {
    return (
      <div className="arrow arrow-left" onClick={onClick}>
        <BsArrowLeft />
      </div>
    );
  }

  function EmptyArrow({ onClick }) {
    return <div></div>;
  }

  return (
    <div className="slider">
      <Slider {...settings}>
        {images.map((img, index) => (
          <div
            className={index === slideIndex ? "slide slide-active" : "slide"}
            key={index}
          >
            <div className="image-container" style={{ position: "relative" }}>
              <img src={img} alt="" />
              <div
                className="text-overlay"
                style={{ ...cardStyle, position: "absolute" }}
              >
                Basic Service
              </div>
              <div className="descriptionStyle" style={descriptionStyle}>
                1000 Kms or 1 Month warranty | Every 5000 Kms or 3 Months
              </div>
              <div style={ratingBoxStyle}>
                <span>4.0&nbsp;</span>
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ fontSize: "13px", marginBottom: "3%" }}
                />
              </div>
            </div>
            <div style={rsStyle}>
              ₹<span style={{ color: "dimgray" }}>3̶0̶0̶0̶ </span>1000
            </div>
            <div style={cartIconsStyle}>
              <button className="cartButton" style={{ marginRight: "80%" }}>
                <FontAwesomeIcon
                  icon={faCartShopping}
                  style={{ fontSize: "100%" }}
                />
              </button>
              &nbsp;
              <button>
                <FontAwesomeIcon
                  className="cartButton"
                  icon={faCartPlus}
                  style={{ fontSize: "100%" }}
                />
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default BoxComponent;
