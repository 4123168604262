import React from 'react';
import quote from '../../src/assets/testimonialPage/Quotes.png';


const TestimonialCard = ({ testimonial }) => (
  
  <div className='flex flex-col sm:flex-row relative sm:justify-around sm:items-center p-3 h-[300px] border-2 w-[95%] mx-auto shadow-md rounded-md'>
      <div className='flex flex-col sm:justify-around items-start gap-2 sm:gap-4 sm:h-60 mb-1 sm:ml-4'>
        <p className='font-bold text-gray-500'>Facebook</p>
        <div className='flex gap-1 items-center h-14'>
          <img
            className="w-10 h-10 rounded-full mr-2 sm:m-2"
            src={`https://placekitten.com/50/50?image=${testimonial.id}`}
            alt={`${testimonial.name} profile`}
          />
          <div>
            <div className="flex items-center">
              {Array.from({ length: testimonial.rating }).map((_, index) => (
                <span key={index} className="text-yellow-500">&#9733;</span>
              ))}
            </div>
            <p className="font-bold font-['Manrope']">{testimonial.name}</p>
            <p className="text-sm text-gray-500">{testimonial.profile}</p>
          </div>
        </div>
      </div>
      <div className='bg-gray-100 sm:w-[60%] overflow-hidden p-3 sm:p-6 mt-2 sm:mt-0 sm:mr-3 h-[250px] rounded-md'>
        <img src={quote} alt="quote" className='absolute right-2 top-2 h-6'/>
        <p className='text-left line-clamp-6 sm:line-clamp-none sm:m-1 font-bold'>
          {testimonial.message}
        </p>
      </div>
  </div>
);

export default TestimonialCard;
