// import { Task } from "@mui/icons-material";
import { useRef, useState, useEffect } from "react";
import TrackPageNavbar from "../components/TrackPageNavbar";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
// import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Xarrow from "react-xarrows";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import "./MechanicDetails.css";
// import CancelIcon from "@mui/icons-material/Cancel";
// import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router";
import mixpanel from "../config/mixpanel";
import carImage from "../assets/OntimeService/CarOntime.png";
import SevicesCompletedGif from "../assets/OntimeService/OntimeCompletedgif.png";
import OntimeSuccessPage from "./OntimeSuccessPage";

const OntimeServiceBooked = () => {
  const [serviceCompleted,setServiceCompleted] = useState(false);
  const [mechanic] = useState({
    name: "Mechanic A",
    rating: 4.6,
    reviews: 18,
  });
  const [onGoingService] = useState([
    "Engine Poblem",
    "A/C Repair",
    "ABC",
  ]);

  const [paymentCompleted, setPaymentCompleted] = useState(false);

  // const navigate = useNavigate();
  const step1 = useRef(null);
  const step2 = useRef(null);
  const step3 = useRef(null);
  // const modalRef = useRef(null);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let track_details = {
      page: window.location.pathname,
      user: `${user.firstName} ${user.lastName}`,
      userId: user.userUid,
      email: user.email,
      ongoingServices: onGoingService,
      mechanic,
    };

    mixpanel.track("SERVICE_SCHEDULE", track_details);
  }, [mechanic, onGoingService]);

  return (
    <>
      {paymentCompleted ? (
        <OntimeSuccessPage />
      ) : (
        <>
          {" "}
          <TrackPageNavbar showOnlyNav={false} />
          <div className="w-full flex flex-row justify-center my-5">
            <div className="w-11/12 lg:flex lg:flex-row md:flex md:flex-col">
              <div className="lg:w-2/5 md:w-full p-5 shadow-xl rounded">
                <div className=" md:w-3/4 md:m-auto">
                  <div className="flex flex-row">
                    <img
                      src={require("../assets/OrderDetailsPage/mechanic_A.png")}
                      alt=""
                      className="rounded-full"
                      style={{
                        width: "77px",
                        height: "77px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="ml-3">
                      <h1 className="text-14/18 font-bold">{mechanic.name}</h1>
                      <h1>
                        <StarIcon style={{ color: "orange" }} />
                        <StarIcon style={{ color: "orange" }} />
                        <StarIcon style={{ color: "orange" }} />
                        <StarIcon style={{ color: "orange" }} />
                        <StarBorderIcon style={{ color: "orange" }} />
                      </h1>
                      <p>
                        {mechanic.rating}({mechanic.reviews} Reviews)
                      </p>
                    </div>
                  </div>
                  <div className="my-4">
                    <h1 className="text-14/18 font-bold mb-1">Ticket Id</h1>
                    <p className="text-slate-700">#123456</p>
                  </div>
                  <div className="my-4 flex justify-start gap-1">
                    <h1 className="text-14/18 font-bold mb-1">ETA: </h1>
                    <p className="text-slate-700">4 hours</p>
                  </div>
                  <div className="my-3">
                    <h3 className="text-sm font-bold">
                      List of ongoing service:
                    </h3>
                    <div className="w-11/12 my-2">
                      <div className="flex flex-row justify-between mb-5">
                        <h2>Basic Service</h2>
                        <p>
                          <CurrencyRupeeIcon /> 1000
                        </p>
                      </div>
                      <div className="flex flex-row justify-between mb-5">
                        <h2>Advance Service</h2>
                        <p>
                          <CurrencyRupeeIcon /> 1000
                        </p>
                      </div>
                      <hr className="border-t my-4" />
                      <div className="flex flex-row justify-between mb-5 font-semibold">
                        <h2>Subtotal ( 2 items )</h2>
                        <p>
                          <CurrencyRupeeIcon /> 800
                        </p>
                      </div>
                      <div className="flex flex-row justify-between mb-5 font-semibold">
                        <h2>Tax</h2>
                        <p>
                          <CurrencyRupeeIcon /> 50
                        </p>
                      </div>
                      <div className="border-b border-t py-2 flex flex-row justify-between mb-5">
                        <h2 className="text-xl font-bold">Total EST</h2>
                        <p className="text-xl font-bold">
                          <CurrencyRupeeIcon /> 2850
                        </p>
                      </div>
                    </div>
                  </div>

                  {!serviceCompleted && (
                    <div
                      className="mb-7"
                      onClick={() => {
                        setPaymentCompleted(true);
                      }}
                    >
                      <button
                        className="rounded my-5 px-16 py-2"
                        style={{
                          background: "#22C55D",
                          border: "none",
                          color: "white",
                        }}
                      >
                        Pay now
                      </button>
                      
                    </div>
                  )}
                  <button
                   onClick={() => {
                    setServiceCompleted(true);
                  }}
                        className="rounded my-5 px-16 py-2"
                        style={{
                          background: "#22C55D",
                          border: "none",
                          color: "white",
                        }}
                      >
                        Continue
                    </button>
                  <div className="flex flex-row items-center">
                    <HelpOutlineIcon />
                    <p className="text-sm ml-3">
                      Need help?{" "}
                      <a
                        href="#"
                        className="font-semibold"
                        style={{
                          color: "#22C55D",
                          textDecoration: "underline",
                        }}
                      >
                        Click here
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:w-3/5 md:w-full">
                <div className="w-full ml-2">
                  <div className="border rounded flex flex-row justify-between p-5 progress">
                    <div className="flex flex-col items-center progress-item">
                      <img
                        src={require("../assets/OrderDetailsPage/Group 33324.png")}
                        alt=""
                        ref={step1}
                        style={{
                          width: "60px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      />
                      <div>
                        <p className="w-40 text-bol font-semibold text-sm text-center">
                          Service Booked successful
                        </p>
                        <p className="font-semibold text-center text-sm">
                          [10:30 AM]
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center progress-item">
                      <img
                        src={require("../assets/OrderDetailsPage/car 1.png")}
                        alt=""
                        ref={step2}
                        style={{
                          width: "60px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      />
                      <div>
                        <p className="w-40 text-bol font-semibold text-sm text-center">
                          Mechanic Started Working
                        </p>
                        <p className="font-semibold text-sm text-center">
                          [10:30 AM]
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center progress-item">
                      <img
                        src={require("../assets/OrderDetailsPage/Group 985.png")}
                        alt=""
                        ref={step3}
                        style={{
                          width: "60px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      />
                      <div>
                        <p className="w-40 text-bol font-semibold text-sm text-center">
                          Service Competed
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center progress-item">
                      <img
                        src={require("../assets/OrderDetailsPage/payment 1.png")}
                        alt=""
                        ref={step3}
                        style={{
                          width: "60px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      />
                      <div>
                        <p className="w-40 text-bol font-semibold text-sm text-center">
                          Payment completed
                        </p>
                      </div>
                    </div>
                    <Xarrow
                      start={step1}
                      end={step2}
                      zIndex={-1}
                      showHead={false}
                      color="#22C55D"
                      dashness
                    />
                    <Xarrow
                      start={step2}
                      end={step3}
                      zIndex={-20}
                      showHead={false}
                      color="#dedad9"
                      dashness
                    />
                  </div>
                  <div className="">
                    {!serviceCompleted && (
                      <div className="p-8 flex flex-col gap-4 items-center">
                        <div
                          style={{ height: "350px" }}
                          className="items-center"
                        >
                          <img alt="" src={carImage}></img>
                        </div>
                        <div className=" left-1/2 justify-center">
                          <h1 className="text-xl font-bold">
                            Mechanic Started Working
                          </h1>
                          <h1 className="font-bold text-3xl my-2">OTP: 2365</h1>
                          <p className="lg:w-96">
                            Share Otp Once Mechanic Completed your service
                          </p>
                        </div>
                      </div>
                    )}
                    {serviceCompleted && (
                      <div className="md:p-8">
                        <div className="p-8 flex flex-col gap-2 items-center">
                          <div
                            style={{ height: "300px" }}
                            className="items-center"
                          >
                            <img alt="" src={SevicesCompletedGif}></img>
                          </div>
                          <div className="flex flex-col items-center">
                            <h1 className="text-xl font-bold">
                              Service completed sucessfull
                            </h1>
                            <p>
                              Payment <CurrencyRupeeIcon /> 2050 for this
                              service is pending
                            </p>
                            {!paymentCompleted && (
                              <div
                                onClick={() => {
                                  setPaymentCompleted(true);
                                  // setServiceCompleted(false)
                                }}
                                className="mb-7"
                              >
                                <button
                                  className="rounded my-5 px-16 py-2"
                                  style={{
                                    background: "#22C55D",
                                    border: "none",
                                    color: "white",
                                  }}
                                >
                                  Pay now
                                </button>
                               
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OntimeServiceBooked;
