import React from 'react'
import ChatHeader from './ChatHeader'
import ChatFooter from './ChatFooter'
import MechanicMsg from './MechanicMsg'
import UserMsg from './UserMsg'

const ChatMessages = ({name,profileImg,orderId}) => {
    const messages=[
        {
            senderName:name,
            senderProfile:profileImg,
            senderMsg:"Hi !",
            sendTime:"10:30 AM",
        },
        {
            senderName:"User name 1",
            senderProfile:require("../assets/Chat/User1.png"),
            senderMsg:"Hi there, I'm having some trouble with my car. It's making a strange noise whenever I accelerate.",
            sendTime:"10:30 AM",
        },
        {
            senderName:name,
            senderProfile:profileImg,
            senderMsg:"Let's see if we can figure out what's going on. ",
            sendTime:"10:30 AM",
        },
        {
            senderName:"User name 1",
            senderProfile:require("../assets/Chat/User1.png"),
            senderMsg:"It started a couple of days ago, but it's gotten progressively worse. I'm worried it might be something serious.",
            sendTime:"10:30 AM",
        },
        {
            senderName:name,
            senderProfile:profileImg,
            senderMsg:"Okay, got it. It could be a number of things, but we'll need to take a look under the hood to diagnose it properly. ",
            sendTime:"10:30 AM",
        },
        {
            senderName:"User name 1",
            senderProfile:require("../assets/Chat/User1.png"),
            senderMsg:"Can't wait for the weekend!",
            sendTime:"10:30 AM",
        },
    ]
  return (
    <div className='h-full relative flex w-full justify-start '>
    <div className='w-full h-full md:border-l-[0.76px] md:border-l-[#DFDFDF] flex flex-col justify-between '>
      <div className='w-full fixed'>
        <ChatHeader name={name} profileImg={profileImg} orderId={orderId}/>
      </div>
      <div className='w-full  pb-2 pt-16 overflow-y-auto no-scrollbar' style={{ maxHeight: "calc(100vh - 220px)" }}>
        {
            messages.map((message,index)=>{
                console.log(message.senderName)
                console.log(name)
                return message.senderName==name?
                  (<MechanicMsg  key={index} message={message} /> )
                  :( <UserMsg key={index} message={message}/>)
                
            } 
            )
        }
      </div>
      <div className='w-full'>
      <div className='w-full fixed bottom-0 z-10 md:w-2/3'>
      <ChatFooter/>
      </div>
      </div>
    </div>
    </div>
  )
}

export default ChatMessages
