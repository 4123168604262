import React, {  useContext } from "react";
import { CartContext } from "../context/CartContext";
import NavbarUpdate from "../components/NavbarUpdate";
import { BsFillCartPlusFill,  } from "react-icons/bs";
// import{BsPlus, BsDash} from "react-icons/bs";
import { Link } from "react-router-dom";
import VehicleSelector from "../components/VehicleSelector";
import { RiDeleteBin6Line } from "react-icons/ri";
import toast from "react-hot-toast";

const CartScreen = () => {
  const {
    cartItems,
    dispatch: cartDispatch,
    cartTotal,
  } = useContext(CartContext);

  const removeFromCart = (item) => {
    cartDispatch({ type: "REMOVE_FROM_CART", payload: item });
    notifyRemoveFromCart();
  };

  const vehicleId = JSON.parse(localStorage.getItem("vehicle-selected"))
    ? JSON.parse(localStorage.getItem("vehicle-selected")).model
    : null;

  console.log(vehicleId);

  const notifyRemoveFromCart = () => toast.success("Removed from cart");

  return (
    <div className="flex flex-col w-full h-screen">
      <NavbarUpdate showOnlyNav={true} />
      <div className="p-4">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
          {/* Cart Section */}
          <div className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 flex flex-col">
            <p className="text-3xl font-bold mb-6">Your Cart</p>
            <div className="flex flex-col w-full">
              {cartItems.length > 0 ? (
                <div>
                  {/* Loop through items here */}

                  {cartItems.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="border bg-white shadow-md p-4 mb-4"
                      >
                        <div className="grid grid-cols-7">
                          <div className="flex items-center justify-start col-span-4">
                            <img
                              src={item.photo}
                              alt={item.name}
                              className="w-16 h-16 mr-4 object-contain"
                            />
                            <h2 className="md:text-lg font-semibold">
                              {item.name}
                            </h2>
                          </div>

                          <div className="flex justify-center items-center text-gray-600 col-span-2">
                            {item.discount !== null ? (
                              <div className="flex flex-col">
                                <p className="text-lg font-semibold">
                                  &#8377;{" "}
                                  {Number(
                                    item.basePrice -
                                      Number(
                                        (item.basePrice * item.discount) / 100
                                      )
                                  ).toFixed(0)}
                                </p>
                                <s className="text-sm text-red-500">
                                  &#8377; {item.basePrice}
                                </s>
                              </div>
                            ) : (
                              <div className="flex flex-col">
                                <p className="text-lg font-semibold">
                                  &#8377; {Number(item.basePrice)}
                                </p>
                              </div>
                            )}
                          </div>

                          <div className="flex justify-end items-center col-span-1">
                            <button
                              onClick={() => removeFromCart(item)}
                              className="text-red-600 hover:text-red-800"
                            >
                              <RiDeleteBin6Line />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Link to="/">
                  <div className="flex my-20 w-full justify-center items-center flex-col">
                    <BsFillCartPlusFill color="#4DDE80" size={100} />
                    <p className="text-xl mt-8 text-center mx-2">
                      No services added yet. Please browse our services{" "}
                      <span className="text-blue-600">here</span>
                    </p>
                  </div>
                </Link>
              )}
            </div>
          </div>

          {/* Vehicle Selection and Confirmation */}
          <div className="col-span-1">
            <div>
              <p className="text-3xl font-bold mb-6">Select your Vehicle</p>
            </div>

            <div>
              <VehicleSelector />
              <Link to="/checkout">
                <div className="bg-green-500  rounded-lg cursor-pointer text-white font-semibold px-3 py-3 mt-4 flex justify-between">
                  <div className="h-8 text-xl flex items-center justify-between w-full">
                    <p>&#8377; {cartTotal}</p>
                    <p>CHECKOUT</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartScreen;
