
import { useMap , useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

const DirectionsComponent = ({Route})=>{

    const map = useMap();
    const routesLibrary = useMapsLibrary('routes');

    const [directionsService,setDirectionsService] = useState();
    const [directionsRenderer,setDirectionsRenderer] = useState();
    const [routes,setRoutes] = useState([]);

    const origin = 'JM9H+V25, Devi Camp, Karnataka 583229, India';
    const destination = 'Katapur, Karnataka 583229, India';

    useEffect(()=>{
        if(!map || !routesLibrary){
            console.log("Unable to load maps and routes Library :(");
            return;
        }   

        console.log("Maps and routes library loaded successfully :)");
        console.log(routesLibrary);

        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));

    },[map,routesLibrary]);

    useEffect(()=>{
        if(!directionsService || !directionsRenderer){
            console.log("Unable to set Direction service || Directions Renderer :(");
            return;
        }

        console.log("Directions Service and Direction Renderer Loaded successfully :)",directionsService);

        directionsService.route({
            origin:Route.origin,
            destination: Route.destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
            // provideRouteAlternatives: true
        })
        .then((response)=>{
            console.log("Routes are ",response);
            directionsRenderer.setDirections(response);
        })
        .catch((err)=>{
            console.log("Something went wrong while fetching routes :(",err);
        })

    },[directionsService,directionsRenderer])

    return null;
}

export default DirectionsComponent;