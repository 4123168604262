
import { useMapsLibrary , useMap } from "@vis.gl/react-google-maps";
import { useEffect, useState , useCallback } from "react";
import TextField from "@mui/material/TextField";


const InputSuggestionMaps = ({Set_coords , Set_range , Coordinates , Address })=>{

    const maps = useMap();
    const places = useMapsLibrary('places');
    const geocoding = useMapsLibrary('geocoding');

    const [inputValue,setInputValue] = useState('');

    const [sessionToken,setSessionToken] = useState(null);
    const [autoCompleteService,setAutoCompleteService] = useState(null);
    const [placesService,setPlacesService] = useState(null);
    const [predictionResult,setPredictionResult] = useState([]);

    useEffect(()=>{
        console.log(places)
        if(!places || !maps){
            console.log("Unable to load maps library and maps");
            return;
        }
        console.log("Loaded maps and places API successfully :)");

        setAutoCompleteService(new places.AutocompleteService());
        setPlacesService(new places.PlacesService(maps));
        setSessionToken(new places.AutocompleteSessionToken());


        // console.log("Geocoding API is ready :)",geocoding);
        let geocoding_API = new geocoding.Geocoder();
        const latlng =  new window.google.maps.LatLng(Coordinates?.lat, Coordinates?.lng)

        geocoding_API.geocode({location: latlng},(results,status)=>{
            // console.log("Results and status are ",results[0].formatted_address)
            // console.log(status);
            if(status === 'OK' && results[0]){
                Address(results[0].formatted_address);
            }
            else{
                console.log("Unable to fetch the Location address using coordinates :(")
            }
        })


        return ()=> setAutoCompleteService(null);
    },[places,maps,geocoding]);


    const fetchPredictions = useCallback(
        async(inp)=>{

            if(!autoCompleteService || !inp){
                console.log("There is a problem with autoCompleteService || inputValue :(");
                setPredictionResult([]);
                return;
            }

            const request = { input: inp , sessionToken };
            const response = await autoCompleteService.getPlacePredictions(request);

            // console.log("Response from google is ",response.predictions);
            if(inp) setPredictionResult(response.predictions);
        },
        [autoCompleteService,sessionToken]
    )

    const handleLocationSearch = useCallback(
        (value)=>{
            console.log(value);

            if(value.length == 0) setPredictionResult([])

            setInputValue(value);
            fetchPredictions(value)
        },
        [fetchPredictions]
    );

    const handleSuggestionClick = async (placeId)=>{
        if(!places){
            console.log("Something went wrong while loading places API :(");
            return;
        }

        const detailRequestOptions = {
            placeId,
            fields: ['geometry', 'name', 'formatted_address'],
            sessionToken
        };

        
        placesService.getDetails(detailRequestOptions,(response)=>{
            
            // Response includes location coordinates and viewport
            // console.log("Response is ",response);

            // This will be used to zoom map to the selected Place on maps
            let bound = new window.google.maps.LatLngBounds();
            
            if(response.geometry.viewport) bound.union(response.geometry.viewport)
            else bound.extend(response.geometry.viewport);
            
            // Getting the location coordinates of selected place
            let selectedCoordinates = {
                lat: response.geometry.location.lat(),
                lng: response.geometry.location.lng(),
            }
            
            // console.log("Selected place's coordinates are ",selectedCoordinates);

            Set_coords(selectedCoordinates); // update the coordinates
            Set_range(500); // for fetching near by mechanics
            setPredictionResult([]); // set suggestion array to []
            setInputValue(response.formatted_address) // update input field
            Address(response.formatted_address); // this will be reflected at the Show user location card
            if(response.geometry.viewport) maps.fitBounds(bound); // zoom to selected place on map
        });        
    }

    return(
        <>
            <div className="w-full">
                <TextField
                    id="outlined-basic"
                    type="text"
                    label="Enter Location"
                    variant="outlined"
                    className="w-full"
                    value={inputValue || ""}
                    onChange={(e) => handleLocationSearch(e.target.value)}
                />
                <div className="relative w-full">
              { inputValue && predictionResult.length > 0 && (
                <ul className="z-50 flex w-full flex-col absolute top-0 left-0 bg-white px-2 pt-2 shadow-md border-2">
                    {/* Show the prediction result on */}
                  { predictionResult.map(({place_id,description})=>{
                    return(
                        <li
                            className="border-b-2 py-2 cursor-pointer"
                            key={place_id}
                            onClick={()=> handleSuggestionClick(place_id) }
                        >   
                        {description}
                        </li>
                    )
                  })}
                </ul>
              )}
            </div>
            </div>
        </>
    )
};

export default InputSuggestionMaps;