// import { Task } from "@mui/icons-material";
import { useRef, useState, useEffect } from "react";
import TrackPageNavbar from "../components/TrackPageNavbar";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Xarrow from "react-xarrows";
import mixpanel from "../config/mixpanel";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./MechanicDetails.css";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router";
import SERVER_URL from "../constants/constant";
import axios from "axios";

const MechanicDetails = () => {
  const [diagnosing ] = useState(true);
  const [mechanic] = useState({
    name: "Mechanic A",
    rating: 4.6,
    reviews: 18,
  });

  const [onGoingService, setOnGoingService] = useState([
    "Engine Poblem",
    "A/C Repair",
    "ABC",
  ]);

  const [totalCost, setTotalCost] = useState(2800);
  const [totalTime,setTotalTime] = useState(4);
  const [show, setShow] = useState(false);
  const [continueService, setService] = useState(false);
  const [ontime, setOntime] = useState(false);
  const [book, setBook] = useState(false);
  const [success, setSuccess] = useState(false);
  const [cancel, setCancel] = useState(false);

  const navigate = useNavigate();
  const step1 = useRef(null);
  const step2 = useRef(null);
  const step3 = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let track_details = {
      page: window.location.pathname,
      user: `${user?.firstName} ${user?.lastName}`,
      userId: user?.userUid,
      email: user?.email,
      ongoingServices: onGoingService,
      mechanic,
    };

    mixpanel.track("SERVICE_SCHEDULE", track_details);
  }, [mechanic, onGoingService]);


  useEffect(()=>{

    let id = '65f475e3c8b52dd3a38012ee';
    let url = `${SERVER_URL}//ticket/mechanic/getEstimated/${id}`;

    axios({
      method:"GET",
      url,
      withCredentials: true,
      headers:{
        'Content-Type':'application/json',
      },
    })
    .then((response)=>{
      console.log("Data from backend is ",response.data);
      setOnGoingService(response.data.serviceDetail);
      setTotalCost(response.data.totalEstimatedPrice);
      setTotalTime(response.data.totalEstimatedTime);

    })
    .catch((err)=>{
      console.log("Something went wrong !!",err);
    })

  },[]);

  return (
    <>
      <TrackPageNavbar showOnlyNav={false} />
      {show && (
        <div
          style={{ background: "#212121" }}
          className="w-full z-2 h-screen fixed top-0 right-0 left-0 grid place-items-center"
          onClick={(e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
              setService(false);
              setOntime(false);
              setBook(false);
              setSuccess(false);
              setCancel(false);
              setShow(false);
            }
          }}
        >
          {continueService && (
            <div
              className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4  rounded"
              ref={modalRef}
            >
              <CancelIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow(false);
                  setService(false);
                }}
              />
              <div className="text-center">
                <h1 className="text-lg font-bold">
                  Convert to schedule service ?
                </h1>
                <p className="my-3">
                  The assessment and service for your car may take longer than
                  expected, approximately 1 day. To better accommodate this,
                  would you like to convert your ongoing service to a scheduled
                  one?
                </p>
              </div>
              <div className="flex md:flex-row flex-col items-center justify-center">
                <button
                  onClick={() => {
                    setOntime(true);
                    setService(false);
                  }}
                  style={{
                    borderColor: "#22C55D",
                    border: "1px solid #22C55D",
                  }}
                  className="py-2 px-4 mr-3 rounded md:my-0 my-2"
                >
                  On time Service
                </button>
                <button
                  onClick={() => {
                    setOntime(false);
                    setService(false);
                    setBook(true);
                  }}
                  style={{ background: "#22C55D" }}
                  className="text-white py-2 px-4 rounded md:my-0 my-2"
                >
                  Schedule Service
                </button>
              </div>
            </div>
          )}
          {ontime && (
            <div
              className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4 rounded"
              ref={modalRef}
            >
              <CancelIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow(false);
                  setService(false);
                  setOntime(false);
                }}
              />
              <div className="text-center my-4">
                <ErrorIcon style={{ color: "red", fontSize: "50px" }} />
                <h1 className="text-lg font-bold">
                  On-Time Service is not available for this service.
                </h1>
              </div>
              <div className="flex md:flex-row flex-col items-center justify-center my-3">
                <button
                  onClick={() => {
                    setShow(false);
                    setService(false);
                    setOntime(false);
                    setBook(false);
                  }}
                  style={{
                    borderColor: "#22C55D",
                    border: "1px solid #22C55D",
                  }}
                  className="py-2 px-4 mr-3 rounded md:my-0 my-2"
                >
                  Cancel service
                </button>
                <button
                  onClick={() => {
                    setService(false);
                    setOntime(false);
                    setBook(true);
                  }}
                  style={{ background: "#22C55D" }}
                  className="text-white py-2 px-4 rounded md:my-0 my-2"
                >
                  Schedule Service
                </button>
              </div>
            </div>
          )}
          {book && (
            <div
              className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4  rounded"
              ref={modalRef}
            >
              <CancelIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow(false);
                  setService(false);
                  setOntime(false);
                  setBook(false);
                }}
              />
              <h1 className="text-center text-lg font-bold mt-5 mb-3">
                Are you sure you want to book service ?
              </h1>
              <div className="flex flex-row justify-center mt-3 mb-8">
                <button
                  onClick={() => {
                    setCancel(true);
                    setService(false);
                    setBook(false);
                    setOntime(false);
                    setSuccess(false);
                  }}
                  style={{ border: "1px solid red" }}
                  className="py-1 px-5 mr-3 rounded"
                >
                  No
                </button>
                <button
                  onClick={() => {
                    let user = JSON.parse(localStorage.getItem("user"));
                    mixpanel.track("SUCCESS_SCHEDULE", {
                      userId: user.userUid,
                      email: user.email,
                      username: `${user.firstName} ${user.lastName}`,
                      mechanic,
                      onGoingServices: onGoingService,
                      totalCost: totalCost,
                    });
                    setSuccess(true);
                    setCancel(false);
                    setBook(false);
                    setService(false);
                    setOntime(false);
                  }}
                  style={{ background: "#22C55D" }}
                  className="text-white py-1 px-5 rounded"
                >
                  Yes
                </button>
              </div>
            </div>
          )}
          {success && (
            <div
              className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4  rounded"
              ref={modalRef}
            >
              <div className="grid place-items-center mt-5">
                <img
                  alt=""
                  src={require("../assets/OrderDetailsPage/excellence 1.png")}
                  style={{
                    width: "100px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <h1
                className="text-lg text-center font-bold my-5"
                style={{ color: "#22C55D" }}
              >
                Service Booked Successfully!
              </h1>
            </div>
          )}
          {cancel && (
            <div
              className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4  rounded"
              ref={modalRef}
            >
              <div className="flex flex-col items-center mt-8">
                <h1 className="text-lg font-bold text-center">
                  Are you sure you wanna cancel service ?
                </h1>
                <p className="w-3/4 text-center mb-3">
                  You will be charged an average amount for the mechanic's visit
                  due to the service cancellation
                </p>
              </div>
              <div className="flex md:flex-row flex-col items-center justify-center mt-5 mb-8">
                <button
                  style={{
                    borderColor: "#22C55D",
                    border: "1px solid #22C55D",
                  }}
                  className="py-2 px-4 mr-3 rounded md:my-0 my-2"
                >
                  Continue Service
                </button>
                <button
                  onClick={() => {
                    navigate("/cancel/service");
                  }}
                  style={{ background: "#22C55D" }}
                  className="text-white py-2 px-4 md:my-0 my-2 rounded"
                >
                  Pay Now
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="w-full flex flex-row justify-center my-5">
        <div className="w-11/12 lg:flex lg:flex-row md:flex md:flex-col">
          <div className="lg:w-2/5 md:w-full p-5 shadow-xl rounded">
            <div className=" md:w-3/4 md:m-auto">
              <div className="flex flex-row">
                <img
                alt=""
                  src={require("../assets/OrderDetailsPage/mechanic_A.png")}
                  className="rounded-full"
                  style={{
                    width: "77px",
                    height: "77px",
                    objectFit: "cover",
                  }}
                />
                <div className="ml-3">
                  <h1 className="text-14/18 font-bold">{mechanic.name}</h1>
                  <h1>
                    <StarIcon style={{ color: "orange" }} />
                    <StarIcon style={{ color: "orange" }} />
                    <StarIcon style={{ color: "orange" }} />
                    <StarIcon style={{ color: "orange" }} />
                    <StarBorderIcon style={{ color: "orange" }} />
                  </h1>
                  <p>
                    {mechanic.rating}({mechanic.reviews} Reviews)
                  </p>
                </div>
              </div>
              <div className="my-4">
                <h1 className="text-14/18 font-bold mb-1">Ticket Id</h1>
                <p className="text-slate-700">#123456</p>
              </div>
              <div className="my-3">
                <h3 className="text-sm font-bold">List of ongoing service:</h3>
                {onGoingService.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-row items-center my-3"
                    >
                      <TaskAltIcon />
                      <p className="text-slate-700 ml-4 text-sm">{item.name}</p>
                    </div>
                  );
                })}
              </div>
              <div className="my-3">
                <h1 className="text-base font-bold">Issue Discription</h1>
                <p className="my-3 text-slate-700 text-sm">
                  While traveling, my car stalled, and I'm uncertain about the
                  exact issue. I suspect it might be an engine problem
                </p>
              </div>
              <div className="mb-7">
                <h1 className="text-base font-bold">
                  if you want to add more services?
                </h1>
                <button
                  className="rounded my-5"
                  style={{
                    background: "#22C55D",
                    padding: "5px 10px",
                    border: "none",
                    color: "white",
                  }}
                >
                  Add new Service
                </button>
              </div>
              <div className="flex flex-row items-center">
                <HelpOutlineIcon />
                <p className="text-sm ml-3">
                  Need help?{" "}
                  <a
                    href="#"
                    className="font-semibold"
                    style={{ color: "#22C55D", textDecoration: "underline" }}
                  >
                    Click here
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-3/5 md:w-full">
            <div className="w-full ml-2">
              <div className="border rounded flex flex-row justify-between p-5 progress">
                <div className="flex flex-col items-center progress-item">
                  <img
                    src={require("../assets/OrderDetailsPage/Group 33324.png")}
                    alt=""
                    ref={step1}
                    style={{
                      width: "60px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <p className="w-40 text-bol font-semibold text-sm text-center">
                      Mechanic reached location
                    </p>
                    <p className="font-semibold text-center text-sm">
                      [10:30 AM]
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center progress-item">
                  <img
                    src={require("../assets/OrderDetailsPage/car 1.png")}
                    alt=""
                    ref={step2}
                    style={{
                      width: "60px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <p className="w-40 text-bol font-semibold text-sm text-center">
                      Mechanic Started Working
                    </p>
                    <p className="font-semibold text-sm text-center">
                      [10:30 AM]
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center progress-item">
                  <img
                    src={require("../assets/OrderDetailsPage/Group 985.png")}
                    alt=""
                    ref={step3}
                    style={{
                      width: "60px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <p className="w-40 text-bol font-semibold text-sm text-center">
                      Assesses the problem
                    </p>
                    <p className="font-semibold text-sm text-center">
                      [10:45 AM]
                    </p>
                  </div>
                </div>
                <Xarrow
                  start={step1}
                  end={step2}
                  zIndex={-1}
                  showHead={false}
                  color="#22C55D"
                  dashness
                />
                <Xarrow
                  start={step2}
                  end={step3}
                  zIndex={-1}
                  showHead={false}
                  color="#dedad9"
                  dashness
                />
              </div>
              <div className="">
                {!diagnosing && (
                  <div className="p-8">
                    <div>
                      <h1 className="text-xl font-bold">
                        Mechanic Started Working
                      </h1>
                      <p className="lg:w-96">
                        Mechanic is currently diagnosing the issue and will
                        provide a cost estimation for the service.
                      </p>
                    </div>
                    <div
                      style={{ height: "300px" }}
                      className="grid place-items-center mt-5"
                    >
                      <div
                        className="rounded-full grid place-items-center"
                        style={{
                          width: "200px",
                          height: "200px",
                          borderWidth: "15px",
                        }}
                      >
                        <p
                          className="w-32 text-center font-bold"
                          style={{ color: "#22C55D" }}
                        >
                          Estimation in Progress
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {diagnosing && (
                  <div className="md:p-8">
                    <div>
                      <h1 className="text-xl font-bold">Mechanic Assessment</h1>
                      <p className="text-sm lg:w-96 text-slate-700">
                        “Problem Identified Here’s the Cost and time estimation
                        for your service”
                      </p>
                    </div>
                    <div className="flex flex-row mt-5 mech-time">
                      <h1 className="font-bold ml-2 text-3xl">EST - {totalTime} Hours</h1>
                      <p className="font-bold text-base self-end ml-3">
                        (On time service Possible)
                      </p>
                    </div>
                    <div className="mt-5 ml-2 flex flex-row service-billing">
                      <h1 className="mr-6">Service:</h1>
                      <div className="w-80">
                        <div className="w-11/12 md:m-auto">
                          {
                            onGoingService.map((item,index)=>{
                              return(
                                <div className="flex flex-row justify-between mb-5">
                                  <h2>{item.name}</h2>
                                  <p>
                                    <CurrencyRupeeIcon /> {item.price}
                                  </p>
                                </div>
                              )
                            })
                          }
                          <div className="border-b border-t py-2 flex flex-row justify-between mb-5">
                            <h2 className="text-xl font-bold">Total EST</h2>
                            <p className="text-xl font-bold">
                              <CurrencyRupeeIcon /> {totalCost}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center mb-4">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="text-slate-800 ml-3"
                          >
                            Towing Service
                          </label>
                        </div>
                        <p className="w-11/12 m-auto text-xs">
                          If vehicle is not running condition we will be adding
                          towing services. Charges included seperately.
                        </p>
                        <div className="mt-7">
                          <button
                            onClick={() => {
                              let user = JSON.parse(
                                localStorage.getItem("user")
                              );
                              let track_details = {
                                username: `${user.firstName} ${user.lastName}`,
                                userId: user.userUid,
                                email: user.email,
                                page: window.location.pathname,
                                mechanic,
                                ongoingServices: onGoingService,
                                totalCost,
                              };

                              mixpanel.track("CANCEL_SERVICE", track_details);
                            }}
                            style={{ color: "#22C55D" }}
                            className="w-1/2 py-2 text-xs"
                          >
                            Cancel Service
                          </button>
                          <button
                            style={{ background: "#22C55D" }}
                            className="rounded text-xs w-1/2 py-2 text-white"
                            onClick={() => {
                              setShow(true);
                              setService(true);
                            }}
                          >
                            Continue Service
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MechanicDetails;
