import React, { useState } from 'react'
import Picker from 'emoji-picker-react'
import InputEmoji from 'react-input-emoji'
import EmojiPicker from 'react-emoji-picker' 
import { BsEmojiSmile } from "react-icons/bs";
import { FaMicrophone } from "react-icons/fa";
import { CgAttachment } from "react-icons/cg";
import { IoMdSend } from "react-icons/io";




const ChatFooter = () => {
    const [message, setMessage] = useState("");
    const [showEmoji,setShowEmoji]=useState(false);
    function handleEmojiSelect(emoji){
        console.log(emoji)
        //setMessage(((prevMsg)=>prevMsg+emoji));
        console.log(message)
    }
    return (
    <div className=' bg-white flex justify-between w-full py-2 border-t-[0.76px] border-t-[#DFDFDF] md:px-4 md:justify-between'>
        <div className='p-1 w-2/3 flex justify-start items-center'>
        <BsEmojiSmile className='text-[#A0A0A0]' onClick={()=>setShowEmoji((val)=>!val)}/>
        {/*{showEmoji && 
        <EmojiPicker onEmojiSelect={handleEmojiSelect}/>}*/}
        <input
        type='text'
        value={message} 
        onChange={(e)=>setMessage(e.target.value)} 
        placeholder='Type Message'
        className='pl-2 w-full md:text-md' />
        </div>
        <div className='w-1/3 flex justify-center gap-2 items-center md:justify-end md:text-md'>
        <FaMicrophone  className='text-[#A0A0A0] '/>
        <CgAttachment className='text-[#A0A0A0] '/>
        <button className='flex items-center bg-[#22C55D] text-white p-1.5 text-sm rounded-md gap-1 md:text-lg'>
            Send
            <IoMdSend />
        </button>
        </div>
      
    </div>
  )
}

export default ChatFooter
