import React, { forwardRef, useState } from "react";
//import all from '../data/Vehicle';
import VehicleIcon from "./VehicleIcon";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const VehicleSelector = forwardRef((props,ref) => {
  const [brandSearch, setBrandSearch] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const [selectionDone, setSelectionDone] = useState(false);

  const [brandSelected, setBrandSelected] = useState(null);
  const [modelSelected, setModelSelected] = useState([]);
  const [fuellist, setFuellist]=useState([]);
  // console.log("MS",modelSelected);
  const [fuelSelected, setFuelSelected] = useState(null);

  const [vehicleData, setVehicleData] = useState(
    JSON.parse(localStorage.getItem("vehicle-selected"))
      ? JSON.parse(localStorage.getItem("vehicle-selected"))
      : []
  );
  
  const [brands, setBrands] = useState(() => {
    try {
      const storedBrands = localStorage.getItem("user-brands");
      if (storedBrands) {
        return JSON.parse(storedBrands);
      }
    } catch (error) {
      console.error("Error parsing stored brands:", error);
    }
    return []; // Fallback to empty array if there's an error
  });
  
  // const [brands, setBrands] = useState(() => {
  //   const storedBrands = localStorage.getItem("user-brands");
  //   return storedBrands ? JSON.parse(storedBrands) : [];
  // });
  
  // const [brands,setBrands]=useState([]);
  // const [brands, setBrands] = useState(
  //   JSON.parse(localStorage.getItem("user-brands"))
  //     ? JSON.parse(localStorage.getItem("user-brands"))
  //     : []
  // );

  const [vehicles, setVehicles] = useState(
    JSON.parse(localStorage.getItem("user-vehicles"))
      ? JSON.parse(localStorage.getItem("user-vehicles"))
      : []
  );

  const [fuels, setFuels] = useState(
    JSON.parse(localStorage.getItem("user-vehicles-fuels"))
      ? JSON.parse(localStorage.getItem("user-vehicles-fuels"))
      : []
  );

  const [copyData, setCopyData] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (brands.length < 1) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    // setLoading(true);
    
  }, [brands]);

  // console.log(loading);

  useEffect(() => {
    fetch(`${SERVER_URL}/brand/getAll`)
      .then((response) => {
       
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("brrrrrrr ",data);
        const initialData = data?.data;
        console.log("brand base",data);
        // console.log(initialData,"iniiiiii");
        localStorage.setItem("user-brands",JSON.stringify(initialData));
        
        setBrands(initialData);
       
      })
      .catch((error) => console.error(error));

    // fetch(`${SERVER_URL}/model/getAll`)
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error(`Network response was not ok: ${response.status}`);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
        
    //     const initialData = data?.models;
    //     // console.log(initialData);
       
    //     console.log("model ",data);
    //     localStorage.setItem("user-vehicles", JSON.stringify(initialData));
    //     setVehicles(initialData);
        
    //   })
    //   .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("vehicle-selected"));

    
      
    if (data && data.brand) {
      setVehicleData(data);
      setCopyData(data);
      setSelectionDone(true);
      setMenu(false);
    }
  }, []);

  const getModel= async (brand)=>{
    const brand_id=brand._id;
    try{
      const response=await fetch(`${SERVER_URL}/model/consumer/get-by-brand?brandId=${brand_id}`);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      const result = await response.json();
      const modelData=result?.models;
      
      setModelSelected(modelData);
      console.log("setmode ",modelData);
      console.log(result,"models new is there ");
    }
    catch (error) {
      // If there was an error, set the error state
      console.log(error.message);
  }
}

  const [placeholder, setPlaceholder] = useState([
    "Search Brand",
    "Search Model",
    "Search Fuel",
  ]);

  const [count, setCount] = useState(0);

  const [menu, setMenu] = useState(true);

  function handleBackPress() {
    setBrandSearch("");
   
    if (count === 0) 
      {
        
        setMenu(false);
      }
    if (count > 0) 
      {
        if(count===2)
        {
          handleBrandClick(brandSelected);
          setCount(1);
        }
        else
        setCount(count-1);
        
      }
  }

  const handleBrandClick = (brand) => {
    console.log("brand clecked is ", brand);
    getModel(brand);
    setBrandSearch("");
    setBrandSelected(brand);
    setVehicleData({ ...vehicleData, brand: brand });
    setFuelSelected(null);
    setCount(count + 1);
   
  };

  const handleModelClick = (model) => {
    console.log("model clicked fine");
    setBrandSearch("");
    console.log("model selected is ",model);
    const fuellists=model.fuelType;
    setFuellist(fuellists);
    setModelSelected(model);
    setVehicleData({ ...vehicleData, model: model });
    setCount(count + 1);
  };

  const handleFuelClick = (fuel) => {
    
    setFuelSelected(fuel);
    setSelectionDone(true);
    setMenu(false);
    setCount(0);
    setVehicleData({ ...vehicleData, fuel: fuel });
    setCopyData({
      brand: brandSelected,
      model: modelSelected,
      fuel: fuel,
    });
  };
  useEffect(() => {
    localStorage.setItem("vehicle-selected", JSON.stringify(vehicleData));
    
  }, [vehicleData]);

  // console.log("brands", brandSelected?.models);
  const handleRemoveSelection = () => {
    setBrandSelected(null);
    setModelSelected(null);
    setFuelSelected(null);
    setSelectionDone(false);
    setCopyData({});
    setCount(0);
    setVehicleData({});
    localStorage.removeItem("vehicle-selected");
    
  };


  // console.log("brand data",brandSelected, modelSelected, fuelSelected);
  // console.log("model data", modelSelected);
  // console.log("fuel data",fuelSelected);
  return (
    <div className="p-4 w-full">
      <div className=" flex flex-col rounded-md w-full flex-grow border-red-600">
        <div className="flex items-center space-x-2 px-2 py-2">
          <AiOutlineArrowLeft size={20} onClick={() => handleBackPress()} />
          <input
            type="text"
            value={brandSearch}
            className="px-3 py-2 w-full"
            placeholder={placeholder[count]}
            onChange={(e) => setBrandSearch(e.target.value)}
            onClick={() => setMenu(true)}
            ref={ref}
          />
        </div>
        {menu &&
          (!loading ? (
            count === 0 ? (
              <div className="flex h-[200px] overflow-auto flex-col">
                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-3 p-3 gap-5">
                  {brands.map((brand) =>
                    brand.name
                      ?.toLowerCase()
                      .includes(brandSearch?.toLowerCase()) ? (
                      <div
                        key={brand._id}
                        onClick={() => handleBrandClick(brand)}
                      >
                        <VehicleIcon name={brand.name} icon={brand.logo} />
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            ) : count === 1 ? (
              <div className="flex h-[200px] overflow-auto flex-col">
                <div className="flex flex-row">
                  {/* {brandSelected &&
                    brandSelected.models.map((model, i) =>
                      model.name
                        ?.toLowerCase()
                        .includes(brandSearch?.toLowerCase()) ? (
                        <div key={i} onClick={() => handleModelClick(model)}>
                          <VehicleIcon name={model.name} icon={model.photo} />
                        </div>
                      ) : null
                    )} */}
                    {Array.isArray(modelSelected) && modelSelected.map((model,i)=>{
                       
                       return (
                        <div key={i} className="flex flex-col mr-4 cursor-pointer " onClick={() => handleModelClick(model)}>
                          <img src={model.photo} alt={model.name} className="w-20 h-20 object-cover mb-2" />
                          <p className="text-lg font-medium">{model.name}</p>
                        </div>
                       )
                        
                       
                      })}
                </div>
              </div>
            ) : count === 2 ? (
              <div className="flex h-[200px] overflow-auto flex-col">
                <div className="flex flex-row">
                  {/* {modelSelected &&
                    modelSelected.fuelType &&
                    [modelSelected.fuelType].map((fuelType, i) =>
                      fuelType
                        ?.toLowerCase()
                        .includes(brandSearch?.toLowerCase()) ? (
                        <div key={i} onClick={() => handleFuelClick(fuelType)}>
                          <VehicleIcon
                            name={ fuelType }
                            icon={`./${fuelType.toLowerCase()}.jpg`}
                          />
                        </div>
                      ) : null
                    )} */}
                      {fuellist.map((fuel,i)=>{
                       
                       return (
                        <div key={i} className="flex flex-col mr-4 cursor-pointer " onClick={() => handleFuelClick(fuel.fuelType)}>
                          <img src={fuel.icon} alt={fuel.icon} className="w-20 h-20 object-cover mb-2" />
                          <p className="text-lg font-medium">{fuel.fuelType}</p>
                        </div>
                       )
                        
                       
                      })}

                </div>
              </div>
            ) : null
          ) : null)}
      </div>

      {selectionDone &&
        (fuelSelected ? (
          <div
            className="border border-gray-300 rounded-lg overflow-hidden shadow-lg my-4 p-4 bg-white relative"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="flex justify-between items-center mb-4">
              <p className="font-semibold text-lg">Selected Car:</p>
              {isHovered && (
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="cursor-pointer text-red-500"
                  onClick={handleRemoveSelection}
                  title={isHovered ? "Remove Selected Car" : ""}
                />
              )}
            </div>
            <div className="grid grid-cols-5 gap-1">
              <div className="col-span-3 flex w-full justify-center items-center">
                <img alt="" src={modelSelected.photo} />
              </div>
              <div className="col-span-2 flex w-full justify-center items-center">
                <div className="flex flex-col  justify-center">
                  <p>
                    <span className="font-semibold">Brand:</span>{" "}
                    {brandSelected.name}
                  </p>
                  <p>
                    <span className="font-semibold">Model:</span>{" "}
                    {modelSelected.name}
                  </p>
                  <p>
                    <span className="font-semibold">Fuel:</span> 
                    {fuelSelected}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : copyData.fuel || (selectionDone && copyData.fuel) ? (
          <div
            className="border border-gray-300 rounded-lg overflow-hidden shadow-lg my-4 p-4 bg-white relative"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="flex justify-between items-center mb-4">
              <p className="font-semibold text-lg">Selected Car:</p>
              {isHovered && (
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="cursor-pointer text-red-500"
                  onClick={handleRemoveSelection}
                />
              )}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
              <div className="col-span-1 md:col-span-3 flex justify-center items-center">
                <img
                  className="max-w-full h-auto rounded-md"
                  src={copyData.model.photo}
                  alt={copyData.model.name}
                />
              </div>
              <div className="col-span-1 md:col-span-2 flex flex-col justify-center">
                <p className="mb-2">
                  <span className="font-semibold">Brand:</span>{" "}
                  {copyData.brand.name}
                </p>
                <p className="mb-2">
                  <span className="font-semibold">Model:</span>{" "}
                  {copyData.model.name}
                </p>
                <p>
                  <span className="font-semibold">Fuel:</span> {copyData.fuel}
                </p>
              </div>
            </div>
          </div>
        ) : null)}
    </div>
  );
});

export default VehicleSelector;
