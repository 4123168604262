import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const ProtectedRoutes = () => {
  const token = localStorage.getItem('TOKEN');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      // Redirect to the login page if there's no valid token
      navigate('/LoginPage');
    } else {
      
      const handleBackArrow = (event) => {
        // Check if the user is trying to navigate back
        if (event.type === 'popstate') {
          // Use navigate to go back to the previous page
          navigate(-1);
        }
      };

      window.addEventListener('popstate', handleBackArrow);

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('popstate', handleBackArrow);
      };
    }
  }, [token, navigate]);

  if (token) {
    return <Outlet />;
  }

  return null;
};

export default ProtectedRoutes;
