import React, { useEffect, useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import faq from "../data/Faq";

export default function Faq() {
  const [open, setOpen] = useState("");
  const [isOpened, setIsOpened] = useState(false);
  const titleControl = useAnimation();
  const [titleRef, titleinView] = useInView();

  useEffect(() => {
    if (titleinView) {
      titleControl.start("visible");
    } else {
      titleControl.start("hidden");
    }
  }, [titleControl, titleinView]);

  const titleVariants = {
    hidden: { opacity: 1, scale: 1, x: "100%" },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.0 }, x: "0%" },
  };

  const openHandler = (index) => {
    if (open === index) {
      setOpen("");
      setIsOpened(!isOpened);
    }
    setOpen(index);
  };

  return (
    <div className="flex flex-col px-4  md:px-8 mt-8  sm:mb-4 md:mb-6 lg:mb-8">
      <motion.div
        ref={titleRef}
        variants={titleVariants}
        initial="hidden"
        animate={titleControl}
        className="text-center mb-4"
        style={{ fontFamily: "poppins", color: "#333" }}
      >
        <h1 className="text-4xl font-bold">
          Frequently Asked Questions
        </h1>
      </motion.div>

      <div className="rounded-md p-4 px-2 sm:p-2 md:p-3 lg:p-4">
        {faq.map((item, index) => (
          <div className="mb-5 bg-white text-black" key={index}>
            <div
              className="bg-white text-black flex w-full h-16 sm:h-12 px-4 sm:px-2 md:px-3 lg:px-4 border-black border font-semibold text-md lg:text-base items-center justify-between cursor-pointer"
              onClick={() => openHandler(index)}
            >
              {item.title}
              {open === index && isOpened === true ? (
                <MdExpandLess />
              ) : (
                <MdExpandMore />
              )}
            </div>
            <div
              className="flex bg-white text-black border-x border-b border-black w-full h-auto mb-1 pt-1 pb-2 px-1 sm:px-2 md:px-3 lg:px-4 text-sm lg:text-base text-justify" // Adjusted lg:text-lg
              style={{
                display: open === index && isOpened === true ? "block" : "none",
              }}
            >
              {item.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
