import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import download from '../../src/assets/download/download.png';

const DownloadApp = () => {
  const [sliderRef, setSliderRef] = useState(null);
  const titleControl = useAnimation();
  const [titleRef, titleinView] = useInView();

  useEffect(() => {
    if (titleinView) {
      titleControl.start('visible');
    } else {
      titleControl.start('hidden');
    }
  }, [titleControl, titleinView]);

  const titleVariants = {
    hidden: { opacity: 1, scale: 1, x: '-100%' },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.0 }, x: '0%' },
  };

  return (
    <div className='mt-10 lg:mt-20 px-4 mb-8'>
      <motion.div
        variants={titleVariants}
        ref={titleRef}
        initial='hidden'
        animate={titleControl}
      >
        <p className='text-3xl md:text-4xl font-bold font-sans text-center'>
          Download the
          <div className='p-6 lg:mb-20'>HelpyMoto App</div>
        </p>
      </motion.div>
      <div className='flex flex-col md:flex-row px-4 bg-sky-100 relative border-2 border-solid w-11/12 mx-auto rounded-md py-2'>
        <div className='flex justify-center col-span-4 md:col-span-1 lg:col-span-1'>
          <img src={download} alt="" className='rounded-2xl mb-4 md:mb-0 md:-mt-32' />
        </div>
        <div className='flex flex-col items-center p-0 md:p-8 gap-8 md:w-1/2'>
          <h1 className='text-3xl font-bold text-center text-black p-3'>Customer App</h1>
          <p className='text-lg font-poppins text-center font-medium'>
            Choose and book a seamless car service experience and get up to Rs 750 off with the HelpyMoto App
          </p>
          <div className='grid grid-cols-2 gap-4 py-3'>
            <div className='cursor-pointer'>
              <img src="appstore.png" alt="" className='h-[60px] object-contain' />
            </div>
            <div className='cursor-pointer'>
              <img src="playstore.png" alt="" className='h-[55px] object-contain' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
