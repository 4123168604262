import React, { useState } from "react";
import TrackPageNavbar from "../components/TrackPageNavbar";
import { handleLogout } from "../utils/authUtils";


const Settings = () => {
  const [currentPhone, setCurrentPhone] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [verifyPhone, setVerifyPhone] = useState("");

  const handleInputChange = (e, setter) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
      setter(inputValue);
    }
  };
  const handleLogoutClick= async ()=>{
    await handleLogout();
  }
  return (
    <div className="flex flex-col mb-4">
      <TrackPageNavbar showOnlyNav={false} />
      <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
    <p class="text-sm text-gray-500 font-bold">
    <a href="/" class="hover:text-black hover:cursor-pointer">Profile</a> 
              <span class="text-black"> / </span>
                <span class="text-black">Settings</span>
                
    </p>
</div>

      <div className="max-w-8xl mx-4 md:mx-14">
        <div className="grid lg:grid-cols-7 gap-1">
          <div className="lg:col-span-4 flex justify-between items-center">
            <h2 className="text-xl sm:text-3xl mt-2 sm:font-extraboldtext-xl font-bold max-w-max relative after:h-[2px] after:bg-green-400 after:mt-0.5 after:w-[40px] after:flex after:absolute after:right-0">
              Settings
            </h2>
          </div>
        </div>
        <div className="grid lg:grid-cols-8 gap-4 mt-2">
          <div className="lg:col-span-3">
            <div className="mt-1 md:mt-4">
              <h1 className="font-semibold text-base text-black sm:text-xl sm:font-bold">
                Account Settings
              </h1>
              <h2 className="text-md lg:w-2/3 text-gray-500">
                Update your email by providing your current email and entering a
                new one
              </h2>
            </div>
          </div>
          <div className="bg-white h-max rounded-md p-2 md:p-6 border border-gray-300 lg:col-span-5">
            <div className="px-2 md:px-4">
              <div className="mb-4 flex flex-col sm:flex-row items-center">
                <label
                  htmlFor="current_phone"
                  className="block text-sm font-semibold text-gray-700  w-full sm:w-1/3"
                >
                  Current Phone Number
                </label>
                <input
                  type="text"
                  id="current_phone"
                  name="current_phone"
                  placeholder="10 digit no"
                  value={currentPhone}
                  onChange={(e) => handleInputChange(e, setCurrentPhone)}
                  className="mt-1 sm:ml-4 sm:mt-0 p-2 w-full sm:w-2/3 border border-gray-300"
                />
              </div>
              <div className="mb-4 flex flex-col sm:flex-row items-center">
                <label
                  htmlFor="new_phone"
                  className="block text-sm font-semibold text-gray-700 w-full sm:w-1/3"
                >
                  New Phone Number
                </label>
                <input
                  type="text"
                  id="new_phone"
                  name="new_phone"
                  placeholder="10 digit no"
                  value={newPhone}
                  onChange={(e) => handleInputChange(e, setNewPhone)}
                  className="mt-1 sm:ml-4 sm:mt-0 p-2 w-full sm:w-2/3 border border-gray-300"
                />
              </div>
              <div className="mb-4 flex flex-col sm:flex-row items-center">
                <label
                  htmlFor="verify_phone"
                  className="block text-sm font-semibold text-gray-700 w-full sm:w-1/3"
                >
                  Verify Phone Number
                </label>
                <input
                  type="text"
                  id="verify_phone"
                  name="verify_phone"
                  placeholder="10 digit no"
                  value={verifyPhone}
                  onChange={(e) => handleInputChange(e, setVerifyPhone)}
                  className="mt-1 sm:ml-4 sm:mt-0 p-2 w-full sm:w-2/3 border border-gray-300"
                />
              </div>
              <div className="flex justify-end">
                <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">
                  Update
                </button>
              </div>
            </div>
          </div>

        </div>
        <div className="grid lg:grid-cols-8 gap-4 mt-10">
          <div className="lg:col-span-3">
            <div className="mt-1 md:mt-4">
              <h1 className="font-semibold text-base text-black sm:text-xl sm:font-bold">
                Login Sessions
              </h1>
              <h2 className="text-md lg:w-4/5 text-gray-500">
                Places and devices where you logged into the app
              </h2>
            </div>
          </div>
          <div className="bg-white h-max rounded-md p-2 md:p-6 border border-gray-300 lg:col-span-5">
            <div className="px-2 md:px-6">
              <ul>
                <li className="flex flex-col md:flex-row items-start justify-between py-2 border-b border-gray-200">
                  <div className="flex items-start">
                    <svg
                      className="w-6 h-6 mt-2 text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <div className="ml-2">
                      <p className="text-md font-semibold text-gray-900">
                        Chrome on window 10
                      </p>
                      <p className="text-sm text-gray-600">
                        10 Jan 2024 / 10:30AM
                      </p>
                      <p className="text-sm text-gray-600">Chennai, India</p>
                    </div>
                  </div>
                  <a
                    href="#"
                    className="bg-white border border-black text-black py-2 px-4 rounded mt-2 md:mt-0 ml-8 md:ml-2 hover:bg-red-500 hover:text-white"
                    onClick={handleLogoutClick}
                  >
                    Log Out
                  </a>
                </li>
                <li className="flex flex-col md:flex-row items-start justify-between py-2 border-gray-200 mb-4">
                  <div className="flex items-start">
                    <svg
                      className="w-6 h-6 mt-2 text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <div className="ml-2">
                      <p className="text-md font-semibold text-gray-800">
                        Chrome on Vivo Phone
                      </p>
                      <p className="text-sm text-gray-600">
                        10 Jan 2024 / 10:30AM
                      </p>
                      <p className="text-sm text-gray-600">Chennai, India</p>
                    </div>
                  </div>
                  <a
                    href="#"
                    onClick={handleLogoutClick}
                    className="bg-white border border-black text-black py-2 px-4 rounded mt-2 md:mt-0 ml-8 md:ml-2 hover:bg-red-500 hover:text-white"
                  >
                    Log Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="grid mt-6">
          <div className="ml-2">
            <h1 className="font-semibold text-base text-black sm:text-xl sm:font-bold">
              Delete Account
            </h1>
            <h2 className="text-md lg:w-4/5 text-gray-500">
              If you no longer wish to use helpymoto, You can permanently delete
              your account.
            </h2>
          </div>
        </div>
        <button className="bg-red-500 border text-white ml-2 mt-4 py-2 px-4 rounded">
          Deactivate Account
        </button>
      </div>
    </div>
  );
};

export default Settings;
