import mixpanel from "mixpanel-browser";
const TOKEN = process.env.REACT_APP_MIXPANEL

mixpanel.init(TOKEN,{
    debug: true
});


export default mixpanel;

