import React, { useState, useContext, useEffect } from 'react';
import NavbarUpdate from '../components/NavbarUpdate';
import '../styles/navbar.css';
import Footer from '../components/Footer';



const PrivacyPolicy = () => {

  return (
    <div>
      <div className='flex flex-col w-full h-screen'>
        <NavbarUpdate showOnlyNav={true} />
        <div className='p-4'>
          <div className='grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3'>
            { /* Cart Section */ }
          </div>
        </div>
      </div> 
      <Footer/>
    </div>
    
  );
};

export default PrivacyPolicy;