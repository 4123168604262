
import React,{useState,useEffect, useRef} from "react";
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from "@chatscope/chat-ui-kit-react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import axios from "axios";
import BackspaceIcon from '@mui/icons-material/Backspace';

const ChatScreen=()=>{

    // const [firstscreen,setFirstscreen]=useState(true);
    const [secondscreen,setSecondscreen]=useState(true);
    const [aiscreen, setAiScreen]=useState(false);
    const [supportscreen, setSupportscreen]=useState(false);
    const [token, setToken] = useState();
  const [visibl, setvisible]=useState(false)
  const [showChatBox,setShowChatBox] = useState(false);
  const [typing,setTyping] = useState(false);
  const [messages,setMessages] = useState([]);
  // const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API);
  const genAI = new GoogleGenerativeAI("AIzaSyAfVhTUM8MKoviJkPEavSD69v1YqeuVv-8");
  const [userMessage, setUserMessage] = useState(''); // User input message
  const [botResponse, setBotResponse] = useState(''); 

  
  const date = new Date();
  const showTime =  String(date.getHours()).padStart(2, '0') + ':' +
                    String(date.getMinutes()).padStart(2, '0') ;
  
  

  const handleSend = async (message)=>{
    
    // let newMessage = {
    //   message:message,
    //   sender:"user",
    //   direction: 'outgoing',
    // };
    
    
    // let newMessages = [...messages,newMessage];
    // setMessages(newMessages);
    // setTyping(true);
    
    // const model = genAI.getGenerativeModel({ model: "gemini-pro"});
    
    // const prompt = message;
    // const result = await model.generateContent(prompt);
    // const response = await result.response;

    // // console.log("Result of the Given Prompt is ",response);
    // console.log("Result of the Given Prompt is ",response.text());

    // setTyping(false);
    // let text = {
    //   message: response.text(),
    //   sender: "GEMINI",
    //   direction:'incoming',
    // };
    // const promptMessages = [...messages,newMessage,text];
    // setMessages(promptMessages);

    // let user = JSON.parse(localStorage.getItem('user'))
    // let url = 'http://localhost:5000/api/v1/add/query';

    // axios({
    //   method: "POST",
    //   url,
    //   data:{
    //     query: message,
    //     response: text.message,
    //     userId: user?._id
    //   },
    //   withCredentials: true,
    //   headers:{
    //     'Content-Type':'application/json',
    //   }
    // })
    // .then((data)=>{
    //   console.log(data);
    // })
    // .catch((err)=>{
    //   console.log(err);
    // })
    // try {
    //   const res = await fetch('https://service-provider-apis.onrender.com/api/v1/bot/askbot', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ userId: '6527b9c2bf176b8fd3674def', prompt: userMessage }), 
    //   });

    //   const data = await res.json();
    //   console.log(data,"response from AI is");
    //   setBotResponse(data.text); // Assuming the response has a 'text' field
    // } catch (error) {
    //   console.error('Error:', error);
    // }
    let newMessages = [
      ...messages,
      {
        message: message,
        sender: "user",
        direction: 'outgoing',
      }
    ];
    setMessages(newMessages);
    setUserMessage(''); 
    
    setTyping(true);
  try {
    const res = await fetch('https://service-provider-apis.onrender.com/api/v1/bot/askbot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        userId: '6527b9c2bf176b8fd3674def',
        prompt: message  
      }),
    });

    const data = await res.json();
    console.log("Response from AI is", data);
    
    newMessages = [
      ...newMessages,
      {
        message: data.text, 
        sender: "Helpy AI",
        direction: 'incoming',
      }
    ];
   
    setMessages(newMessages);
    console.log("message is ",messages);
    setTyping(false);
  } catch (error) {
    console.error('Error:', error);
    setTyping(false);
  }
    
  }
  const handleChatClick = (event) => {
    setvisible(true)
    event.stopPropagation();
    setShowChatBox(prevState => !prevState);
  };


    return (
        
        <div>
          {/* {firstscreen && (
            <div className="w-[400px] h-[500px] bg-gray-100 rounded-md flex justify-center items-center relative">
              <div className="flex flex-col justify-center items-center cursor-pointer">
                <img
                  src="/Component11.png"
                  alt="Image"
                  onClick={() => {
                    setFirstscreen(false);
                    setSecondscreen(true);
                  }}
                />
              </div>
              </div>
          )} */}
          {secondscreen && (
            <div className="w-[400px] h-[500px]  rounded-md flex justify-center items-center relative">
           
           
            <div className="flex flex-col">
              <div className="flex justify-center items-center cursor-pointer ">
                <img
                  className="w-12 h-12 absolute bottom-14 right-4 "
                  src="/Frame32.png"
                  alt="Image"
                  style={{ transform: "rotate(0deg) translate(50px) rotate(-0deg)" }}
                  onClick={() => {
                    setAiScreen(true);
                    setSecondscreen(false);

                  }}
                />
              </div>
              <div className="flex flex-row">
              <div className="flex  justify-center items-center cursor-pointer">
                <img

                  className="w-12 h-12 absolute bottom-14 right-4  "  // Set the icon size
                  style={{ transform: "rotate(100deg) translate(50px) rotate(-120deg)" }}
                  src="/Frame33.png"
                  alt="Image"
                  onClick={() => {
                    // setFirstscreen(false)
                    setSupportscreen(true);
                    setSecondscreen(false);
                  }}
                />
              </div>
              {/* <div className="flex justify-center items-center cursor-pointer">
                <img
                  src="/Component11.png"
                  alt="Image"
                  
                  onClick={() => {
                    setFirstscreen(true)
                    setSecondscreen(false);
                  }  
                }
                />
              </div> */}
             
              </div>
            </div>


            
            </div>
            
          )}
        
          {aiscreen && (
                  <div className="w-[400px] h-[500px] bg-gray-100 rounded-md">
                    <div className="w-full h-12 rounded-md flex flex-row justify-between items-center text-white bg-black mb-1">
                     <div className="flex flex-row justify-center items-center">
                         <img
                         src="/Frame32.png"
                         alt="Image"
                         className="w-10 h-10 ml-3"
                         />
                            <div className="ml-3">
                              Chat with Helpy AI !
                            </div>
                       </div>
                           <div className="text-white text-lg items-end mr-3 cursor-pointer" onClick={()=>{
                             setAiScreen(false);
                             }}>
                             X
                           </div>
                     </div>
                
                
                {/* <div>
                                <MainContainer  style={{display:"flex", flexDirection:"column"}} className="">
                                  <div className="cancelbutton">
                                    <button onClick={handleChatClick}><BackspaceIcon style={{color:"red"}}/></button>
                                  </div>
                                  <ChatContainer >
                                      <MessageList
                                        typingIndicator={typing ? <TypingIndicator content="Gemini is Typing..." />: null}
                                        style={{ height:"300px",backgroundColor:"rgb(243, 244, 246)", border:"none" }}
                                        
                                    >
                                      {
                                          messages.map((message,index)=>{
                                            {console.log("msg",message)}
                                           return <Message key={index} model={message} />
                                          })
                                          
                                       }
                                     </MessageList>
                                     
        
                                      <MessageInput   placeholder="Enter your query..." onSend={handleSend}/>
                                      
                              </ChatContainer>
                                </MainContainer>
                                

                                
                         </div> */}
                         <div className="w-full h-[400px] bg-gray-100 flex flex-col relative">
                                   
                             <div className=" overflow-y-auto p-2">
                                  <div className="px-4 border-0 mb-2">
                                       <div className="p-4 rounded-2xl font-thin text-base shadow-lg">
                                           Hi there i'm Helpy AI. A bot working for <br></br>
                                           HELPY MOTO. How can i help you today.
                                        </div>
                
                                    </div>    
                                   <div className="flex-1 overflow-y-auto p-2 space-y-2">
                                    {messages.map((msg, index) => (
                                       <div
                                         key={index}
                                         className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'} space-x-2`}
                                       >
                                        <div className="flex flex-col ">
                                     <div
                                       className={`p-2 rounded-lg max-w-xs ${msg.sender === 'user' ? 'bg-gray-200 text-black' : 'bg-green-300 text-black'}`}
                                     >
                                    
                                      {msg.message}
                                      
                                     </div>
                                     <div className={`text-xs ${msg.sender==='user'?`text-end`:`text-start` }`}>
                                     {showTime}
                                      </div>
                                     </div>
                                     
                                  </div>
                                  
                                 ))}
                                 
                              {typing && <div className="p-2 rounded-lg max-w-xs bg-green-300 text-black">
                                      Gemini is typing...
                                    </div>}
                              </div>
                            </div>   
                                
                            </div>  
                              

                              <div className="w-full border-none border-t-2">
                               <div className="flex p-2  w-full  bg-gray-100">
                                 <input
                                    type="text"
                                    value={userMessage}
                                    className="flex-grow p-2 bg-gray-100 rounded h-10 mr-2 outline-none"
                                    placeholder="Type your message..."
                                    onChange={(e)=>setUserMessage(e.target.value)}
                                  />
                                   <div className="w-10 h-10 p-1 bg-green-600 text-white rounded-full cursor-pointer" style={{ transform: 'rotate(320deg)' }}>
                                  <img src="/Frame37.png" alt="send"  className="w-full h-full filter invert brightness-0" onClick={()=>handleSend(userMessage)}></img>
                                    </div>
                                </div>
                             </div>
                          </div>

                     
                
            )
          }

               {supportscreen && (
                  <div className="w-[400px] h-[500px] bg-gray-100 rounded-md">
                    <div className="w-full h-12 rounded-md flex flex-row justify-between items-center text-white bg-black mb-1">
                     <div className="flex flex-row justify-center items-center">
                         <img
                         src="/Frame33.png"
                         alt="Image"
                         className="w-10 h-10 ml-3"
                         />
                     <div className="ml-3">
                     Chat with team !
                     </div>
                    </div>
                 <div className="text-white text-lg items-end mr-3 cursor-pointer" onClick={()=>{
                    setSupportscreen(false);
                 }}>
                    X
                </div>
                </div>
                   
                
                {/* <div>
                                <MainContainer  style={{display:"flex", flexDirection:"column"}} className="">
                                  <div className="cancelbutton">
                                    <button onClick={handleChatClick}><BackspaceIcon style={{color:"red"}}/></button>
                                  </div>
                                  <ChatContainer >
                                      <MessageList
                                        typingIndicator={typing ? <TypingIndicator content="Gemini is Typing..." />: null}
                                        style={{ height:"300px",backgroundColor:"rgb(243, 244, 246)", border:"none" }}
                                        
                                    >
                                      {
                                          messages.map((message,index)=>{
                                           {console.log("msg",message)}
                                           return <Message key={index} model={message} />
                                          })
                                       }
                                     </MessageList>
        
                                      <MessageInput   placeholder="Enter your query..." onSend={handleSend}/>
                                      
                              </ChatContainer>
                                </MainContainer>
                         </div> */}
                         <div className="w-full h-[400px] bg-gray-100 flex flex-col relative">
                                   
                                   <div className=" overflow-y-auto p-2">
                                      <div className="px-4 border-0 mb-2">
                                        <div className="p-4 rounded-2xl font-thin text-base shadow-lg">
                                           Hi there i'm <strong className="font-bold">Rajesh</strong> support team HELPY
                                          MOTO. How can i help you today.
                                        </div>
                                      </div>     
                                         <div className="flex-1 overflow-y-auto p-2 space-y-2">
                                          {messages.map((msg, index) => (
                                             <div
                                               key={index}
                                               className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'} space-x-2`}
                                             >
                                            <div className="flex flex-col ">
                                     <div
                                       className={`p-2 rounded-lg max-w-xs ${msg.sender === 'user' ? 'bg-gray-200 text-black' : 'bg-green-300 text-black'}`}
                                     >
                                    
                                      {msg.message}
                                      
                                     </div>
                                     <div className={`text-xs font-sans ${msg.sender==='user'?`text-end`:`text-start` }`}>
                                     {showTime}
                                      </div>
                                     </div>
                                        </div>
                                       ))}
                                    {typing && <div className="p-2 rounded-lg max-w-xs bg-green-300 text-black">
                                            Gemini is typing...
                                          </div>}
                                    </div>
                                  </div>   
                                      
                                  </div>  
                                    
      
                                    <div className="w-full border-none border-t-2">
                                     <div className="flex p-2  w-full  bg-gray-100">
                                       <input
                                          type="text"
                                          value={userMessage}
                                          className="flex-grow p-2 bg-gray-100 rounded h-10 mr-2 outline-none"
                                          placeholder="Type your message..."
                                          onChange={(e)=>setUserMessage(e.target.value)}
                                        />
                                         <div className="w-10 h-10 p-1 bg-green-600 text-white rounded-full cursor-pointer" style={{ transform: 'rotate(320deg)' }}>
                                        <img src="/Frame37.png" alt="send"  className="w-full h-full filter invert brightness-0" onClick={()=>handleSend(userMessage)}></img>
                                          </div>
                                      </div>
                                   </div>


                </div>
                
            )
          }

          </div>
        
      );
    }      
export default ChatScreen;