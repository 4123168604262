import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "./components/Box";
import DownloadApp from "./components/DownloadApp";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import Form from "./components/Form";
import NavbarUpdate from "./components/NavbarUpdate";
import Services from "./components/Services";
import WhyService from "./components/WhyService";
import Works from "./components/Works";
import ServiceModal from "./components/ServiceModal";
import LoadingPage from "./components/LoadingPage";
import useFetch from "./hooks/useFetch";
import Cookies from "universal-cookie";
import axios from "axios";
import SERVER_URL from "./constants/constant";
import ServicePartner from "./components/ServicePartner";
import SubscriptionPlansContainer from "./components/SubscriptionPlansContainer";
import TestimonialCarousel from "./components/TestimonialCarousel.js";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import mixpanel from "./config/mixpanel";
import BackspaceIcon from '@mui/icons-material/Backspace';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from "@chatscope/chat-ui-kit-react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import ChatScreen from "./components/ChatScreen.js";

const tagManagerArgs = {
  gtmId: "GTM-57VFRXXK",
};

TagManager.initialize(tagManagerArgs);
ReactGA.initialize("G-KEQEE9N00L");

const cookies = new Cookies();

function App() {
  const [service, setService] = useState({
    show: false,
    id: null,
  });
 
  const [token, setToken] = useState();
  const [visibl, setvisible]=useState(false)
  const [showChatBox,setShowChatBox] = useState(false);
  const [typing,setTyping] = useState(false);
  const [messages,setMessages] = useState([]);
  // const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API);
  const genAI = new GoogleGenerativeAI("AIzaSyAfVhTUM8MKoviJkPEavSD69v1YqeuVv-8");

  const [parents, setParents] = useState(
    JSON.parse(localStorage.getItem("user-services-parents"))
      ? JSON.parse(localStorage.getItem("user-services-parents"))
      : []
  );

  //const token = cookies.get("TOKEN");
  

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Home Page",
    });

    const tokenVal = localStorage.getItem("TOKEN");
    
    // send page view data
    mixpanel.track("PAGE_VIEW", {
      page: window.location.pathname,
    });

    setToken(tokenVal);

    axios
      .get(`${SERVER_URL}/service/getAll-by-category`)
      .then((response) => {
        console.log("response of ap is",response);
        localStorage.setItem(
          "user-services",
          JSON.stringify(response?.data?.services)
        );
        localStorage.setItem(
          "user-services-parents",
          JSON.stringify(
            response.data?.services.filter(
              (service) => service?.parent === null
            )
          )
        );
        setParents(
          response.data?.services.filter((service) => service?.parent === null)
        );
      })
      .catch((error) => console.log(error));
  }, []);

  const { handleGoogle, loading, error } = useFetch(
    `${SERVER_URL}/user/auth/google/webcallback`
  );

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id:
          "853095200492-1ms2opskdpvb7ov9ltu5ip2singgr420.apps.googleusercontent.com",
        callback: handleGoogle,
      });

      if (!localStorage.getItem("TOKEN")) google.accounts.id.prompt();
    }
  }, [handleGoogle, token]);
  
  const handleSend = async (message)=>{
    console.log(message);
    let newMessage = {
      message:message,
      sender:"user",
      direction: 'outgoing',
    };
    
    
    let newMessages = [...messages,newMessage];
    setMessages(newMessages);
    setTyping(true);
    
    const model = genAI.getGenerativeModel({ model: "gemini-pro"});
    
    const prompt = message;
    const result = await model.generateContent(prompt);
    const response = await result.response;

    // console.log("Result of the Given Prompt is ",response);
    console.log("Result of the Given Prompt is ",response.text());

    setTyping(false);
    let text = {
      message: response.text(),
      sender: "GEMINI",
      direction:'incoming',
    };
    const promptMessages = [...messages,newMessage,text];
    setMessages(promptMessages);

    let user = JSON.parse(localStorage.getItem('user'))
    let url = 'http://localhost:5000/api/v1/add/query';

    axios({
      method: "POST",
      url,
      data:{
        query: message,
        response: text.message,
        userId: user?._id
      },
      withCredentials: true,
      headers:{
        'Content-Type':'application/json',
      }
    })
    .then((data)=>{
      console.log(data);
    })
    .catch((err)=>{
      console.log(err);
    })
    
  }
//  handle click pe ye ho rha h 
  //handles clicks both inside and outside the chatbox correctly
  const handleChatClick = (event) => {
    setvisible(true)
    event.stopPropagation();
    setShowChatBox(prevState => !prevState);
  };

  //Closes the popup when clicking outside of it.
  // const handleDocumentClick = () => {
  //   setShowChatBox(false);
  // };
  
  // if (showChatBox) {
  //   document.addEventListener('click', handleDocumentClick);
  // } else {
  //   document.removeEventListener('click', handleDocumentClick);
  // }

  return (
    <div className="flex w-full flex-col m-0 p-0">
      <NavbarUpdate showOnlyNav={false} />
      <div className="flex w-full pt-8 mb-4 justify-center md:hidden lg:hidden xl:hidden">
        <Link
          to={"/ontime"}
          className="bg-green-500 w-[35%] py-[2%] lg:py-[3%] text-center text-lg text-white rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold"
        >
          Hire Now
        </Link>
      </div>
      <div className="flex w-full justify-center md:hidden lg:hidden xl:hidden">
        <Form />
      </div>
      <Services parents={parents} setService={setService} />
      {service.show ? <ServiceModal setService={setService} /> : null}
      <Works />
      <ServicePartner />
      <div  className=" t right-0 fixed bottom-[10px] z-50">
        <div id="chatContainer" className=" h-fit flex justify-end items-end ">
            {
                // showChatBox && <div id="chatBoxVisibilty" className={`bg-red-300 w-[380px] h-[500px]  mr-[10px] ${visibl ? 'block':'hidden'}`}>
                //                 <MainContainer  style={{display:"flex", flexDirection:"column"}}className="chatContainer bg-red-400">
                //                   <div className="cancelbutton">
                //                     <button onClick={handleChatClick}><BackspaceIcon style={{color:"red"}}/></button>
                //                   </div>
                //                   <ChatContainer >
                //                       <MessageList
                //                         typingIndicator={typing ? <TypingIndicator content="Gemini is Typing..." />: null}
                //                       >
                //                         {
                //                           messages.map((message,index)=>{
                //                             return <Message key={index} model={message} />
                //                           })
                //                         }
                //                       </MessageList>
                //                       <MessageInput  placeholder="Enter your query..." onSend={handleSend}/>
                //                   </ChatContainer>
                //                 </MainContainer>
                //               </div>

                showChatBox && <ChatScreen />
              
            }
          <img  src="/Component11.png" 
            width='50' height='50' 
            className="chatBot cursor-pointer float-right h-[45px] w-[45px] mr-[20px]" 
            onClick={handleChatClick}
              alt="chat_Bot"
          />
        </div>
      </div>
      <WhyService />
      <TestimonialCarousel />
      <SubscriptionPlansContainer />
      <DownloadApp />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
