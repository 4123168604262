import React, { useState, useContext, useEffect } from "react";
import TrackPageNavbar from "../components/TrackPageNavbar";
import "../styles/navbar.css";
import Footer from "../components/Footer";
import tcLogo from "../assets/terms-and-conditions 3.png";

const Terms = () => {
  return (
    <>
      <TrackPageNavbar showOnlyNav={false} />
      <div className="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
        <p className="text-sm text-gray-500 font-bold">
        <a href="/" class="hover:text-black hover:cursor-pointer">profile</a> 
          <span className="text-black"> / </span>
          <span className="text-black"> Terms & conditions</span>
        </p>
      </div>
      <div className="px-4 ml-12 flex flex-col lg:flex-row justify-start items-center py-2 ">
        <img alt="" src={tcLogo} className="h-16 sm:h-32 lg:h-30 w-auto"></img>
        <div className="p-2">
          <h1 className="text-lg sm:text-xl lg:text-4xl font-semibold ml-4">
            {" "}
            Terms and conditions
          </h1>
        </div>
      </div>

      <div
        style={{
          maxHeight: "400px",
          overflowY: "scroll",
          scrollbarWidth: "thin",
          scrollbarColor: "#00ff00 #f1f1f1",
          marginRight: "20px",
          fontFamily: "poppins",
        }}
      >
        <div className="px-4 ml-8 shadow-gray-950 shadow-sm ">
          <div className="p-4">
            <div className="w-full">
              <div className="px-3 py-2 shadow-md border-l-4 border-green-500 font-poppins font-semibold ">
                <h1 className="text-xl">1. Introduction</h1>
              </div>
              <p className="w-full px-3 py-4 ml-4">
                These Terms and Conditions ("Terms") advocate your access to and
                use of the Helpy Moto platform, including the mobile application
                and website ("Platform"). By accessing or using the platform,
                you agree to follow the terms and conditions of the Helpy Motto.
                If you do not agree to these terms, you will not get access to
                the platform.
              </p>
            </div>
            <div className="w-full">
              <div className="px-3 py-2 shadow-md border-l-4 border-green-500 font-poppins font-semibold">
                <h1 className="text-xl">2. Definitions</h1>
              </div>
              <ul className="w-full px-3 py-4 ml-4 list-disc">
                <li>
                  <div className="mb-4 flex">
                    <h2 className="font-semibold ">
                      <span className=" border-black border-b-2">Users:</span>
                      <span className="font-normal ml-2">
                        Any individual or entity that uses the platform to find
                        and book vehicle services or purchase tools, equipment,
                        and spare parts.
                      </span>
                    </h2>
                  </div>
                </li>
                <li>
                  <div className="mb-4">
                    <h2 className="font-semibold mr-2 ">
                      <span className=" border-black border-b-2">
                        {" "}
                        Mechanic:
                      </span>
                      <span className="font-normal ml-2">
                        Any individual or entity registered on the platform to
                        provide mechanical services.
                      </span>
                    </h2>
                  </div>
                </li>
                <li>
                  <div className="mb-4 flex">
                    <h2 className="font-semibold mr-2 ">
                      <span className=" border-black border-b-2">
                        HelpyStore:
                      </span>
                      <span className="font-normal ml-2">
                        The online store within the platform offers tools,
                        equipment, and spare parts for your service.
                      </span>
                    </h2>
                  </div>
                </li>
                <li>
                  <div className="mb-4 flex">
                    <h2 className="font-semibold mr-2 ">
                      <span className=" border-black border-b-2">
                        {" "}
                        KYC (Know your customer):
                      </span>
                      <span className="font-normal ml-2">
                        is a process to verify user identities for further
                        processing.
                      </span>
                    </h2>
                  </div>
                </li>
                <li>
                  <div className="mb-4 flex">
                    <h2 className="font-semibold mr-2 ">
                      <span className=" border-black border-b-2"> IPC:</span>
                      <span className="font-normal ml-2">
                        {" "}
                        Indian Penal Code—the criminal code of India for
                        accessing crime.
                      </span>
                    </h2>
                  </div>
                </li>

                {/* Add similar structure for other list items */}
              </ul>
            </div>

            {/* 3 */}

            <div className="w-full">
              <div className="px-3 py-2 shadow-md border-l-4 border-green-500 font-poppins font-semibold ">
                <h1 className="text-xl">3. User Terms</h1>
              </div>
              <ol className="w-full px-3 py-4 ml-4 list-decimal">
                <li>
                  <div className="mb-4">
                    <h2 className="font-semibold mr-2 ">Account Creation:</h2>
                    <p>
                      You must be at least 18 years old and legally capable of
                      entering into binding contracts to create an account on
                      the platform. You agree to provide accurate and complete
                      information during registration and to keep your
                      information updated.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="mb-4">
                    <h2 className="font-semibold mr-2 ">Booking Services:</h2>
                    <p>
                      While you book a service, you agree to provide accurate
                      information about your vehicle, the required service, and
                      your location. You are responsible for ensuring the
                      vehicle is legally operable and properly documented.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="mb-4">
                    <h2 className="font-semibold mr-2 ">Payments:</h2>
                    <p>
                      You will agree to pay all charges incurred through the
                      platform, including service fees, parts costs, and
                      delivery charges. Helpy Moto acts as a facilitator for
                      payments and does not guarantee specific pricing by
                      mechanics or shops.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="mb-4">
                    <h2 className="font-semibold mr-2  ">Vehicle Condition:</h2>
                    <p>
                      You declare that Helpy Moto will not be responsible for
                      any pre-existing vehicle issues or damage caused by the
                      mechanic during the service, except for those explicitly
                      covered by Helpy Moto's limited warranty.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="mb-4">
                    <h2 className="font-semibold mr-2 ">Refunds:</h2>
                    <p>
                      Refunds have adhered to Helpy Moto's refund policy, which
                      considers factors like service completion, cancellation
                      reason, and dispute resolution outcomes.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="mb-4">
                    <h2 className="font-semibold mr-2 ">Vehicle Driving:</h2>
                    <p>
                      You will be only responsible for the safe and legal
                      operation of your vehicle. Helpy Moto will not be
                      responsible for any traffic violations or accidents
                      arising from your driving, as it can only help you in the
                      service of a vehicle.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="mb-4">
                    {" "}
                    <h2 className="font-semibold mr-2 ">Towing:</h2>
                    <p>
                      Towing services are subject to separate terms and
                      conditions set by the towing provider. Helpy Moto is not
                      responsible for any issues arising during the towing
                      process.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="mb-4">
                    <h2 className="font-semibold mr-2 ">KYC:</h2>
                    <p>
                      It is a very important process of KYC verification for
                      using certain features or exceeding specific transaction
                      limits.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="mb-4">
                    <h2 className="font-semibold mr-2 ">Governing Law:</h2>
                    <p>These user terms abide by the laws of India.</p>
                  </div>
                </li>
                {/* Add similar structure for other list items */}
              </ol>
            </div>

            {/* 4 */}
            <div className="w-full">
              <div className="px-3 py-2 shadow-md border-l-4 border-green-500 font-poppins font-semibold ">
                <h1 className="text-xl">4. Mechanic Terms</h1>
              </div>
              <ol className="w-full px-3 py-4 ml-4">
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Registration:</h2>
                  <p>
                    You must be eligible and should have the necessary licences
                    and permits to provide mechanical services in India. You
                    agree to provide accurate information about your
                    qualifications, experience, and workshop details.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Service Quality:</h2>
                  <p>
                    You agree to provide professional, competent, and ethical
                    service to all users. You must use genuine parts and adhere
                    to industry standards.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Pricing:</h2>
                  <p>
                    You are responsible for setting your service prices and
                    informing users of any additional charges before starting
                    the service.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2  ">Payment:</h2>
                  <p>
                    You are responsible for collecting payments from users
                    through the platform or directly, complying with Helpy
                    Moto's payment guidelines.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Vehicle Care:</h2>
                  <p>
                    You are responsible for using your customer's vehicles with
                    care and also providing the best quality of work to avoid
                    any inconvenience.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Dispute Resolution:</h2>
                  <p>
                    You agree to cooperate with Helpy Moto in resolving any
                    disputes with users and adhere to Helpy Moto's dispute
                    resolution process.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">KYC:</h2>
                  <p>
                    You may be required to complete KYC verification for using
                    certain features or exceeding specific transaction limits.
                  </p>
                </li>

                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Governing Law:</h2>
                  <p>These mechanical terms abide by the laws of India.</p>
                </li>
                {/* Add similar structure for other list items */}
              </ol>
            </div>

            {/* 5 */}
            <div className="w-full">
              <div className="px-3 py-2 shadow-md border-l-4 border-green-500 font-poppins font-semibold ">
                <h1 className="text-xl">5. Mechanic Shop Terms</h1>
              </div>
              <ol className="w-full px-3 py-4 ml-4">
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Registration:</h2>
                  <p>
                    You must be legally registered and authorised to sell tools,
                    equipment, and spare parts in India. You agree to provide
                    accurate information about your shop, products, and
                    licensing.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Product Quality:</h2>
                  <p>
                    You agree to sell only genuine and brand-approved products
                    that meet relevant quality standards.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Pricing:</h2>
                  <p>
                    Helpy Moto is responsible for setting your product prices
                    and informing users of any additional charges beforehand.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2  ">Payment:</h2>
                  <p>
                    Helpy Moto is responsible for collecting payments from users
                    through the platform or directly, complying with Helpy
                    Moto's payment guidelines.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Product Availability:</h2>
                  <p>
                    It is your responsibility to display accurate product
                    availability information on the platform and fulfil orders
                    promptly.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">warranties:</h2>
                  <p>
                    Helpy Moto and you are responsible for any warranties
                    offered on your products and for adhering to manufacturer
                    guidelines.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Dispute Resolution:</h2>
                  <p>
                    You agree to cooperate with Helpy Moto in resolving any
                    disputes and adhere to Helpy Moto's dispute resolution
                    policy.
                  </p>
                </li>

                {/* Add similar structure for other list items */}
              </ol>
            </div>

            {/* 6 */}

            <div className="w-full">
              <div className="px-3 py-2 shadow-md border-l-4 border-green-500 font-poppins font-semibold ">
                <h1 className="text-xl">6. User Terms</h1>
              </div>
              <ol className="w-full px-3 py-4 ml-4">
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">
                    Helpy Moto Responsibility:
                  </h2>
                  <p>
                    For scheduled services in which vehicle pick-up and drop-off
                    are requested, Helpy Moto will arrange for a qualified
                    driver or mechanic from the selected mechanic shop to
                    operate the user's vehicle.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">
                    Driver/Mechanic Qualifications:
                  </h2>
                  <p>
                    The assigned driver/mechanic must possess a valid driving
                    licence and any necessary permits for operating the specific
                    vehicle type. Helpy Moto will verify these qualifications
                    and conduct background checks on all drivers and mechanics.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Pricing:</h2>
                  <p>
                    Helpy Moto is responsible for setting your product prices
                    and informing users of any additional charges beforehand.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2  ">Pricing and Payment:</h2>
                  <p>
                    Helpy Moto will determine the pricing for the entire
                    journey, including vehicle driving, service fees, and parts
                    costs. Users will pay this consolidated price through the
                    platform. No additional charges or cash transactions are
                    permitted between the mechanic or shop and the user.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">
                    Transparency and Fairness:
                  </h2>
                  <p>
                    Helpy Moto's pricing structure will be transparent and
                    communicated clearly on the platform for each service and
                    subscription plan. The margin between service cost and
                    mechanic/shop payment will remain fixed unless explicitly
                    communicated by Helpy Moto.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">warranties:</h2>
                  <p>
                    Helpy Moto and you are responsible for any warranties
                    offered on your products and for adhering to manufacturer
                    guidelines.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Prohibited Actions:</h2>
                  <p>
                    Mechanics and shops are strictly prohibited from: You agree
                    <ul className="list-disc ml-8">
                      <li>
                        Collecting any additional fees or payments from users on
                        behalf of Helpy Moto.
                      </li>
                      <li>
                        Engaging in any malpractice or misleading practices
                        towards users.
                      </li>
                      <li>
                        Offering unauthorised or personal services outside the
                        scope of the scheduled service.
                      </li>
                      <li>
                        Stealing, damaging, or misusing the user's vehicle.
                      </li>
                    </ul>
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Prohibited Actions:</h2>
                  <h className="from-neutral-900">
                    Any violation of these terms by mechanics or shops may
                    result in disciplinary actions, including:
                  </h>
                  <ul className="list-disc ml-8">
                    <li>
                      Penalties, deductions from future payments, or termination
                      of their agreement with Helpy Moto.
                    </li>
                    <li>
                      Reporting to relevant authorities for legal action in
                      cases of serious offences.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="font-semibold mr-2 ">Payment Process:</h2>
                  <p>
                    All service fees, subscription payments, and on-time service
                    bonuses will be collected by Helpy Moto through the platform
                    (no cash accepted). Payments to mechanics and shops will be
                    deposited directly into their bank accounts within four
                    business days after service completion and the deduction of
                    any penalties or deductions.
                  </p>
                </li>

                {/* Add similar structure for other list items */}
              </ol>
            </div>

            {/* 7 */}

            <div className="w-full">
              <div className="px-3 py-2 shadow-md border-l-4 border-green-500 font-poppins font-semibold ">
                <h1 className="text-xl">7. Introduction</h1>
              </div>
              <p className="py-3 ml-4">
                Welcome to Helpy Moto! These Terms and Conditions ("Terms")
                govern your use of the Helpy Moto platform and services, whether
                you are a user, service provider, product seller, delivery
                partner, collaborator, or payment gateway bank ("you" or
                "User"). Our mission is to foster a collaborative ecosystem
                where everyone benefits. By using our platform, you agree to
                follow our terms and conditions for Helpy Motto.
              </p>
              <ol className="w-full px-3 py-4 ml-4">
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">
                    Third-Party Interference::
                  </h2>
                  <p>
                    Any attempt by a third party to disrupt or harm Helpy Moto,
                    its users, collaborators, or service partners is strictly
                    prohibited. These include actions that cause inconvenience,
                    reputational damage, or financial loss. Any potential
                    amendments to these terms must comply with legal procedures
                    and government regulations to ensure fairness and
                    transparency for all parties. Violations of these terms may
                    result in penalties and compensation for any losses incurred
                    by Helpy Moto or its connected partners.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">
                    Acceptance and Continued Engagement:
                  </h2>
                  <p>
                    By accessing or using any part of the Helpy Moto platform or
                    services, you agree that you have read, understood, and
                    agree to follow every term and condition. It includes all
                    users, service providers, product sellers, delivery
                    partners, collaborators, and payment gateway banks.
                    Continued use of our platform constitutes your ongoing
                    acceptance of these terms.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2 ">Pricing:</h2>
                  <p>
                    Helpy Moto is responsible for setting your product prices
                    and informing users of any additional charges beforehand.
                  </p>
                </li>
                <li className="mb-4">
                  <h2 className="font-semibold mr-2  ">
                    Additional Key Points:
                  </h2>
                  <ul className="list-disc ml-8 mt-4">
                    <li className="mb-4">
                      <h2 className="font-semibold mr-2">
                        <span className="border-b-2 border-black inline-block ">
                          Dispute Resolution:
                        </span>
                        <span className="ml-2 font-normal">
                          We strive to resolve any disputes carefully. However,
                          if an issue arises, these terms outline the dispute
                          resolution process.
                        </span>
                      </h2>
                    </li>
                    <li className="mb-4">
                      <h2 className="font-semibold mr-2">
                        <span className="border-b-2 border-black inline-block ">
                          Limitation of Liability:
                        </span>
                        <span className="ml-2 font-normal">
                          Our liability for any damages is limited as outlined
                          in these Terms.
                        </span>
                      </h2>
                    </li>
                    <li className="mb-4">
                      <h2 className="font-semibold mr-2 ">
                        <span className="border-b-2 border-black inline-block ">
                          Intellectual Property Rights:
                        </span>
                        <span className="ml-2 font-normal">
                          All intellectual property rights associated with the
                          Helpy Moto platform belong to us or our licensors.
                        </span>
                      </h2>
                    </li>
                    <li className="mb-4">
                      <h2 className="font-semibold mr-2">
                        <span className="border-b-2 border-black inline-block ">
                          Data Privacy and Security:{" "}
                        </span>
                        <span className="font-normal ml-2">
                          We take customer data privacy seriously and have
                          measures in place to protect your information. Please
                          refer to our privacy policy for further details.
                        </span>
                      </h2>
                    </li>

                    <li className="mb-4">
                      <h2 className="font-semibold mr-2">
                        <span className="border-b-2 border-black inline-block">
                          Governing Law and Jurisdiction:
                        </span>
                        <span className="ml-2 font-normal">
                          These terms abide by the laws of India, and any
                          disputes will be resolved in the courts of India.
                        </span>
                      </h2>
                    </li>
                  </ul>
                </li>
              </ol>
            </div>

            {/* 8 */}
            <div className="w-full">
              <div className="px-3 py-2 shadow-md border-l-4 border-green-500 font-poppins font-semibold text-xl">
                <h1>5. Mechanic Shop Terms</h1>
              </div>
              <p className="w-full px-3 py-4 ml-4">
                Helpy Moto is committed to building a harmonious and mutually
                beneficial ecosystem and providing a value system for all
                customers. By abiding by these terms, we can ensure a positive
                and productive experience for everyone. For more detailed
                information, please refer to the complete Terms and Conditions
                document available on our website.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
