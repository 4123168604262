import React, { useState, useEffect } from 'react';
import axios from 'axios';
import pusher from '../config/pusherConfig';
import SERVER_URL from '../constants/constant'
// let SERVER_URL = "http://localhost:5000/api/v1"
let BASE_URL = "http://localhost:5000/api/v1";



const SampleMechanic = () => {
  const [mechanicId, setMechanicId] = useState();
  let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWNoYW5pY1VpZCI6IjEwMDA0NSIsInJvbGUiOiJtZWNoYW5pYyIsImlhdCI6MTcxMTExMTE5NiwiZXhwIjoyNTc1MTExMTk2fQ.WjOJjb6l5nnGz8__5rH_cK_JVC3kAynw9HtaW_Q8tGU";
  const [mechanicData, setMechanicData] = useState();
  const [currentHash, setCurrentHash] = useState({
    currentHash: '',
    success: false,
  });

  const [mechanicLocation, setMechanicLocation] = useState([0, 0]);

  const [requests, setRequests] = useState([]);

  const [finalHash, setFinalHash] = useState(null);

  const channel = pusher.subscribe('mechanic-finder');

  const channel2 = pusher.subscribe('ticket-response');


  channel2.bind('success', (data) => {
    console.log(data)
  });


  channel.bind('ticket', (data) => {
    console.log(data)
    // if(data['targetHashes'] !== null && data['targetHashes'].length > 0) {
      // for( var i in data['targetHashes']){
        // if(Number(data['targetHashes'][i]) === Number(mechanicData?.locationHash)){
    setRequests([...requests, data]);
        // }
      // }
    // }
  });

  useEffect(() => {
    setFinalHash(null);
  }, [mechanicData]);

  const changeLocationHash = async (location) => {

    axios
      .post(
        `${SERVER_URL}/mechanic/updateLocationHash`,
        {
          mechanicUid: mechanicId,
          newLocationHash: location,
        }
      )
      .then((response) => {
        response.data.success ? setFinalHash(response.data.newHash) : console.log('Failure');
      });

    setMechanicLocation([0, 0]);
  };

  const handelTemporaryLogin = async (mechanicId) => {
    axios
      .get(
        `${SERVER_URL}/mechanic/100045`,
        {
          mechanicUid: mechanicId,
        }
      )
      .then((resp)=>{
        console.log(resp.data.mechanic)
        setMechanicData(resp.data.mechanic)
      // .then((response) => setMechanicData(response.data.mechanic))

      })
      // .then((response) => setMechanicData(response.data.mechanic))

    console.log('mechs ', mechanicData);
    setRequests([]);
  };


  const subscribeToUserChannel = async (ticketId) => {
    //Unsubscirbe from finding requests
    pusher.unsubscribe('mechanic-finder');
    channel.unbind('active-requests');
    console.log(ticketId);
    axios.post(
      `${SERVER_URL}/ticket/mechanic/updateStatus`, 
      {
      mechanicId: mechanicData._id,
      mechanicUid: mechanicData.mechanicUid,
      ticketId: ticketId,
      status: "accepted",
      trackingLocation: {
        latitude: mechanicData.location.latitude,
        longitude: mechanicData.location.longitude
      },
    },
    {
      withCredentials: true,
      headers:{
        'Authorization':`${token}`
      }
    }
    
    ).then((response) => {
      axios.post(
        `${SERVER_URL}/mechanic/sendConfirmation`, 
        {
        ticketId: ticketId,
        },
        {
          withCredentials: true,
          headers:{
            'Authorization':`${token}`
          }
        }
      ).then((response) => {
        if (response.data?.success) {
          console.log("Acceptance sent");
        }
      })
    })



  }


  return (
    <div className='px-4 py-4 max-w-4xl mx-auto'>
      <p className='text-3xl text-center font-bold'>Temporary mechanic Login</p>
      <div className='flex flex-col space-y-2 md:flex-row lg:flex-row xl:flex-row space-x-3 items-center mt-5'>
        <p className=' font-semibold text-2xl'>Enter Mechanic ID:</p>
        <input
          type='number'
          className='border-[1px] rounded-md border-black bg-gray-100 px-2 py-1'
          onChange={(e) => setMechanicId(e.target.value)}
        />
        <button
          className='bg-blue-500 text-white px-2 py-1 rounded-md font-semibold'
          onClick={() => handelTemporaryLogin(mechanicId)}
        >
          Login
        </button>
      </div>

      {mechanicData != null && (
        <>
          <div className='mt-5'>
            <p className='font-semibold text-lg mb-2 mt-5'>Mechanic Details</p>
            <p>Name: {mechanicData.generalDetails['fullName']}</p>
            <p>Email: {mechanicData.generalDetails['email']}</p>
            <p>Phone No: {mechanicData.generalDetails['phoneNo']}</p>
            <p>Online: {mechanicData.isOnline == true ? 'True' : 'False'}</p>
            <p>
              Your Location Hash :{' '}
              <span className='font-semibold'>
                {finalHash != null ? finalHash : mechanicData.locationHash}
              </span>
            </p>

            <p className='font-semibold text-lg mt-5 mb-2'>Change Hash</p>
            <div className='flex flex-col space-y-2 md:flex-row lg:flex-row xl:flex-row space-x-3 items-center '>
              <p>Enter New Coordinates</p>

              <form action='' className='flex flex-col space-y-2 lg:flex-row xl:flex-row lg:space-x-3 xl:space-x-3'>
                <input
                  type='text'
                  className='border-[1px] rounded-md border-black bg-gray-100 px-2 py-1'
                  value={mechanicLocation[0]}
                  required
                  onChange={(e) =>
                    setMechanicLocation([e.target.value, mechanicLocation[1]])
                  }
                />

                <input
                  type='text'
                  className='border-[1px] rounded-md border-black bg-gray-100 px-2 py-1'
                  value={mechanicLocation[1]}
                  required
                  onChange={(e) =>
                    setMechanicLocation([mechanicLocation[0], e.target.value])
                  }
                />
                <button
                  className='bg-blue-500 text-white px-2 py-1 rounded-md font-semibold'
                  onClick={(e) => {
                    e.preventDefault();
                    changeLocationHash(mechanicLocation);
                  }}
                >
                  Change
                </button>
              </form>
            </div>
          </div>

          <p className='mt-8 font-semibold text-3xl'>Live user requests</p>
          <div className='mt-3 gap-x-2 gap-y-2 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5'>
            {requests.map((request, index) => (
              <div
                className='cols-span-1 w-full flex flex-col shadow-md bg-gray-100 px-2 py-2 rounded-md'
                key={index}
              >
                <img src={request?.ticketId?.customerId?.photo} className='w-[70px] object-contain rounded-full' alt="" />
                <p>
                  <span className='font-semibold'>Name: </span>
                  {request?.ticketId?.customerId?.firstName} {request?.ticketId?.customerId?.lastName}
                </p>
                <p>
                  <span className='font-semibold'>Phone: </span>
                  {request?.ticketId?.customerId?.phoneNo}
                </p>

                <p>
                  <span className='font-semibold'>Issue: </span>
                  {request?.ticket?.description}
                </p>

                <p>
                  <span className='font-semibold'>Vehicle: </span>
                  {request?.ticket?.vehicleData?.vehicleBrand} {request?.ticket?.vehicleData?.vehicleModel} {request?.ticket?.vehicleData?.vehicleFuel} {request?.ticket?.vehicleData?.vehicleNo}
                </p>


                <div className='flex mt-3 justify-between w-full '>
                  <button className='bg-red-500 text-white px-2 py-1 rounded-md font-semibold'>
                    Cancel
                  </button>
                  <button onClick={() => subscribeToUserChannel(request?.ticket?._id)} className='bg-green-500 text-white px-2 py-1 rounded-md font-semibold'>
                    Accept
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SampleMechanic;