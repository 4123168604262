import React, { useEffect, useState, useContext, useRef } from "react";
import Logo from "../assets/Logo.png";
import logoText from "../assets/Logo/image-removebg-preview 1.png";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../context/CartContext";
import { useWishlist } from "../context/WishlistContext";
//import Videos from '../assets/HM_final.mp4';
import axios from "axios";

import SERVER_URL from "../constants/constant";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Badge from "@mui/material/Badge";
import Form from "./Form";
import Cookies from "universal-cookie";
import Avatar from "../assets/userAvatar.png";
import Profile from "./ProfilePage";
import mixpanel from "../config/mixpanel";
import order from "../../src/assets/profile/order.png";
import vehicle from "../../src/assets/profile/vehicle.png";
import notification from "../../src/assets/profile/notification.png";
import setting from "../../src/assets/profile/setting.png";
import terms from "../../src/assets/profile/terms.png";
import help from "../../src/assets/profile/help.png";
import logoutImg from "../../src/assets/profile/logout.png";
import userAdd from "../assets/profile/user-add.png";
import { motion } from "framer-motion";

import { handleLogout } from "../utils/authUtils";
import { Popup } from "react-map-gl";

import CancelIcon from "@mui/icons-material/Cancel"
import OopsIcon from "../assets/addVehicle/Oops.png";
const cookies = new Cookies();

const profileSections = [
  {
    label: "Personal Information",
    to: "/profile/personalInformation",
    image: userAdd,
  },
  { label: "Order History", to: "/profile/order-history", image: order },
  { label: "My Vehicle", to: "/profile/my-vehicle", image: vehicle },
  { label: "Notifications", to: "/profile/notifications", image: notification },
  { label: "Settings", to: "/profile/settings", image: setting },
  {
    label: "Terms & Conditions",
    to: "/profile/terms-conditions",
    image: terms,
  },
  {
    label: "Subscriptions",
    image: help,
    hasDropdown: true,
    dropdownOptions: [
      { label: "Mechanic plan", to: "/profile/SubscriptionPlan" },
      { label: "Driver plan", to: "/profile/subscription/option2" },
      { label: "Cleaner plan", to: "/profile/subscription/option3" },
    ],
  },
  
  { label: "Help & Support", to: "/profile/help-support", image: help },
  
];

const NavbarUpdate = ({ showOnlyNav }) => {
  const [showSubscriptionsDropdown, setShowSubscriptionsDropdown] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  
  const toggleSubscriptionsDropdown = (e) => {
    e.stopPropagation();
    setShowSubscriptionsDropdown((prev) => !prev);
  };

  const dropdownRef = useRef(null);

  const [menu, setMenu] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState();
  const [hamBurgerOptions, setHamburgerOptions] = useState(false);
  //const token = cookies.get("TOKEN");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const { wishlistItems } = useWishlist();

   

  
  
  


  useEffect(() => {
    const tokenVal = localStorage.getItem("TOKEN");
    setToken(tokenVal);
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
  }, [token]);

  useEffect(() => {
    const closeDropdownOnOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", closeDropdownOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeDropdownOnOutsideClick);
    };
  }, [isDropdownOpen]);

  // const logout = async () => {
  //   await handleLogout();
  // };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("TOKEN");
    setToken(null); // Update the token state to null
    window.location.href = "/";
  };


  const toggleDropdown = (e) => {
    e.stopPropagation();

    setDropdownOpen(!isDropdownOpen);
    if (menu) {
      setMenu(!menu);
    }
  };

  const toggleHamburgerOptions = () => {
    setMenu(!menu);
    if (isDropdownOpen) {
      setDropdownOpen(!isDropdownOpen);
    }
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    console.log(e.target.to);

    mixpanel.track("Login Button clicked", {
      referrer: window.location.pathname,
    });

    navigate("/LoginPage");
  };
   



  const handleHireNowClick = (e) => {
    e.preventDefault();
    mixpanel.track("HIRE_NOW");
    navigate("/ontime");
  };
  

  const [showPopup, setShowPopup] = useState(false);
  const modalRef=useRef();
  const handleService = (e) => {
    e.preventDefault();
    
    
  


    const vehicleData = JSON.parse(localStorage.getItem("vehicle-selected"));
    console.log("vehicle data inside the service function", vehicleData);

    if (vehicleData && vehicleData.brand && vehicleData.model && vehicleData.model.fuelType) {
      navigate("/servicepage");
    } else {
      setShowPopup(true);
    }
  };

const closePopup=()=>
{
  setShowPopup(false);
} 




  return (

    <div className="flex w-full items-center z-20 flex-col gap-0 relative">
      {/* Details and Buttons */}
      <div
        className={
          menu
            ? "flex flex-col w-full bg-black md:bg-transparent text-white px-4 py-2"
            : showOnlyNav
            ? "flex flex-col w-full  bg-black text-white px-4 py-2"
            : "flex flex-col w-full bg-gradient-to-b from-black to-transparent  text-white px-4 md:px-3 lg:px-8 xl:px-8 py-2"
        }
      >
        {/* Logo and Slogan */}
        <div className="flex items-center justify-between">
          <div className="flex w-[63%] space-x-3 items-center">
            <div className="flex flex-col lg:flex-row items-center">
              <img
                src={Logo}
                alt="logo"
                className="w-12 h-12 lg:w-20 lg:h-20 sm:w-8 sm:h-8 object-contain"
              />
              <img
                alt=""
                src={logoText}
                className="text-center w-full lg:w-auto sm:w-full mt-1 lg:mt-0 sm:mt-1 max-w-[120px] object-contain"
              />
            </div>

            {/* Rest buttons */}
            <ul className="ml-4 w-full justify-end text-lg items-center hidden md:flex lg:flex xl:flex space-x-8">
              <li className="nav-item relative group">
                <Link
                  className="text-white hover:text-white hover:no-underline text-start relative"
                  to="/"
                >
                  Home
                  <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-green-500 transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </Link>
              </li>



              <li className="nav-item relative group">
                <Link
                  className="text-white hover:text-white hover:no-underline text-start relative"
                  // to="/ServicePage"
                
                >
                  Services
                  <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-green-500 transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </Link>
              </li>



              <li className="nav-item relative group">
                <Link
                  className="text-white hover:text-white hover:no-underline text-start relative"
                  to="https://helpystore.com/" target="_blank"
                >
                  HeplyStore
                  <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-green-500 transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </Link>
              </li>
              <li className="nav-item relative group">
                <Link
                  className="text-white hover:text-white hover:no-underline text-start relative"
                  to="/About"
                >
                  About Us
                  <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-green-500 transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </Link>
              </li>
            </ul>
          </div>

          <div
            className={`flex items-center md:hidden lg:hidden xl:hidden justify-between w-[25%] sm:w-[${
              user !== null ? "15%" : "25%"
            }] mr-3`}
          >
            {/* User Avatar options */}
            <div className="w-[35%] sm:w-[45%]" ref={dropdownRef}>
              {!token || token === null ? (
                <Link
                  to="/LoginPage"
                  className="bg-green-500 md:w-[60%] sm:w-[100%] py-[12%] px-[30%] text-center md:text-base rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold"
                >
                  Login
                </Link>
              ) : (
                <div className="relative flex md:flex justify-end">
                  {/* <Link to={'/ontime'} className='bg-[#0ae395] py-2 px-8 text-lg rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold'>
                    Hire Now
                  </Link> */}
                  <img
                    onClick={toggleDropdown}
                    src={Avatar}
                    alt="profile_photo"
                    className="h-10 w-10 object-contain cursor-pointer rounded-full hover:scale-110 transform transition duration-300 ease-in-out"
                  />

                  {/* <span onClick={toggleDropdown} className="cursor-pointer text-white font-semibold">
                    {user?.firstName}
                  </span> */}

                  {isDropdownOpen && (
                    <div className="w-48 bg-red-500 absolute  bottom-0 right-12 ">
                      <Profile />
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Hamburger */}
            <div className="w-[30%] sm:w-[35%]">
              <div
                className="w-[35px] h-[25px] flex flex-col justify-between cursor-pointer"
                onClick={toggleHamburgerOptions}
              >
                <div className="h-[3px] bg-white w-full"></div>
                <div className="h-[3px] bg-white w-full"></div>
                <div className="h-[3px] bg-white w-full"></div>
              </div>
            </div>
          </div>

          {/* Login */}
          <div
            className={`hidden md:flex items-center md:space-x-8 ${
              user && user !== null ? "w-[20%]" : "w-[25%]"
            } justify-end`}
          >
            {/* <div className='flex space-x-4'>
              <Link to='/wishlist'>
                <Badge badgeContent={wishlistItems && wishlistItems.length} color='error'>
                  <FavoriteIcon className='text-white' fontSize='large' />
                </Badge>
              </Link>
              <Link to='/cart'>
                <Badge badgeContent={cartItems && cartItems.length} color='primary'>
                  <ShoppingCartIcon className='text-[#18f98f]' fontSize='large' />
                </Badge>
              </Link>
            </div> */}
            <Link
              to={"/ontime"}
              onClick={handleHireNowClick}
              className="bg-green-500 w-full md:px-4 py-2 text-center text-sm lg:text-base rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold"
            >
              Hire Now
            </Link>

            {!token || token === null ? (
              <Link
                onClick={handleLoginClick}
                to="/LoginPage"
                className=" bg-green-500 w-full md:w-auto md:px-4 py-2 text-center text-sm lg:text-base rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold"
              >
                {/* <img src={Avatar} alt="profile_photo" className='lg:h-[100%] object-contain cursor-pointer rounded-full hover:scale-110 transform transition duration-300 ease-in-out' /> */}
                {/* <button
                  type='submit'
                  className='bg-green-500 rounded-md px-4 py-2 text-white text-lg hover:scale-110 transform transition duration-300 ease-in-out font-semibold'
                > */}
                Login
                {/* </button> */}
              </Link>
            ) : (
              <div className="relative md:flex md:items-center md:space-x-4 md:w-[21%] lg:w-[18%] justify-between">
                {/* <Link to={'/ontime'} className='bg-[#0ae395] py-2 px-8 text-lg rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold'>
                  Hire Now
                </Link> */}
                <img
                  onClick={toggleDropdown}
                  src={Avatar}
                  alt="profile_photo"
                  className=" object-contain cursor-pointer rounded-full hover:scale-110 transform transition duration-300 ease-in-out"
                />

                {/* <span onClick={toggleDropdown} className="cursor-pointer text-white font-semibold">
                  {user?.firstName}
                </span> */}

                {isDropdownOpen && (
                  <motion.div
                    initial={{ opacity: 0.5 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="origin-top-right absolute right-3 top-12 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="p-2">
                      {profileSections.map((section, index) => (
        <div key={index}>
          {section.hasDropdown ? (
            <div className="relative w-full">
              <button
                 onClick={(e) => {
                  e.stopPropagation();
                  toggleSubscriptionsDropdown(e);
                }}
                className="flex items-center justify-between w-full  font-semibold px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:rounded"
              >
                <div className="flex items-center gap-x-2">
                
                
                <div className="bg-green-100 rounded-[50%] w-8 h-8 flex justify-center items-center">
                  <img src={section.image} alt="" className="w-5 h-5" />
                </div>
                {section.label}
                </div>
                
                <svg
              className={`w-4 h-4 transition-transform transform ${
                showSubscriptionsDropdown ? "rotate-180" : ""
              }`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
              </button>
              {showSubscriptionsDropdown && (
                <div className="mt-2 pl-6 relative left-0 w-full">
                  <ul className="list-disc pl-10">
                    {section.dropdownOptions.map((option, idx) => (
                      <li key={idx} className="text-gray-700 hover:bg-gray-100 hover:rounded px-2 py-1 cursor-pointer"
                      style={{ paddingLeft: '2px' }}
                      >
                        <Link
                          to={option.to}
                          className="block text-sm text-gray-700 hover:bg-gray-100 hover:rounded"
                          style={{ padding: '4px 0', margin: '2px 0' }}
                        >
                          {option.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            // Render other sections without dropdown
            <Link
              to={section.to}
              className={`flex items-center gap-x-2 font-semibold px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:rounded ${
                index === profileSections.length - 1 && "mb-4"
              }`}
            >
              <div className="bg-green-100 rounded-[50%] w-8 h-8 flex justify-center items-center">
                <img src={section.image} alt="" className="w-5 h-5" />
              </div>
              {section.label}
            </Link>
          )}
        </div>
      ))}

                        <div className="border-t-2 border-dotted pt-1.5">
                          <div
                            onClick={() => logout()}
                            className="flex items-center pl-4 gap-x-[11px] px-2 py-3.5 font-semibold cursor-pointer text-sm text-red-600 hover:bg-red-100 hover:rounded"
                          >
                            <img src={logoutImg} alt="" className="w-5 h-5" />
                            Sign Out
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Menu */}

        <div
          className={
            menu
              ? "md:hidden z-40  duration-1000 ease-in-out transform transition"
              : " z-40 h-[0px] hidden duration-100000 ease-in-out transform transition"
          }
        >
          <div className="flex w-full pb-1 justify-end">
            <ul className=" text-lg items-end flex flex-col ">
              <li className="nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-green-400 after:h-[2px] -bottom-[2px] w-[85px] text-end ">
                <Link
                  className="text-white hover:text-white hover:no-underline text-end"
                  to="/"
                >
                  Home
                </Link>
              </li>












              <li className="nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-green-400 after:h-[2px] -bottom-[2px] w-[85px] text-end">
                <Link
                  className="text-white hover:text-white hover:no-underline text-end"
                  // to="/ServicePage"
                  onClick={handleService}
                >
                  Services
                </Link>
              </li>


   
 
 {showPopup && (
  <div
    style={{ background: "#212121" }}
    className="w-full h-screen fixed top-0 right-0 left-0 grid place-items-center z-50"
    onClick={(e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setShowPopup(false);
      }
    }}
  >
    <div
      className="bg-white w-full sm:w-3/4 lg:w-1/3 py-2 px-4 rounded flex flex-col items-center"
      ref={modalRef}
      style={{ maxWidth: '500px', boxSizing: 'border-box' }}
    >
      <div className="flex justify-between w-full">
        <CancelIcon style={{ cursor: "pointer", color: "black" }} onClick={closePopup} />
        <span className="text-lg font-bold mx-auto text-black">My vehicle</span>
      </div>
      <div className="flex text-center flex-col items-center justify-center">
        <img alt="" src={OopsIcon} className="mt-4" />
        <p className="my-3 text-black">
          No result for your old vehicle. Add a new vehicle and explore
          compatible parts!
        </p>
      </div>
      <div className="flex md:flex-row flex-col items-center justify-center">
        <button
          // onClick={openAddVehicleModal}
          style={{ background: "#22C55D", color: "#fff" }}
          className="py-2 px-4 rounded md:my-0 my-2"
        >
          Add new vehicle
        </button>
      </div>
    </div>
  </div>
)}
{/* {showPopup && (
  <div
    style={{ background: "#212121" }}
    className="w-full h-screen fixed top-0 right-0 left-0 grid place-items-center z-50"
    
  >
    <div
      className="bg-white w-full sm:w-3/4 lg:w-1/3 py-2 px-4 rounded flex flex-col items-center"
      ref={modalRef}
      style={{ maxWidth: '500px', boxSizing: 'border-box' }}
    >
      <div className="flex justify-between w-full">
        <CancelIcon style={{ cursor: "pointer", color: "black" }} onClick={closePopup} />
        <span className="text-lg font-bold mx-auto text-black">My vehicle</span>
      </div>
      <div className="flex text-center flex-col items-center justify-center">
        <img alt="" src={OopsIcon} className="mt-4" />
        <p className="my-3 text-black">
          No result for your old vehicle. Add a new vehicle and explore
          compatible parts!
        </p>
      </div>
      <div className="flex md:flex-row flex-col items-center justify-center">
        <button
          // onClick={openAddVehicleModal}
          style={{ background: "#22C55D", color: "#fff" }}
          className="py-2 px-4 rounded md:my-0 my-2"
        >
          Add new vehicle
        </button>
      </div>
    </div>
  </div>
)} */}




















              <li className="nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-green-400 after:h-[2px] -bottom-[2px] w-[85px] text-end">
                <Link
                  className="text-white hover:text-white hover:no-underline text-end"
                  to="/" 
                >
                  HelpyStore
                </Link>
              </li>

              
              <li className="nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-green-400 after:h-[2px] -bottom-[2px] w-[85px] text-end">
                <Link
                  className="text-white hover:text-white hover:no-underline text-end"
                  to="/about"
                >
                  About Us
                </Link>
              </li>

              {/* <li className='mt-2'>
              <Link className='ml-2' to='/cart'>
                <Badge badgeContent={cartItems && cartItems.length} color='primary'>
                  <ShoppingCartIcon className='text-[#18f98f]' fontSize='large' />
                </Badge>
              </Link>
              <Link className='ml-2' to='/wishlist'>
                <Badge badgeContent={wishlistItems && wishlistItems.length} color='error'>
                  <FavoriteIcon className='text-red-500' fontSize='large' />
                </Badge>
              </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      {/* Video */}
      {!showOnlyNav && (
        <div className="flex w-full -z-40">
          <div className="flex w-full aspect-video  -mt-28 relative top-0 left-0">
            {!loaded && (
              <div className="aspect-video bg-black w-screen flex justify-center items-center">
                <div className="animate-ping bg-gray-800 h-[50px] rounded-full w-[50px]"></div>
              </div>
            )}
            <video
              autoPlay
              muted
              preload="auto"
              loop={true}
              onLoadedData={() => setLoaded(true)}
            >
              <source src="HM_final.mp4" type="video/mp4" />
            </video>
            {/* <div className='absolute w-full justify-center flex  text-white md:hidden lg:hidden xl:hidden bottom-5 left-0 right-0 '>
          <div className='w-fit md:hidden lg:hidden xl:hidden bg-red-500 px-5 py-2 rounded-md font-semibold cursor-pointer'>
            Hire A Mechanic!
          </div>
        </div> */}

            {/* Form */}
            <div className="absolute left-1/2 -translate-x-1/2 top-full transform -translate-y-1/2 hidden md:block lg:block lg:mt-6 w-3/4">
              <Form />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarUpdate;
