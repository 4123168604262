import React, { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import SERVER_URL from "../constants/constant";
import "swiper/css";
import "swiper/css/effect-coverflow";
//import 'swiper/css/pagination';
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "./pp.css";
import toast from "react-hot-toast";

function Popularpairing() {
  const [servicesData, setServicesData] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [cartAdded, setCartAdded] = useState({});
  const [error, setError] = useState(null);
  const serviceId = "6561ce70addec79fbae4dd04";

  useEffect(() => {
    // axios.get(`${SERVER_URL}/service/popularPairingServices?serviceId=6561ce70addec79fbae4dd04`)
    axios
      .get(`${SERVER_URL}/service/getTopServices`)
      .then((response) => {
        const { success, message, dummyData: services } = response.data;
        console.log("dummyData that we are getting is ", services);
        if (success) {
          if (services && services.length > 0) {
            setServicesData(services);
          } else {
            console.error("No services found in the response.");
            setError("No services found in the response.");
          }
        } else {
          console.error("Error while fetching data:", message);
          setError(message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again later.");
      });
  }, [serviceId]);

  const [serviceHoveredIndex, setServiceHoveredIndex] = useState(-1);

  const toggleServiceHover = (hovered, index) => {
    if (hovered) {
      setServiceHoveredIndex(index);
    } else {
      setServiceHoveredIndex(-1);
    }
  };

  const toggleServiceName = (index) => {
    if (index === clickedIndex) {
      setClickedIndex(-1);
    } else {
      setClickedIndex(index);
    }
    const updatedServicesData = [...servicesData];
    updatedServicesData[index].showFull = !updatedServicesData[index].showFull;
    setServicesData(updatedServicesData);
  };

  if (!servicesData || servicesData.length === 0) {
    return <div>No services available</div>;
  }

  const handleAddToCart = (id, modelId) => {
    console.log("Service ID:", id);
    console.log("Model ID:", modelId);
    const token = localStorage.getItem("TOKEN");
    console.log("TOKEN:", token);
    axios
    .post(
      `${SERVER_URL}/user/cart/add`,
      { 
        serviceId: id, 
        modelId: modelId 
      },
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {

        
        console.log("Full Response:", response);

        if (
          (response.data.success === true) &&
         (response.data.message === "successfully added to cart")
        ) {
          toast.success("Service added successfully to cart");
        } 

        // else if (response.data.success === true) {
        //   toast.error(response.data.message);
        // }

         else {
          // toast.error("Failed to add Service to cart");
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding Service to cart:", error);
        toast.error("Failed to add Service to cart");
      });
  };

  return (
    <div className=" mx-auto">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        spaceBetween={70}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={{ clickable: true }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper"
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        {servicesData.map((service, index) => (
          <SwiperSlide key={index}>
            <div
              className="card bg-white rounded-lg mb-4 p-4 border border-black"
              onMouseEnter={() => toggleServiceHover(true, index)}
              onMouseLeave={() => toggleServiceHover(false, index)}
            >
              <img
                src="/cartcarousel.png"
                alt={`slide_image_${index}`}
                className="w-full h-48 object-cover rounded-md mb-4"
              />
              <p
                className="text-xl text-start text-black mb-2"
                style={{
                  whiteSpace:
                    serviceHoveredIndex === index ? "normal" : "nowrap",
                  overflow: "hidden",
                  textOverflow:
                    serviceHoveredIndex === index ? "unset" : "ellipsis",
                  cursor: "pointer",
                }}
                onClick={() => toggleServiceName(index)}
              >
                {serviceHoveredIndex === index || clickedIndex === index
                  ? service.name
                  : service.name.length > 22
                  ? `${service.name.slice(0, 22)}...`
                  : service.name}
              </p>
              <p className="text-xl text-start text-black mb-2">
                ₹ {service.price}
              </p>
              <button
                className="w-full p-2 bg-green-500 text-white text-md rounded-md hover:bg-green-600 transition-colors duration-300"
                onClick={() => handleAddToCart(service._id, service.modelId)}
              >
                Add to Cart
              </button>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev slider-arrow">
          <ion-icon name="arrow-back-outline"></ion-icon>
        </div>
        <div className="swiper-button-next slider-arrow">
          <ion-icon name="arrow-forward-outline"></ion-icon>
        </div>
      </Swiper>
    </div>
  );
}

export default Popularpairing;
