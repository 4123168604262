
import { useMap , useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

const Trackdirections = ({Route})=>{

    const map = useMap();
    const routesLibrary = useMapsLibrary('routes');

    const [directionsService,setDirectionsService] = useState();
    const [directionsRenderer,setDirectionsRenderer] = useState();
    const [routes,setRoutes] = useState([]);

    const origin = 'JM9H+V25, Devi Camp, Karnataka 583229, India';
    const destination = 'Katapur, Karnataka 583229, India';

    useEffect(()=>{
        if(!map || !routesLibrary){
            console.log("Unable to load maps and routes Library :(");
            return;
        }   

        console.log("Maps and routes library loaded successfully :)");
        console.log(routesLibrary);

        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));

    },[map,routesLibrary]);

    useEffect(()=>{
        if(!directionsService || !directionsRenderer){
            console.log("Unable to set Direction service || Directions Renderer :(");
            return;
        }

        console.log("Directions Service and Direction Renderer Loaded successfully :)",directionsService);

        directionsService.route({
            origin:Route.origin,
            destination: Route.destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
            // provideRouteAlternatives: true
        })
        .then((response)=>{
            console.log("Routes are ",response);
            directionsRenderer.setDirections(response);
        })
        .catch((err)=>{
            console.log("Something went wrong while fetching routes :(",err);
        })

    },[directionsService,directionsRenderer,Route])

    return null;
}

export default Trackdirections;


// import { useEffect, useState } from "react";

// const Trackdirections = ({ Route }) => {
//     const [directionsService, setDirectionsService] = useState();
//     const [directionsRenderer, setDirectionsRenderer] = useState();
//     const [distance, setDistance] = useState(0);
//     const [travelTime, setTravelTime] = useState(""); 

//     useEffect(() => {
//         if (!window.google || !window.google.maps) return;

//         const directionsService = new window.google.maps.DirectionsService();
//         const directionsRenderer = new window.google.maps.DirectionsRenderer();

//         setDirectionsService(directionsService);
//         setDirectionsRenderer(directionsRenderer);
//     }, []);

//     useEffect(() => {
//         if (!directionsService || !directionsRenderer) return;

//         directionsService.route(
//             {
//                 origin: Route.origin,
//                 destination: Route.destination,
//                 travelMode: window.google.maps.TravelMode.DRIVING,
//             },
//             (response, status) => {
//                 if (status === "OK") {
//                     directionsRenderer.setDirections(response);
//                     const route = response.routes[0];
//                     const leg = route.legs[0];
//                     setDistance(leg.distance.value); 
//                     console.log(distance)// Distance in meters
//                     setTravelTime(leg.duration.text); // Textual representation of travel time
//                     console.log(travelTime)
//                 } else {
//                     console.error("Directions request failed due to " + status);
//                 }
//             }
//         );
//     }, [directionsService, directionsRenderer, Route]);

//     return (
//         <div>
//             <p>Distance: {distance / 1000} km</p>
//             <p>Estimated Travel Time: {travelTime}</p>
//         </div>
//     );
// };

// export default Trackdirections;
