import React from 'react'
import { BsThreeDotsVertical } from "react-icons/bs";


const ChatHeader = ({name,profileImg,orderId}) => {
  return (
    <div className='bg-white pb-2 w-full mb-4 md:px-4 flex justify-between align-center border-b-[0.76px] border-b-[#DFDFDF]'>
        <div className='flex justify-start align-center '>
      <div className='relative '>
        <img src={profileImg} alt='Profile Image' className='w-12 h-12'/>
        <div className='w-3 h-3 bg-[#22C55D] rounded-lg absolute bottom-0 right-0'></div>
      </div>
      <div className='flex flex-col align-center justify-center ml-4'>
        <p className='text-[#515151]'>{name}</p>
        <p className='text-[#22C55D]'>Order ID:{orderId}</p>
      </div>
      </div>
      <div className='flex items-center '>
      <BsThreeDotsVertical className='text-2xl text-[#A0A0A0]'/>
      </div>
    </div>
  )
}

export default ChatHeader
