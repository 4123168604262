import React, { useState, useEffect, useRef, useContext } from 'react';
import useService from '../hooks/useService';
import servicesData from '../data/Data';
// import 'mapbox-gl/dist/mapbox-gl.css';
// import mapboxgl, { Marker } from 'mapbox-gl';
import * as turf from '@turf/turf';
import NavbarUpdate from '../components/NavbarUpdate';
import ProviderInfo from '../components/ProviderInfo';
import Footer from '../components/Footer';
import LoadingPage from '../components/LoadingPage';
import Logo from '../assets/Logo.png';
import { Link, useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
//import MapboxDirections from '@mapbox/mapbox-gl-directions';
// import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
// import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { LocationItemState, LocationData } from '../context/LocationContext';
import SERVER_URL from '../constants/constant'


import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Service_Screen() {
  const { id } = useParams();

  const { locationdispatch, locationstate } = useContext(LocationData);

  const {
    searching,
    setSearching,
    setProviderDetails,
    setProviderLocation,
    setTicket,
    ticket,
    providerDetails,
    otp,
    setOTP,
    otpVerified,
    setOtpVerified,
    handleSearching,
    setLocation,
    userLocation,
    providerLocation,
    locationHash,
    mechanicsInSegment,
    searchLocations,
    update
  } = useService();

  const [screenSize, setScreenSize] = useState('');
  const [eta, setEta] = useState('');
  const [from, setFrom] = useState([0, 0]);

  const [loading, setLoading] = useState(true);

  var fromCoordinates = [0, 0];

  useEffect(() => {
    if (providerLocation[0] !== undefined && providerLocation[0] !== 0) {
      fromCoordinates = [
        parseFloat(providerLocation[1]),
        parseFloat(providerLocation[0]),
      ];
      changeFrom(fromCoordinates);
    }
  }, [providerLocation, searching]);

  useEffect(() => {
    setLoading(true);
    const getTicketDetails = async (ticket_id) => {
      await axios
        .get(
          `${SERVER_URL}/ticket/mechanic/${ticket_id}`
        )
        .then((response) => {
          console.log("HI",response);
          console.log(response.data?.ticket?.customerId?._id)
          console.log(JSON.parse(localStorage.getItem("user"))?._id)
          if (response.data?.success && response.data?.ticket?.userId?._id === JSON.parse(localStorage.getItem("user"))?._id) setTicket(response.data?.ticket);
          // else window.location.href = '/';
        });
    };

    getTicketDetails(id);
  }, [update]);

  useEffect(() => {
    if (ticket && loading) {
      if (ticket?.status === 'pending') {
        console.log('resetting');
        setSearching(true);
      } else {
        setOTP(ticket?.onTimeOTP);
        setProviderDetails({
          _id: ticket?.mechanicId?._id,
          name: ticket?.mechanicId?.generalDetails?.fullName,
          rating: 4,
          workshopName: 'Test Workshop',
          photo: ticket?.mechanicId?.generalDetails?.photo,
        });

        setProviderLocation([
          ticket?.trackingLocation?.latitude,
          ticket?.trackingLocation?.longitude,
        ]);

        setSearching(false);
      }

      setLoading(false);
    }
  }, [ticket]);

  const changeFrom = (fromCoordinates) => {
    setFrom(fromCoordinates);
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 768) {
      setScreenSize('Mobile');
    } else if (width >= 768 && width < 1024) {
      setScreenSize('Tablet');
    } else {
      setScreenSize('Desktop');
    }
  };

  useEffect(() => {
    setLocation([
      parseFloat(locationstate.location.latitude).toFixed(5),
      parseFloat(locationstate.location.longitude).toFixed(5),
    ]);

    handleResize(); // Set initial screen size on component mount

    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener on component unmount
    };
  }, [locationstate]);

  const [map, setMap] = useState();

  // React ref to store a reference to the DOM node that will be used
  // as a required parameter `container` when initializing the mapbox-gl
  // will contain `null` by default
  const mapNode = useRef(null);

  //providing value to hook
  const toCoordinates = [
    locationstate?.location?.longitude,
    locationstate?.location?.latitude,
  ];

  // useEffect(() => {
  //   const node = mapNode.current;
  //   // if the window object is not found, that means
  //   // the component is rendered on the server
  //   // or the dom node is not initialized, then return early
  //   if (typeof window === 'undefined' || node === null) return;

  //   // otherwise, create a map instance
  //   var mapboxMap = new mapboxgl.Map({
  //     container: node,
  //     accessToken:
  //       'pk.eyJ1IjoiaGVscHltb3RvIiwiYSI6ImNsamNscHVuejAyOXAzZG1vNXppYnM1NzkifQ.BB9fpPJb9eDpRJkWwkRHXA',
  //     style: 'mapbox://styles/mapbox/streets-v11',
  //     center: [
  //       from[0] + from[0] - toCoordinates[0],
  //       from[1] + from[1] - toCoordinates[1],
  //     ],
  //     zoom: 16,
  //   });

  //   mapboxMap &&
  //     mapboxMap.easeTo({
  //       padding: {
  //         top: 0,
  //         left: 0,
  //         right:
  //           screenSize == 'Mobile' ? 0 : screenSize == 'Tablet' ? 400 : 500,
  //         bottom: 0,
  //       },
  //       duration: 1000,
  //     });

  //   mapboxMap &&
  //     mapboxMap.on('load', () => {
  //       var markers = [];

  //       for (let i = 0; i < searchLocations.length; i++) {
  //         const marker = new mapboxgl.Marker({
  //           anchor: 'center',
  //           scale: 1,
  //           color: '#f3f3f3',
  //           draggable: false,
  //         }).setLngLat([
  //           searchLocations[i].longitude,
  //           searchLocations[i].latitude,
  //         ]);

  //         markers.push(marker); // Add each marker to the markers array
  //       }

  //       // Add all the markers to the map in one go
  //       markers.forEach((marker) => marker.addTo(mapboxMap));

  //       new mapboxgl.Marker({
  //         anchor: 'center',
  //         scale: 1.2,
  //         color: '#18f98f',
  //         draggable: false,
  //       })
  //         .setLngLat(from)
  //         .addTo(mapboxMap);

  //       new mapboxgl.Marker({
  //         anchor: 'center',
  //         scale: 1.2,
  //         color: '#1c5fcce0',
  //         draggable: false,
  //       })
  //         .setLngLat(toCoordinates)
  //         .addTo(mapboxMap);

  //       // Initialize the Mapbox Directions control
  //       // const directions = new MapboxDirections({
  //       //   accessToken:
  //       //     'pk.eyJ1IjoiaGVscHltb3RvIiwiYSI6ImNsamNscHVuejAyOXAzZG1vNXppYnM1NzkifQ.BB9fpPJb9eDpRJkWwkRHXA',
  //       //   unit: 'metric',
  //       //   profile: 'mapbox/driving',
  //       //   controls: false,
  //       //   alternatives: false,
  //       //   interactive: false,
  //       // });

  //       // Add the Directions control to the map
  //       mapboxMap.addControl(directions);

  //       // Get ETA between the two points
  //       directions.setOrigin(from);
  //       directions.setDestination(toCoordinates);

  //       // Event listener for the 'route' event fired when the route is updated
  //       directions.on('route', (event) => {
  //         const route = event.route[0];
  //         if (route) {
  //           const { duration } = route;
  //           const estimatedTimeOfArrival = duration / 60; // Convert to minutes
  //           console.log('ETA:', estimatedTimeOfArrival);
  //           setEta(estimatedTimeOfArrival.toFixed(2));
  //         }
  //       });

  //       const midpoint = turf.midpoint(from, toCoordinates);
  //       const distance = turf.distance(from, toCoordinates, {
  //         units: 'miles',
  //       });
  //       const bearing = turf.bearing(from, toCoordinates);
  //       const leftSideArc =
  //         bearing + 90 > 180 ? -180 + (bearing + 90 - 180) : bearing + 90;
  //       const destination = turf.destination(
  //         midpoint,
  //         distance / 7,
  //         leftSideArc,
  //         { units: 'miles' }
  //       );

  //       const curvedLine = turf.bezierSpline(
  //         turf.lineString([
  //           from,
  //           destination.geometry.coordinates,
  //           toCoordinates,
  //         ])
  //       );

  //       // Add the line source and layer to the map
  //       mapboxMap.addSource('route', {
  //         type: 'geojson',
  //         data: curvedLine,
  //       });

  //       mapboxMap.addLayer({
  //         id: 'route',
  //         type: 'line',
  //         source: 'route',
  //         layout: {
  //           'line-join': 'round',
  //           'line-cap': 'round',
  //         },
  //         paint: {
  //           'line-color': '#000000',
  //           'line-width': 3,
  //         },
  //       });

  //       const lineCoordinates = [from, toCoordinates];
  //       const bounds = lineCoordinates.reduce(
  //         (bounds, coord) => bounds.extend(coord),
  //         new mapboxgl.LngLatBounds()
  //       );
  //       mapboxMap.fitBounds(bounds, {
  //         padding: {
  //           top: screenSize == 'Mobile' ? 60 : 120,
  //           bottom: screenSize == 'Mobile' ? 60 : 120,
  //         },
  //       });
  //     });

  //   // save the map object to React.useState
  //   setMap(mapboxMap);

    /* const timeout = setTimeout(() => {
      navigate('/order'); // Navigate to "/order" route
    }, 5000); */

  //   return () => {
  //     //clearTimeout(timeout);
  //     mapboxMap.remove();
  //   };
  // }, [searching, from]);

  return (
    <div>
      <NavbarUpdate showOnlyNav={true} />

      {!loading ? (
        searching ? (
          <div className='mt-20'>
            <LoadingPage />
            <p className='text-center text-xl font-semibold mt-5 px-3'>
              Your Location Hash is {locationHash}
            </p>

            <p className='text-center text-md'>
              {mechanicsInSegment.length} mechanic
              {mechanicsInSegment.length > 1 ? 's' : ''} found near you!
            </p>
          </div>
        ) : (
          <div className='relative'>
            <div
              ref={mapNode}
              style={{
                height: screenSize == 'Mobile' ? '60svh' : '100svh',
                width: '100%',
              }}
              className=' flex flex-col overflow-hidden  top-0 left-0 relative'
            >
              <ProviderInfo
                providerData={providerDetails}
                otp={otp}
                eta={eta}
                screen='large'
              />
            </div>
            <ProviderInfo
              providerData={providerDetails}
              otp={otp}
              eta={eta}
              screen='small'
            />
            <Footer />
          </div>
        )
      ) : (
        <div className='fixed top-1/2 left-1/2 -ml-[50px] -mt-[30px]'>
          <div className='bg-green-400 animate-ping w-[100px] h-[100px] rounded-full'></div>
        </div>
      )}
    </div>
  );
}

export default Service_Screen;
