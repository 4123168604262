import React, { useEffect, useState } from "react";
import { Route, Navigate, Outlet, useParams, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

// receives component and any other props represented by ...rest
export default function ProtectedServiceRoutes() {
    //const token = cookies.get("TOKEN");

    const token = localStorage.getItem("TOKEN");



    if (token && token!==null) {
        return <Outlet />;
      } else {
        // returns the user to the landing page if there is no valid token set
        return (
          <Navigate
            to={{
              pathname: "/LoginPage",             
            }}
          />
        );
      }
  
}