export const initialstate = {
    location:{
        latitude:0,
        longitude:0
    }
}

export const LocationReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_LOCATION":
            const item = action.payload;

            
            return{
                ...state,
                location: {latitude : item.latitude, longitude : item.longitude}
            }
      

    
        default:
            return state;
    }
  };